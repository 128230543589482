import React, { useState } from "react";
import { Button, Row, Col, Modal, InputGroup, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { uploadImagesToS3 } from "bucket/s3";
import { Spinner } from "react-bootstrap";
import { message } from "antd";
import { useCreateDriverMutation } from "services/drivers";

function AddDriverModal({ show, handleClose, setPageNumber,count }) {
  let active = Math.floor(((count - 1) / 10) + 1);
  const [createDriver, responseInfo, status] = useCreateDriverMutation();
  const [loading, setLoading] = useState(false);
  const [avatar, setAvatar] = useState(
    require("assets/img/upload.png").default
  );
  const [password, setPassword] = useState(true);
  const [licenseFontText, setLicenseFontText] = useState(
    "Upload Driving License - Front"
  );
  const [licenseBackText, setLicenseBackText] = useState(
    "Upload Driving License - Back"
  );
  const [insuranceText, setInsuranceText] = useState("Upload Insurance");
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
  } = useForm({ mode: "all", reValidateMode: "onChange" });
  const onSubmit = async (data) => {
    setLoading(true);
    let formData = {
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      phone: data.phone,
      password: data.password,
      image: null,
      license_front: null,
      license_back: null,
      insurance: null,
    };
    try {
      if (data.image.length > 0) {
        const imageUrl = await uploadImagesToS3(data.image);
        formData.image = imageUrl[0].location;
        ///  message.success("Driver Image Uploaded Successfully");
      }
      if (data.license_front.length > 0) {
        const licenseFrontUrl = await uploadImagesToS3(data.license_front);
        formData.license_front = licenseFrontUrl[0].location;
        /// message.success("Driver License Front Uploaded Successfully");
      }
      if (data.license_back.length > 0) {
        const licenseBackUrl = await uploadImagesToS3(data.license_back);
        formData.license_back = licenseBackUrl[0].location;
        /// message.success("Driver License Back Uploaded Successfully");
      }
      if (data.insurance.length > 0) {
        const insuranceUrl = await uploadImagesToS3(data.insurance);
        formData.insurance = insuranceUrl[0].location;
        /// message.success("Driver Insurance Uploaded Successfully");
      }
      const response = await createDriver(formData);
      if (response.error) {
        message.error(response.error.data.message);
      }

      if (response.data.message === "Created") {
        setLoading(false);
        message.success("Driver Created Successfully");
        handleClose();
        setPageNumber(0);
        setAvatar(require("assets/img/upload.png").default);
        setLicenseBackText("Upload Driving License - Back");
        setLicenseFontText("Upload Driving License - Front");
        setInsuranceText("Upload Insurance");
        reset();
      } else {
        setLoading(false);
        console.log(response.data.message);
        message.error(response.data.message);
      }
    } catch (error) {
      message.error("Something went wrong in file upload try again");
      setLoading(false);
    }
  };
  return (
    <Modal className="right-modal" show={show} onHide={handleClose}>
      <Modal.Header closeButton className="border-bottom pb-3">
        <Modal.Title className="m-0 font-weight-bold">Add Driver</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit(onSubmit)}>
          <label className="addphoto dotted_border mb-3 mr-4" htmlFor="image">
            <img
              className="rounded img-fluid cursor-p"
              alt="..."
              src={avatar}
            ></img>
          </label>
          <input
            accept="image/png, image/gif, image/jpeg"
            type="file"
            id="image"
            className="opacity-0"
            {...register("image", { required: false })}
            onChange={(e) => {
              if (e.target.files[0]) {
                setAvatar(URL.createObjectURL(e.target.files[0]));
              }
            }}
          />
          <div className="text-center mb-4">
            <h5 className="font-weight-bold">Upload Photo</h5>
          </div>
          {errors.driver_avatar && (
            <label className="text-danger">Driver Photo is required</label>
          )}
          <Row>
            <Col lg="6">
              <div className="mb-3">
                <label className="font-weight-bold">First Name</label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="First Name"
                  {...register("first_name", { required: true })}
                />
                {errors.first_name && (
                  <label className="text-danger">First Name is required</label>
                )}
              </div>
            </Col>
            <Col lg="6">
              <div className="mb-3">
                <label className="font-weight-bold">Last Name</label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Last Name"
                  {...register("last_name", { required: true })}
                />
                {errors.last_name && (
                  <label className="text-danger">Last Name is required</label>
                )}
              </div>
            </Col>
          </Row>
          <div className="mb-3">
            <label className="font-weight-bold">Email</label>
            <input
              className="form-control"
              type="text"
              placeholder="Email Address"
              autoComplete="new-password"
              {...register("email", { required: true, pattern: /^\S+@\S+$/i })}
            />
            {errors.email && errors.email.type === "required" && (
              <label className="text-danger">Email is required</label>
            )}
            {errors.email && errors.email.type === "pattern" && (
              <label className="text-danger">Email is invalid</label>
            )}
          </div>
          <div className="mb-3">
            <label className="font-weight-bold">Phone</label>
            <input
              className="form-control"
              type="text"
              placeholder="Phone"
              {...register("phone", { required: true, pattern: /^\d{10}$/ })}
            />
            {errors.phone && errors.phone.type === "required" && (
              <label className="text-danger">Phone is required</label>
            )}
            {errors.phone && errors.phone.type === "pattern" && (
              <label className="text-danger">Phone is invalid</label>
            )}
          </div>
          <div className="mb-3" controlId="formBasicEmail">
            <label className="font-weight-bold">Password</label>
            <div className="position-relative">
              <input
                type={password ? "password" : "text"}
                className="form-control"
                {...register("password", { required: true })}
                autoComplete="new-password"
                placeholder="Password"
                minLength="6"
                maxLength="50"
                />
              <div
                onClick={() => setPassword(!password)}
                className="show_false btn"
              >
                {password ? <EyeInvisibleOutlined /> : <EyeTwoTone />}
              </div>
            </div>
            {errors.password && (
              <label className="text-danger">Password is required</label>
            )}
          </div>

          <div className="mb-3">
            <label className="font-weight-bold">Driving License - Front </label>
            <input
              type="file"
              accept="image/png, image/gif, image/jpeg"
              id="license_front"
              className="opacity-0 w-0 h-0"
              {...register("license_front", { required: false })}
              onChange={(e) => {
                setLicenseFontText(e?.target?.files[0]?.name || "Upload");
              }}
            />
            <InputGroup>
              <input
                className="form-control"
                type="text"
                placeholder={licenseFontText}
                disabled
              />
              <label
                className="btn btn-black rounded-0 rounded-right m-0 d-flex align-items-center "
                htmlFor="license_front"
              >
                Browse
              </label>
            </InputGroup>
            {errors.license_front && (
              <label className="text-danger">Front is required</label>
            )}
          </div>

          <div className="mb-3">
            <label className="font-weight-bold">Driving License - Back </label>
            <input
              type="file"
              accept="image/png, image/gif, image/jpeg"
              id="license_back"
              className="opacity-0 w-0 h-0"
              {...register("license_back", { required: false })}
              onChange={(e) => {
                setLicenseBackText(e?.target?.files[0]?.name || "Upload");
              }}
            />
            <InputGroup>
              <input
                className="form-control"
                type="text"
                placeholder={licenseBackText}
                disabled
              />
              <label
                className="btn btn-black rounded-0 rounded-right m-0 d-flex align-items-center "
                htmlFor="license_back"
              >
                Browse
              </label>
            </InputGroup>
            {errors.license_back && (
              <label className="text-danger">Back is required</label>
            )}
          </div>
          <div className="mb-3">
            <label className="font-weight-bold">Insurance </label>
            <input
              type="file"
              accept="image/png, image/gif, image/jpeg"
              id="insurance"
              className="opacity-0 w-0 h-0"
              {...register("insurance", { required: false })}
              onChange={(e) => {
                setInsuranceText(e?.target?.files[0]?.name || "Upload");
              }}
            />
            <InputGroup>
              <input
                className="form-control"
                type="text"
                placeholder={insuranceText}
                disabled
              />
              <label
                className="btn btn-black rounded-0 rounded-right m-0 d-flex align-items-center "
                htmlFor="insurance"
              >
                Browse
              </label>
            </InputGroup>
            {errors.insurance && (
              <label className="text-danger">Insurance is required</label>
            )}
          </div>
          <div>
            {loading && (
              <Spinner
                className="mx-auto mt-3
                     d-flex"
                size="sm"
                animation="border"
                variant="primary"
              />
            )}
            <input
              type="submit"
              className="btn btn-primary btn-lg w-100 mt-3"
              value="Submit"
            />
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default AddDriverModal;
