import React from "react";
import { Card, Avatar } from "antd";
import { DownOutlined } from "@ant-design/icons";

const { Meta } = Card;
function NotificationCard({ notification }) {
   return (
    <Meta
      avatar={
        <Avatar
          src={notification?.image || "https://joeschmoe.io/api/v1/random"}
        />
      }
      title={
        <p className="p-0 m-0 notification_title">
          {notification?.title}
          {!notification?.read_status && (
            <b className="ml-1 active_dote"></b>
          )}{" "}
          <br />
          <span className="">{notification?.duration}</span>
        </p>
      }
      description={notification?.description}
      style={{
        borderBottom: "1px solid #e9e9e9",
        paddingBottom: "10px",
        paddingTop: "10px",
        width: "200px",
      }}
    />
  );
}

export default NotificationCard;
