import React, { useState } from "react";
import { Modal, Form, Col, Row, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import {
  useAddPeakDayMutation,
  useUpdatePeakDayMutation,
} from "services/peakDays";
import { message } from "antd";

function AddEditPeakDay({ handleClose, show }) {
  const [defaultValue, setDefaultValue] = useState({
    rate: true,
    peak_day: true,
  });
  const [addPeakDay] = useAddPeakDayMutation();
  const [updatePeakDay] = useUpdatePeakDayMutation();
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    reset,
  } = useForm();
  const modalCloseHandler = () => {
    setDefaultValue({ ...defaultValue, rate: true, peak_day: true });
    reset();
    handleClose();
  };
  const onSubmitAdd = async (data) => {
    setLoading(true);
    let vehicle_id = parseInt(data.vehicle_id);
    let rate = parseInt(data.rate);
    const addPeakDayRes = await addPeakDay({ ...data, vehicle_id, rate });
    if (addPeakDayRes?.data?.message === "Created") {
      setLoading(false);
      message.success("Peak Day Added Successfully");
      modalCloseHandler();
      reset();
    } else {
      setLoading(false);
      addPeakDayRes?.data?.message
        ? message.error(addPeakDayRes?.data?.message)
        : message.error("Something went wrong");
      // handleClose();
    }
  };

  const onSubmitEdit = async (data) => {
    setLoading(true);
    let vehicle_id = parseInt(data.vehicle_id);
    let rate = parseInt(data.rate || show?.peakData?.rate);
    let id = parseInt(show?.peakData?.id);
    const addPeakDayRes = await updatePeakDay({
      ...data,
      vehicle_id,
      rate,
      id,
    });
    if (addPeakDayRes?.data?.message === "Updated") {
      setLoading(false);
      message.success("Peak Day Updated Successfully");
      modalCloseHandler();
      reset();
    } else {
      setLoading(false);
      addPeakDayRes?.data?.message
        ? message.error(addPeakDayRes?.data?.message)
        : message.error("Something went wrong");
      // handleClose();
    }
  };
  const disablePastDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  };
  return (
    <Modal
      className="right-modal"
      show={show.showPeakDay}
      onHide={modalCloseHandler}
      onEnter={() =>
        setDefaultValue({ ...defaultValue, rate: false, peak_day: false })
      }
    >
      <Modal.Header closeButton className="border-bottom pb-3">
        <Modal.Title className="m-0 font-weight-bold">
          {show.peakData ? "Edit" : "Add"} Peak Day
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="align-items-center mb-4">
          <Col md="3">
            <img
              className="img-fluid rounded"
              alt="..."
              src={show?.id?.vehicle_image[0]?.image}
            ></img>
          </Col>
          <Col md="9">
            <h5 className="secondary-gray m-0">{show?.id?.plate_number}</h5>
            <h3 className="m-0 pb-2 font-weight-bold">{show?.id?.name}</h3>
          </Col>
        </Row>

        <form
          className="form-default"
          onSubmit={handleSubmit(
            show?.peakData?.id ? onSubmitEdit : onSubmitAdd
          )}
        >
          <input
            type={"hidden"}
            name="vehicle_id"
            defaultValue={show?.id?.id}
            {...register("vehicle_id", { required: true })}
          />
          <Form.Group>
            <label>Rates per hour </label>
            <input
              className="form-control"
              type="number"
              placeholder="$ 00"
              min="1"
              step=".01"
              name="rate"
              defaultValue={show?.peakData?.rate}
              disabled={defaultValue.rate}
              {...register("rate", {
                required: show?.peakData?.rate ? false : true,
              })}
            />
            {errors?.rate?.type === "required" && (
              <span className="text-danger">Rate is required</span>
            )}
          </Form.Group>
          <Form.Group>
            <label>Select the peak day </label>
            <input
              type="date"
              className="form-control"
              name="peak_day"
              defaultValue={show?.peakData?.peak_day}
              disabled={defaultValue.peak_day}
              min={disablePastDate()}

              {...register("peak_day", {
                required: show?.peakData?.peak_day ? false : true,
              })}
            />
            {errors?.peak_day?.type === "required" && (
              <span className="text-danger">Peak day is required</span>
            )}
          </Form.Group>
          {loading && (
            <Spinner
              className="mx-auto mt-3
                   d-flex"
              size="sm"
              animation="border"
              variant="primary"
            />
          )}
          <input
          disabled={loading}
            type={"submit"}
            value={show.peakData ? "Save" : "Done"}
            className="btn btn-primary btn-lg w-100 mt-3 primary_btn btn-outline-primary"
          />
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default AddEditPeakDay;
