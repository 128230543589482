import React from "react";

export default function AvailabilityData() {
  return [
    {
      active: false,
      day_of_week: "Sunday",
      start_at: "08:00:00",
      end_at: "21:00:00",
    },
    {
      active: false,
      day_of_week: "Monday",
      start_at: "08:00:00",
      end_at: "21:00:00",
    },
    {
      active: false,
      day_of_week: "Tuesday",
      start_at: "08:00:00",
      end_at: "21:00:00",
    },
    {
      active: false,
      day_of_week: "Wednesday",
      start_at: "08:00:00",
      end_at: "21:00:00",
    },
    {
      active: false,
      day_of_week: "Thursday",
      start_at: "08:00:00",
      end_at: "21:00:00",
    },
    {
      active: false,
      day_of_week: "Friday",
      start_at: "08:00:00",
      end_at: "21:00:00",
    },
    {
      active: false,
      day_of_week: "Saturday",
      start_at: "08:00:00",
      end_at: "21:00:00",
    },
  ];
}
