import React from "react";
import { Col } from "react-bootstrap";

function Skeleton() {
  return (
    <Col md="6" className="mt-4">
      <div className=" bg-white border rounded">
        <div className="mapSkeleton skeleton"></div>
        <div className="p-3 d-flex justify-content-between align-items-center py-2">
          <div>
            <h5 className="m-0">
              <span className="skeleton">World Trade Center</span>
            </h5>
            <p className="m-0">
              <span className="skeleton">
                285 Fulton St, New York, NY 10007
              </span>
            </p>
          </div>
          <span>
          </span>
        </div>
      </div>
    </Col>
  );
}

export default Skeleton;
