import React from "react";
import { Form } from "react-bootstrap";

function DriversCardsSkeleton() {
  return (
    <div className="border p-3 rounded  d-flex justify-content-around flex-column flex-lg-row align-items-center mb-2">
      <div className="d-flex align-items-center">
        <div className="opacity-0">
          <Form.Check className="pr-2 pl-0">
            <Form.Check.Label>
              <Form.Check.Input
                defaultChecked
                type="checkbox"
              ></Form.Check.Input>
              <span className="form-check-sign"></span>
            </Form.Check.Label>
          </Form.Check>
        </div>
        <div>
          <div className="thumbnail-round-md mr-3 skeleton">
          
          </div>
        </div>
        <h4 className="m-0 font-weight-bold">
          <span className="skeleton"> Matthew Wagner</span>
          <br />
          <h6>
            <span className="text- skeleton">Next -</span>{" "}
            <span className="text- skeleton">Oct 25, Thursday 5:30 pm</span>
          </h6>
        </h4>
      </div>
      <div>
       
      </div>
    </div>
  );
}

export default DriversCardsSkeleton;
