import React, { useState, useEffect } from "react";

// react-bootstrap components
import { Card, Form, Container, Col } from "react-bootstrap";

function LoginPage() {
  const [cardClasses, setCardClasses] = useState("card-hidden");

  useEffect(() => {
    setTimeout(function () {
      setCardClasses("");
    }, 1000);
  });
  return (
    <>
      <div className="full-page section-image" data-color="black">
        <div className="content d-flex align-items-center p-0">
          <Container>
            <Col className="mx-auto" lg="4" md="8">
              <Card className={"card-login " + cardClasses}>
                <Card.Header>
                  <h1 className="header text-center">404</h1>
                </Card.Header>
                <Card.Body>
                  <Card.Body className="overflow-hidden">
                    <img
                      src={require("assets/img/car.gif").default}
                      alt="404"
                      className="w-100"
                    />
                  </Card.Body>
                </Card.Body>
              </Card>
            </Col>
          </Container>
        </div>
        <div
          className="full-page-background"
          style={{
            backgroundImage:
              "url(" +
              require("assets/img/full-screen-image-2.jpg").default +
              ")",
          }}
        ></div>
      </div>
    </>
  );
}

export default LoginPage;
