import React, { useEffect } from "react";
import Router from "routes/index";
import { useSelector, useDispatch } from "react-redux";
import { userSelector, validateUser } from "features/auth/authSlice";
import { getToken } from "cookies/token";
import Loading from "views/Pages/Loading/index";
import LoginPage from "views/Pages/Auth/Login";
function App() {
  const { data, isFetching, validating } = useSelector(userSelector);
  const token = getToken();
  const dispatch = useDispatch();
  useEffect(() => {
    if (token) {
      dispatch(validateUser());
    }
  }, []);
  if (isFetching || validating) return <Loading />;
  if (!data) return <LoginPage />;
  return <Router />;
}

export default App;
