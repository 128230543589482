import React from "react";
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  Form,
  Modal,
} from "react-bootstrap";
function Skeleton() {
  return (
    <Container fluid>
      <Card className="mb-3 rounded-0 rounded-top border-top-0 border-left-0 border-right-0 ">
        <Card.Body>
          <Row className="align-items-center">
            <Col col="12" xl="10">
              <div className="d-flex align-items-center">
                <h3 className="m-0 pb-0 font-weight-bold mr-2 mb-1">
                  <span className="skeleton">#1236589</span>
                </h3>
                <div className="text-completed">
                  <b className="skeleton">Not Started</b>
                </div>
              </div>
              <h6 className="secondary-gray m-0 pb-2">
                <span className="skeleton"> Oct 10, 2021 Monday 10:30 am</span>
              </h6>
            </Col>
            <Col className="my-1 " col="12" md="2">
              <button
                type="button"
                className="btn  w-100"
                onClick={() => handleShow()}
              >
                <span className="skeleton">skeleton</span>
              </button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <div className="bg-white p-3">
        <div className="border px-3 mb-3">
          <Row>
            <Col lg="6" className="py-3">
              <Row className="align-items-center">
                <Col md="3" className="">
                  <div className="skeleton">
                    <img
                      className="img-fluid rounded opacity-0"
                      alt="..."
                      src={require("assets/img/car.jpg").default}
                    ></img>
                  </div>
                </Col>
                <Col md="9" className="mt-3 mt-md-0">
                  <h5 className=" m-0 mb-1">
                    <span className="skeleton ">TEXAS 202 HSZ</span>
                  </h5>
                  <h3 className="m-0 pb-2 font-weight-bold">
                    <span className="skeleton">Lamborghini Aventador</span>
                  </h3>
                </Col>
              </Row>
            </Col>
            <Col lg="6" className="pt-0 py-md-3 ">
              <Row>
                <Col md="7">
                  <div className="d-flex flex-column flex-md-row pt-md-3 ">
                    <h6 className="font-weight-bold pr-3 m-0">
                      <span className="skeleton">
                        {" "}
                        RESCHEDULED RIDE Details
                      </span>
                    </h6>
                    <h6 className="text-">
                      <span className="skeleton">( View History )</span>
                    </h6>
                  </div>
                  <h6 className="text-gray m-0">
                    <span className="skeleton">DURATION</span>
                  </h6>
                  <h6 className="pb-2">
                    <b className="skeleton">5 Hours</b>
                  </h6>
                  <h6 className="text-gray m-0">
                    <span className="skeleton">DURATION</span>
                  </h6>
                  <h6 className="pb-2">
                    <b className="skeleton">5 Hours</b>
                  </h6>
                </Col>
                <Col md="5" className="d-flex flex-column justify-content-end">
                  <h6 className="text-gray m-0">
                    <span className="skeleton">DURATION</span>
                  </h6>
                  <h6 className="pb-2">
                    <b className="skeleton">5 Hours</b>
                  </h6>
                  <Button
                    className="w-100 btn-primary btn-lg mb-3 mb-md-0 opacity-0"
                    type="submit"
                  >
                    Reschedule
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <Row>
          <Col lg="6">
            <div className="border p-3 mb-3">
              <h5 className="font-weight-bold pb-3 m-0 black-one">
                <span className="skeleton"> CUSTOMER INFO</span>
              </h5>
              <Row className="align-items-center">
                <Col lg="6" className="mb-3 mb-md-0">
                  <div className="d-flex align-items-center">
                    <div className="thumbnail-round mr-3 skeleton"></div>
                    <div>
                      <h5 className="m-0 pb-1">
                        <b className="skeleton">Shawn Tom</b>
                      </h5>
                      <h6 className="text-primary m-0">
                        <span className="skeleton">12365456</span>
                      </h6>
                    </div>
                  </div>
                </Col>
                <Col lg="6">
                  <h6 className="m-0 pb-1">
                    <span className="skeleton">Phone +1 99856 25642 </span>
                  </h6>
                  <h6 className="m-0 pb-1">
                    <span className="skeleton">Phone +1 99856 25642 </span>
                  </h6>
                </Col>
              </Row>
            </div>
            <div className="border p-3 mb-3">
              <h5 className="font-weight-bold pb-3 m-0 black-one">
                <b className="skeleton">Shawn Tom</b>
              </h5>
              <div className="d-flex align-items-center">
                <div>
                  <div className="thumbnail-round skeleton mr-3"></div>
                </div>
                <div>
                  <h5 className="m-0 pb-1">
                    <b className="skeleton">World Trade Center</b>
                  </h5>
                  <h6 className="m-0">
                    {" "}
                    <span className="skeleton">Phone +1 99856 25642 </span>
                  </h6>
                </div>
              </div>
            </div>
            <div className="border p-3 mb-3">
              <div className="d-flex flex-column flex-md-row py-2">
                <h5 className="font-weight-bold pr-2 m-0">
                  <span className="skeleton">INVOICE</span>
                </h5>
                <h5 className="text-primary">
                  <u className="skeleton">1233456654</u>
                </h5>
              </div>
              <Row>
                <Col col="8" className="pb-2">
                  <h5 className="m-0 pb-1">
                    <b className="skeleton">World Trade Center</b>
                  </h5>{" "}
                </Col>
                <Col col="4" className="pb-2 text-right">
                  <h5 className="m-0 pb-1">
                    <b className="skeleton">World Trad</b>
                  </h5>
                </Col>
              </Row>
              <Row>
                <Col col="8" className="pb-2">
                  <h5 className="m-0 pb-1">
                    <b className="skeleton">World Trad</b>
                  </h5>{" "}
                </Col>
                <Col col="4" className="pb-2 text-right">
                  <h5 className="m-0 pb-1">
                    <b className="skeleton">World Trad</b>
                  </h5>{" "}
                </Col>
              </Row>
              <Row>
                <Col col="8" className="pb-2">
                  <h5 className="m-0 pb-1">
                    <b className="skeleton">World Traccc dddd</b>
                  </h5>{" "}
                </Col>
                <Col col="4" className="pb-2 text-right">
                  <h5 className="m-0 pb-1">
                    <b className="skeleton">Worlrad</b>
                  </h5>{" "}
                </Col>
              </Row>
              <hr />
              <Row className="align-items-center mb-3">
                <Col col="8" className="pb-2">
                  <h5 className="m-0">
                    <b className="skeleton">TOTAL</b>
                  </h5>
                </Col>
                <Col col="4" className="pb-2 text-right">
                  <h3 className="m-0 pb-1">
                    <b className="skeleton">World Trad</b>
                  </h3>{" "}
                </Col>
              </Row>
              <h6>
                {" "}
                <span className="mb-0 skeleton">
                  Paid via credit card ending 9856s
                </span>
              </h6>
              <h6>
                {" "}
                <span className="mb-0 skeleton">
                  Paid via credit card ending 9856s
                </span>
              </h6>
            </div>
          </Col>
          <Col lg="6">
            <div className="border p-3 mb-3">
              <h5 className="font-weight-bold pb-3 m-0 black-one">
                <span className="skeleton"> DRIVER INFO</span>
              </h5>
              <div className="d-flex align-items-center">
                <div className="thumbnail-round mr-3 skeleton"></div>
                <div>
                  <h5 className="m-0 pb-1">
                    <b className="skeleton">Kyle Lee</b>
                  </h5>
                  <h6 className="text-primary m-0">
                    {" "}
                    <b className="skeleton">Kyle Lee</b>
                  </h6>
                </div>
              </div>
              <div className="d-flex flex-column flex-md-row py-2">
                <h5 className="font-weight-bold pr-2 m-0">
                  <span className="skeleton">INVOICE</span>
                </h5>
                <h5 className="text-primary">
                  <u className="skeleton">1233456654</u>
                </h5>
              </div>
              <Row>
                <Col col="8" className="pb-2">
                  <h5 className="m-0 pb-1">
                    <b className="skeleton">World Trade Center</b>
                  </h5>{" "}
                </Col>
                <Col col="4" className="pb-2 text-right">
                  <h5 className="m-0 pb-1">
                    <b className="skeleton">World Trad</b>
                  </h5>
                </Col>
              </Row>
              <Row>
                <Col col="8" className="pb-2">
                  <h5 className="m-0 pb-1">
                    <b className="skeleton">World Trad</b>
                  </h5>{" "}
                </Col>
                <Col col="4" className="pb-2 text-right">
                  <h5 className="m-0 pb-1">
                    <b className="skeleton">World Trad</b>
                  </h5>{" "}
                </Col>
              </Row>
              <Row>
                <Col col="8" className="pb-2">
                  <h5 className="m-0 pb-1">
                    <b className="skeleton">World Traccc dddd</b>
                  </h5>{" "}
                </Col>
                <Col col="4" className="pb-2 text-right">
                  <h5 className="m-0 pb-1">
                    <b className="skeleton">Worlrad</b>
                  </h5>{" "}
                </Col>
              </Row>
              <hr />
              <Row className="align-items-center mb-3">
                <Col col="8" className="pb-2">
                  <h5 className="m-0">
                    <b className="skeleton">TOTAL</b>
                  </h5>
                </Col>
                <Col col="4" className="pb-2 text-right">
                  <h3 className="m-0 pb-1">
                    <b className="skeleton">World Trad</b>
                  </h3>{" "}
                </Col>
              </Row>
              <h6>
                {" "}
                <span className="mb-0 skeleton">
                  Paid via credit card ending 9856s
                </span>
              </h6>
              <h6>
                {" "}
                <span className="mb-0 skeleton">
                  Paid via credit card ending 9856s
                </span>
              </h6>
            </div>
          </Col>
        </Row>
      </div>
    </Container>
  );
}

export default Skeleton;
