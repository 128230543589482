import React, { useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { Popconfirm, message, Modal } from "antd";
import { useUpdateRefundMutation } from "services/paymentReport";
import { Spinner } from "react-bootstrap";
function ListCard({ index, payment }) {
  const [updateRefund] = useUpdateRefundMutation();
  const [Loading, setLoading] = useState(false);
  const upData = {
    transaction_id: payment?.transaction_id,
  };
  async function confirm() {
    setLoading(true);

    const refund = await updateRefund(upData);
    if (refund?.data?.message === "Ok") {
      setLoading(false);
      message.success("Refunded");
      handleCancel();
    } else {
      setLoading(false);
      message.error(refund?.data?.message);
      handleCancel();
    }
  }
  let status = true;
  if (payment?.ride?.ride_status_id === 4) {
    status = false;
  } else if (payment?.ride?.ride_status_id === 5) {
    status = false;
  } else if (payment?.ride?.ride_status_id === 6) {
    status = false;
  } else if (payment?.ride?.ride_status_id === 7) {
    status = false;
  }
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  return (
    <>
      {" "}
      <Modal
        title={null}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        centered
      >
        <div className="py-5">
          <h4 className="text-danger text-center font-weight-bold">
            Are you sure?
          </h4>
          <p className="text-center">Are you sure want to process the refund</p>
          <div className="d-flex justify-content-center mt-2">
            <button
              className="btn btn-outline-secondary px-5 mr-2"
              onClick={handleCancel}
            >
              Cancel
            </button>
            <button className="btn btn-danger px-4 ml-2 d-flex" onClick={confirm}>
              Yes, Confirm
              {Loading && (
                <Spinner
                  className="mx-auto mt-1 ml-3
                   d-flex"
                  size="sm"
                  animation="border"
                  variant="primary"
                />
              )}
            </button>
          </div>
        </div>
      </Modal>
      <tr>
        <td>{index}</td>
        <td>{moment(payment?.created_at).format("MM/DD/YY, h:mm a")}</td>
        <td>
          <p className="text-primary m-0 p-0">{payment?.transaction_id}</p>
        </td>
        <td>${payment?.total_amount}</td>
        <td>
          <u>
            <Link
              to={
                payment?.category === "ride"
                  ? `/admin/rides/${payment?.ride?.id}`
                  : `/admin/orders/${payment?.order?.id}`
              }
            >
              <a className="text-primary ">
                {payment?.category === "ride"
                  ? payment?.ride?.ride_code
                  : payment?.order?.code}
              </a>
            </Link>
          </u>
        </td>
        <td>
          <h5 className="m-0 font-weight-bold">{payment?.category}</h5>
        </td>
        <td>{payment?.user?.full_name}</td>
        <td
          className={`text-${
            payment?.status === "succeeded" && "active"
          } text-${payment?.status === "processing" && "w"} text-${
            payment?.status === "payment_failed" && "completed"
          }`}
        >
          <span></span>
          {payment?.status === "succeeded" && "paid"}
          {payment?.status === "processing" && "processing"}
          {payment?.status === "payment_failed" && "failed"}
        </td>
        <td className="text-danger text-center py-4">
          {/* {payment?.user?.active && "active"} */}
          {payment?.refund_status ? (
            <h5 className="font-weight-bold m-0 py-2 text-primary">
              Refund Processed
            </h5>
          ) : (
            <>
              {status ? (
                <button
                  className="btn btn-sm btn-primary px-5"
                  onClick={showModal}
                >
                  Refund
                </button>
              ) : (
                <button className="btn btn-4 px-5">Can't Refund</button>
              )}
            </>
          )}
        </td>
      </tr>
    </>
  );
}

export default ListCard;
