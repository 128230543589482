import React, { useState, useEffect } from "react";
import { db } from "firebase/index";
import {
  collection,
  getDocs,
  doc,
  onSnapshot,
  query,
  orderBy,
  limit,
} from "firebase/firestore";

import {
  GoogleMap,
  LoadScriptNext,
  Marker,
  DirectionsRenderer,
  Polyline,
} from "@react-google-maps/api";
function TrackMap({ ride }) {
  const [driverLocation, setDriverLocation] = useState({
    lat: null,
    lng: null,
  });
  const [pickupLocation, setPickupLocation] = useState({
    lat: null,
    lng: null,
  });
  useEffect(() => {
    setDriverLocation({
      lat: parseFloat(ride?.latitude),
      lng: parseFloat(ride?.longitude),
    });
    setPickupLocation({
      lat: parseFloat(ride?.location?.latitude),
      lng: parseFloat(ride?.location?.longitude),
    });
  }, [ride]);

  const [location, setLocation] = React.useState(37.33135095);
  const [track, setTrack] = React.useState([]);

  useEffect(() => {
    if (ride?.id) {
      onSnapshot(
        doc(
          db,
          process.env.REACT_APP_FIREBASE_COLORATION,
          ride?.driver?.id.toString(),
          "Rides",
          ride?.id.toString()
        ),
        (doc) => {
          let data = doc.data();
          let driverLocation = {
            lat: parseFloat(data?.lat),
            lng: parseFloat(data?.long),
          };
          console.log(driverLocation);
          setDriverLocation(driverLocation);
          return driverLocation;
        }
      );
    }
  }, [ride]);

  const [map, setMap] = React.useState({
    directions: null,
  });
  const containerStyle = {
    width: "100%",
    height: "70vh",
  };
  const onLoad = React.useCallback(
    function callback(map) {
      const DirectionsService = new google.maps.DirectionsService();

      DirectionsService.route(
        {
          origin: new google.maps.LatLng(
            driverLocation?.lat,
            driverLocation?.lng
          ),
          destination: new google.maps.LatLng(
            pickupLocation?.lat,
            pickupLocation?.lng
          ),
          travelMode: google.maps.TravelMode.DRIVING,
        },
        (result, status) => {
          if (status === google.maps.DirectionsStatus.OK) {
            setMap({ directions: result });
          } else {
            console.log(`error fetching directions ${result}`);
          }
        }
      );
    },
    [driverLocation]
  );
  const directions = {
    suppressMarkers: true,
    polylineOptions: { strokeColor: "#F6491E" },
  };
  const path = track.slice(1, track.length - 2).map((loc, i) => {
    return {
      lat: loc.lat,
      lng: loc.long,
    };
  });
  const drawLine = {
    strokeColor: "#FF0000",
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: "#FF0000",
    fillOpacity: 0.35,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: 30000,
    paths: path,
    zIndex: 1,
  };
  return (
    <div className="bg-white p-3">
      <div className="border ">
        <LoadScriptNext googleMapsApiKey="AIzaSyDIj0QBVIMnAxlKJf4WbGcISWXPvchSj-Y">
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={driverLocation}
            zoom={20}
            onLoad={onLoad}
          >
            <Marker
              icon={require("assets/img/car.svg").default}
              position={driverLocation}
              animation={google.maps.Animation.DROP}
            />
            {ride?.ride_status_id != 6 && ( 
              <>
                <Marker
                  icon={require("assets/img/mark.png").default}
                  position={pickupLocation}
                  label={{
                    text: "Pickup Location",
                    color: "#F6491E",
                    fontSize: "12px",
                  }}
                />
                <DirectionsRenderer
                  directions={map.directions}
                  options={directions}
                />
              </>
            )}

            {/* <Polyline path={path} options={drawLine} /> */}
          </GoogleMap>
        </LoadScriptNext>
      </div>
    </div>
  );
}

export default TrackMap;
