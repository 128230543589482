import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { DatePicker, Empty, Pagination } from "antd";
const { RangePicker } = DatePicker;
import {
  Button,
  Card,
  Table,
  Container,
  Row,
  Col,
  InputGroup,
} from "react-bootstrap";
import MetaTitle from "components/Meta";
import Skelton from "components/CouponCodes/Skeleton";
import ListCards from "components/CouponCodes/ListCards";
import AddCouponModal from "components/CouponCodes/AddCouponModal";
import Item from "antd/lib/list/Item";
import { FilterBy } from "components/Common/FilterBy";
import { useGetAllCouponQuery } from "services/coupon";
import EditCouponModal from "components/CouponCodes/EditCouponModal";
import TableTitle from "components/Orders/List/TableTitle";

function CouponCodes() {
  // initial set up for Item
  const [populate, setPopulate] = useState("");
  const [filter, setFilter] = useState("");
  const [sort, setSort] = useState({
    sortBy: "created_at",
    sortDirection: "desc",
  });
  const [PageSize, setPageSize] = useState(10); // default page size change value in query also
  const [page, setPageNumber] = useState(0);
  // initial set up for Item END
  const { isLoading, isError, isSuccess, data, status } = useGetAllCouponQuery(
    { page, PageSize, sort, filter, populate },
    {
      refetchOnMountOrArgChange: false,
    }
  );
  const [addShow, setAddShow] = useState(false);
  const handleAddClose = () => setAddShow(false);
  const handleAddShow = () => setAddShow(true);

  const [show, setShow] = useState({
    status: false,
    code: null,
  });
  const handleClose = (code) => {
    setShow({
      status: false,
      code: code,
    });
  };
  const handleShow = (code) => {
    setShow({
      code: code,
      status: true,
    });
  };
  const [formData, setFormData] = useState(null);

  const handlePicker = (data, date) => {
    const fromDate = date[0] + " 00:00:00";
    const toDate = date[1] + " 23:59:00";
    const created_at = { $gte: fromDate, $lte: toDate };
    if (date[0] && date[1]) {
      setFilter({ ...filter, created_at });
    } else {
      const { created_at, ...newObj } = filter;
      setFilter((prev) => newObj);
    }
    setPageNumber(0);
  };
  const handlePagination = (page, pageSize) => {
    setPageNumber((prev) => page - 1);
    setPageSize(pageSize);
  };

  return (
    <>
      <MetaTitle text="Coupon Code" />
      <AddCouponModal show={addShow} handleClose={handleAddClose} />
      <EditCouponModal
        show={show}
        handleClose={handleClose}
        formData={formData}
        setFormData={setFormData}
      />
      <Container fluid>
        <Card className="mb-3 border-0">
          <Card.Body>
            <Row className="align-items-center">
              <Col col="12" md="8" lg="10">
                <h3 className="m-0 py-2 font-weight-bold">Coupon Codes</h3>
              </Col>
              <Col col="12" md="4" lg="2">
                <Button
                  className="w-100 btn-primary"
                  type="submit"
                  onClick={() => handleAddShow(true)}
                >
                  Add Coupon
                </Button>
              </Col>
            </Row>
          </Card.Body>
        </Card>

        <div className="bg-white">
          <div className="p-3 border-bottom">
            <Row className="align-items-center">
              <Col col="12" md="8" lg="6" xl="4">
                <RangePicker
                  className="form-control"
                  onChange={(start, end) => handlePicker(start, end)}
                  format="MM/DD/YY"
                />
              </Col>
              <Col col="12" md="4" lg="2" className="ml-auto">
                <FilterBy
                  filterKey={"active"}
                  setPageNumber={setPageNumber}
                  filter={filter}
                  setFilter={setFilter}
                  filterItems={[
                    { name: "Blocked", value: false },
                    { name: "Active", value: true },
                  ]}
                />
              </Col>
            </Row>
          </div>

          <div className="table-full-width table-responsive">
            <Table className="table-hover revup-table m-0">
              <thead>
                <tr>
                  <th className="border-0">#</th>
                  <TableTitle
                    title="Code"
                    setSort={setSort}
                    sortBy="code"
                    sort={sort}
                  />
                  <TableTitle
                    title="Category"
                    setSort={setSort}
                    sortBy="category"
                    sort={sort}
                  />
                  <TableTitle
                    title="Offer"
                    setSort={setSort}
                    sortBy="type"
                    sort={sort}
                  />
                  <TableTitle
                    title="Active From"
                    setSort={setSort}
                    sortBy="active_date"
                    sort={sort}
                  />
                  <TableTitle
                    title="Expire On"
                    setSort={setSort}
                    sortBy="expiry_date"
                    sort={sort}
                  />
                  <TableTitle
                    title="Applied "
                    setSort={setSort}
                    sortBy="applied"
                    sort={sort}
                  />
                  <TableTitle
                    title="Created on "
                    setSort={setSort}
                    sortBy="created_at"
                    sort={sort}
                  />
                  <TableTitle
                    title="Status "
                    setSort={setSort}
                    sortBy="active"
                    sort={sort}
                  />
                  <th className="border-0 text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {isLoading && [...Array(10)].map((d, i) => <Skelton key={i} />)}
                {status === "pending" &&
                  [...Array(PageSize)].map((d, i) => <Skelton key={i} />)}
                {isError && <p>Error </p>}
                {isSuccess &&
                  status === "fulfilled" &&
                  data?.data?.coupon_codes.map((coupon, i) => (
                    <ListCards
                      key={i}
                      coupon={coupon}
                      index={page * PageSize + i + 1}
                      handleShow={handleShow}
                      setFormData={setFormData}
                    />
                  ))}
                {data?.data?.coupon_codes.length === 0 && (
                  <tr>
                    <td colspan="10" className="empty_items">
                      <Empty
                        description={false}
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                      >
                        <div className="ant-empty-description">
                          <p>No Coupon Found</p>
                        </div>
                      </Empty>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </div>
        <Row className="mt-3">
          <Col col="12" md="12" lg="12">
            <Pagination
              current={page + 1}
              defaultCurrent={1}
              defaultPageSize={PageSize}
              onChange={handlePagination}
              total={parseInt(data?.data?.count)}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default CouponCodes;
