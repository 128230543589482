import React from "react";
import { Card, Col } from "react-bootstrap";
function DriverCardSkelton() {
  return (
    <Col md="4" lg="3" xl="3">
      <Card>
        <Card.Body className="text-center ">
          <div className="text-right">
            <span className="skeleton badge badge-success rounded-pill py-2 px-3 font-weight-light">
              skeleton
            </span>
          </div>
          <div className="thumbnail-round-lg m-auto skeleton"></div>
          <h5 className="m-0 pt-3 pb-1 font-weight-bold  ">
            <span className="skeleton">skeleton skeleton</span>
          </h5>
          <h5 className="m-0 skeleton">+1 98952 25643</h5>
          <h5 className="skeleton mt-1 ">shawntom@example.com</h5>
          <div className="d-flex align-items-baseline justify-content-center py-2 skeleton ">
            <h5 className=" m-0 opacity-0">TOTAL RIDES</h5>
            <h3 className="font-weight-bold  m-0 pl-1 opacity-0">20</h3>
          </div>
        </Card.Body>
      </Card>
    </Col>
  );
}

export default DriverCardSkelton;
