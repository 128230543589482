import React from "react";

function Skeleton() {
  return (
    <tr>
      <td><span className="skeleton">1</span></td>
      <td>
        <a href="#" className="text-primary">
        <span className="skeleton">PR100063</span>
        </a>
      </td>
      <td>
        <div className="d-flex align-items-center">
          <div className="thumbnail-box mr-3 skeleton">
         
          </div>
          <h6 className="m-0">
          <span className="skeleton">    Tonino Lamborghini 8850 Au</span>
         <br />
            <span className="text-gray skeleton">Tonino Lamborghini</span>
          </h6>
        </div>
      </td>
      <td>
        <h4 className="m-0">
          <span className="badge bg-primary text-white p-2 font-weight-light skeleton">
            20
          </span>
        </h4>
      </td>
      <td>
        <b className="skeleton">$ 350.00</b>
      </td>
      <td>
      <span className="skeleton">25/11/2021</span>
         <span className="text-gray skeleton"> 12:00 pm</span>
      </td>
      <td>
        <b className="skeleton">10</b>
      </td>
      <td className="text-primary-s">
        
        <b className="skeleton">Inactive</b>
      </td>
      {/* <td>
        <div className="w-100 btn skeleton">Active</div>
      </td> */}
    </tr>
  );
}

export default Skeleton;
