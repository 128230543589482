import React, { useState } from "react";
import ChartistGraph from "react-chartist";
// react-bootstrap components
import MetaTitle from "components/Meta/index";
import Skelton from "components/Rides/Skelton";
import NewRideListCards from "components/Rides/NewRideListCards";
import AssignDriverModal from "components/Rides/AssignDriverModal";
import { useSelector } from "react-redux";
import { userSelector } from "features/auth/authSlice";
import { useGetRidesQuery } from "services/rides";
import { Empty, Pagination } from "antd";
import { Card, Table, Container, Row, Col } from "react-bootstrap";
import TableTitle from "components/Products/List/TableTitle";
import { useGetDashboardQuery } from "services/dashboard";

function Dashboard() {
  const dashboard = useGetDashboardQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const userState = useSelector(userSelector);
  const userData = userState?.data?.data?.user;
  const [activeRide, setActiveRide] = useState(null);
  const [sort, setSort] = useState({
    sortBy: "created_at",
    sortDirection: "desc",
  });
  const [rideType, setRideType] = useState("new");
  const [filter, setFilter] = useState("");
  const [search, setSearch] = useState("");
  const [PageSize, setPageSize] = useState(10); // default page size change value in query also
  const [page, setPageNumber] = useState(0);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (rideId) => {
    setShow(true);
    setActiveRide(rideId);
  };
  const { isLoading, isError, isSuccess, data, status } = useGetRidesQuery(
    { page, PageSize, sort, filter, search, rideType },
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const handlePagination = (page, pageSize) => {
    setPageNumber((prev) => page - 1);
    setPageSize(pageSize);
  };
  function kFormatter(num) {
    return Math.abs(num) > 999
      ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(2) + "k"
      : Math.sign(num) * Math.abs(num);
  }

  const height = data?.data ? "100px" : "0px";
  return (
    <>
      <MetaTitle text="Dashboard" />
      <AssignDriverModal
        show={show}
        handleClose={handleClose}
        handleShow={handleShow}
        activeRide={activeRide}
      />
      <Container fluid>
        <Card className="bg-dashboard py-md-3 px-md-4 rounded-20 border-0">
          <Card.Body>
            <h2 className="m-0 text-white">
              <b>Welcome {"Admin"}</b>
            </h2>
            <p className="text-white">{userData?.email}</p>
            <Row>
              <Col
                lg="4"
                sm="6"
                className="display_anim"
                style={{
                  maxHeight: height,
                  transition: "max-height 0.10s ease-in",
                }}
              >
                <Card className="  mb-2">
                  <Card.Body className="py-3">
                    <Row>
                      <Col xs="3" md="2">
                        <div className="text-center d-flex align-items-center justify-content-center py-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="47.944"
                            height="48"
                            viewBox="0 0 47.944 48"
                          >
                            <g id="revenue" transform="translate(-2)">
                              <path
                                id="Path_2226"
                                data-name="Path 2226"
                                d="M62.222,40.4A20.219,20.219,0,1,0,82.453,60.622,20.1,20.1,0,0,0,62.222,40.4Zm4.894,30.272a7.756,7.756,0,0,1-3.459,1.744v1.875a1.875,1.875,0,0,1-3.75,0v-1.6a13.38,13.38,0,0,1-3.928-.9,1.75,1.75,0,0,1-1-2.128l.009-.019a1.743,1.743,0,0,1,.928-1.078,1.761,1.761,0,0,1,1.444-.028,9.652,9.652,0,0,0,3.722.778c2.569,0,4.163-1.266,4.163-3.3,0-1.819-1.013-2.859-3.872-3.956a11.833,11.833,0,0,1-4.509-2.588,5.8,5.8,0,0,1-1.734-4.228,6.144,6.144,0,0,1,2.166-4.734,7.437,7.437,0,0,1,2.522-1.4V47.019a1.875,1.875,0,0,1,3.75,0v1.65a11.541,11.541,0,0,1,3.3.6,1.763,1.763,0,0,1,1.078,2.222,1.752,1.752,0,0,1-2.269,1.087,8.464,8.464,0,0,0-2.906-.506c-2.728,0-3.694,1.528-3.694,2.831,0,1.7,1,2.625,4.116,3.834,4.228,1.631,6.038,3.741,6.038,7.05a6.48,6.48,0,0,1-2.109,4.884Z"
                                transform="translate(-36.25 -36.612)"
                                fill="none"
                              />
                              <path
                                id="Path_2227"
                                data-name="Path 2227"
                                d="M42.922,7.041A23.972,23.972,0,0,0,2,24.009,23.964,23.964,0,0,0,42.922,40.969a24,24,0,0,0,0-33.928ZM40.269,38.316A20.219,20.219,0,1,1,46.2,24.009,20.1,20.1,0,0,1,40.269,38.316Z"
                                fill="#f13903"
                              />
                              <path
                                id="Path_2228"
                                data-name="Path 2228"
                                d="M187.872,104.594c-3.113-1.209-4.116-2.137-4.116-3.834,0-1.3.966-2.831,3.694-2.831a8.464,8.464,0,0,1,2.906.506,1.778,1.778,0,0,0,1.369-.066,1.731,1.731,0,0,0,.9-1.022,1.763,1.763,0,0,0-1.078-2.222,11.542,11.542,0,0,0-3.3-.6v-1.65a1.875,1.875,0,1,0-3.75,0v2.091a7.282,7.282,0,0,0-2.522,1.4,6.144,6.144,0,0,0-2.166,4.734,5.811,5.811,0,0,0,1.734,4.228,11.712,11.712,0,0,0,4.509,2.588c2.859,1.1,3.872,2.138,3.872,3.956,0,2.034-1.594,3.3-4.163,3.3a9.652,9.652,0,0,1-3.722-.778,1.763,1.763,0,0,0-1.444.028,1.715,1.715,0,0,0-.928,1.078l-.009.019a1.75,1.75,0,0,0,1,2.128,13.024,13.024,0,0,0,3.928.9v1.612a1.875,1.875,0,0,0,3.75,0v-1.875a7.8,7.8,0,0,0,3.459-1.744,6.5,6.5,0,0,0,2.109-4.894C193.91,108.334,192.1,106.225,187.872,104.594Z"
                                transform="translate(-160.935 -82.469)"
                                fill="#f13903"
                              />
                            </g>
                          </svg>
                        </div>
                      </Col>
                      <Col xs="9" md="10" className="pl-0">
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="">
                            <h2 className="font-weight-bold m-0 text-primary">
                              {dashboard.isLoading && (
                                <span className="skeleton">$ 25400k</span>
                              )}
                              {dashboard?.data?.data?.total_ride_amount &&
                                "$" +
                                  kFormatter(
                                    dashboard?.data?.data?.total_ride_amount
                                  )}
                            </h2>
                            <h6 className="font-weight-bold text-uppercase m-0">
                              Revenue from Rides
                            </h6>
                          </div>
                          <div className="d-none">
                            <div className="border-left-dashed pl-3">
                              <h5 className="text-primary font-weight-bold m-0 pb-1">
                                $ 20K
                              </h5>
                              <h6 className="text-primary ">Last Week</h6>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>

              <Col
                lg="4"
                sm="6"
                className="display_anim"
                style={{
                  maxHeight: height,
                  transition: "max-height 0.20s ease-in",
                }}
              >
                <Card className="  mb-2">
                  <Card.Body className="py-3">
                    <Row>
                      <Col xs="3" md="2">
                        <div className="text-center d-flex align-items-center justify-content-center py-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="47.944"
                            height="48"
                            viewBox="0 0 47.944 48"
                          >
                            <g id="revenue" transform="translate(-2)">
                              <path
                                id="Path_2226"
                                data-name="Path 2226"
                                d="M62.222,40.4A20.219,20.219,0,1,0,82.453,60.622,20.1,20.1,0,0,0,62.222,40.4Zm4.894,30.272a7.756,7.756,0,0,1-3.459,1.744v1.875a1.875,1.875,0,0,1-3.75,0v-1.6a13.38,13.38,0,0,1-3.928-.9,1.75,1.75,0,0,1-1-2.128l.009-.019a1.743,1.743,0,0,1,.928-1.078,1.761,1.761,0,0,1,1.444-.028,9.652,9.652,0,0,0,3.722.778c2.569,0,4.163-1.266,4.163-3.3,0-1.819-1.013-2.859-3.872-3.956a11.833,11.833,0,0,1-4.509-2.588,5.8,5.8,0,0,1-1.734-4.228,6.144,6.144,0,0,1,2.166-4.734,7.437,7.437,0,0,1,2.522-1.4V47.019a1.875,1.875,0,0,1,3.75,0v1.65a11.541,11.541,0,0,1,3.3.6,1.763,1.763,0,0,1,1.078,2.222,1.752,1.752,0,0,1-2.269,1.087,8.464,8.464,0,0,0-2.906-.506c-2.728,0-3.694,1.528-3.694,2.831,0,1.7,1,2.625,4.116,3.834,4.228,1.631,6.038,3.741,6.038,7.05a6.48,6.48,0,0,1-2.109,4.884Z"
                                transform="translate(-36.25 -36.612)"
                                fill="none"
                              />
                              <path
                                id="Path_2227"
                                data-name="Path 2227"
                                d="M42.922,7.041A23.972,23.972,0,0,0,2,24.009,23.964,23.964,0,0,0,42.922,40.969a24,24,0,0,0,0-33.928ZM40.269,38.316A20.219,20.219,0,1,1,46.2,24.009,20.1,20.1,0,0,1,40.269,38.316Z"
                                fill="#3fc227"
                              />
                              <path
                                id="Path_2228"
                                data-name="Path 2228"
                                d="M187.872,104.594c-3.113-1.209-4.116-2.137-4.116-3.834,0-1.3.966-2.831,3.694-2.831a8.464,8.464,0,0,1,2.906.506,1.778,1.778,0,0,0,1.369-.066,1.731,1.731,0,0,0,.9-1.022,1.763,1.763,0,0,0-1.078-2.222,11.542,11.542,0,0,0-3.3-.6v-1.65a1.875,1.875,0,1,0-3.75,0v2.091a7.282,7.282,0,0,0-2.522,1.4,6.144,6.144,0,0,0-2.166,4.734,5.811,5.811,0,0,0,1.734,4.228,11.712,11.712,0,0,0,4.509,2.588c2.859,1.1,3.872,2.138,3.872,3.956,0,2.034-1.594,3.3-4.163,3.3a9.652,9.652,0,0,1-3.722-.778,1.763,1.763,0,0,0-1.444.028,1.715,1.715,0,0,0-.928,1.078l-.009.019a1.75,1.75,0,0,0,1,2.128,13.024,13.024,0,0,0,3.928.9v1.612a1.875,1.875,0,0,0,3.75,0v-1.875a7.8,7.8,0,0,0,3.459-1.744,6.5,6.5,0,0,0,2.109-4.894C193.91,108.334,192.1,106.225,187.872,104.594Z"
                                transform="translate(-160.935 -82.469)"
                                fill="#3fc227"
                              />
                            </g>
                          </svg>
                        </div>
                      </Col>
                      <Col xs="9" md="10" className="pl-0">
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="">
                            <h2 className="font-weight-bold m-0 text-green">
                              {dashboard.isLoading && (
                                <span className="skeleton">$ 25400k</span>
                              )}
                              {dashboard?.data?.data?.total_shop_amount &&
                                "$" +
                                  kFormatter(
                                    dashboard?.data?.data?.total_shop_amount
                                  )}
                            </h2>
                            <h6 className="font-weight-bold text-uppercase m-0">
                              Revenue from Shop
                            </h6>
                          </div>
                          <div className="d-none">
                            <div className="border-left-dashed pl-3">
                              <h5 className="text-green font-weight-bold m-0 pb-1">
                                $ 20K
                              </h5>
                              <h5 className="text-green ">Last Week</h5>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
              <Col
                lg="4"
                sm="6"
                className="display_anim"
                style={{
                  maxHeight: height,
                  transition: "max-height 0.30s ease-in",
                }}
              >
                <Card className="  mb-2">
                  <Card.Body className="py-3">
                    <Row>
                      <Col className="border-right">
                        <Row>
                          <Col xs="3" md="4">
                            <div className="text-center d-flex align-items-center justify-content-center py-2">
                              <img
                                className="img-fluid mt-2"
                                alt="..."
                                src={require("assets/img/rides.svg").default}
                              ></img>
                            </div>
                          </Col>
                          <Col xs="9" md="8" className="pl-0">
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="">
                                <h2 className="font-weight-bold m-0 text-primary">
                                  {dashboard.isLoading && (
                                    <span className="skeleton">150 </span>
                                  )}
                                  {dashboard?.data?.data?.total_rides &&
                                    dashboard?.data?.data?.total_rides}
                                </h2>
                                <h6 className="font-weight-bold text-uppercase m-0">
                                  RIDES
                                </h6>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Col>

                      <Col>
                        <Row>
                          <Col xs="3" md="4">
                            <div className="text-center d-flex align-items-center justify-content-center py-2">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="47.944"
                                height="48"
                                viewBox="0 0 47.944 48"
                              >
                                <g id="revenue" transform="translate(-2)">
                                  <path
                                    id="Path_2226"
                                    data-name="Path 2226"
                                    d="M62.222,40.4A20.219,20.219,0,1,0,82.453,60.622,20.1,20.1,0,0,0,62.222,40.4Zm4.894,30.272a7.756,7.756,0,0,1-3.459,1.744v1.875a1.875,1.875,0,0,1-3.75,0v-1.6a13.38,13.38,0,0,1-3.928-.9,1.75,1.75,0,0,1-1-2.128l.009-.019a1.743,1.743,0,0,1,.928-1.078,1.761,1.761,0,0,1,1.444-.028,9.652,9.652,0,0,0,3.722.778c2.569,0,4.163-1.266,4.163-3.3,0-1.819-1.013-2.859-3.872-3.956a11.833,11.833,0,0,1-4.509-2.588,5.8,5.8,0,0,1-1.734-4.228,6.144,6.144,0,0,1,2.166-4.734,7.437,7.437,0,0,1,2.522-1.4V47.019a1.875,1.875,0,0,1,3.75,0v1.65a11.541,11.541,0,0,1,3.3.6,1.763,1.763,0,0,1,1.078,2.222,1.752,1.752,0,0,1-2.269,1.087,8.464,8.464,0,0,0-2.906-.506c-2.728,0-3.694,1.528-3.694,2.831,0,1.7,1,2.625,4.116,3.834,4.228,1.631,6.038,3.741,6.038,7.05a6.48,6.48,0,0,1-2.109,4.884Z"
                                    transform="translate(-36.25 -36.612)"
                                    fill="none"
                                  />
                                  <path
                                    id="Path_2227"
                                    data-name="Path 2227"
                                    d="M42.922,7.041A23.972,23.972,0,0,0,2,24.009,23.964,23.964,0,0,0,42.922,40.969a24,24,0,0,0,0-33.928ZM40.269,38.316A20.219,20.219,0,1,1,46.2,24.009,20.1,20.1,0,0,1,40.269,38.316Z"
                                    fill="#3fc227"
                                  />
                                  <path
                                    id="Path_2228"
                                    data-name="Path 2228"
                                    d="M187.872,104.594c-3.113-1.209-4.116-2.137-4.116-3.834,0-1.3.966-2.831,3.694-2.831a8.464,8.464,0,0,1,2.906.506,1.778,1.778,0,0,0,1.369-.066,1.731,1.731,0,0,0,.9-1.022,1.763,1.763,0,0,0-1.078-2.222,11.542,11.542,0,0,0-3.3-.6v-1.65a1.875,1.875,0,1,0-3.75,0v2.091a7.282,7.282,0,0,0-2.522,1.4,6.144,6.144,0,0,0-2.166,4.734,5.811,5.811,0,0,0,1.734,4.228,11.712,11.712,0,0,0,4.509,2.588c2.859,1.1,3.872,2.138,3.872,3.956,0,2.034-1.594,3.3-4.163,3.3a9.652,9.652,0,0,1-3.722-.778,1.763,1.763,0,0,0-1.444.028,1.715,1.715,0,0,0-.928,1.078l-.009.019a1.75,1.75,0,0,0,1,2.128,13.024,13.024,0,0,0,3.928.9v1.612a1.875,1.875,0,0,0,3.75,0v-1.875a7.8,7.8,0,0,0,3.459-1.744,6.5,6.5,0,0,0,2.109-4.894C193.91,108.334,192.1,106.225,187.872,104.594Z"
                                    transform="translate(-160.935 -82.469)"
                                    fill="#3fc227"
                                  />
                                </g>
                              </svg>
                            </div>
                          </Col>
                          <Col xs="9" md="8" className="pl-0">
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="">
                                <h2 className="font-weight-bold m-0 text-green">
                                  {dashboard.isLoading && (
                                    <span className="skeleton">150 </span>
                                  )}
                                  {dashboard?.data?.data?.total_orders &&
                                    dashboard?.data?.data?.total_orders}
                                </h2>
                                <h6 className="font-weight-bold text-uppercase m-0">
                                  TOTAL ORDERS
                                </h6>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <Row>
          <Col md="12">
            <Card className="m-0 rounded-0 rounded-top border-bottom-0">
              <Card.Body>
                <h3 className="m-0 py-2 font-weight-bold">New Ride Requests</h3>
              </Card.Body>
            </Card>
            <Card className="strpied-tabled-with-hover rounded-0 rounded-bottom ">
              <Card.Body className="table-full-width table-responsive p-0 ">
                <Table className="table-hover revup-table m-0">
                  <thead>
                    <tr>
                      <th className="border-0">#</th>

                      <TableTitle
                        title="Order Id"
                        setSort={setSort}
                        sortBy="id"
                        sort={sort}
                      />
                      <TableTitle
                        title="Customer"
                        setSort={setSort}
                        sortBy={`customer","full_name`}
                        sort={sort}
                      />
                      <TableTitle
                        title="Vehicle"
                        setSort={setSort}
                        sortBy={`vehicle","plate_number`}
                        sort={sort}
                      />
                      <TableTitle
                        title="Start Date"
                        setSort={setSort}
                        sortBy="ride_start"
                        sort={sort}
                      />
                      <TableTitle
                        title="Duration"
                        setSort={setSort}
                        sortBy="duration"
                        sort={sort}
                      />
                      <TableTitle
                        title="Pickup Location"
                        setSort={setSort}
                        sortBy={`location","title`}
                        sort={sort}
                      />
                      <TableTitle
                        title="Ride Fee"
                        setSort={setSort}
                        sortBy="ride_cost"
                        sort={sort}
                      />
                      <th className="border-0 text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading &&
                      [...Array(10)].map((d, i) => <Skelton key={i} />)}
                    {status === "pending" &&
                      [...Array(PageSize)].map((d, i) => <Skelton key={i} />)}
                    {isError && <p>Error </p>}
                    {isSuccess &&
                      status === "fulfilled" &&
                      data?.data?.rides?.map((ride, i) => (
                        <NewRideListCards
                          handleShow={handleShow}
                          key={i}
                          ride={ride}
                          index={page * PageSize + i + 1}
                        />
                      ))}
                    {data?.data?.rides?.length === 0 && (
                      <tr>
                        <td colspan="9" className="empty_items">
                          <Empty
                            description={false}
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                          >
                            <div className="ant-empty-description">
                              <p>No Rides Found</p>
                            </div>
                          </Empty>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col col="12" md="12" lg="12">
            {data?.data?.count > 0 && (
              <Pagination
                current={page + 1}
                defaultCurrent={1}
                defaultPageSize={PageSize}
                onChange={handlePagination}
                total={parseInt(data?.data?.count)}
              />
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Dashboard;
