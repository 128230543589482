import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { WarningFilled, LoadingOutlined } from "@ant-design/icons";
import { Modal, Avatar, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { userSelector, logoutUser } from "features/auth/authSlice";
import { EyeTwoTone, EyeInvisibleOutlined } from "@ant-design/icons";
import { useConfirmPasswordMutation } from "services/passwordConformation";
import { Spinner } from "react-bootstrap";
import { useDeleteCustomerByIdMutation } from "services/customers";
import { useDeleteDriverByIdMutation } from "services/drivers";
import { useNavigate } from "react-router-dom";

function PasswordModal({
  showPasswordModal,
  hidePasswordModalHandler,
  type,
  userId,
}) {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const [password, setPassword] = useState(true);
  const [loading, setLoading] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const { data } = useSelector(userSelector);
  const userData = data?.data?.user;
  const [confirmPassword] = useConfirmPasswordMutation();
  const [deleteCustomerById] = useDeleteCustomerByIdMutation();
  const [deleteDriverById] = useDeleteDriverByIdMutation();
  const onSubmit = async (data) => {
    setLoading(true);
    const verifyData = {
      username: userData.email,
      password: data.password,
    };
    const confirmPasswordReq = await confirmPassword({ verifyData });
    if (confirmPasswordReq?.data?.message === "Success") {
      setLoading(false);
      message.success("Password verified successfully");
      setDeleting(true);
      if (type === "customer") {
        const deleteCustomerReq = await deleteCustomerById(userId);
        console.log(deleteCustomerReq);
        if (deleteCustomerReq?.data?.message === "Deleted") {
          message.success("Customer deleted successfully");
          navigate("/admin/customers");
        } else {
          message.error("Error deleting customer");
          setDeleting(false);
        }
      }
      if (type === "driver") {
        const deleteDriverReq = await deleteDriverById(userId);
        console.log(deleteDriverReq);
        if (deleteDriverReq?.data?.message === "Deleted") {
          message.success("Driver deleted successfully");
          navigate("/admin/drivers");
        } else {
          message.error("Error deleting Driver");
          setDeleting(false);
        }
      }
    } else {
      setPasswordError(
        (confirmPasswordReq?.error?.data?.error === "Unauthorized" &&
          "Incorrect Password") ||
          "Password not verified"
      );
      setLoading(false);
      message.error(
        (confirmPasswordReq?.error?.data?.error === "Unauthorized" &&
          "Incorrect Password") ||
          "Password not verified"
      );
    }
  };
  return (
    <Modal
      visible={showPasswordModal}
      footer={null}
      centered
      onCancel={hidePasswordModalHandler}
    >
      {!deleting && (
        <aside className=" p-4 d-flex justify-content-center flex-column password_conform_main">
          <WarningFilled
            style={{
              fontSize: "50px",
              color: "#ff0000",
              marginBottom: "10px",
            }}
          />
          <h4 className="text-center m-2 ">
            <strong>
              Are you sure to delete the user from the application?
            </strong>
          </h4>
          <p className="text-center m-0   ">
            Once deleted, this account cannot be retrieved
          </p>
          <p className="text-center mt-4 mb-2">
            Please update your password to confirm
          </p>
          <div className="position-relative ">
            <input
              type={password ? "password" : "text"}
              placeholder="Password"
              className="   form-control text-center text-gray"
              {...register("password", { required: true })}
              onChange={() => setPasswordError(false)}
            />
            <div
              onClick={() => setPassword(!password)}
              className="show_false btn"
            >
              {password ? (
                <EyeInvisibleOutlined style={{ color: "#000" }} />
              ) : (
                <EyeTwoTone style={{ color: "#000" }} />
              )}
            </div>
          </div>
          {errors?.password && (
            <div className="text-danger text-center m-auto  py-2">
              Password is required
            </div>
          )}
          {passwordError && (
            <div className="text-danger text-center m-auto py-2">
              {passwordError}
            </div>
          )}
          {loading && (
            <Spinner
              className="mx-auto mt-1 mb-3
          d-flex"
              size="sm"
              animation="border"
              variant="primary"
            />
          )}

          <div className="d-flex justify-content-center mt-4">
            {/* <button
              className="btn btn-outline-dark px-4  mr-2"
              onClick={hidePasswordModalHandler}
            >
              Cancel
            </button> */}
            <input
              type="submit"
              value={"Confirm"}
              className="btn btn-danger ml-2"
              onClick={handleSubmit(onSubmit)}
            />
          </div>
        </aside>
      )}
      {deleting && (
        <img
          src={require("assets/img/deleting.gif").default}
          alt="deleting"
          className="deleting_gif"
        />
      )}
    </Modal>
  );
}

export default PasswordModal;
