import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getToken } from "cookies/token";
import { authCheck } from "./functions/authCheck";

const header = (headers, { getState }) => {
  const token = getToken();
  if (token) {
    headers.set("authorization", `Bearer ${token}`);
  }
  return headers;
};
export const dashboardApi = createApi({
  reducerPath: "DashboardApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: header,
  }),
  tagTypes: ["Dashboard"],
  endpoints: (builder) => ({
    getDashboard: builder.query({
      query: (...data) => {
        return {
          url: `/user/admin_dashboard`,
          method: "GET",
          validateStatus: (response, result) => authCheck(response, result),
        };
      },
    }),
  }),
});

export const { useGetDashboardQuery } = dashboardApi;
