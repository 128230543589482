import React from "react";

function DetailSkeleton() {
  return (
    <div className="content">
      <div className="container-fluid">
        <div className="mb-3 rounded-0 rounded-top border-top-0 border-left-0 border-right-0  card">
          <div className="card-body">
            <div className="align-items-center row">
              <div col={12} className="col-xl-10">
                <div className="d-flex align-items-center">
                  <h3 className="m-0 pb-0 font-weight-bold mr-2 skeleton">
                    #OR100002
                  </h3>
                </div>
                <h6 className="secondary-gray m-0 pb-2">
                  <span className="skeleton">skeleton</span>
                </h6>
              </div>
              <div col={12} className="my-1 col-md-2" />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-7">
            <div className=" px-3">
              <div className="bg-white  py-3 align-items-center mb-4 rounded row">
                <div className="col-md-3">
                  <span
                    className="skeleton rounded"
                    style={{ display: "inline-block" }}
                  >
                    <img
                      className="img-fluid rounded skeleton opacity-0"
                      src="https://via.placeholder.com/500x500"
                    />
                  </span>
                </div>
                <div className="col-md-9">
                  <h4 className="m-0 pb-1 font-weight-bold">
                    <span className="skeleton">skeleton dsd sdfsd</span>
                  </h4>
                  <h5 className="secondary-gray m-0 pb-1">
                    <span className="skeleton">skeleton dfg fdg sdfds</span>
                  </h5>
                  <h5 className="secondary-gray m-0 pb-1">
                    <span className="skeleton">skeleton</span>
                  </h5>
                  <h5 className="secondary-gray m-0 pb-1">
                    <span className="skeleton">sken</span>{" "}
                    <strong className="font-weight-bold text-black">
                      {" "}
                      <span className="skeleton">skon</span>
                    </strong>
                    <span className="ml-2 text- skeleton">Track Now</span>
                    <strong className="text- skeleton"> KH0213654</strong>
                  </h5>
                </div>
              </div>
              <div className="bg-white  py-3 align-items-center mb-4 rounded row">
                <div className="col-md-3">
                  <span
                    className="skeleton rounded"
                    style={{ display: "inline-block" }}
                  >
                    <img
                      className="img-fluid rounded skeleton opacity-0"
                      src="https://via.placeholder.com/500x500"
                    />
                  </span>
                </div>
                <div className="col-md-9">
                  <h4 className="m-0 pb-1 font-weight-bold">
                    <span className="skeleton">skeleton dsd sdfsd</span>
                  </h4>
                  <h5 className="secondary-gray m-0 pb-1">
                    <span className="skeleton">skeleton dfg fdg sdfds</span>
                  </h5>
                  <h5 className="secondary-gray m-0 pb-1">
                    <span className="skeleton">skeleton</span>
                  </h5>
                  <h5 className="secondary-gray m-0 pb-1">
                    <span className="skeleton">sken</span>{" "}
                    <strong className="font-weight-bold text-black">
                      {" "}
                      <span className="skeleton">skon</span>
                    </strong>
                    <span className="ml-2  skeleton">Track Now</span>
                    <strong className=" skeleton"> KH0213654</strong>
                  </h5>
                </div>
              </div>
            </div>
            <div className="bg-white p-3 rounded">
              <h5 className="font-weight-bold pb-3 m-0 black-one">
                DELIVER TO
              </h5>
              <div className="border p-3 mb-3">
                <div className="align-items-center row">
                  <div className="mb-3 mb-md-0 col-lg-6">
                    <div className="d-flex align-items-center">
                      <div className="thumbnail-round mr-3 skeleton"></div>
                      <div>
                        <h5 className="m-0 pb-1">
                          <b className="skeleton">Shawn Tom</b>
                        </h5>
                        <h6 className=" m-0 skeleton">#12365456</h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <h6 className="m-0 pb-1 skeleton">
                      <span className="text-gray opacity-0">Phone </span>+1
                      99856 25642
                    </h6>
                    <h6 className="m-0 skeleton">
                      <span className="opacity-0">Email </span>
                      shawntom@example.com
                    </h6>
                  </div>
                </div>
              </div>
              <div className="bg-white  p-3 mb-3">
                <h5 className="font-weight-bold pb-3 m-0 black-one skeleton">
                  DELIVERY ADDRESS
                </h5>
                <p>
                  <span className="skeleton">285 Fulton St,</span>
                </p>
                <p>
                  <span className="skeleton">
                    285 Fulton St, New York, NY 10007
                  </span>
                </p>
                <h5 className="font-weight-bold pb-3 m-0 black-one">
                  <span className="skeleton"> SHIPMENT INFO </span>
                </h5>
              </div>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="bg-white p-3 p-3 mb-3">
              <div className="d-flex flex-column flex-md-row py-2">
                <h5 className="font-weight-bold pr-2 m-0 skeleton">INVOICE</h5>
                <h5 className="text-">
                  <u className="skeleton">1233456654</u>
                </h5>
              </div>
              <div className="row">
                <div col={8} className="pb-2 col">
                  <h5 className="m-0 skeleton">Ride Cost ($350/Hour)</h5>
                </div>
                <div col={4} className="pb-2 text-right col">
                  <h5 className="m-0">
                    <b className="skeleton">$1750.00</b>
                  </h5>
                </div>
              </div>
              <div className="row">
                <div col={8} className="pb-2 col">
                  <h5 className="m-0 skeleton">Service Chanrge</h5>
                </div>
                <div col={4} className="pb-2 text-right col">
                  <h5 className="m-0 skeleton">-$ 31.00</h5>
                </div>
              </div>
              <div className="row">
                <div col={8} className="pb-2 col">
                  <h5 className="m-0 skeleton">Coupon Code Applied</h5>
                </div>
                <div col={4} className="pb-2 text-right col">
                  <h5 className="m-0 text- skeleton">-$ 100.00</h5>
                </div>
              </div>
              <hr />
              <div className="align-items-center mb-3 row">
                <div col={8} className="pb-2 col">
                  <h5 className="m-0 skeleton">
                    <b>TOTAL</b>
                  </h5>
                </div>
                <div col={4} className="pb-2 text-right col">
                  <h3 className="m-0 font-weight-bold skeleton">$1650.00</h3>
                </div>
              </div>
              <p className="mb-0">
                {" "}
                <span className="skeleton">
                  285 Fulton St, New York, NY 10007
                </span>
              </p>
              <p className="mb-0">
                {" "}
                <span className="skeleton">
                  285 Fulton St, New York, NY 10007
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetailSkeleton;
