import React from "react";
export function FilterBy({
  e,
  filterKey,
  setFilter,
  filter,
  setPageNumber,
  filterItems,
}) {
  return (
    <select
      className="form-control form-select form-select-lg "
      aria-label=".form-select-lg example"
      onChange={(e) => {
        if (e.target.value === "0") {
          const { [filterKey]: any, ...newResponse } = filter;
          setFilter((prev) => newResponse);
          setPageNumber(0);
        } else {
          if (e.target.value === "true" || e.target.value === "false") {
            let boolean = e.target.value === "true" ? true : false;
            setFilter({ ...filter, [filterKey]: boolean });
          } else {
            setFilter({ ...filter, [filterKey]: e.target.value });
          }
          setPageNumber(0);
        }
      }}
    >
      <option selected className="d-none">
        Filter by
      </option>
      <option value="0">All</option>
      {filterItems.map((item) => (
        <option key={item.value} value={item.value}>
          {item.name}
        </option>
      ))}
    </select>
  );
}
