import React, { useEffect, useRef, useState } from "react";
import { Empty, Pagination } from "antd"; // react-bootstrap components
import {
  Button,
  Card,
  Table,
  Container,
  Row,
  Col,
  Form,
  Modal,
  InputGroup,
} from "react-bootstrap";
import AddEditModal from "components/Products/AddEditModal";
import Skeleton from "components/Products/Skeleton";
import ListCard from "components/Products/ListCard";
import { useGetAllProductQuery } from "services/products";
import TableTitle from "components/Products/List/TableTitle";
import MetaTitle from "components/Meta";
import { CloseCircleOutlined } from "@ant-design/icons";
function Products() {
  const searchValue = useRef("");
  const [sort, setSort] = useState({
    sortBy: "created_at",
    sortDirection: "desc",
  });
  const [filter, setFilter] = useState("");
  const [search, setSearch] = useState("");
  const [PageSize, setPageSize] = useState(10); // default page size change value in query also
  const [page, setPageNumber] = useState(0);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { isLoading, isError, isSuccess, data, status } = useGetAllProductQuery(
    { page, PageSize, sort, filter, search },
    {
      refetchOnMountOrArgChange: false,
    }
  );
  const handlePagination = (page, pageSize) => {
    setPageNumber((prev) => page - 1);
    setPageSize(pageSize);
  };
  return (
    <>
      <MetaTitle text="Products" />
      <AddEditModal show={show} handleClose={handleClose} />
      <Container fluid>
        <Card className="mb-3 border-top-0 border-left-0 border-right-0 rounded-0 ">
          <Card.Body>
            <Row className="align-items-center">
              <Col col="12" md="8" lg="10">
                <h3 className="m-0 py-2 font-weight-bold">Products</h3>
              </Col>
              <Col col="12" md="4" lg="2">
                <Button
                  className="w-100 btn-primary"
                  type="submit"
                  onClick={handleShow}
                >
                  Add New Products
                </Button>
              </Col>
            </Row>
          </Card.Body>
        </Card>

        <div className="bg-white ">
          <Card className="mb-0 border-bottom-0 rounded-0">
            <Card.Body>
              <Row className="align-items-center">
                <Col col="12" lg="6" xl="4">
                  <div className="position-relative">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search Products"
                      ref={searchValue}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    {searchValue?.current?.value?.length > 0 && (
                      <div
                        onClick={() => {
                          searchValue.current.value = "";
                          setSearch("");
                        }}
                        className="show_false btn"
                      >
                        <CloseCircleOutlined />
                      </div>
                    )}
                  </div>
                </Col>
                <Col col="12" lg="6" xl="2" className="ml-auto mt-2 mt-md-0">
                  <select
                    className="custom-select form-control"
                    placeholder="Filter"
                    onChange={(e) => {
                      if (e.target.value === "all") {
                        setFilter("");
                        setPageNumber(0);
                      } else {
                        setPageNumber(0);
                        if (e.target.value === "0") {
                          setFilter(
                            "&where=%7B%22stock%22%3A" + e.target.value + "%7D"
                          );
                        } else {
                          setFilter(
                            "&where=%7B%22active%22%3A" + e.target.value + "%7D"
                          );
                        }
                      }
                    }}
                  >
                    <option value="all" className="d-none">
                      Filter By
                    </option>
                    <option value="all">All</option>
                    <option value="true">Active</option>
                    <option value="false">Inactive</option>
                    <option value="0">Out Of stock</option>
                  </select>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <div className="table-full-width table-responsive border">
            <Table className="table-hover revup-table m-0">
              <thead>
                <tr>
                  <th className="border-0">#</th>
                  <TableTitle
                    title="Product ID"
                    setSort={setSort}
                    sortBy="id"
                    sort={sort}
                  />
                  <TableTitle
                    title="Item"
                    setSort={setSort}
                    sortBy="title"
                    sort={sort}
                  />
                  <TableTitle
                    title="Qty Available"
                    setSort={setSort}
                    sortBy="stock"
                    sort={sort}
                  />
                  <TableTitle
                    title="List Price"
                    setSort={setSort}
                    sortBy="unit_price"
                    sort={sort}
                  />
                  <TableTitle
                    title="Product Added On"
                    setSort={setSort}
                    sortBy="created_at"
                    sort={sort}
                  />
                  <TableTitle
                    title="# Orders"
                    setSort={setSort}
                    sortBy="orders"
                    sort={sort}
                  />
                  <th className="border-0 ">Status</th>
                  {/* <th className="border-0  text-center">Action</th> */}
                </tr>
              </thead>
              <tbody>
                {isLoading &&
                  [...Array(10)].map((d, i) => <Skeleton key={i} />)}
                {status === "pending" &&
                  [...Array(PageSize)].map((d, i) => <Skeleton key={i} />)}
                {isError && <p>Error </p>}
                {isSuccess &&
                  status === "fulfilled" &&
                  data?.data?.products.map((product, i) => (
                    <ListCard
                      key={i}
                      product={product}
                      index={page * PageSize + i + 1}
                    />
                  ))}
                {data?.data?.products.length === 0 && (
                  <tr>
                    <td colspan="9" className="empty_items">
                      <Empty
                        description={false}
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                      >
                        <div className="ant-empty-description">
                          <p>No Products Found</p>
                        </div>
                      </Empty>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </div>
        <Row className="mt-3">
          <Col col="12" md="12" lg="12">
            {data?.data?.count > 0 && (
              <Pagination
                current={page + 1}
                defaultCurrent={1}
                defaultPageSize={PageSize}
                onChange={handlePagination}
                total={parseInt(data?.data?.count)}
              />
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Products;
