import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { Avatar, Image } from "antd";
import { Popover } from "antd";
export const checkStyle = (e) => {
  switch (e) {
    case "Ordered":
      return "ordered";
      break;
    case "Cancelled":
      return "cancelled";
      break;
    case "Shipped":
      return "on-the-way";
      break;
    case "Delivered":
      return "delivered";
      break;
    default:
      break;
  }
};
function ListCard({ order, index }) {
  const firstIndexStatus = order?.order_item[0]?.status;
  //console.log(order);
  const content = (
    <>
      {order?.order_item?.map((item, index) => (
        <div key={index} className="d-flex align-items-center ">
          <div className="thumbnail-box mr-3">
            <Avatar
              shape="square"
              src={
                <Image
                  src={
                    item?.product?.product_image[0]?.image ||
                    "www.placehold.it/100x100"
                  }
                />
              }
            />
          </div>
          <h5 className="m-0">
            {item?.product?.title.substring(0, 40)}
            {item?.product?.title.length > 40 && "..."}
            <span className="text-gray"> x {item?.qty}</span>
          </h5>
        </div>
      ))}
    </>
  );
  const statusContent = (
    <>
      {order?.order_item?.map((item, index) => (
        <div
          key={index}
          className={`d-flex align-items-center ` + checkStyle(item?.status)}
        >
          <span></span>
          {item?.status} - {item?.qty}
        </div>
      ))}
    </>
  );
  return (
    <tr>
      <td>{index}</td>
      <td>
        <Link to={`/admin/orders/${order.id} `}>
          <p  className="text-primary p-0 m-0">
            {order?.code}
          </p>
        </Link>
      </td>
      <td>
        <div className="d-flex align-items-center">
          <div className="thumbnail-box mr-3">
            <Avatar
              shape="square"
              src={
                <Image
                  src={
                    order?.order_item[0]?.product?.product_image[0]?.image ||
                    "www.placehold.it/100x100"
                  }
                />
              }
            />
          </div>
          <h5 className="m-0">
            {order?.order_item[0]?.product?.title?.substring(0, 25)}
            {order?.order_item[0]?.product?.title?.length > 25 && "..."}
            <br />

            {order?.order_item?.length > 1 && (
              <Popover content={content}>
                <span className="text-gray btn p-0">
                  +{parseInt(order?.total_qty) - 1} Products
                </span>
              </Popover>
            )}
          </h5>
        </div>
      </td>
      <td>
        <h5 className="m-0">{order?.total_qty}</h5>
      </td>
      <td>
        <h5 className="m-0">
          <b>$ {order?.total_amount}</b>
        </h5>
      </td>
      <td>
        <h5 className="m-0">{order?.customer?.full_name}</h5>
      </td>
      <td>
        <h5 className="m-0">
          {moment(order?.created_at).format("MM/DD/YY, h:mm:ss a")}{" "}
        </h5>
      </td>
      <td className={checkStyle(firstIndexStatus)}>
        <span></span>
        {firstIndexStatus}
        <br />
        {order?.order_item?.length > 1 && (
          <Popover content={statusContent}>
            <p className="text-gray btn p-0 m-0">
              +{parseInt(order?.total_qty) - 1} Status
            </p>
          </Popover>
        )}
      </td>
    </tr>
  );
}

export default ListCard;
