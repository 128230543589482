import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import PickupCard from "components/PickupLocations/Card";
import Skeleton from "components/PickupLocations/Skeleton";
// react-bootstrap components
import { Button, Card, Container, Row, Col, Modal } from "react-bootstrap";
import AddEditLocationModal from "components/PickupLocations/AddEditLocationModal";
import { useGetAllPickupLocationQuery } from "services/pickupLocations";
import { Empty } from "antd";
import EditLocationModal from "components/PickupLocations/EditLocationModal";
import { AutoComplete } from "components/PickupLocations/autoComplete";
function CouponCodes() {
  // initial set up for Item
  const [populate, setPopulate] = useState([
    "vehicle_image",
    "vehicle_availability",
  ]);
  const [filter, setFilter] = useState("");
  const [sort, setSort] = useState({
    sortBy: "created_at",
    sortDirection: "desc",
  });
  const [PageSize, setPageSize] = useState(100); // default page size change value in query also
  const [page, setPageNumber] = useState(0);
  // initial set up for Item END
  const { isLoading, isError, isSuccess, data, status } =
    useGetAllPickupLocationQuery(
      { page, PageSize, sort, filter },
      {
        refetchOnMountOrArgChange: true,
      }
    );
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setEditData(null);
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
  };
  const [editData, setEditData] = useState(null);
  const EditTrigger = (id) => {
    setEditData(id);
    handleShow();
  };

  return (
    <>
      {editData ? (
        <EditLocationModal
          show={show}
          handleClose={handleClose}
          editData={editData}
        />
      ) : (
        <AddEditLocationModal show={show} handleClose={handleClose} />
      )}

      <div className="d-none">
        <AutoComplete />
      </div>
      <Container fluid>
        <Card className="mb-3 border-top-0 border-left-0 border-right-0 rounded-0 ">
          <Card.Body>
            <Row className="align-items-center">
              <Col col="12" md="8" lg="10">
                <h3 className="m-0 py-2 font-weight-bold">Pickup Locations</h3>
              </Col>

              <Col col="12" md="4" lg="2">
                <Button
                  className="w-100 btn-primary btn"
                  type="submit"
                  onClick={handleShow}
                >
                  Add New Location
                </Button>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <Row>
          {isLoading &&
            [...Array(data?.data?.pickup_locations?.length || 10)].map(
              (d, i) => <Skeleton key={i} />
            )}
          {status === "pending" &&
            [...Array(PageSize)].map((d, i) => <Skeleton key={i} />)}
          {isError && <p>Error </p>}
          {isSuccess &&
            status === "fulfilled" &&
            data?.data?.pickup_locations?.map((location, i) => (
              <PickupCard
                key={i}
                location={location}
                EditTrigger={EditTrigger}
              />
            ))}
          {data?.data?.pickup_locations?.length === 0 && (
            <tr>
              <td colspan="9" className="empty_items">
                <Empty description={false} image={Empty.PRESENTED_IMAGE_SIMPLE}>
                  <div className="ant-empty-description">
                    <p>No Pickup Location Found</p>
                  </div>
                </Empty>
              </td>
            </tr>
          )}
        </Row>
      </Container>
    </>
  );
}

export default CouponCodes;
