import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { useUpdateProductByIdMutation } from "services/products";
import { Popconfirm, message } from "antd";
import { Avatar, Image } from "antd";

function ListCard({ product, index }) {
  const [updateProductById] = useUpdateProductByIdMutation();
  //console.log(product);
  async function confirm(e) {
    if (product?.id) {
      let updateData = [product?.id, { active: e }];
      const changeStatusResponds = await updateProductById(updateData);
      if (changeStatusResponds?.data?.message === "Updated") {
        message.success("Product status changed successfully");
      }
      if (changeStatusResponds?.error) {
        message.error("Something went wrong");
      }
    }
  }
  return (
    <tr>
      <td>{index}</td>
      <td>
        <Link to={`/admin/products/${product.id}`}>
          <p className="text-primary p-0 m-0">{product?.code}</p>
        </Link>
      </td>
      <td>
        <div className="d-flex align-items-center">
          <div className="thumbnail-box mr-3">
            <Avatar
              shape="square"
              src={
                <Image
                  src={
                    product?.product_image[0]?.image ||
                    "http://www.placehold.it/100x100"
                  }
                />
              }
            />
          </div>
          <h6 className="m-0">
            {product?.title.substring(0, 25)}
            {product?.title.length > 25 && "..."}
            <br />
            <span className="text-gray">
              {product?.brand.substring(0, 25)}
              {product?.brand.length > 25 && "..."}
            </span>
          </h6>
        </div>
      </td>
      <td className="d-flex justify-content-center">
        <h4 className="m-0">
          <span
            className={`badge bg-${
              product?.stock < 50 ? "primary" : "success"
            } text-white p-2 font-weight-light ${
              product?.stock === 0 ? " warning_red " : ""
            }`}
          >
            {product?.stock === 0 ? "Out Of Stock" : product?.stock}
          </span>
        </h4>
      </td>
      <td>
        <b>$ {product?.unit_price}</b>
      </td>
      <td>{moment(product?.created_at).format("MM/DD/YY, h:mm:ss a")}</td>
      <td>
        <b>{product?.orders}</b>
      </td>
      {product?.active ? (
        <td className="text-active">
          <span></span>
          Active
        </td>
      ) : (
        <td className="text-primary-s">
          <span></span>
          Inactive
        </td>
      )}
      {/* {product?.active ? (
        <td>
          <Popconfirm
            title="Are you sure you want to deactivate this product?"
            onConfirm={() => confirm(false)}
            okText="Yes"
            cancelText="No"
            placement="left"
          >
            {" "}
            <button
              type="button"
              className="btn btn-outline-danger w-100 font-weight-bold"
            >
              Deactivate
            </button>
          </Popconfirm>
        </td>
      ) : (
        <td>
          <Popconfirm
            title="Are you sure you want to activate this product?"
            onConfirm={() => confirm(true)}
            okText="Yes"
            cancelText="No"
            placement="left"
          >
            {" "}
            <button
              type="button"
              className="btn btn-success w-100 font-weight-bold"
            >
              Activate
            </button>
          </Popconfirm>
        </td>
      )} */}
    </tr>
  );
}

export default ListCard;
