import React from "react";

function Skeleton() {
  return (
    <tr>
      <td>
        <span className="skeleton">1</span>{" "}
      </td>
      <td>
        <h5 className="m-0 text-dark-blue font-weight-bold skeleton">WEL50</h5>
      </td>
      <td className="font-weight-bold">
        <span className="skeleton"> ride ride</span>
      </td>
      <td className="font-weight-bold ">
        <span className="skeleton">Price Discount- 50%</span>
      </td>
      <td>
        <h5 className="m-0 skeleton ">
          25/11/2021 <span className=""> 12:00 pm</span>
        </h5>
      </td>
      <td>
        <h5 className="m-0 skeleton">
          25/11/2021 <span className=""> 12:00 pm</span>
        </h5>
      </td>
      <td>
        <span className="skeleton">250</span>
      </td>
      <td>
        <h5 className="m-0 skeleton ">
          25/11/2021 <span className=""> 12:00 pm</span>
        </h5>
      </td>
      <td className="text-active">
        <i className="skeleton">Active</i>
      </td>
      <td>
        <span className="skeleton w-100">
          {" "}
          <button type="button" className="btn btn-outline- opacity-0">
            Edit
          </button>
          <button type="button" className="btn btn-outline- mx-2 opacity-0">
            Block
          </button>
          <button className="btn btn- opacity-0" type="submit">
            Delete
          </button>
        </span>
      </td>
    </tr>
  );
}

export default Skeleton;
