import React from "react";

function Skelton() {
  return (
    <tr>
      <td>
        <span className="skeleton">1</span>
      </td>
      <td>
        <div className=" skeleton">______</div>
      </td>
      <td>
        <div className="d-flex align-items-center">
          <div className="thumbnail-round mr-3 skeleton"></div>
          <div>
            <h5 className="m-0 text-truncate">
              <b className="skeleton">Shawn Tom</b>
            </h5>
          </div>
        </div>
      </td>
      <td>
        <h5 className="m-0 skeleton">
          TEXAS 202 HSZ
          <br />
          Lamborghini Aventador
        </h5>
      </td>
      <td>
        <h5 className="m-0 skeleton">
          <b>Oct 16</b> Saturday, 8:00 pm
        </h5>
      </td>
      <td>
        <h5 className="m-0 skeleton">
          5 <span className="text-gray skeleton">Hours</span>
        </h5>
      </td>
      <td>
        <h5 className="m-0 skeleton">World Trade Center</h5>
      </td>
      <td>
        <div className="d-flex align-items-center">
          <div className="thumbnail-round mr-3 skeleton"></div>

          <div>
            <h5 className="m-0 text-truncate skeleton">
              <b>Shawn Tom</b>
            </h5>
          </div>
        </div>
      </td>
      <td className="text-completed ">
        <p className="skeleton">skeleton</p>
      </td>
    </tr>
  );
}

export default Skelton;
