import React from "react";
import { Col } from "reactstrap";
import { Popconfirm, message } from "antd";
import { useDeletePeakDayMutation } from "services/peakDays";
import moment from "moment";

function PeakDayCard({ type, handleShow, vehicle, peak }) {
  const [deletePeakDay] = useDeletePeakDayMutation();
  async function confirm(e) {
    const deleteResp = await deletePeakDay(peak.id);
    if (deleteResp?.data?.message === "Deleted") {
      message.success("Peak Day Deleted Successfully");
    } else {
      message.error("Something went wrong");
    }
  }

  return type === "Skeleton" ? (
    <PeakSkeleton />
  ) : (
    <Col Col="12" lg="6" className="mb-3">
      <div className="outline-card d-flex justify-content-between flex-column flex-lg-row">
        <h5 className="m-0 text-truncate">
          {moment(peak?.peak_day).format("MM/DD/YY")}
          <Popconfirm
            title="Are you sure to Remove Peak Day?"
            onConfirm={confirm}
            okText="Remove"
            cancelText="No"
          >
            <a className="text-primary px-3">Remove</a>{" "}
          </Popconfirm>
          <a onClick={() => handleShow(vehicle, peak)}>Edit</a>
        </h5>
        <h5 className="m-0 text-primary text-truncate text-right mt-2 mt-lg-0">
          {" "}
          <b>{peak?.rate}</b> / Hour
        </h5>
      </div>
    </Col>
  );
}

export default PeakDayCard;

function PeakSkeleton({}) {
  return (
    <Col Col="12" lg="6" className="mb-3">
      <div className="outline-card d-flex justify-content-between flex-column flex-lg-row border_nun">
        <h5 className="m-0 text-truncate">
          <span className="skeleton mr-1"> Dec 24, 2021</span>
          <a href="#" className=" px-3 skeleton">
            Remove
          </a>
          <a href="#" className="skeleton">
            Edit
          </a>
        </h5>
        <h5 className="m-0 text-primary text-truncate text-right mt-2 mt-lg-0">
          <b className="skeleton">$500 / Hour</b>
        </h5>
      </div>
    </Col>
  );
}
