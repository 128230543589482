import React from "react";
import { Link } from "react-router-dom";
import { Avatar, Image } from "antd";
import moment from "moment";
function RideCard({ type, ride, index }) {
  return type === "Skeleton" ? (
    <tr>
      <td>
        <span className="skeleton">1</span>
      </td>
      <td>
        <u>
          <a href="#" className="text-primary skeleton">
            1236589
          </a>
        </u>
      </td>
      <td>
        <div className="d-flex align-items-center">
          <div className="thumbnail-round mr-3 skeleton"></div>
          <h5 className="m-0">
            <b className="skeleton">Shawn Tom</b>
          </h5>
        </div>
      </td>
      <td>
        <h5 className="m-0 ">
          <span className="font-weight-bold skeleton">Oct 16,</span>{" "}
          <span className="skeleton">Saturday, 8:00 pm</span>
        </h5>
      </td>
      <td>
        <h5 className="m-0 ">
          <span className="font-weight-bold skeleton">Oct 16,</span>{" "}
          <span className="skeleton">Saturday, 8:00 pm</span>
        </h5>
      </td>
      <td>
        <h5 className="m-0 ">
          <span className="skeleton">5:00</span>
        </h5>
      </td>
      <td>
        <h5 className="m-0 ">
          <span className="skeleton">World Trade Center</span>
        </h5>
      </td>

      <td>
        <h5 className="m-0 font-weight-bold">
          <span className="skeleton">1781.00</span>
        </h5>
      </td>
      <td>
        <div className="d-flex align-items-center">
          <div className="thumbnail-round mr-3 skeleton"></div>
          <h5 className="m-0">
            <b className="skeleton">Shawn Tom</b>
          </h5>
        </div>
      </td>
      <td className="text-completed">
        <b className="skeleton">skeleton</b>
      </td>
    </tr>
  ) : (
    <tr>
      <td>
        <span>{index}</span>
      </td>
      <td>
        <p className="text-primary p-0 m-0">{ride.ride_code}</p>
      </td>
      <td>
        <div className="d-flex align-items-center">
          <div className="thumbnail-round mr-3">
            <Avatar
              shape="square"
              className="w-100 h-100"
              src={
                <Image
                  src={
                    ride?.customer?.image ||
                    `https://joeschmoe.io/api/v1/${ride?.customer?.full_name}`
                  }
                />
              }
            />
          </div>
          <h5 className="m-0">
            <b>{ride?.customer?.full_name}</b>
          </h5>
        </div>
      </td>
      <td>
        <h5 className="m-0 ">
          {moment(ride?.ride_start).format("MM/DD/YY, h:mm a")}
        </h5>
      </td>
      <td>
        <h5 className="m-0 ">
          {moment(ride?.ride_end).format("MM/DD/YY, h:mm a")}
        </h5>
      </td>
      <td>
        <h5 className="m-0 text-center ">
          {}
          {Math.floor(ride?.duration / 60)}
          {ride?.duration % 60 > 0 && ":" + (ride?.duration % 60)}
          {Math.floor(ride?.duration / 60) > 1 ? (
            <span class="text-gray"> Hours</span>
          ) : (
            <span class="text-gray"> Hour</span>
          )}
        </h5>
      </td>
      <td>
        <h5 className="m-0 ">{ride?.location?.title}</h5>
      </td>

      <td>
        <h5 className="m-0 font-weight-bold">${ride?.ride_cost}</h5>
      </td>
      <td>
        {ride?.driver?.full_name ? (
          <div className="d-flex align-items-center">
            <div className="thumbnail-round mr-3">
              <Avatar
                shape="square"
                className="w-100 h-100"
                src={
                  <Image
                    src={
                      ride?.driver?.image ||
                      `https://joeschmoe.io/api/v1/${ride?.driver?.full_name}`
                    }
                  />
                }
              />
            </div>
            <h5 className="m-0">
              <b>{ride?.driver?.full_name}</b>
            </h5>
          </div>
        ) : (
          <div className="d-flex align-items-center justify-content-center">
           <h5 className="text-primary">Driver Unassigned</h5>
          </div>
        )}
      </td>
      <td className="ride_status" style={{ color: ride?.ride_status.color }}>
        <span style={{ backgroundColor: ride?.ride_status.color }}></span>
        {ride?.ride_status.name}
      </td>
    </tr>
  );
}

export default RideCard;
