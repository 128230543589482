import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getToken } from "cookies/token";
import { authCheck } from "./functions/authCheck";

const header = (headers, { getState }) => {
  const token = getToken();
  if (token) {
    headers.set("authorization", `Bearer ${token}`);
  }
  return headers;
};
export const CustomersApi = createApi({
  reducerPath: "CustomersApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: header,
  }),
  tagTypes: ["Customer"],
  endpoints: (builder) => ({
    getCustomerById: builder.query({
      query: ({ id, populate, sort }) => {
        const populateData = encodeURIComponent(JSON.stringify(populate));
        return {
          url: `/user/customer/${id}?populate=${populateData}&sort=%5B%5B%22${sort.sortBy}%22%2C%22${sort.sortDirection}%22%5D%5D`,
          method: "GET",
          validateStatus: (response, result) => authCheck(response, result),
        };
      },
      providesTags: ["Customer"],
    }),
    getAllCustomers: builder.query({
      query: (...data) => {
        const populate = encodeURIComponent(JSON.stringify([]));
        const filter = encodeURIComponent(JSON.stringify(data[0].filter));
        const sort = encodeURIComponent(
          JSON.stringify([[data[0].sort.sortBy, data[0].sort.sortDirection]])
        );
        return {
          url: `/user/customer?offset=${
            data[0].PageSize * data[0].page
          }&limit=${
            data[0].PageSize
          }&populate=${populate}&sort=${sort}&where=${filter}`,
          method: "GET",
          validateStatus: (response, result) => authCheck(response, result),
        };
      },
      providesTags: ["Customer"],
    }),
    updateCustomerById: builder.mutation({
      query: (user) => {
        return {
          url: `/user/${user.id}`,
          method: "PUT",
          body: { active: user.active },
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        };
      },
      invalidatesTags: ["Customer"],
    }),
    deleteCustomerById: builder.mutation({
      query: (id) => {
        return {
          url: `/user/${id}`,
          method: "DELETE",
        };
      },
    }),
  }),
});

export const {
  useGetCustomerByIdQuery,
  useGetAllCustomersQuery,
  useUpdateCustomerByIdMutation,
  useDeleteCustomerByIdMutation,
} = CustomersApi;
