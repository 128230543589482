// Initialize Cloud Firestore through Firebase
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
const firebaseApp = initializeApp({
  apiKey: "AIzaSyAzexvO5uoVsVY30DmxHJ8wnmnj6h55W4k",
  authDomain: "revup-nyc.firebaseapp.com",
  projectId: "revup-nyc",
  storageBucket: "revup-nyc.appspot.com",
  messagingSenderId: "314561098694",
  appId: "1:314561098694:web:9c4d485ff9730c677cb8c7",
  measurementId: "G-9Y425D8SP1"
});

export const db = getFirestore();
