import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { Avatar, Image } from "antd";

function DetailRideCard({ ride, index }) {
  return (
    <tr>
      <td>{index}</td>
      <td>
        <Link to={`/admin/rides/${ride?.id}`}>
          <p className="text-primary p-0 m-0">{ride?.ride_code}</p>
        </Link>
      </td>

      <td>
        <h5 className="m-0 "> {moment(ride?.ride_date).format("MM/DD/YY")}</h5>
      </td>
      <td>
        <h5 className="m-0 ">
          {ride?.vehicle.plate_number} <br />
          {ride?.vehicle.name}
        </h5>
      </td>
      <td>
        <h5 className="m-0 ">
          {" "}
          {Math.floor(ride?.duration / 60)}{" "}
          {Math.floor(ride?.duration / 60) === 1 ? "hour" : "hours"}{" "}
        </h5>
      </td>
      <td>
        <h5 className="m-0 ">
          <b>{ride?.location?.title}</b>
        </h5>
      </td>

      <td>
        <h5 className="m-0 font-weight-bold">$ {ride?.ride_cost}</h5>
      </td>
      <td>
        {ride?.driver?.full_name ? (
          <div className="d-flex align-items-center">
            <div className="thumbnail-round mr-3">
              <Avatar
                shape="square"
                className="w-100 h-100"
                src={
                  <Image
                    src={
                      ride?.driver?.image ||
                      `https://joeschmoe.io/api/v1/${ride?.driver?.full_name}`
                    }
                  />
                }
              />
            </div>
            <h5 className="m-0">
              <b>{ride?.driver?.full_name}</b>
            </h5>
          </div>
        ) : (
          <div className="d-flex align-items-center justify-content-center">
            <h5 className="text-primary">Driver Unassigned</h5>
          </div>
        )}
      </td>

      <td className="ride_status" style={{ color: ride?.ride_status?.color }}>
        <span style={{ backgroundColor: ride?.ride_status?.color }}></span>
        {ride?.ride_status?.name}
      </td>
    </tr>
  );
}

export default DetailRideCard;
