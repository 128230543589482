import AssignDriverModal from "components/Rides/AssignDriverModal";
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  Form,
  InputGroup,
  Spinner,
} from "react-bootstrap";
import ScheduleRideModal from "components/Rides/ScheduleRideModal";
import { useGetAllVehiclesQuery } from "services/vehicles";
import { useGetAllPickupLocationQuery } from "services/pickupLocations";
import { useUpdateSpecialBookingMutation } from "services/specialBookings";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { useGetRideByIdQuery } from "services/rides";
import Skeleton from "components/SpecialBookings/Skeleton";
import moment from "moment";
import MetaTitle from "components/Meta";
function EditSpecialBookings() {
  const { id } = useParams();

  const ride = useGetRideByIdQuery(id, {
    refetchOnMountOrArgChange: true,
  });
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    getValues,
    formState: { errors },
  } = useForm({ defaultValues: ride?.data?.data?.ride });
  let ride_date_start_at = "";
  const [schedule, setSchedule] = useState({
    ride_date:
      ride?.data?.data?.ride?.ride_date ||
      new Date().toISOString().slice(0, 10),
    vehicle_id: 1,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    freeslot: 3,
    start_at: moment(ride?.data?.data?.ride?.start_at, "hh").format("LT") || "",
  });
  useEffect(() => {
    if (ride?.data?.data?.ride) {
      console.log(ride?.data?.data?.ride?.ride_date);
      ride_date_start_at =
        ride?.data?.data?.ride?.ride_date +
        " " +
        moment(ride?.data?.data?.ride?.start_at, "hh").format("LT");
      reset({
        ...ride?.data?.data?.ride,
        driver_name: ride?.data?.data?.ride?.driver?.full_name,
        ride_date_start_at,
      });
      setSchedule({
        ride_date:
          ride?.data?.data?.ride?.ride_date ||
          new Date().toISOString().slice(0, 10),
        vehicle_id: 1,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        freeslot: 3,
        start_at:
          moment(ride?.data?.data?.ride?.start_at, "hh").format("LT") || "",
      });
    }

    return () => {
      reset({});
    };
  }, [ride?.data?.data?.ride]);

  const navigate = useNavigate();
  const [updateSpecialBooking] = useUpdateSpecialBookingMutation();
  const [loading, setLoading] = useState(false);
  const [showScheduleRideModal, setShowScheduleRideModal] = useState(false);

  // initial set up for Item
  const [populate, setPopulate] = useState([
    "vehicle_image",
    "vehicle_availability",
  ]);
  const [filter, setFilter] = useState("");
  const [sort, setSort] = useState({
    sortBy: "created_at",
    sortDirection: "desc",
  });
  const [PageSize, setPageSize] = useState(100); // default page size change value in query also
  const [page, setPageNumber] = useState(0);
  // initial set up for Item END
  const { isLoading, isError, isSuccess, data, status } =
    useGetAllVehiclesQuery(
      { page, PageSize, sort, filter, populate },
      {
        refetchOnMountOrArgChange: true,
      }
    );
  const locations = useGetAllPickupLocationQuery(
    { page, PageSize, sort, filter },
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const [pickDriver, setPickDriver] = useState({
    name: ride?.data?.data?.ride?.driver?.full_name,
    id: ride?.data?.data?.ride?.driver_id,
  });

  const handleCloseScheduleRideModal = () => {
    setShowScheduleRideModal(false);
  };
  const handleShowScheduleRideModal = () => {
    const { vehicle_id, duration } = getValues();
    if (vehicle_id && duration) {
      let freeslot = parseInt(duration);
      setSchedule({ ...schedule, vehicle_id, freeslot });
      setShowScheduleRideModal(true);
    } else {
      if (!vehicle_id) {
        message.error("Please select vehicle");
      }
      if (!duration) {
        message.error("Please select duration");
      }
    }
  };
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (rideId) => {
    setShow(true);
  };
  const onSubmit = async (data) => {
    setLoading(true);
    let driver_id = pickDriver?.id;
    let vehicle_id = parseInt(data.vehicle_id);
    let location_id = parseInt(data.location_id);
    let duration = parseInt(data.duration);
    let ride_cost = parseInt(data.ride_cost);
    let upData = {
      ...data,
      ...schedule,
      driver_id,
      vehicle_id,
      location_id,
      duration,
      ride_cost,
    };
    const addSpecialBookingRes = await updateSpecialBooking(upData);
    if (addSpecialBookingRes?.data?.message === "Updated") {
      setLoading(false);
      message.success("Special Booking Updated Successfully");
      reset();
      navigate("/admin/special-booking");
    } else {
      setLoading(false);
      addSpecialBookingRes?.data?.message
        ? message.error(addSpecialBookingRes?.data?.message)
        : message.error("Something went wrong");
    }
  };
  return (
    <>
      <MetaTitle text="Edit Special Booking" />

      <AssignDriverModal
        show={show}
        handleClose={handleClose}
        handleShow={handleShow}
        activeRide={0}
        pickDriver={pickDriver}
        setPickDriver={setPickDriver}
      />
      {ride?.data?.data?.ride && (
        <ScheduleRideModal
          show={showScheduleRideModal}
          handleClose={handleCloseScheduleRideModal}
          schedule={schedule}
          setSchedule={setSchedule}
        />
      )}
      {ride?.isLoading && <Skeleton />}
      {ride?.status === "pending" && <Skeleton />}
      {ride?.isSuccess && ride?.data?.data?.ride && (
        <Container fluid>
          <Card className="m-0 rounded-0 rounded-top border-top-0 border-left-0 border-right-0 ">
            <Card.Body>
              <h3 className="m-0 py-2 font-weight-bold">
                Edit Special Bookings
              </h3>
            </Card.Body>
          </Card>
          <Card className="m-0 rounded-0 rounded-top border-0 special_main ">
            <Card.Body>
              <Row>
                <Col col="12" xl="10" className="m-auto">
                  <div className="py-3">
                    <form
                      className="form-default"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <h4 className="text-black font-weight-bold  pb-2">
                        CUSTOMER DETAILS
                      </h4>
                      <Row>
                        <Col col="12" md="6">
                          <Form.Group>
                            <label>First Name</label>
                            <input
                              className="form-control"
                              type="text"
                              placeholder="First Name"
                              name="first_name"
                              {...register("first_name", {
                                required: true,
                                minLength: 3,
                                maxLength: 64,
                              })}
                            />
                            {errors?.first_name?.type === "required" && (
                              <span className="text-danger">
                                First Name is required
                              </span>
                            )}
                            {errors?.first_name?.type === "minLength" && (
                              <span className="text-danger">
                                First Name must be at least 3 characters long
                              </span>
                            )}
                            {errors?.first_name?.type === "maxLength" && (
                              <span className="text-danger">
                                First Name must be at most 64 characters long
                              </span>
                            )}
                          </Form.Group>
                        </Col>
                        <Col col="12" md="6">
                          <Form.Group>
                            <label>Last Name</label>
                            <input
                              className="form-control"
                              type="text"
                              placeholder="Last Name"
                              name="last_name"
                              {...register("last_name", {
                                required: true,
                                minLength: 3,
                                maxLength: 64,
                              })}
                            />
                            {errors?.last_name?.type === "required" && (
                              <span className="text-danger">
                                Last Name is required
                              </span>
                            )}
                            {errors?.last_name?.type === "minLength" && (
                              <span className="text-danger">
                                Last Name must be at least 3 characters long
                              </span>
                            )}
                            {errors?.last_name?.type === "maxLength" && (
                              <span className="text-danger">
                                Last Name must be at most 64 characters long
                              </span>
                            )}
                          </Form.Group>
                        </Col>
                        <Col col="12" md="6">
                          <Form.Group>
                            <label>Email</label>
                            <input
                              className="form-control"
                              type="email"
                              placeholder="Email"
                              name="email"
                              {...register("email", {
                                required: true,
                                pattern: /^\S+@\S+$/i,
                              })}
                            />
                            {errors?.email?.type === "required" && (
                              <span className="text-danger">
                                Email is required
                              </span>
                            )}
                            {errors?.email?.type === "pattern" && (
                              <span className="text-danger  m-auto">
                                Invalid email format
                              </span>
                            )}
                          </Form.Group>
                        </Col>
                        <Col col="12" md="6">
                          <Form.Group>
                            <label>Phone</label>
                            <input
                              className="form-control"
                              type="tel"
                              placeholder="Phone"
                              name="phone"
                              {...register("phone", {
                                required: true,
                                pattern: /^[0-9]{10}$/,
                              })}
                            />
                            {errors?.phone?.type === "required" && (
                              <span className="text-danger">
                                Phone is required
                              </span>
                            )}
                            {errors?.phone?.type === "pattern" && (
                              <span className="text-danger  m-auto">
                                Invalid phone format
                              </span>
                            )}
                          </Form.Group>
                        </Col>
                      </Row>
                      <h4 className="text-black font-weight-bold  pb-2">
                        RIDE DETAILS
                      </h4>
                      <Row>
                        <Col className="col-12 mb-3">
                          <Form.Group className="m-0">
                            <label>Select Vehicle</label>
                            {data?.data?.vehicles && (
                              <select
                                className="form-control"
                                id="vehicle_id"
                                name="vehicle_id"
                                {...register("vehicle_id", { required: true })}
                              >
                                <option value={""}>Select</option>
                                {data?.data?.vehicles?.map((item, i) =>
                                  item?.active ? (
                                    <option value={item?.id} key={i}>
                                      {item?.name} - ({item?.plate_number})
                                    </option>
                                  ) : (
                                    <option value={item?.id} key={i} disabled>
                                      {item?.name} - (inactive)
                                    </option>
                                  )
                                )}
                              </select>
                            )}
                            {errors?.vehicle_id?.type === "required" && (
                              <span className="text-danger">
                                Vehicle is required
                              </span>
                            )}
                          </Form.Group>
                        </Col>
                        <Col col="12" md="6">
                          <Form.Group
                            controlId="exampleForm.ControlSelect1"
                            className="m-0"
                          >
                            <label>
                              Duration{" "}
                              <span className="text-gray h5">(hours)</span>
                            </label>
                            <input
                              className="form-control"
                              type="number"
                              placeholder="Duration"
                              min={1}
                              max={24}
                              {...register("duration", { required: true })}
                            />

                            {errors?.duration?.type === "required" && (
                              <span className="text-danger">
                                Duration is required
                              </span>
                            )}
                          </Form.Group>
                        </Col>

                        <Col col="12" md="6">
                          <Form.Group>
                            <label>Start Date & Time Slot</label>
                            <InputGroup>
                              <input
                                className="form-control"
                                type={schedule?.start_at ? "hidden" : "text"}
                                placeholder="Select Start Date"
                                disabled
                              />
                              <input
                                className="form-control"
                                type={schedule?.start_at ? "text" : "hidden"}
                                placeholder="Select Start Date"
                                disabled
                                value={
                                  schedule?.ride_date + " " + schedule?.start_at
                                }
                              />
                              <input
                                className="form-control"
                                type="hidden"
                                placeholder="Select Start Date"
                                name="ride_date"
                                value={schedule?.ride_date}
                                disabled
                                {...register("ride_date", {
                                  required: schedule?.ride_date ? false : true,
                                })}
                              />
                              <input
                                className="form-control"
                                type="hidden"
                                placeholder="Select Start Date"
                                name="start_at"
                                value={schedule?.start_at}
                                disabled
                                {...register("start_at", {
                                  required: schedule?.start_at ? false : true,
                                })}
                              />

                              <Button
                                className="btn btn-black rounded-0 rounded-right px-3"
                                onClick={() => handleShowScheduleRideModal()}
                              >
                                Select
                              </Button>
                            </InputGroup>
                            {errors?.ride_date?.type === "required" &&
                              !schedule?.ride_date && (
                                <span className="text-danger">
                                  Start Date is required
                                </span>
                              )}
                            {errors?.start_at?.type === "required" &&
                              !schedule?.start_at && (
                                <span className="text-danger ml-1">
                                  Start Time is required
                                </span>
                              )}
                          </Form.Group>
                        </Col>

                        <Col className="col-12 mb-3">
                          <Form.Group>
                            <label>Service Location</label>

                            {locations?.data?.data?.pickup_locations && (
                              <select
                                className="form-control"
                                id="location_id"
                                name="location_id"
                                {...register("location_id", { required: true })}
                              >
                                <option value={""}>
                                  Type Service Location
                                </option>
                                {locations?.data?.data?.pickup_locations?.map(
                                  (item, i) => (
                                    <option value={item?.id} key={i}>
                                      {item?.title} - ({item?.location})
                                    </option>
                                  )
                                )}
                              </select>
                            )}
                            {errors?.location_id?.type === "required" && (
                              <span className="text-danger">
                                Location is required
                              </span>
                            )}
                          </Form.Group>
                        </Col>
                        <Col col="12" md="6">
                          <Form.Group>
                            <label>Ride Cost</label>
                            <input
                              className="form-control"
                              placeholder="$00"
                              type="number"
                              name="ride_cost"
                              min={0}
                              {...register("ride_cost", { required: true })}
                            />
                            {errors?.ride_cost?.type === "required" && (
                              <span className="text-danger">
                                Ride Cost is required
                              </span>
                            )}
                          </Form.Group>
                        </Col>
                        <Col col="12" md="6">
                          <Form.Group>
                            <label>Select Driver</label>
                            <InputGroup>
                              <input
                                className="form-control"
                                type="text"
                                placeholder={pickDriver?.name}
                                value={pickDriver?.name}
                                disabled
                                {...register("driver_name")}
                              />
                              <input
                                className="form-control"
                                type="hidden"
                                value={pickDriver?.id}
                                disabled
                                name="driver_id"
                                {...register("driver_id", {
                                  required: pickDriver?.id ? false : true,
                                })}
                              />

                              <Button
                                className="btn btn-black rounded-0 rounded-right px-3"
                                onClick={() => handleShow()}
                              >
                                Re-Assign Driver
                              </Button>
                            </InputGroup>
                          </Form.Group>
                          {errors?.driver_id?.type === "required" &&
                            !pickDriver?.id && (
                              <span className="text-danger">
                                Driver is required
                              </span>
                            )}
                        </Col>
                      </Row>

                      <div className="clearfix"></div>
                      <Row>
                        <Col col="12" xl="6" className="m-auto">
                          {loading && (
                            <Spinner
                              className="mx-auto mt-3 d-flex"
                              size="sm"
                              animation="border"
                              variant="primary"
                            />
                          )}
                          <input
                            type="submit"
                            value="Edit Booking"
                            className="btn btn-primary w-100 mt-3 btn-lg add-booking-btn btn-outline-primary"
                          />
                        </Col>
                      </Row>
                    </form>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Container>
      )}
    </>
  );
}

export default EditSpecialBookings;
