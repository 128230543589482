import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getToken } from "cookies/token";
import { authCheck } from './functions/authCheck';

const header = (headers, { getState }) => {
  const token = getToken();
  if (token) {
    headers.set("authorization", `Bearer ${token}`);
  }
  return headers;
};
export const productApi = createApi({
  reducerPath: "ProductApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: header,
  }),
  tagTypes: ["Product"],
  endpoints: (builder) => ({
    getProductById: builder.query({
      query: (id) => ({
        url: `/product/${id}?populate=%5B%22product_image%22%2C%22review%22%5D`,
        method: "GET",
        validateStatus: (response, result) => authCheck(response, result),

      }),
      providesTags: ["Product"],
    }),
    getAllProduct: builder.query({
      query: (...data) => 
      ({
        url: `/product?offset=${
          data[0].PageSize * data[0].page
        }&limit=${data[0].PageSize}&populate=%5B%22product_image%22%5D&sort=%5B%5B%22${
          data[0].sort.sortBy
        }%22%2C%22${data[0].sort.sortDirection}%22%5D%5D${data[0].filter}&search=${data[0].search}`,
        method: "GET",
        validateStatus: (response, result) => authCheck(response, result),

      }),
      providesTags: ["Product"],
    }),
    addProduct: builder.mutation({
      query: (newProduct) => {
        return {
          url: `/product`,
          method: "POST",
          body: newProduct,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        };
      },
      invalidatesTags: ["Product"],
    }),
    deleteProductById: builder.mutation({
      query: (id) => {
        return {
          url: `/product/${id}`,
          method: "DELETE",
          body: null,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        };
      },
      invalidatesTags: ["Product"],
    }),
    updateProductById: builder.mutation({
      query: (requestData) => {
        return {
          url: `/product/${requestData[0]}`,
          method: "PUT",
          body: requestData[1],
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        };
      },
      invalidatesTags: ["Product"],
    }),
  }),
});

export const {
  useGetProductByIdQuery,
  useDeleteProductByIdMutation,
  useAddProductMutation,
  useGetAllProductQuery,
  useUpdateProductByIdMutation,
} = productApi;
