import React from "react";

function DetailRideCardSkeleton() {
  return (
    <tr>
      <td>
        <span className="skeleton">1</span>
      </td>
      <td>
        <a href="#" className="text-primary">
          <span className="skeleton"> #1236589</span>
        </a>
      </td>

      <td>
        <b className="m-0 skeleton">11/15/2021</b>
      </td>
      <td>
        <b className="m-0  skeleton">
          TEXAS 202 HSZ <br />
          Lamborghini Aventador
        </b>
      </td>
      <td>
        <b className="m-0 skeleton ">5:00</b>
      </td>
      <td>
        <h5 className="m-0 ">
          <b className="skeleton">World Trade Center</b>
        </h5>
      </td>

      <td>
        <b className="m-0 font-weight-bold skeleton">$1781.00</b>
      </td>
      <td className="text-completed text-center">
        <b className="skeleton">skeleton</b>
      </td>

      <td className="text-completed">
        <b className="skeleton">skeleton</b>
      </td>
    </tr>
  );
}

export default DetailRideCardSkeleton;
