import React, { useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Popconfirm, message } from "antd";
import moment from "moment";
import { useAddDriverToRideMutation } from "services/rides";
function DriversCards({
  handleClose,
  driver,
  id,
  active,
  setActive,
  activeRide,
  setSearch,
  pickDriver,
  setPickDriver,
}) {
  const [loading, setLoading] = useState(false);

  const [addDriverToRide] = useAddDriverToRideMutation();
  const handleClick = (id) => {
    setActive({ id: id, active: true });
  };
  function confirm(driverId) {
    if (pickDriver) {
      setLoading(true);
      setPickDriver({ name: driver?.full_name, id: driverId });
      message.success("Driver assigned successfully");
      handleClose();
      setSearch("");
      setLoading(false);
    } else {
      setLoading(true);
      const data = {
        ride_id: activeRide,
        driver_id: driverId,
      };
      const responds = addDriverToRide(data);
      responds.then((res) => {
        console.log(res);
        if (res?.data?.message === "Created") {
          setLoading(false);
          message.success("Driver assigned successfully");
          handleClose();
          setSearch("");
          setActive({ id: 0, active: false });
        } else {
          setLoading(false);
          message.error( res?.error?.data?.message || "Driver not added");
          setSearch("");
          setActive({ id: 0, active: false });
        }
      });
    }
  }

  function cancel(e) {
    // message.error("Click on No");
  }
  //console.log(driver);
  return (
    <div className="border p-3 rounded  d-flex justify-space-between  flex-column flex-lg-row align-items-center mb-2">
      <div className="d-flex align-items-center pl-4">
        <div>
          <Form.Check className="pr-2 pl-0">
            <Form.Check.Label>
              <Form.Check.Input
                checked={active.id === id && active.active}
                type="checkbox"
                onClick={() => handleClick(id)}
              ></Form.Check.Input>
              <span className="form-check-sign"></span>
            </Form.Check.Label>
          </Form.Check>
        </div>
        <div>
          <div className="thumbnail-round-md mr-3">
            <LazyLoadImage
              alt={driver?.full_name}
              effect="blur"
              src={
                driver?.image ||
                `https://joeschmoe.io/api/v1/${driver?.full_name}`
              }
            />
          </div>
        </div>
        <h4 className="m-0 font-weight-bold">
          {driver?.full_name} <br />
          <h6>
            <span className="text-gray">Next -</span>{" "}
            <span className="text-primary">
              {" "}
              {driver?.next_ride !== null
                ? moment(driver?.next_ride).format("MM/DD/YY, h:mm a")
                : "No Rides"}
            </span>
          </h6>
        </h4>
      </div>
      <div className="ml-auto">
        {active.id === id && active.active && loading ? (
          <Spinner
            className="mx-auto mt-3
                     d-flex"
            size="sm"
            animation="border"
            variant="primary"
          />
        ) : (
          <Popconfirm
            title={
              "Are you sure do you want to Assign " +
              driver?.full_name +
              " to this ride ?"
            }
            onConfirm={() => confirm(driver?.id)}
            onCancel={cancel}
            okText="Assign"
            cancelText="Cancel"
            placement="left"
            className="opacity-0"
          >
            <button
              type="button"
              className="btn btn-primary btn-md"
              style={{ opacity: active.id === id && active.active ? 1 : 0 }}
              disabled={active.id === id && active.active ? false : true}
            >
              Assign
            </button>
          </Popconfirm>
        )}
      </div>
    </div>
  );
}

export default DriversCards;
