import React from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { Avatar, Image } from "antd";
import moment from "moment";
function ListCard({ index, user }) {
  const navigate = useNavigate();
  return (
    <tr>
      <td>{index}</td>
      <td>
        <u>
          <Link to={`/admin/customers/${user?.id}`} className="text-primary">
            {user?.user_code}
          </Link>
        </u>
      </td>
      <td>
        <div className="d-flex align-items-center">
          <div className="thumbnail-round mr-3">
            <Avatar
              shape="square"
              className="w-100 h-100"
              src={
                <Image
                  src={
                    user?.image ||
                    `https://joeschmoe.io/api/v1/${user?.first_name}`
                  }
                />
              }
            />
          </div>
          <h5 className="m-0">
          <Link to={`/admin/customers/${user?.id}`} className="text-primary">
          <button className="btn">{user?.full_name}</button>
          </Link>
          </h5>
        </div>
      </td>
      <td>{user?.email}</td>
      <td>
        {user?.phone_code} {user?.phone}
      </td>
      <td>{moment(user?.created_at).format("MM/DD/YY")}</td>
      <td>
        <h5 className="m-0 font-weight-bold">{user?.total_rides}</h5>
      </td>
      <td>
        <h5 className="m-0 font-weight-bold">{user?.total_orders}</h5>
      </td>
      {user?.active ? (
        <td className="text-active">
          <span></span>
          Active
        </td>
      ) : (
        <td className="text-active text-danger">
          <span></span>
          Inactive
        </td>
      )}
    </tr>
  );
}

export default ListCard;
