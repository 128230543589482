import React, { useState, useEffect, useRef } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import Geocode from "react-geocode";
import { set, useForm } from "react-hook-form";
import { AutoComplete } from "./autoComplete";

Geocode.setApiKey("AIzaSyDIj0QBVIMnAxlKJf4WbGcISWXPvchSj-Y");
Geocode.setLanguage("en");
Geocode.setRegion("es");
Geocode.setLocationType("ROOFTOP");
Geocode.enableDebug();
import {
  GoogleMap,
  LoadScriptNext,
  Marker,
  DirectionsRenderer,
  Polyline,
} from "@react-google-maps/api";
import { useAddPickupLocationMutation } from "services/pickupLocations";
import { message } from "antd";

function AddEditLocationModal({ handleClose, show }) {
  const [locationData, setLocationData] = useState();
  const [Loading, setLoading] = useState(false);
  const [addPickupLocation] = useAddPickupLocationMutation();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm();

  const onSubmit = async (upData) => {
    if (!locationData?.location) {
      setLocationData({ ...locationData, error: true });
    } else {
      setLoading(true);
      const data = {
        ...upData,
        latitude: location.lat,
        longitude: location.lng,
        location: locationData?.location,
      };
      const addLocation = await addPickupLocation({ data });
      if (addLocation?.data?.message === "Created") {
        setLocationData({});
        setLoading(false);
        message.success("pickup location added successfully");
        handleClose();
        reset();

      } else {
        setLoading(false);
        message.error(addLocation?.data?.message || "Something went wrong");
        reset();
        // handleClose();
      }
    }
  };
  const [locationText, setLocationText] = useState("unites states");
  const [location, setLocation] = useState({ lat: 9.935, lng: 76.26 });
  useEffect(() => {
    if (locationText) {
      Geocode.fromAddress(locationText).then(
        (response) => {
          const { lat, lng } = response.results[0].geometry.location;
          setLocation({ lat, lng });
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }, []);

  const containerStyle = {
    width: "100%",
    height: "50vh",
  };
  const handleClick = (locationText) => {
    Geocode.fromAddress(locationText).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        console.log(lat, lng);
        setLocation({ lat: lat, lng: lng });
      },
      (error) => {
        console.error(error);
      }
    );
  };
  return (
    <Modal className="right-modal" show={show} onHide={handleClose}>
      <Modal.Header closeButton className="border-bottom pb-3">
        <Modal.Title className="m-0 font-weight-bold">
          Add Pickup Locations
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-3" controlId="formBasicEmail">
            <label className="font-weight-bold">Location Title</label>
            <input
              type="text"
              placeholder="Type Location Title"
              className="form-control"
              name="title"
              {...register("title", {
                required: true,
                maxLength: 120,
              })}
            />
            {errors.title && (
              <span className="text-danger">
                {errors.title.type === "required" &&
                  "Location title is required"}
                {errors.title.type === "maxLength" &&
                  "Location title should be less than 120 characters"}
              </span>
            )}
          </div>
          <AutoComplete
            locationData={locationData}
            setLocationData={setLocationData}
            setLocation={setLocation}
            setValue={setValue}
          />
          <input
            type="text"
            placeholder="Ride"
            className="form-control d-none"
            name="locationDummy"
            {...register("locationDummy", {
              required: true,
              maxLength: 120,
            })}
          />
          {errors.locationDummy && (
            <span className="text-danger">
              {errors.locationDummy.type === "required" && "Location is required"}{" "}
            </span>
          )}
          <div className="border rounded">
            <LoadScriptNext googleMapsApiKey="AIzaSyDIj0QBVIMnAxlKJf4WbGcISWXPvchSj-Y">
              <GoogleMap
                mapContainerStyle={containerStyle}
                center={location}
                zoom={15}
              >
                <Marker
                  icon={require("assets/img/mark.png").default}
                  position={location}
                  animation={google?.maps?.Animation?.DROP}
                  draggable={true}
                  onDragEnd={(e) => {
                    console.log(e);
                    setLocation({ lat: e.latLng.lat(), lng: e.latLng.lng() });
                  }}
                />
              </GoogleMap>
            </LoadScriptNext>
          </div>
          {Loading && (
            <Spinner
              className="mx-auto mt-3 d-flex"
              size="sm"
              animation="border"
              variant="primary"
            />
          )}
          <Button
            variant="primary"
            type="submit"
            className="btn btn-primary btn-lg w-100 mt-3"
          >
            Submit
          </Button>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default AddEditLocationModal;
