import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Card, Table, Container, Row, Col, Form, Modal } from "react-bootstrap";
import ListCard from "components/SpecialBookings/ListCard";
import { useGetAllSpecialBookingsQuery } from "services/specialBookings";
import { Empty, Pagination } from "antd";
import { FilterBy } from "components/Common/FilterBy";
import TableTitle from "components/Orders/List/TableTitle";
import MetaTitle from "components/Meta";

function VehiclesDetail() {
  // initial set up for Item
  const [populate, setPopulate] = useState("");
  const [filter, setFilter] = useState("");
  const [sort, setSort] = useState({
    sortBy: "created_at",
    sortDirection: "desc",
  });
  const [PageSize, setPageSize] = useState(10); // default page size change value in query also
  const [page, setPageNumber] = useState(0);
  // initial set up for Item END
  const { isLoading, isError, isSuccess, data, status } =
    useGetAllSpecialBookingsQuery(
      { page, PageSize, sort, filter, populate },
      {
        refetchOnMountOrArgChange: false,
      }
    );
  const handlePagination = (page, pageSize) => {
    setPageNumber((prev) => page - 1);
    setPageSize(pageSize);
  };
  return (
    <>
      <link
        rel="preload"
        as="image"
        href={require("assets/img/otpSent.gif").default}
      />
      <MetaTitle text="Special booking" />
      <Container fluid>
        <Card className="mb-3 border-top-0 border-left-0 border-right-0 rounded-0 ">
          <Card.Body>
            <Row className="align-items-center">
              <Col col="12" md="7">
                <h3 className="m-0 py-2 font-weight-bold">Special Bookings</h3>
              </Col>
              <Col col="12" md="2">
                <FilterBy
                  filterKey={"ride_status_id"}
                  setPageNumber={setPageNumber}
                  filter={filter}
                  setFilter={setFilter}
                  filterItems={[
                    {
                      name: "Pending",
                      value: "1",
                    },
                    {
                      name: "Cancelled",
                      value: "3",
                    },
                    {
                      name: "Completed",
                      value: "7",
                    },
                  ]}
                />
              </Col>
              <Col col="12" md="3">
                <Link
                  to={"/admin/special-booking/add-special-booking"}
                  className="btn w-100 btn-primary btn-lg"
                >
                  Add New Booking
                </Link>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <div className="bg-white">
          <div className="table-full-width table-responsive">
            <Table className="table-hover revup-table m-0">
              <thead>
                <tr>
                  <th className="border-0">#</th>
                  <TableTitle
                    title="Customer"
                    setSort={setSort}
                    sortBy={"first_name"}
                    sort={sort}
                  />
                  <TableTitle
                    title="Vehicle"
                    setSort={setSort}
                    sortBy={`vehicle","plate_number`}
                    sort={sort}
                  />
                  <TableTitle
                    title="Ride Time & Duration"
                    setSort={setSort}
                    sortBy={"ride_date"}
                    sort={sort}
                  />
                  <TableTitle
                    title="Pickup Location"
                    setSort={setSort}
                    sortBy={`location","title`}
                    sort={sort}
                  />{" "}
                  <TableTitle
                    title="Amount"
                    setSort={setSort}
                    sortBy="ride_cost"
                    sort={sort}
                  />{" "}
                  <TableTitle
                    title="Driver Info"
                    setSort={setSort}
                    sortBy={`driver","full_name`}
                    sort={sort}
                  />{" "}
                  <TableTitle
                    title="Status"
                    setSort={setSort}
                    sortBy="ride_status_id"
                    sort={sort}
                  />{" "}
                  <th className="border-0 text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {isLoading &&
                  [...Array(10)].map((d, i) => (
                    <ListCard type="Skeleton" key={i} />
                  ))}
                {status === "pending" &&
                  [...Array(PageSize)].map((d, i) => (
                    <ListCard type="Skeleton" key={i} />
                  ))}
                {isError && <p>Error </p>}
                {isSuccess &&
                  status === "fulfilled" &&
                  data?.data?.rides.map((ride, i) => (
                    <ListCard
                      key={i}
                      ride={ride}
                      index={page * PageSize + i + 1}
                    />
                  ))}
                {data?.data?.rides.length === 0 && (
                  <tr>
                    <td colspan="9" className="empty_items">
                      <Empty
                        description={false}
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                      >
                        <div className="ant-empty-description">
                          <p>No SpecialBookings Found</p>
                        </div>
                      </Empty>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </div>
        <Row className="mt-3">
          <Col col="12" md="12" lg="12">
            {data?.data?.count > 0 && (
              <Pagination
                current={page + 1}
                defaultCurrent={1}
                defaultPageSize={PageSize}
                onChange={handlePagination}
                total={parseInt(data?.data?.count)}
              />
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default VehiclesDetail;
