import React from "react";
import { Row, Col } from "react-bootstrap";
import { useGetPaymentModeQuery } from "services/settings";
function PaymentSettings() {
  const { isLoading, isError, isSuccess, data, status, isFetching } =
    useGetPaymentModeQuery(
      {},
      {
        refetchOnMountOrArgChange: false,
      }
    );
  if (!isLoading) {
    return (
      <div className="bg-white p-4 border">
        <Row className="py-4 border-bottom align-items-center">
          <Col lg="8">
            <h4 className="m-0 p-0 font-weight-bold">
              <b>Stripe</b>
            </h4>
            <h6 className="m-0">{data?.data?.stripe_email}</h6>
          </Col>
          <Col lg="4" className="text-right">
            <div className="text-active">
              <span></span>
              Connected
            </div>
          </Col>
        </Row>
        <p className="mt-4 text-gray ">
          <small>All the settings will be update once you save it</small>
        </p>
      </div>
    );
  }
  return (
    <div className="bg-white p-4 border">
      <Row className="py-4 border-bottom align-items-center">
        <Col lg="8">
          <h4 className="m-0 p-0 font-weight-bold">
            <b className="skeleton">Stripe</b>
          </h4>
          <h6 className="m-0">
            <span className="skeleton">admin@examplemail.com</span>
          </h6>
        </Col>
        <Col lg="4" className="text-right">
          <div>
            <span className=" skeleton">Connected</span>
          </div>
        </Col>
      </Row>
      <p className="mt-4 text-gray ">
        <small className="skeleton">
          Al the settings will be update once you save it
        </small>
      </p>
    </div>
  );
}

export default PaymentSettings;
