import React, { useState } from "react";
import { Button, Card, Container, Row, Col } from "react-bootstrap";
import { Timeline } from "antd";
import { Avatar, Image, Rate } from "antd";

import moment from "moment";
import AssignDriverModal from "../AssignDriverModal";

function Content({ setTrack, handleShowScheduleRideModal, ride }) {
  let ride_log = ride?.ride_log || {};
  let user_notification = ride?.user_notification || [];
  const [activeRide, setActiveRide] = useState(null);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (rideId) => {
    setShow(true);
    setActiveRide(rideId);
  };
  const thumbnail = ride?.vehicle?.vehicle_image?.filter((img) => {
    return img?.thumbnail === true;
  });
  const getDate = (id) => {
    let filtered = {};
    user_notification?.filter((notification) => {
      if (notification?.ride_status_id === id) {
        filtered = notification;
        return notification.id;
      }
    });
    return filtered;
  };
  return (
    <>
      <AssignDriverModal
        show={show}
        handleClose={handleClose}
        handleShow={handleShow}
        activeRide={activeRide}
      />
      <div className="bg-white p-3">
        <div className="border px-3 mb-3">
          <Row>
            <Col lg="6" className="py-3">
              <Row className="align-items-center">
                <Col md="3">
                  <img
                    className="img-fluid rounded detail_image"
                    alt="..."
                    src={
                      thumbnail?.[0]?.image ||
                      "https://via.placeholder.com/350x350?text=404"
                    }
                  />
                </Col>
                <Col md="9" className="mt-3 mt-md-0">
                  <h5 className=" m-0">{ride?.vehicle?.plate_number}</h5>
                  <h3 className="m-0 pb-2 font-weight-bold">
                    {ride?.vehicle?.name}{" "}
                  </h3>
                </Col>
              </Row>
            </Col>
            <Col lg="6" className="pt-0 py-md-3  ">
              <Row>
                <Col md="7">
                  <div className="d-flex flex-column flex-md-row pt-md-3 ">
                    <h6 className="font-weight-bold pr-3 m-0">RIDE DETAILS</h6>
                  </div>
                  <Timeline color="red" className="active reschedule  mt-4">
                    <Timeline.Item color="red" className="starting">
                      <h6 className="text-gray m-0">START TIME</h6>
                      <h6 className="pb-2">
                        {moment(ride?.ride_start).format("MM/DD/YY, h:mm a")}
                      </h6>
                    </Timeline.Item>
                    <Timeline.Item color="red">
                      <h6 className="text-gray m-0">END TIME</h6>
                      <h6 className="pb-2">
                        {moment(ride?.ride_end).format("MMM Do dddd, h:mm a")}
                      </h6>
                    </Timeline.Item>
                  </Timeline>
                </Col>
                <Col
                  md="5"
                  className="d-flex flex-column justify-content-center"
                >
                  <h6 className="text-gray m-0">DURATION</h6>
                  <h6 className="pb-2">
                    <b>{ride?.duration}</b>{" "}
                    {ride?.duration > 1 ? "Hours" : "Hour"}
                  </h6>
                  {ride?.ride_status_id != 7 ? (
                    <Button
                      className="w-100 btn-primary btn-lg mb-3 mb-md-0 h6"
                      type="submit"
                      onClick={() => handleShowScheduleRideModal(true)}
                    >
                      Reschedule
                    </Button>
                  ) : null}
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <Row>
          <Col lg="6">
            <div className="border p-3 mb-3">
              <h5 className="font-weight-bold pb-3 m-0 black-one">
                CUSTOMER INFO
              </h5>
              <Row className="align-items-center">
                <Col lg="6" className="mb-3 mb-md-0">
                  <div className="d-flex align-items-center">
                    <div className="thumbnail-round mr-3">
                      <Avatar
                        shape="square"
                        className="w-100 h-100"
                        src={
                          <Image
                            src={
                              ride?.customer?.image ||
                              `https://joeschmoe.io/api/v1/${ride?.customer?.full_name}`
                            }
                          />
                        }
                      />
                    </div>
                    <div>
                      <h5 className="m-0 pb-1">
                        <b>{ride?.customer?.full_name}</b>
                      </h5>
                      <h6 className="text-primary m-0">
                        #{ride?.customer?.user_code}
                      </h6>
                    </div>
                  </div>
                </Col>

                <Col lg="6">
                  <h6 className="m-0 pb-1">
                    <span className="text-gray">Phone </span>
                    {ride?.customer?.phone_code}

                    {ride?.customer?.phone}
                  </h6>
                  <h6 className="m-0">
                    <span className="text-gray">Email </span>
                    {ride?.customer?.email}
                  </h6>
                </Col>
              </Row>
              {ride?.review?.active && (
                <>
                  <h5 className="font-weight-bold pb-1 mt-2 black-one">
                    RATING & REVIEW
                  </h5>
                  <Rate
                    disabled
                    defaultValue={ride?.review?.rating}
                    style={{ color: "#F1390" }}
                  />
                  <span className="font-weight-bold ml-3">
                    {ride?.review?.rating}/5
                  </span>
                  <div>
                    <p>{ride?.review?.review}</p>
                  </div>
                </>
              )}
            </div>
            <div className=" p-3 mb-3">
              <h5 className="font-weight-bold pb-3 m-0 black-one">
                PICKUP LOCATION
              </h5>
              <div className="d-flex align-items-center">
                <div>
                  <div className="thumbnail-round mr-3">
                    <img
                      alt="..."
                      src={require("assets/img/location.png").default}
                    ></img>
                  </div>
                </div>
                <div>
                  <h5 className="m-0 pb-1 word_break">
                    <b>{ride?.location?.title}</b>
                  </h5>
                  <h6 className="m-0">{ride?.location?.location}</h6>
                </div>
              </div>
            </div>
            <div className="border p-3 mb-3">
              <div className="d-flex flex-column flex-md-row py-2">
                <h5 className="font-weight-bold pr-2 m-0">INVOICE</h5>
                <h5 className="text-primary">
                  <u>{ride?.user_payment?.invoice_no}</u>
                </h5>
              </div>
              <Row>
                <Col col="8" className="pb-2">
                  <h5 className="m-0">
                    Ride Cost (${ride?.user_payment?.cost_per_hour}/Hour)
                  </h5>
                </Col>
                <Col col="4" className="pb-2 text-right">
                  <h5 className="m-0">
                    <b>$ {ride?.user_payment?.ride_cost}</b>
                  </h5>
                </Col>
              </Row>
              <Row>
                <Col col="8" className="pb-2">
                  <h5 className="m-0">Service Charge</h5>
                </Col>
                <Col col="4" className="pb-2 text-right">
                  <h5 className="m-0">
                    $ {ride?.user_payment?.service_charge}
                  </h5>
                </Col>
              </Row>
              <Row>
                {ride?.user_payment?.discount && (
                  <>
                    <Col col="8" className="pb-2">
                      <h5 className="m-0 text-primary">Coupon Code Applied</h5>
                    </Col>
                    <Col col="4" className="pb-2 text-right">
                      <h5 className="m-0 text-primary">
                        - $ {ride?.user_payment?.discount}
                      </h5>
                    </Col>
                  </>
                )}
              </Row>
              <hr />
              <Row className="align-items-center mb-3">
                <Col col="8" className="pb-2">
                  <h5 className="m-0">
                    <b>TOTAL</b>
                  </h5>
                </Col>
                <Col col="4" className="pb-2 text-right">
                  <h3 className="m-0 font-weight-bold">
                    ${ride?.user_payment?.total_amount}
                  </h3>
                </Col>
              </Row>
              {ride?.user_payment?.card_last4 && (
                <p className="mb-0">
                  Paid via {ride?.user_payment?.brand} credit card ending{" "}
                  {ride?.user_payment?.card_last4}
                </p>
              )}
              <p className="mb-0">
                Transaction #{ride?.user_payment?.transaction_id}
              </p>
            </div>
          </Col>
          <Col lg="6">
            <div className="border p-3 mb-3">
              <h5
                className={
                  "font-weight-bold pb-3 m-0 black-one " +
                    ride?.driver?.full_name && " opacity"
                }
              >
                DRIVER INFO
              </h5>
              <div className="d-flex justify-content-between">
                {ride?.ride_status_id != 1 && (
                  <div className="d-flex align-items-center ">
                    <div className="thumbnail-round mr-3">
                      {ride?.driver?.full_name && (
                        <Avatar
                          shape="square"
                          className="w-100 h-100"
                          src={
                            <Image
                              src={
                                ride?.driver?.image ||
                                `https://joeschmoe.io/api/v1/${ride?.driver?.full_name}`
                              }
                            />
                          }
                        />
                      )}
                    </div>
                    <div>
                      <h5 className="m-0 pb-1">
                        <b>{ride?.driver?.full_name}</b>
                      </h5>
                      {ride?.driver?.user_code && (
                        <h6 className="text-primary m-0">
                          # {ride?.driver?.user_code}
                        </h6>
                      )}
                    </div>
                  </div>
                )}
                {ride?.ride_status_id === 2 || ride?.ride_status_id === 1 ? (
                  moment(moment(ride?.ride_start).format("MM/DD/YY")).diff(
                    moment(Date.now()).format("MM/DD/YY"),
                    "days"
                  ) < 0 ? (
                    "Ride expired"
                  ) : (
                    <Button
                      className="w- btn-primary"
                      type="submit"
                      onClick={() => handleShow(ride?.id)}
                    >
                      {ride?.ride_status_id != 2
                        ? "Assign Driver"
                        : "Reassign Driver"}
                    </Button>
                  )
                ) : null}
                {ride?.ride_status_id === 7
                  ? ride?.tip && (
                      <div className="d-flex align-items-center ">
                        <div className="thumbnail-round mr-3 dollar">
                          <span>$</span>
                        </div>
                        <div>
                          <h5 className="m-0 pb-1">
                            <b>${ride?.tip}</b>
                          </h5>
                          <h6 className="text- m-0">
                            Tips earned in this trip{" "}
                          </h6>
                        </div>
                      </div>
                    )
                  : null}
              </div>
              {ride?.ride_status_id != 1 && ride?.ride_status_id != 3 && (
                <Timeline className="mt-5">
                  <Timeline.Item
                    color="red"
                    className={ride?.driver_assigned_on ? "active" : "opacity"}
                  >
                    <strong>Driver Assigned </strong> -{" "}
                    {ride?.driver_assigned_on &&
                      moment(ride?.driver_assigned_on).format(
                        "MM/DD/YY, h:mm a"
                      )}
                  </Timeline.Item>
                  <Timeline.Item
                    color="red"
                    className={getDate(2).created_at ? "active" : "opacity"}
                  >
                    <strong>Push Notification Sent </strong> -{" "}
                    {getDate(2).created_at &&
                      moment(getDate(2).created_at).format(
                        "MM/DD/YY, h:mm a"
                      )}
                  </Timeline.Item>
                  <Timeline.Item
                    color="red"
                    className={getDate(2).read_at ? "active" : "opacity"}
                  >
                    <strong>Push Notification Viewed </strong> -{" "}
                    {getDate(2).read_at &&
                      moment(getDate(2).read_at).format("MM/DD/YY, h:mm a")}
                  </Timeline.Item>
                  <Timeline.Item
                    color="red"
                    className={getDate(4).created_at ? "active" : "opacity"}
                  >
                    <strong>Started to Pickup Location </strong> -{" "}
                    {getDate(4).created_at &&
                      moment(getDate(4).created_at).format(
                        "MM/DD/YY, h:mm a"
                      )}
                  </Timeline.Item>
                  <Timeline.Item
                    color="red"
                    className={getDate(5).created_at ? "active" : "opacity"}
                  >
                    <strong>Reached Pickup Location </strong> -{" "}
                    {getDate(5).created_at &&
                      moment(getDate(5).created_at).format(
                        "MM/DD/YY, h:mm a"
                      )}
                  </Timeline.Item>
                  <Timeline.Item
                    color="red"
                    className={ride_log?.ride_start ? "active" : "opacity"}
                  >
                    <strong>Ride Started </strong> -{" "}
                    {ride_log?.ride_start &&
                      moment(ride_log?.ride_start).format(
                        "MM/DD/YY, h:mm a"
                      )}
                  </Timeline.Item>
                  <Timeline.Item
                    color="red"
                    className={ride_log?.ride_end ? "active" : "opacity"}
                  >
                    <strong>Ride End </strong> -{" "}
                    {ride_log?.ride_end &&
                      moment(ride_log?.ride_end).format("MM/DD/YY, h:mm a")}
                  </Timeline.Item>
                </Timeline>
              )}
              {ride?.ride_status_id === 4 ||
              ride?.ride_status_id === 5 ||
              ride?.ride_status_id === 6 ? (
                <button
                  className="btn btn-primary px-5 my-4 "
                  onClick={() => setTrack(true)}
                >
                  Track Vehicle
                </button>
              ) : null}
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Content;
