import moment from "moment";
import React, { useEffect, useState } from "react";
import { Card, Container, Row, Col, Form, Modal } from "react-bootstrap";
import { useParams } from "react-router";
import { Popconfirm, message } from "antd";
import AddEditModal from "components/Products/AddEditModal";
import {
  useGetProductByIdQuery,
  useDeleteProductByIdMutation,
  useUpdateProductByIdMutation,
} from "services/products";
import { useNavigate } from "react-router-dom";
import DetailSkeleton from "components/Products/DetailSkeleton";
import { Switch } from "antd";
import { LazyLoadImage } from "react-lazy-load-image-component";
import MetaTitle from "components/Meta";

function Detail() {
  const [updateProductById] = useUpdateProductByIdMutation();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigation = useNavigate();

  let { id } = useParams();

  const { isLoading, isError, isSuccess, data } = useGetProductByIdQuery(id, {
    refetchOnMountOrArgChange: false,
  });
  const [deleteProduct] = useDeleteProductByIdMutation();
  const product = data?.data?.product;
  const [thumbnail, setThumbnail] = useState(0);
  const [activeImage, setActiveImage] = useState(
    product?.product_image[thumbnail]
  );
  useEffect(() => {
    let defaultThumbnail = 0;
    for (let index = 0; index < product?.product_image.length; index++) {
      const element = product?.product_image[index];
      if (element.is_thumbnail === true) {
        defaultThumbnail = index;
      }
    }
    setThumbnail(defaultThumbnail);
    setActiveImage(product?.product_image[defaultThumbnail]);
  }, [product]);
  async function confirm(e) {
    const deleteResponds = await deleteProduct(id);
    if (deleteResponds?.data?.message === "Deleted") {
      navigation("/admin/products");
      message.success("Product deleted successfully");
    } else {
      message.error(deleteResponds?.error?.data?.message);
    }
  }
  async function confirmStatusChange(e) {
    if (product?.id) {
      let updateData = [product?.id, { active: e }];
      const changeStatusResponds = await updateProductById(updateData);
      if (changeStatusResponds?.data?.message === "Updated") {
        changeStatusResponds?.data?.data?.product?.active === true
          ? message.success("The product has been enabled")
          : message.success("The product has been disabled");
      }
      if (changeStatusResponds?.error) {
        message.error("Something went wrong");
      }
    }
  }
  return (
    <>
      {isLoading && <DetailSkeleton />}
      {isError && (
        <label className="text-danger">Product Not Available for - {id} </label>
      )}
      {isSuccess && (
        <>
          <MetaTitle text={product?.title} />
          <AddEditModal
            show={show}
            handleClose={handleClose}
            product={product ? product : null}
          />
          <Container fluid>
            <Card className="mb-3 rounded-0 rounded-top border-top-0 border-left-0 border-right-0 ">
              <Card.Body>
                <Row className="align-items-center">
                  <Col col="12" xl="7">
                    <h3 className="m-0 font-weight-bold">#{product?.code}</h3>
                    <h6 className="secondary-gray m-0 pb-2">
                      Added On{" "}
                      {moment(product?.created_at).format(
                        "MM/DD/YY, h:mm:ss a"
                      )}
                    </h6>
                  </Col>
                  <Col col="12" xl="5" className="ml-auto">
                    <Row className="align-items-center">
                      <Col className="my-1" col="12" lg="4">
                        <div className="d-flex text-right justify-content-end">
                          <p className=" mr-3 m-0 text-primary">ACTIVE</p>
                          <Popconfirm
                            title="Are you sure do you want to change status?"
                            onConfirm={() =>
                              confirmStatusChange(
                                product?.active === true ? false : true
                              )
                            }
                            okText="Yes"
                            cancelText="cancel"
                            placement="bottom"
                          >
                            <Switch
                              loading={isLoading}
                              checked={product?.active === true}
                            />
                          </Popconfirm>
                        </div>
                      </Col>
                      <Col className="my-1" col="12" lg="4">
                        <Popconfirm
                          title="Are you sure do you want to delete this product?"
                          onConfirm={confirm}
                          okText="Remove"
                          cancelText="cancel"
                          placement="bottom"
                        >
                          <button
                            type="button"
                            className="btn btn-outline-danger w-100 "
                          >
                            Remove Item
                          </button>
                        </Popconfirm>
                      </Col>
                      <Col className="my-1" col="12" lg="4">
                        <button
                          type="button"
                          className="btn btn-outline-secondary w-100 "
                          onClick={() => handleShow()}
                        >
                          Edit Product
                        </button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            <Card className="mb-3 rounded-0 rounded-top border-0 p-md-4">
              <Card.Body>
                <Row>
                  <Col col="12" lg="7" className="mt-3">
                    <h3 className="m-0 font-weight-bold">{product?.title}</h3>
                    <h6 className="text-gray">Brand: </h6>
                    <h6 className="">{product?.brand}</h6>
                    <Row className="m-0 mt-3">
                      <Col col="12" md="12" className="p-0 m-0">
                        <div className="d-flex flex-row flex-wrap">
                          <div className="p-0">
                            <div className="p-3 border border-right-md-0">
                              <h5 className="secondary-gray m-0 pb-2">
                                Unit Price
                              </h5>
                              <h3 className="m-0 font-weight-bold">
                                ${product?.unit_price}
                              </h3>
                            </div>
                          </div>
                          <div className="p-0 min_fit">
                            <div className="p-3 border">
                              <h5 className="secondary-gray m-0 pb-2">
                                Qty Available
                              </h5>
                              <h4 className="m-0">
                                <span className="badge bg-success text-white p-2 font-weight-light">
                                  {product?.stock}
                                </span>
                              </h4>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <div
                      className="d-flex py-3 align-items-center mb-3 pl-0 btn"
                      onClick={() =>
                        message.warning("Not implemented in this sprint")
                      }
                    >
                      {product?.review.length > 0 && (
                        <h5 className="font-weight-bold m-0 mr-3">
                          Review & Ratings
                        </h5>
                      )}
                      {product?.review.length > 0 && (
                        <h6 className="mr-2">
                          {" "}
                          <i className="fas fa-star text-warning"></i>
                          <span className="secondary-gray">
                            {product?.rating_avg}
                          </span>
                          <span className="text-primary">
                            {" "}
                            Reviews ({product?.review.length})
                          </span>
                        </h6>
                      )}
                    </div>
                    <h6 className="font-weight-bold mb-2 text-uppercase text-gray">
                      Product Description
                    </h6>

                    <p>{product?.description}</p>
                    <div className="col-md-6 m-0 p-0">
                      <div className="row">
                        <div className="col-md-6">
                          <h6>Height</h6>
                        </div>
                        <div className="col-md-6">
                          {product?.height} {product?.height && " Inches"}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <h6>Width</h6>
                        </div>
                        <div className="col-md-6">
                          {product?.width} {product?.width && " Inches"}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <h6>Weight</h6>
                        </div>
                        <div className="col-md-6">
                          {product?.weight}
                          {product?.weight && " Pounds"}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <h6>Length</h6>
                        </div>
                        <div className="col-md-6">
                          {product?.length}
                          {product?.length && " Inches"}
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col col="12" lg="5">
                    <div className="p-2 border rounded mb-3 single_f">
                      <img
                        className="img-fluid w-100"
                        alt="..."
                        src={activeImage?.image}
                      />
                    </div>
                    <div className="d-flex">
                      {product?.product_image.map((image, index) => (
                        <div
                          className={`"border product-img mr-2 btn" ${
                            index === thumbnail ? "border_active" : ""
                          }`}
                          key={index}
                          onClick={() => {
                            setActiveImage(image), setThumbnail(index);
                          }}
                        >
                          <LazyLoadImage
                            alt={product?.title}
                            className="img-fluid"
                            effect="blur"
                            src={image.image}
                          />
                        </div>
                      ))}
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Container>
        </>
      )}
    </>
  );
}

export default Detail;
