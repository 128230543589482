import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";

// react-bootstrap components
import { Spinner, Card, Container, Col } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { loginUser, userSelector } from "features/auth/authSlice";
import { useNavigate } from "react-router";
import MetaTitle from "components/Meta";
import { useSelector } from "react-redux";
import { message } from "antd";

function LoginPage() {
  const [password, setPassword] = useState(true);
  const { isFetching, isSuccess, isError, errorMessage, data } =
    useSelector(userSelector);
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    dispatch(loginUser(data))
      .then((res) => {
        if (res.type === "auth/login/fulfilled") {
          message.success("Login Successfully");
          navigation("/admin/dashboard");
        } else {
          message.error("Login Failed ");
        }
      })
      .catch((e) => {});
  };
  useEffect(() => {
    if (data) {
      navigation("/admin/dashboard");
    }
  }, [data]);

  const [cardClasses, setCardClasses] = useState("card-hidden");

  return (
    <>
      <MetaTitle text="login" />
      <div
        className="full-page section-image"
        data-color="black"
        data-image={require("assets/img/full-screen-image-2.jpg").default}
      >
        <div className="content d-flex align-items-center p-0">
          <Container>
            <Col
              className="mx-auto d-flex align-items-center flex-column"
              lg="6"
              md="8"
            >
              <img
                src={require("assets/img/logo.svg").default}
                alt="logo"
                className="login_logo"
              />
              <Card className={"card-login w-100 " + cardClasses}>
                <Card.Header>
                  <h4 className="header text-center font-weight-bold mt-4">Login</h4>
                </Card.Header>
                <Card.Body>
                  <form onSubmit={handleSubmit(onSubmit)} className="px-md-5">
                    <div className="email d-flex flex-column">
                      <label className="">Email address</label>{" "}
                      <input
                        type="text"
                        placeholder="Email address"
                        className="px-2 py-2 form-control"
                        {...register("email", {
                          required: true,
                          pattern: /^\S+@\S+$/i,
                        })}
                      />
                      {errors?.email?.type === "required" && (
                        <span className="text-danger m-auto">
                          Email Address is required
                        </span>
                      )}
                      {errors?.email?.type === "pattern" && (
                        <span className="text-danger  m-auto">
                          Invalid email format
                        </span>
                      )}
                    </div>
                    <div className="email d-flex flex-column">
                      <label className="mt-3">Password</label>{" "}
                      <div className="position-relative">
                        <input
                          type={password ? "password" : "text"}
                          placeholder="Password"
                          className="px-2 py-2 form-control"
                          {...register("password", { required: true })}
                        />
                        <div
                          onClick={() => setPassword(!password)}
                          className="show_false btn"
                        >
                          {password ? <EyeInvisibleOutlined style={{  color: '#000' }}  /> : <EyeTwoTone  style={{  color: '#000' }} />}
                        </div>
                      </div>
                      {errors.password && (
                        <span className="text-danger  m-auto">
                          Password is required
                        </span>
                      )}
                    </div>
                    {isFetching && (
                      <Spinner
                        className="mx-auto mt-3
                     d-flex"
                        size="sm"
                        animation="border"
                        variant="primary"
                      />
                    )}
                    {isError && (
                      <div className="text-danger text-center mt-2">
                        {errorMessage}
                      </div>
                    )}
                    <input
                      className="d-flex px-5 py-1 border-none btn btn-primary rounded {btn-primary mt-4 mb-4 mx-auto"
                      value={"Login"}
                      type="submit"
                    />
                  </form>
                </Card.Body>
              </Card>
            </Col>
          </Container>
        </div>
        <div
          className="full-page-background"
          style={{
            backgroundImage:
              "url(" +
              require("assets/img/full-screen-image-2.jpg").default +
              ")",
          }}
        ></div>
      </div>
    </>
  );
}

export default LoginPage;
