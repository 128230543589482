import { uploadFile } from "react-s3";

const S3_BUCKET = "revup-newage";
const REGION = "us-east-1";
const ACCESS_KEY = "AKIAY44UW65BCYL72EUO";
const SECRET_ACCESS_KEY = "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";

const config = {
  bucketName: S3_BUCKET,
  region: REGION,
  accessKeyId: ACCESS_KEY,
  secretAccessKey: SECRET_ACCESS_KEY,
};
export const uploadImagesToS3 = async (files) => {
  let uploadResponds = [];
  const handleUpload = async (file) => {
    await uploadFile(file, config)
      .then((data) => {
        uploadResponds.push(data);
        console.log("🚀 ~ file: s3.js ~ line 21 ~ .then ~ data", data);
        return data;
      })
      .catch((err) => {
        console.log("🚀 ~ file: s3.js ~ line 25 ~ handleUpload ~ err", err);
        return err;
      });
  };
  for (let index = 0; index < files.length; index++) {
    let file = files[index];
    Object.defineProperty(file, "name", {
      writable: true,
      value: Date.now() + "_" + file.name,
    });
    await handleUpload(file);
  }
  return uploadResponds;
};
