import React from "react";

function DetailPurchaseCardSkeleton() {
  return (
    <tr>
      <td>
        <span className="skeleton">1</span>
      </td>
      <td>
        <a href="#" className="text-primary skeleton">
          #1236589
        </a>
      </td>
      <td>
        <div className="d-flex align-items-center">
          <div className="thumbnail-round mr-3 skeleton"></div>
          <div>
            <h5 className="m-0 text-truncate">
              <b className="skeleton">Tonino Lamborghini 8850 A</b>
            </h5>
            <span className="skeleton">Tonino Lamborghini</span>
          </div>
        </div>
      </td>
      <td>
        <h5 className="m-0">
          <b className="skeleton">$1781.00</b>
        </h5>
      </td>
      <td>
        <b className="m-0 skeleton">02/15/2019</b>
      </td>
      <td>
        <b className="m-0 skeleton">285 Fulton St, New York, NY 10007</b>
      </td>
      <td>
        <h5 className="m-0">
          <span className="text-primary skeleton">  GPNS - 78500858236589</span>
        </h5>
      </td>
      <td className="text-active">
        <b className="skeleton">Pending</b>
        
      </td>
    </tr>
  );
}

export default DetailPurchaseCardSkeleton;
