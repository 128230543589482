import React, { useEffect, useState } from "react";

import DriverCard from "components/Drivers/DriverCard";
import { useNavigate, useSearchParams } from "react-router-dom";

// react-bootstrap components
import { Button, Container, Row, Col } from "react-bootstrap";
import DriverCardSkelton from "components/Drivers/DriverCardSkelton";
import { useGetAllDriverQuery } from "services/drivers";
import { Pagination } from "antd";
import AddDriverModal from "components/Drivers/AddDriverModal";
import MetaTitle from "components/Meta";
function Drivers() {
  const [PageSize, setPageSize] = useState(10); // default page size change value in query also
  const [page, setPageNumber] = useState(0); // default page number
  const { isLoading, isError, isSuccess, data, status } = useGetAllDriverQuery(
    { page, PageSize },
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handlePagination = (page, pageSize) => {
    setPageNumber((prev) => page - 1);
    setPageSize(pageSize);
  };

  return (
    <>
      <MetaTitle text="Drivers" />
      <AddDriverModal
        show={show}
        handleClose={handleClose}
        setPageNumber={setPageNumber}
        count={data?.data?.count}
      />
      <Container fluid>
        <div className="mb-3 border-0">
          <Row className="align-items-center">
            <Col col="12" md="8" lg="10">
              <h3 className="m-0 py-2 font-weight-bold">Drivers</h3>
            </Col>
            <Col col="12" md="4" lg="2">
              <Button
                className="w-100 btn-primary"
                type="submit"
                onClick={handleShow}
              >
                Add New Driver
              </Button>
            </Col>
          </Row>
        </div>
        <Row>
          {isLoading &&
            [...Array(10)].map((d, i) => <DriverCardSkelton key={i} />)}
          {status === "pending" &&
            [...Array(PageSize)].map((d, i) => <DriverCardSkelton key={i} />)}
          {isError && <p>Error </p>}
          {isSuccess &&
            status === "fulfilled" &&
            data.data.users.map((driver, i) => (
              <DriverCard key={i} driver={driver} />
            ))}
        </Row>
        <Row>
          <Col col="12" md="12" lg="12">
            {data?.data?.count > 0 && (
              <Pagination
                current={page + 1}
                defaultCurrent={1}
                defaultPageSize={PageSize}
                onChange={handlePagination}
                total={parseInt(data?.data?.count)}
              />
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Drivers;
