import React, { useEffect, useState } from "react";
import { Card, Container, Row, Col } from "react-bootstrap";
import OrderedItemsCard from "components/Orders/OrderdItemsCard";
import { checkStyle } from "components/Orders/ListCard";
import { useParams } from "react-router";
import { useGetOrderByIdQuery } from "services/orders";
import moment from "moment";
import DetailSkeleton from "components/Orders/DetailSkeleton";
import Avatar from "antd/lib/avatar/avatar";
import { Image, Popconfirm, message } from "antd";
import MetaTitle from "components/Meta";
import { useCancelOrderByIdMutation } from "services/orders";
function OrderDetail() {
  const [cancelOrderById] = useCancelOrderByIdMutation();
  let { id } = useParams();
  const [order, setOrder] = useState([]);
  const { isLoading, isError, isSuccess, data } = useGetOrderByIdQuery(id, {
    refetchOnMountOrArgChange: false,
  });
  useEffect(() => {
    setOrder(data?.data?.order);
  }, [data]);
  const confirmCancelOrder = async (e) => {
    const cancelOrder = await cancelOrderById(id);
    if (cancelOrder?.data?.message === "Updated") {
      message.success("Order Cancelled");
    } else {
      cancelOrder?.data?.message
        ? message.error(cancelOrder?.data?.message)
        : message.error("Something went wrong");
    }
  };
  let showCancelOrder = false;
  let showCancelOrderArray = [];
  order?.order_item?.map((item) => {
    showCancelOrderArray.push(item?.status);
  });
  if (showCancelOrderArray.every((val, i, arr) => val === "Cancelled")) {
    showCancelOrder = false;
  } else if (showCancelOrderArray.every((val, i, arr) => val === "Delivered")) {
    showCancelOrder = false;
  } else {
    showCancelOrder = true;
  }
  console.log(showCancelOrder);
  return (
    <>
      {isLoading && <DetailSkeleton />}
      {data && (
        <>
          <MetaTitle text={order?.code} />{" "}
          <Container fluid>
            <Card className="mb-3 rounded-0 rounded-top border-top-0 border-left-0 border-right-0 ">
              <Card.Body>
                <Row className="align-items-center">
                  <Col col="12" xl="10">
                    <div className="d-flex align-items-center">
                      <h3 className="m-0 pb-0 font-weight-bold mr-2">
                        #{order?.code}
                      </h3>
                    </div>
                    <h6 className="secondary-gray m-0 pb-2">
                      {moment(order?.created_at).format(
                        "MM/DD/YY, h:mm:ss a"
                      )}
                    </h6>
                  </Col>
                  <Col className="my-1" col="12" md="2">
                    {showCancelOrder && (
                      <Popconfirm
                        title="Are you sure you want to cancel this Order?"
                        onConfirm={confirmCancelOrder}
                        okText="Yes"
                        cancelText="No"
                      >
                        <button
                          type="button"
                          className="btn btn-outline-danger w-100"
                        >
                          Cancel Order
                        </button>{" "}
                      </Popconfirm>
                    )}
                  </Col>
                </Row>
              </Card.Body>
            </Card>

            <Row>
              <Col lg="7">
                <div className=" px-3">
                  {order?.order_item?.map((item, i) => (
                    <OrderedItemsCard key={i} item={item} />
                  ))}
                </div>
                <div className="bg-white p-3 rounded">
                  <h5 className="font-weight-bold pb-3 m-0 black-one">
                    DELIVER TO
                  </h5>
                  <div className="border p-3 mb-3">
                    <Row className="align-items-center">
                      <Col lg="6" sm="4" className="mb-3 mb-md-0">
                        <div className="d-flex align-items-center">
                          {order?.customer?.imag ? (
                            <Avatar
                              size={50}
                              className="mr-2"
                              src={<Image src={order?.customer?.image} />}
                            />
                          ) : (
                            <Avatar
                              style={{
                                color: "#f56a00",
                                backgroundColor: "#fde3cf",
                              }}
                              size={50}
                              className="mr-2"
                            >
                              {order?.customer?.full_name.slice(0, 1)}
                            </Avatar>
                          )}
                          <div>
                            <h5 className="m-0 pb-1">
                              <b>{order?.customer?.full_name}</b>
                            </h5>
                            <h6 className="text-primary m-0">
                              #{order?.customer?.user_code}
                            </h6>
                          </div>
                        </div>
                      </Col>
                      <Col lg="6" sm="8">
                        <h6 className="m-0 pb-1">
                          <span className="text-gray">Phone </span>+1{" "}
                          {order?.customer?.phone}
                        </h6>
                        <h6 className="m-0">
                          <span className="text-gray">Email </span>
                          {order?.customer?.email}
                        </h6>
                      </Col>
                    </Row>
                  </div>
                  <div className="bg-white  py-3 mb-3">
                    <h5 className="font-weight-bold pb-3 m-0 black-one">
                      DELIVERY ADDRESS
                    </h5>
                    <p className="p-0 m-0">{order?.customer?.full_name}</p>
                    <p>
                      {order?.shipping_address?.address} ,
                      {order?.shipping_address?.city} ,
                      {order?.shipping_address?.zip}
                    </p>
                    <h5 className="font-weight-bold pb-3 m-0 black-one d-none">
                      SHIPMENT INFO{" "}
                    </h5>
                  </div>
                </div>
              </Col>
              <Col lg="5">
                <div className="bg-white p-3 p-3 mb-3">
                  <div className="d-none flex-column flex-md-row py-2 ">
                    <h5 className="font-weight-bold pr-2 m-0">INVOICE</h5>
                    <h5 className="text-primary">
                      <u>1233456654</u>
                    </h5>
                  </div>
                  <Row>
                    <Col col="8" className="pb-2">
                      <h5 className="m-0">Total Item Price</h5>
                    </Col>
                    <Col col="4" className="pb-2 text-right">
                      <h5 className="m-0">
                        <b>${order?.transaction?.item_amount || 0}</b>
                      </h5>
                    </Col>
                  </Row>
                  <Row>
                    <Col col="8" className="pb-2">
                      <h5 className="m-0">Shipping Price</h5>
                    </Col>
                    <Col col="4" className="pb-2 text-right">
                      <h5 className="m-0">
                        $ {order?.transaction?.delivery_fee || 0}
                      </h5>
                    </Col>
                  </Row>
                  <Row>
                    <Col col="8" className="pb-2">
                      <h5 className="m-0">Service Charge</h5>
                    </Col>
                    <Col col="4" className="pb-2 text-right">
                      <h5 className="m-0 ">
                        $ {order?.transaction?.service_charge || 0}
                      </h5>
                    </Col>
                  </Row>
                  <Row>
                    <Col col="8" className="pb-2">
                      <h5 className="m-0">
                        {order?.transaction?.discount > 0 ? "Discount" : ""}
                      </h5>
                    </Col>
                    <Col col="4" className="pb-2 text-right">
                      <h5 className="m-0 text-primary">
                        {order?.transaction?.discount > 0 ? "-$" : ""}
                        {order?.transaction?.discount}
                      </h5>
                    </Col>
                  </Row>

                  <hr />
                  <Row className="align-items-center mb-3">
                    <Col col="8" className="pb-2">
                      <h5 className="m-0">
                        <b>TOTAL</b>
                      </h5>
                    </Col>
                    <Col col="4" className="pb-2 text-right">
                      <h3 className="m-0 font-weight-bold">
                        ${order?.transaction?.total_amount || 0}
                      </h3>
                    </Col>
                  </Row>
                  <p className="mb-0">
                    Paid via credit card ending {order?.transaction?.card_last4}
                  </p>
                  <p className="mb-0">
                    Transaction #{order?.transaction?.transaction_id}
                  </p>
                  <p className="mb-0">Status - {order?.transaction?.status}</p>
                </div>
              </Col>
            </Row>
          </Container>
        </>
      )}
    </>
  );
}

export default OrderDetail;
