import React, { useState } from "react";
import { Card, Container, Row, Col, Form, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { message } from "antd";
import { uploadImagesToS3 } from "bucket/s3";
import MetaTitle from "components/Meta";
import { useAddVehicleMutation } from "services/vehicles";
import { useNavigate } from "react-router";
import {
  useGetVehicleByIdQuery,
  useUpdateVehicleMutation,
} from "services/vehicles";
import { useParams } from "react-router";

function EditVehicles({ product }) {
  const [updateVehicle] = useUpdateVehicleMutation();
  let populate = ["vehicle_image", "vehicle_availability"];
  let { id } = useParams();

  const { isLoading, isError, isSuccess, data } = useGetVehicleByIdQuery(
    { id, populate },
    {
      refetchOnMountOrArgChange: false,
    }
  );
  const navigation = useNavigate();
  const [addVehicle] = useAddVehicleMutation();
  const [loading, setLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [filesError, setFilesError] = useState(true);
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({ defaultValues: data?.data?.vehicle });

  //console.log(errors);

  const defaultImages = data?.data?.vehicle?.vehicle_image?.map((image, i) => {
    return {
      location: image.image,
      key: image.id,
      thumbnail: image.thumbnail,
    };
  });
  let defaultThumbnail = 0;
  for (
    let index = 0;
    index < data?.data?.vehicle?.vehicle_image?.length;
    index++
  ) {
    const element = data?.data?.vehicle?.vehicle_image[index];
    if (element.thumbnail === true) {
      defaultThumbnail = index;
    }
  }
  const [thumbnail, setThumbnail] = useState(defaultThumbnail || 0);
  const [productImages, setProductImages] = useState(
    defaultImages ? defaultImages : []
  );
  const handleImagesChange = async (images, e) => {
    if (images.length < 0) {
      setFilesError(true);
    }
    if (images.length > 0) {
      setFilesError(false);
    }

    if (images.length > 5) {
      setFilesError("You can only upload 5 images");
      message.error("You can only upload 5 images");
    }
    let dummy = [];
    for (let index = 0; index < images.length; index++) {
      const element = images[index];
      dummy.push({ location: URL.createObjectURL(element), key: element.name });
    }
    //setProductImages(dummy);
    // productImages.push(...dummy);
    try {
      if (images.length > 0 && images.length < 6) {
        setImageLoading(true);
        const responds = await uploadImagesToS3(images);
        if (responds.length > 0) {
          // console.log("length", productImages.length);
          const lengthDiff = 5 - productImages.length;
          // console.log("lengthDiff", lengthDiff);
          if (images.length > lengthDiff) {
            setFilesError("You can only upload 5 images");
            message.error("You can only upload 5 images");
          } else {
            productImages.push(...responds);
            e.target.value = null;
          }
          setImageLoading(false);
        }
      }
    } catch (error) {
      setImageLoading(false);
      message.error("Something went wrong");
      // console.log(
      //   "🚀 ~ file: AddEditModal.js ~ line 19 ~ onSubmit ~ error",
      //   error
      // );
    }
  };
  const onSubmit = async (data) => {
    setLoading(true);
    let vehicle_images = productImages.map((image, index) => {
      return {
        image: image.location,
        thumbnail: thumbnail === index ? true : false,
      };
    });
    let plate_number = data.plate_number.toUpperCase();
    let seats = parseInt(data.seats);
    let luggage_space = parseInt(data.luggage_space);
    let acceleration = parseInt(data.acceleration);
    let rate = parseInt(data.rate);
    const upData = {
      ...data,
      vehicle_images,
      plate_number,
      seats,
      luggage_space,
      acceleration,
      rate,
    };
    console.log(
      "🚀 ~ file: EditVehicles.js ~ line 110 ~ onSubmit ~ data",
      upData
    );

    const addRep = await updateVehicle({ id, upData });
    if (addRep?.data?.message === "Updated") {
      reset();
      //console.log(createResponds?.data?.data?.product);
      setProductImages([]);
      setLoading(false);
      message.success("Vehicle updated successfully");
      navigation("/admin/vehicles/" + id);
    }
    if (addRep?.data?.error) {
      setLoading(false);
      message.error("Something went wrong");

      setProductImages([]);
    }
    //consol
  };
  return (
    <>
      <MetaTitle text="Edit Vehicle" />
      <Container fluid>
        <Card className="m-0 rounded-0 rounded-top border-top-0 border-left-0 border-right-0 ">
          <Card.Body>
            <h3 className="m-0 py-2 font-weight-bold">Edit Vehicle</h3>
          </Card.Body>
        </Card>
        {data?.data?.vehicle ? (
          <Card className="m-0 rounded-0 rounded-top border-0 vehicle_placeholder ">
            <Card.Body>
              <Row>
                <Col col="12" xl="10" className="m-auto">
                  <div className="py-3">
                    <form
                      className="form-default"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <div className="d-flex align-center justify-content-between">
                        <h5 className="text-black font-weight-bold ">
                          Edit Vehicle Photo
                        </h5>
                        {imageLoading && (
                          <span>
                            {" "}
                            <Spinner
                              className="mx-auto mt-3
                       d-flex "
                              size="sm"
                              animation="border"
                              variant="primary"
                            />
                          </span>
                        )}
                      </div>
                      <div className="d-flex overflow-auto">
                        {productImages?.map(
                          (image, i) =>
                            i < 6 && (
                              <PreviewPhoto
                                key={i}
                                register={register}
                                image={image}
                                index={i}
                                productImages={productImages}
                                setProductImages={setProductImages}
                                setFilesError={setFilesError}
                                imageLoading={imageLoading}
                                setValue={setValue}
                                setThumbnail={setThumbnail}
                                thumbnail={thumbnail}
                              />
                            )
                        )}
                        {productImages.length < 5 && (
                          <label
                            className="addphoto mb-3 mr-4"
                            htmlFor="vehicle_images"
                          >
                            <img
                              className="rounded img-fluid cursor-p"
                              alt="..."
                              src={require("assets/img/upload.png").default}
                            ></img>
                          </label>
                        )}
                        <input
                          className=" w-0 h-0 "
                          type="file"
                          name="vehicle_images"
                          id="vehicle_images"
                          {...register("vehicle_images", {
                            required: productImages.length > 0 ? false : true,
                          })}
                          onChange={(e) => {
                            handleImagesChange(e.target.files, e);
                            if (e.target.files.length > 5) {
                              setProductImages((prev) => []);
                              e.target.value = null;
                            }
                          }}
                          multiple
                        />
                      </div>
                      {filesError && (
                        <div className="text-danger">
                          {filesError}
                          <br />
                        </div>
                      )}
                      {errors?.vehicle_images?.type === "required" &&
                        filesError && (
                          <label className="text-danger">
                            Please upload at least one image
                          </label>
                        )}

                      <Row className="pt-2">
                        <Col col="6" md="6">
                          <Form.Group>
                            <label>Vehicle Plate Number</label>
                            <input
                              className="form-control"
                              type="text"
                              name="plate_number"
                              id="plate_number"
                              placeholder="TEXAS 202 HSZ"
                              {...register("plate_number", {
                                required: true,
                              })}
                            />
                            {errors?.plate_number?.type === "required" && (
                              <label className="text-danger">
                                Please enter vehicle plate number
                              </label>
                            )}
                          </Form.Group>
                        </Col>
                        <Col col="6" md="6">
                          <Form.Group>
                            <label>Vehicle Name</label>
                            <input
                              className="form-control"
                              type="text"
                              name="name"
                              id="name"
                              placeholder="TEXAS 202 HSZ"
                              {...register("name", {
                                required: true,
                              })}
                            />
                            {errors?.name?.type === "required" && (
                              <label className="text-danger">
                                Please enter vehicle name
                              </label>
                            )}
                          </Form.Group>
                        </Col>
                      </Row>
                      <h4 className="text-black font-weight-bold  pb-2">
                        CAR DETAILS
                      </h4>
                      <Row>
                        <Col col="12" md="6">
                          <Form.Group>
                            <label># of Seats</label>
                            <input
                              className="form-control"
                              type="number"
                              name="seats"
                              id="seats"
                              placeholder="4"
                              {...register("seats", {
                                required: true,
                              })}
                            />
                            {errors?.seats?.type === "required" && (
                              <label className="text-danger">
                                Please enter number of seats
                              </label>
                            )}
                          </Form.Group>
                        </Col>
                        <Col col="12" md="6">
                          <Form.Group>
                            <label>Luggage Space</label>
                            <input
                              className="form-control"
                              type="number"
                              name="luggage_space"
                              id="luggage_space"
                              placeholder="2"
                              {...register("luggage_space", {
                                required: true,
                              })}
                            />
                            {errors?.luggage_space?.type === "required" && (
                              <label className="text-danger">
                                Please enter number of luggage space
                              </label>
                            )}
                          </Form.Group>
                        </Col>
                        <Col col="12" md="6">
                          <Form.Group>
                            <label>Engine</label>
                            <input
                              className="form-control"
                              type="text"
                              name="engine"
                              id="engine"
                              placeholder="V10 - 610 hp"
                              {...register("engine", {
                                required: true,
                              })}
                            />
                            {errors?.engine?.type === "required" && (
                              <label className="text-danger">
                                Please enter engine
                              </label>
                            )}
                          </Form.Group>
                        </Col>
                        <Col col="12" md="6">
                          <Form.Group>
                            <label>0 - 100 km/h</label>
                            <input
                              className="form-control"
                              type="number"
                              name="acceleration"
                              id="acceleration"
                              placeholder="3.4 sec"
                              minLength={0}
                              maxLength={100}
                              step="0.01"
                              {...register("acceleration", {
                                required: true,
                              })}
                            />
                            {errors?.acceleration?.type === "required" && (
                              <label className="text-danger">
                                Please enter acceleration
                              </label>
                            )}
                          </Form.Group>
                        </Col>
                        <Col col="12" md="6">
                          <Form.Group>
                            <label>Maximum Speed</label>
                            <input
                              className="form-control"
                              type="text"
                              name="max_speed"
                              id="max_speed"
                              placeholder="324 km/h - 200 mph"
                              {...register("max_speed", {
                                required: true,
                              })}
                            />
                            {errors?.max_speed?.type === "required" && (
                              <label className="text-danger">
                                Please enter maximum speed
                              </label>
                            )}
                          </Form.Group>
                        </Col>
                        <Col col="12" md="6">
                          <Form.Group>
                            <label>Transmission </label>
                            <input
                              className="form-control"
                              type="text"
                              name="transmission"
                              id="transmission"
                              placeholder="Automatic"
                              {...register("transmission", {
                                required: true,
                              })}
                            />
                            {errors?.transmission?.type === "required" && (
                              <label className="text-danger">
                                Please enter transmission
                              </label>
                            )}
                          </Form.Group>
                        </Col>
                        <Col col="12" md="6">
                          <Form.Group>
                            <label>Fuel </label>
                            <input
                              className="form-control"
                              type="text"
                              name="fuel"
                              id="fuel"
                              placeholder="Petrol"
                              {...register("fuel", {
                                required: true,
                              })}
                            />
                            {errors?.fuel?.type === "required" && (
                              <label className="text-danger">
                                Please enter fuel
                              </label>
                            )}
                          </Form.Group>
                        </Col>
                        <Col col="12" md="6">
                          <Form.Group>
                            <label>Options </label>
                            <input
                              className="form-control"
                              type="text"
                              name="options"
                              id="options"
                              placeholder="Full Options"
                              {...register("options", {
                                required: true,
                              })}
                            />
                            {errors?.options?.type === "required" && (
                              <label className="text-danger">
                                Please enter options
                              </label>
                            )}
                          </Form.Group>
                        </Col>
                        <Col md="12">
                          <Form.Group>
                            <label>
                              Description
                              <span className="text-gray">(less than 400)</span>
                            </label>
                            <textarea
                              className="form-control"
                              name="description"
                              id="description"
                              rows="4"
                              cols="80"
                              placeholder="Enter a description"
                              {...register("description", {
                                required: true,
                                maxLength: 400,
                              })}
                            ></textarea>
                            {errors?.description?.type === "required" && (
                              <label className="text-danger">
                                Please enter description
                              </label>
                            )}
                            {errors?.description?.type === "maxLength" && (
                              <label className="text-danger">
                                Description should be less than 400 characters
                              </label>
                            )}
                          </Form.Group>
                        </Col>
                      </Row>

                      <div className="clearfix"></div>
                      <Row>
                        <Col col="12" xl="6" className="m-auto">
                          {loading && (
                            <Spinner
                              className="mx-auto mt-3
                     d-flex"
                              size="sm"
                              animation="border"
                              variant="primary"
                            />
                          )}
                          <input
                            type="submit"
                            value="Edit Vehicle"
                            className="btn w-100 btn-primary btn-outline-primary w-100 mt-3 btn-lg"
                          />
                        </Col>
                      </Row>
                    </form>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        ) : (
          <Spinner
            className="mx-auto mt-3
                     d-flex"
            size="sm"
            animation="border"
            variant="primary"
          />
        )}
      </Container>
    </>
  );
}
const PreviewPhoto = ({
  register,
  watchAllFields,
  image,
  index,
  productImages,
  setProductImages,
  setFilesError,
  imageLoading,
  setValue,
  thumbnail,
  setThumbnail,
}) => {
  return (
    <div
      className={`addphoto btn p-0 mb-3 mr-4 ${imageLoading && " uploading"}`}
    >
      {" "}
      {!imageLoading && (
        <div
          className="close-b "
          onClick={() =>
            removeImage(
              setProductImages,
              productImages,
              image.key,
              setFilesError,
              setValue,
              setThumbnail,
              thumbnail,
              index
            )
          }
        >
          <img
            className=""
            alt="..."
            src={require("assets/img/close.svg").default}
          ></img>
        </div>
      )}
      <label
        htmlFor={image?.key}
        onClick={() => {
          setThumbnail(index);
        }}
      >
        <img
          className="rounded img-fluid skeleton"
          alt="..."
          src={image?.location}
        ></img>
        {thumbnail === index ? (
          <div className="mark-sec">
            <div className="d-flex align-items-center">
              <img
                className="mr-1"
                alt="..."
                src={require("assets/img/mark.svg").default}
              ></img>
              <h6 className="m-0">Mark as thumb</h6>
            </div>
          </div>
        ) : null}
      </label>
    </div>
  );
};
const removeImage = (
  setProductImages,
  productImages,
  image,
  setFilesError,
  setValue,
  setThumbnail,
  thumbnail,
  index
) => {
  if (index === thumbnail) {
    setThumbnail(0);
  }
  const dummy = productImages.filter((item) => item.key !== image);
  setProductImages(dummy);
  if (dummy.length === 0) {
    setValue("vehicle_images", "");
    setProductImages([]);
    setFilesError(true);
    message.error("At least 1 image is required.");
  }
  if (dummy.length < 0) {
    setFilesError(true);
  }
  if (dummy.length > 0) {
    setFilesError(false);
  }

  if (dummy.length > 5) {
    setFilesError("You can only upload 5 images");
    message.error("You can only upload 5 images");
  }
};

export default EditVehicles;
