import React, { useEffect, useState } from "react";
import {
  Card,
  Nav,
  Table,
  Container,
  Row,
  Col,
  Form,
  Modal,
  Tab,
} from "react-bootstrap";
import NewRideListCards from "components/Rides/NewRideListCards";
import AssignDriverModal from "components/Rides/AssignDriverModal";
import ScheduledRidesCards from "components/Rides/ScheduledRidesCards";
import Skelton from "components/Rides/Skelton";
import CompletedRidesCards from "components/Rides/CompletedRidesCards";
import { useGetRidesQuery } from "services/rides";
import { Empty, Pagination } from "antd";
import MetaTitle from "components/Meta";
import { ReloadOutlined } from "@ant-design/icons";
import TableTitle from "components/Orders/List/TableTitle";
import { FilterBy } from "components/Common/FilterBy";

function VehiclesDetail() {
  //const searchValue = useRef("");
  const [skip, setSkip] = useState(false);
  const [activeRide, setActiveRide] = useState(null);
  const [sort, setSort] = useState({
    sortBy: "id",
    sortDirection: "desc",
  });
  const [rideType, setRideType] = useState("new");
  const [filter, setFilter] = useState("");
  const [search, setSearch] = useState("");
  const [reload, setReload] = useState(false);
  const [PageSize, setPageSize] = useState(10); // default page size change value in query also
  const [page, setPageNumber] = useState(0);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (rideId) => {
    setShow(true);
    setActiveRide(rideId);
  };
  const { isLoading, isError, isSuccess, data, status } = useGetRidesQuery(
    { page, PageSize, sort, filter, search, rideType, reload },
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const handlePagination = (page, pageSize) => {
    setPageNumber((prev) => page - 1);
    setPageSize(pageSize);
  };

  return (
    <>
      <AssignDriverModal
        show={show}
        handleClose={handleClose}
        handleShow={handleShow}
        activeRide={activeRide}
      />
      <MetaTitle text="Rides" />
      <Container fluid>
        <Card className="mb-0 border-0">
          <Card.Body className="pb-0">
            <Row>
              <Col col="12" md="8" lg="10">
                <h3 className="m-0 py-2 font-weight-bold d-flex align-items-center">
                  Rides
                  <ReloadOutlined
                    className="ml-2 d-none"
                    onClick={() => setReload((prev) => !prev)}
                    style={{ fontSize: "19px" }}
                    spin={status === "pending"}
                  />
                </h3>
              </Col>
              <Col col="12" md="4" lg="2">
                {rideType == "scheduled" && (
                  <FilterBy
                    filterKey={"ride_status_id"}
                    setFilter={setFilter}
                    filter={filter}
                    setPageNumber={setPageNumber}
                    filterItems={[
                      { name: "Pending", value: "1" },
                      {
                        name: "Scheduled",
                        value: "2",
                      },
                      {
                        name: "Driver on the way",
                        value: "4",
                      },
                      {
                        name: "Driver Reached",
                        value: "5",
                      },
                      {
                        name: "Ride Started",
                        value: "6",
                      },
                      {
                        name: "Reschedule Requested",
                        value: "8",
                      },
                    ]}
                  />
                )}
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <Tab.Container
          id="plain-tabs-example"
          defaultActiveKey="new"
          onSelect={(e) => {
            if (e === "completed") {
              setRideType(e);
              setPageNumber(0);
              setSort({
                sortBy: "ride_start",
                sortDirection: "desc",
              });
            } else {
              setRideType(e);
              setPageNumber(0);
              setSort({
                sortBy: "id",
                sortDirection: "desc",
              });
            }
          }}
        >
          <Nav className="custom-tab mb-3" role="tablist" variant="tabs">
            <Nav.Item>
              <Nav.Link eventKey="new">New Requests</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="scheduled">Scheduled Rides</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="completed">Completed Rides</Nav.Link>
            </Nav.Item>
          </Nav>

          <Tab.Content className="bg-white">
            <Tab.Pane eventKey="new">
              <div className="table-full-width table-responsive">
                <Table className="table-hover revup-table m-0">
                  <thead>
                    <tr>
                      <th className="border-0">#</th>
                      <TableTitle
                        title="Ride Id"
                        setSort={setSort}
                        sortBy="id"
                        sort={sort}
                      />
                      <TableTitle
                        title="Customer"
                        setSort={setSort}
                        sortBy={`customer","full_name`}
                        sort={sort}
                      />
                      <TableTitle
                        title="Vehicle"
                        setSort={setSort}
                        sortBy={`vehicle","plate_number`}
                        sort={sort}
                      />
                      <TableTitle
                        title="Start Date"
                        setSort={setSort}
                        sortBy="ride_start"
                        sort={sort}
                      />
                      <TableTitle
                        title="Duration"
                        setSort={setSort}
                        sortBy="duration"
                        sort={sort}
                      />
                      <TableTitle
                        title="Pickup Location"
                        setSort={setSort}
                        sortBy={`location","title`}
                        sort={sort}
                      />
                      <TableTitle
                        title="Ride Fee"
                        setSort={setSort}
                        sortBy="ride_cost"
                        sort={sort}
                      />
                      <th className="border-0 text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading &&
                      [...Array(data?.data?.rides?.length || 10)].map(
                        (d, i) => <Skelton key={i} />
                      )}
                    {status === "pending" &&
                      [...Array(PageSize)].map((d, i) => <Skelton key={i} />)}
                    {isError && <p>Error </p>}
                    {isSuccess &&
                      status === "fulfilled" &&
                      data?.data?.rides?.map((ride, i) => (
                        <NewRideListCards
                          handleShow={handleShow}
                          key={i}
                          ride={ride}
                          index={page * PageSize + i + 1}
                        />
                      ))}
                    {data?.data?.rides?.length === 0 && (
                      <tr>
                        <td colspan="9" className="empty_items">
                          <Empty
                            description={false}
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                          >
                            <div className="ant-empty-description">
                              <p>No Rides Found</p>
                            </div>
                          </Empty>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="scheduled">
              <div className="table-full-width table-responsive">
                <Table className="table-hover revup-table m-0">
                  <thead className="">
                    <tr>
                      <th className="border-0">#</th>
                      <TableTitle
                        title="Ride Id"
                        setSort={setSort}
                        sortBy="id"
                        sort={sort}
                      />
                      <TableTitle
                        title="Customer"
                        setSort={setSort}
                        sortBy={`customer","full_name`}
                        sort={sort}
                      />
                      <TableTitle
                        title="Vehicle"
                        setSort={setSort}
                        sortBy={`vehicle","plate_number`}
                        sort={sort}
                      />
                      <TableTitle
                        title="Start Date"
                        setSort={setSort}
                        sortBy="ride_start"
                        sort={sort}
                      />
                      <TableTitle
                        title="Duration"
                        setSort={setSort}
                        sortBy="duration"
                        sort={sort}
                      />
                      <TableTitle
                        title="Pickup Location"
                        setSort={setSort}
                        sortBy={`location","title`}
                        sort={sort}
                      />
                      <TableTitle
                        title="Driver Info"
                        setSort={setSort}
                        sortBy={`driver","full_name`}
                        sort={sort}
                      />
                      <TableTitle
                        title="Status"
                        setSort={setSort}
                        sortBy={"ride_status_id"}
                        sort={sort}
                      />
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading &&
                      [...Array(10)].map((d, i) => <Skelton key={i} />)}
                    {status === "pending" &&
                      [...Array(PageSize)].map((d, i) => <Skelton key={i} />)}
                    {isError && <p>Error </p>}
                    {isSuccess &&
                      status === "fulfilled" &&
                      data?.data?.rides?.map((ride, i) => (
                        <ScheduledRidesCards
                          handleShow={handleShow}
                          key={i}
                          ride={ride}
                          index={page * PageSize + i + 1}
                        />
                      ))}
                    {data?.data?.rides?.length === 0 && (
                      <tr>
                        <td colspan="9" className="empty_items">
                          <Empty
                            description={false}
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                          >
                            <div className="ant-empty-description">
                              <p>No Rides Found</p>
                            </div>
                          </Empty>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="completed">
              <div className="table-full-width table-responsive">
                <Table className="table-hover revup-table m-0">
                  <thead className="">
                    <tr>
                      <th className="border-0">#</th>
                      <TableTitle
                        title="Ride Id"
                        setSort={setSort}
                        sortBy="id"
                        sort={sort}
                      />
                      <TableTitle
                        title="Customer"
                        setSort={setSort}
                        sortBy={`customer","full_name`}
                        sort={sort}
                      />
                      <TableTitle
                        title="Vehicle"
                        setSort={setSort}
                        sortBy={`vehicle","plate_number`}
                        sort={sort}
                      />
                      <TableTitle
                        title="Start Date"
                        setSort={setSort}
                        sortBy="ride_start"
                        sort={sort}
                      />
                      <TableTitle
                        title="Duration"
                        setSort={setSort}
                        sortBy="duration"
                        sort={sort}
                      />
                      <TableTitle
                        title="Pickup Location"
                        setSort={setSort}
                        sortBy={`location","title`}
                        sort={sort}
                      />
                      <TableTitle
                        title="Driver Info"
                        setSort={setSort}
                        sortBy={`driver","full_name`}
                        sort={sort}
                      />
                      <TableTitle
                        title="Status"
                        setSort={setSort}
                        sortBy={"ride_status_id"}
                        sort={sort}
                      />{" "}
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading &&
                      [...Array(10)].map((d, i) => <Skelton key={i} />)}
                    {status === "pending" &&
                      [...Array(PageSize)].map((d, i) => <Skelton key={i} />)}
                    {isError && <p>Error </p>}
                    {isSuccess &&
                      status === "fulfilled" &&
                      data?.data?.rides?.map((ride, i) => (
                        <CompletedRidesCards
                          handleShow={handleShow}
                          key={i}
                          ride={ride}
                          index={page * PageSize + i + 1}
                        />
                      ))}
                    {data?.data?.rides?.length === 0 && (
                      <tr>
                        <td colspan="9" className="empty_items">
                          <Empty
                            description={false}
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                          >
                            <div className="ant-empty-description">
                              <p>No Rides Found</p>
                            </div>
                          </Empty>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
        <Row className="mt-3">
          <Col col="12" md="12" lg="12">
            {data?.data?.count > 0 && (
              <Pagination
                current={page + 1}
                defaultCurrent={1}
                defaultPageSize={PageSize}
                onChange={handlePagination}
                total={parseInt(data?.data?.count)}
              />
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default VehiclesDetail;
