import Cookies from "js-cookie";
export const setToken = (token) => {
  Cookies.set("token", token, { expires: 1 });
};
export const getToken = () => {
  const token = Cookies.get("token");
  return token;
};
export const removeToken = () => {
  Cookies.remove("token");
};

// session /////////////////////////////////////
export const setSession = (session) => {
  Cookies.set("session", session, { expires: 1 });
};
export const getSession = () => {
  const session = Cookies.get("session");
  return session;
};
export const removeSession = () => {
  Cookies.remove("session");
};
// session end ///////////////////////////////
