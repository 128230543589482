import React, { useEffect, useState } from "react";
import { Pagination } from "antd"; // react-bootstrap components
import { Card, Nav, Table, Container, Row, Col, Tab } from "react-bootstrap";
import { DatePicker, Empty } from "antd";
import ListCard from "components/Orders/ListCard";
import Skeleton from "components/Orders/Skeleton";
import { useGetAllOrderQuery } from "services/orders";
import moment from "moment";
import TableTitle from "components/Products/List/TableTitle";
import MetaTitle from "components/Meta";
const { RangePicker } = DatePicker;
function VehiclesDetail() {
  useEffect(() => {
    setFilterOrder({ ...filterOrder, filter: "NewOrders" });
  }, []);
  const populate = encodeURIComponent(
    JSON.stringify([
      "customer",
      "order_item",
      "order_item.product",
      "order_item.product.product_image",
    ])
  );
  const [sort, setSort] = useState({
    sortBy: "created_at",
    sortDirection: "desc",
  });
  const [hideFilter, setHideFilter] = useState(false);

  const [filterOrder, setFilterOrder] = useState("");
  const [PageSize, setPageSize] = useState(10); // default page size change value in query also
  const [page, setPageNumber] = useState(0);

  const { isLoading, isError, isSuccess, data, status } = useGetAllOrderQuery(
    { page, PageSize, sort, filterOrder, populate },
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const handlePagination = (page, pageSize) => {
    setPageNumber((prev) => page - 1);
    setPageSize(pageSize);
  };
  const handlePicker = (data, date) => {
    const fromDate = date[0] + " 00:00:00";
    const toDate = date[1] + " 23:59:00";
    const created_at = { $gte: fromDate, $lte: toDate };
    if (date[0] && date[1]) {
      setFilterOrder({ ...filterOrder, created_at });
    } else {
      const { created_at, ...newObj } = filterOrder;
      setFilterOrder((prev) => newObj);
    }
    setPageNumber(0);
  };
  return (
    <>
      <MetaTitle text="Orders" />
      <Container fluid>
        <Card className="mb-0 border-0">
          <Card.Body className="pb-0">
            <Row>
              <Col>
                <h3 className="m-0 py-2 font-weight-bold">Orders</h3>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <Tab.Container
          id="plain-tabs-example"
          defaultActiveKey="NewOrders"
          onSelect={(e) => {
            const filter = e;
            setFilterOrder({ ...filterOrder, filter });

            if (filter === "PastOrders") {
              setHideFilter(true);
            } else {
              setHideFilter(false);
            }
            setPageNumber(0);
          }}
        >
          <Nav className="custom-tab mb-3" role="tablist" variant="tabs">
            <Nav.Item>
              <Nav.Link eventKey="NewOrders">New Orders</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="PastOrders">Past Orders</Nav.Link>
            </Nav.Item>
          </Nav>

          <Tab.Content className="bg-white">
            <div className="p-3 border-bottom">
              <Row className="align-items-center">
                <Col col="12" lg="6">
                  {isLoading && (
                    <h4 className="m-0 p-0 font-weight-bold">
                      <b className="skeleton">dummy Orders</b>
                    </h4>
                  )}
                  {data?.data?.orders?.length > 0 && (
                    <h4 className="m-0 p-0 font-weight-bold">
                      <b>{data?.data?.count} Orders</b>
                    </h4>
                  )}
                </Col>
                <Col col="12" lg="6">
                  <Row>
                    <div className={`col-md-8 col-12`}>
                      <RangePicker
                        className="form-control"
                        onChange={(start, end) => handlePicker(start, end)}
                        format="MM/DD/YY"
                      />
                    </div>
                    <div className={`mt-2 mt-md-0  col-md-4 `}>
                      <select
                        className="form-control"
                        onChange={(e) => {
                          const filter = e.target.value;
                          if (filter === "0") {
                            // const { filter, ...newObj } = filterOrder;
                            // setFilterOrder((prev) => newObj);
                            setFilterOrder({
                              ...filterOrder,
                              filter: "NewOrders",
                            });
                            setPageNumber(0);
                          } else {
                            setFilterOrder({ ...filterOrder, filter });
                            setPageNumber(0);
                          }
                        }}
                      >
                        <option value="0" className="d-none">
                          Filter By
                        </option>
                        {!hideFilter && (
                          <>
                            <option value="0">All</option>
                            <option value="Ordered">Ordered</option>
                            <option value="Shipped">Shipped</option>
                          </>
                        )}
                        {hideFilter && (
                          <>
                            <option value="PastOrders">All</option>
                            <option value="Cancelled">Cancelled</option>
                            <option value="Delivered">Delivered</option>
                          </>
                        )}
                      </select>
                    </div>
                  </Row>
                </Col>
              </Row>
            </div>
            <div className="table-full-width table-responsive">
              <Table className="table-hover revup-table m-0">
                <thead>
                  <tr>
                    <th className="border-0">#</th>
                    <TableTitle
                      title="Order ID"
                      setSort={setSort}
                      sortBy="id"
                      sort={sort}
                    />
                    <TableTitle
                      title="Item"
                      setSort={setSort}
                      sortBy={`order_item","product","title`}
                      sort={sort}
                    />
                    <TableTitle
                      title="Qty"
                      setSort={setSort}
                      sortBy="total_qty"
                      sort={sort}
                    />
                    <TableTitle
                      title="Amount"
                      setSort={setSort}
                      sortBy="total_amount"
                      sort={sort}
                    />
                    <TableTitle
                      title="Customer"
                      setSort={setSort}
                      sortBy={`customer","full_name`}
                      sort={sort}
                    />
                    <TableTitle
                      title="Order Placed"
                      setSort={setSort}
                      sortBy="created_at"
                      sort={sort}
                    />
                    <th className="border-0">Order Status</th>
                  </tr>
                </thead>
                <tbody>
                  {isLoading &&
                    [...Array(10)].map((d, i) => <Skeleton key={i} />)}
                  {status === "pending" &&
                    [...Array(PageSize)].map((d, i) => <Skeleton key={i} />)}
                  {isError && <p>Error </p>}
                  {isSuccess &&
                    status === "fulfilled" &&
                    data?.data?.orders.map((order, i) => (
                      <ListCard
                        key={i}
                        order={order}
                        index={page * PageSize + i + 1}
                      />
                    ))}
                  {data?.data?.orders.length === 0 && (
                    <tr>
                      <td colspan="9" className="empty_items">
                        <Empty
                          description={false}
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                        >
                          <div className="ant-empty-description">
                            <p>No orders Found</p>
                          </div>
                        </Empty>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </Tab.Content>
          <Row className="mt-3">
            <Col col="12" md="12" lg="12">
              {data?.data?.count > 0 && (
                <Pagination
                  current={page + 1}
                  defaultCurrent={1}
                  defaultPageSize={PageSize}
                  onChange={handlePagination}
                  total={parseInt(data?.data?.count)}
                />
              )}
            </Col>
          </Row>
        </Tab.Container>
      </Container>
    </>
  );
}

export default VehiclesDetail;
