import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getToken } from "cookies/token";
import { authCheck } from "./functions/authCheck";

const header = (headers, { getState }) => {
  const token = getToken();
  if (token) {
    headers.set("authorization", `Bearer ${token}`);
  }
  return headers;
};

export const passwordConformationApi = createApi({
  reducerPath: "PasswordConformationApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: header,
  }),
  tagTypes: ["PasswordConformation"],
  endpoints: (builder) => ({
    confirmPassword: builder.mutation({
      query: ({ verifyData, id }) => {
        return {
          url: `/auth/confirm_password`,
          method: "POST",
          body: verifyData,
        };
      },
    }),
  }),
});

export const { useConfirmPasswordMutation } = passwordConformationApi;
