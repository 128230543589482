import React from "react";
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  Form,
  InputGroup,
  Spinner,
} from "react-bootstrap";
function Skeleton() {
  return (
    <Container fluid>
      <Card className="m-0 rounded-0 rounded-top border-top-0 border-left-0 border-right-0 ">
        <Card.Body>
          <h3 className="m-0 py-2 font-weight-bold">
            <span className="skeleton">Edit Special Bookings</span>
          </h3>
        </Card.Body>
      </Card>
      <Card className="m-0 rounded-0 rounded-top border-0 ">
        <Card.Body>
          <Row>
            <Col col="12" xl="10" className="m-auto">
              <div className="py-3">
                <form className="form-default">
                  <h4 className="text-black font-weight-bold  pb-2">
                    
                    <span className="skeleton">CUSTOMER DETAILS</span>

                  </h4>
                  <Row>
                    <Col col="12" md="6">
                      <Form.Group>
                        <label className="skeleton">First Name</label>
                        <input
                          className="form-control"
                          type="text"
                          name="first_name"
                        />
                      </Form.Group>
                    </Col>
                    <Col col="12" md="6">
                      <Form.Group>
                        <label className="skeleton">Last Name</label>
                        <input
                          className="form-control"
                          type="text"
                          name="last_name"
                        />
                      </Form.Group>
                    </Col>
                    <Col col="12" md="6">
                      <Form.Group>
                        <label className="skeleton">Email</label>
                        <input
                          className="form-control"
                          type="email"
                          name="email"
                        />
                      </Form.Group>
                    </Col>
                    <Col col="12" md="6">
                      <Form.Group>
                        <label className="skeleton">Phone</label>
                        <input
                          className="form-control"
                          type="tel"
                          name="phone"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <h4 className="text-black font-weight-bold  pb-2">
                                             <label className="skeleton">Select Vehicle</label>

                  </h4>
                  <Row>
                    <Col className="col-12 mb-3">
                      <Form.Group className="m-0">
                        <label className="skeleton">Select Vehicle</label>
                        <input
                            className="form-control"
                            type="text"
                            name="ride_date"
                            disabled
                          />
                      </Form.Group>
                    </Col>
                    <Col col="12" md="6">
                      <Form.Group
                        controlId="exampleForm.ControlSelect1"
                        className="m-0"
                      >
                        <label className="skeleton">Duration</label>
                        <input
                          className="form-control"
                          type="number"
                          min={1}
                          max={24}
                        />
                      </Form.Group>
                    </Col>

                    <Col col="12" md="6">
                      <Form.Group>
                        <label className="skeleton">Start Date & Time Slot</label>
                        <InputGroup>
                          <input
                            className="form-control"
                            type="text"
                            name="ride_date"
                            disabled
                          />

                         
                        </InputGroup>
                      </Form.Group>
                    </Col>

                    <Col className="col-12 mb-3">
                      <Form.Group>
                        <label className="skeleton">Service Location</label>

                        <input
                            className="form-control"
                            type="text"
                            name="ride_date"
                            disabled
                          />
                      </Form.Group>
                    </Col>
                    <Col col="12" md="6">
                      <Form.Group>
                        <label className="skeleton">Ride Cost</label>
                        <input
                          className="form-control"
                          type="number"
                          name="ride_cost"
                          min={0}
                        />
                      </Form.Group>
                    </Col>
                    <Col col="12" md="6">
                      <Form.Group>
                        <label className="skeleton">Select Driver</label>
                        <InputGroup>
                          <input
                            className="form-control"
                            type="text"
                            disabled
                          />
                         

                        
                        </InputGroup>
                      </Form.Group>
                    </Col>
                  </Row>

                  <div className="clearfix"></div>
                </form>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
}

export default Skeleton;
