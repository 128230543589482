import React from "react";
import { Link } from "react-router-dom";
import { Avatar, Image } from 'antd';
import moment from "moment";

function CompletedRidesCards({ index, ride }) {
  return (
    <tr>
      <td>{index}</td>
      <td>
        <Link to={"/admin/rides/" + ride?.id} className="text-primary">
          {ride?.ride_code}
        </Link>
      </td>
      <td>
        <div className="d-flex align-items-center">
          <div className="thumbnail-round mr-3">
            <Avatar
              shape="square"
              className="w-100 h-100"
              src={
                <Image
                  src={
                    ride?.customer?.image ||
                    `https://joeschmoe.io/api/v1/${ride?.customer?.full_name}`
                  }
                />
              }
            />
          </div>
          <div>
            <h5 className="m-0 text-truncate">
              <b>{ride?.customer?.full_name}</b>
            </h5>
          </div>
        </div>
      </td>
      <td>
        <h5 className="m-0">
          {ride?.vehicle?.plate_number} <br />
          {ride?.vehicle?.name}{" "}
        </h5>
      </td>
      <td>
        <h5 className="m-0">
          {moment(ride?.ride_start).format("MM/DD/YY, h:mm a")}
        </h5>
      </td>
      <td>
        <h5 className="m-0">
          {ride?.duration}{" "}
          <span className="text-gray">
            {ride?.duration > 1 ? "Hours" : "Hour"}
          </span>
        </h5>
      </td>
      <td>
        <h5 className="m-0">{ride?.location?.title}</h5>
      </td>
      <td>
        <div className="d-flex align-items-center">
          <div className="thumbnail-round mr-3">
            <Avatar
              shape="square"
              className="w-100 h-100"
              src={
                <Image
                  src={
                    ride?.driver?.image ||
                    `https://joeschmoe.io/api/v1/${ride?.driver?.full_name}`
                  }
                />
              }
            />
          </div>
          <div>
            <h5 className="m-0 text-truncate">
              <b>{ride?.driver?.full_name}</b>
            </h5>
          </div>
        </div>
      </td>
      <td className="ride_status" style={{ color: ride?.status_colour }}>
        <span style={{ backgroundColor: ride?.status_colour }}></span>
        {ride?.status}
      </td>
    </tr>
  );
}

export default CompletedRidesCards;
