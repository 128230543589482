import React, { useState } from "react";
import { Card, Row, Col } from "react-bootstrap";
import {
  ArrowLeftOutlined,
  WarningFilled,
  LoadingOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { useUpdateSpecialBookingMutation } from "services/specialBookings";
import { useUpdateRideBookingMutation } from "services/rides";
import { Popconfirm, message, Modal } from "antd";
import { Rides } from "views/Pages/Admin/Rides/index";

function Header({ track, setTrack, ride }) {
  const [loading, setLoading] = useState(false);
  const [updateRideBooking] = useUpdateRideBookingMutation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    setLoading(true);
    let upData = {
      id: ride.id,
      ride_status_id: 3,
    };
    const addSpecialBookingRes = await updateRideBooking(upData);
    if (addSpecialBookingRes?.data?.message === "Updated") {
      message.success("Ride Cancelled");
      setLoading(false);
      setIsModalVisible(false);
    } else {
      addSpecialBookingRes?.data?.message
        ? message.error(addSpecialBookingRes?.data?.message)
        : message.error("Something went wrong");
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <Card className="mb-3 rounded-0 rounded-top border-top-0 border-left-0 border-right-0 ">
      <Card.Body>
        <Row className="align-items-center">
          <Col col="12" xl="10">
            <div className="d-flex align-items-center">
              <h3 className="m-0 pb-0 font-weight-bold mr-2">
                #{ride?.ride_code}
              </h3>
              <div
                className="ride_status"
                style={{ color: ride?.ride_status?.color }}
              >
                <span
                  style={{ backgroundColor: ride?.ride_status?.color }}
                ></span>
                {ride?.ride_status?.name}
              </div>
            </div>
            <h6 className="secondary-gray m-0 pb-2">
              {moment(ride?.ride_start).format("MM/DD/YY, h:mm a")}
            </h6>
          </Col>
          <Col className="my-1" col="12" md="2">
            {track ? (
              <button
                type="button"
                className="btn btn-outline-dark float-right "
                onClick={() => setTrack(false)}
              >
                <ArrowLeftOutlined /> Back
              </button>
            ) : (
              ride?.ride_status_id === 1 ||
              ride?.ride_status_id === 2 ||
              ride?.ride_status_id === 8 ? (
                <button
                  type="button"
                  className="btn btn-outline-danger w-100"
                  onClick={showModal}
                >
                  Cancel Ride
                </button>
              ):null
            )}
          </Col>
        </Row>
      </Card.Body>
      <>
        <Modal
          visible={isModalVisible}
          footer={null}
          centered
          onCancel={handleCancel}
        >
          <aside className=" p-4 d-flex justify-content-center flex-column">
            <WarningFilled
              style={{
                fontSize: "50px",
                color: "#ff0000",
              }}
            />
            <p className="text-center mt-3 font-weight-bold ">
              Are you sure to cancel this ride?
            </p>
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-outline-dark px-5  mr-2"
                onClick={handleCancel}
              >
                No
              </button>
              <button
                className="btn btn-outline-danger ml-2  "
                onClick={handleOk}
              >
                Yes Cancel
                {loading && (
                  <LoadingOutlined
                    style={{
                      marginLeft: "10px",
                      marginTop: "-2px",
                    }}
                  />
                )}
              </button>
            </div>
          </aside>
        </Modal>
      </>
    </Card>
  );
}

export default Header;
