import CustomerDetailSkeleton from "components/Customers/Detail/CustomerDetailSkeleton";
import React, { useEffect, useState } from "react";
import { Card, Nav, Table, Container, Row, Col, Tab } from "react-bootstrap";
import TableTitle from "components/Orders/List/TableTitle";

import {
  MoreOutlined,
  WarningFilled,
  LoadingOutlined,
} from "@ant-design/icons";
import { Menu, Dropdown, Empty, message, Modal, Pagination } from "antd";
import DetailRideCard from "./../../../../components/Customers/Detail/DetailRideCard";
import DetailRideCardSkeleton from "components/Customers/Detail/DetailRideCardSkeleton";
import DetailPurchaseCard from "components/Customers/Detail/DetailPurchaseCard";
import DetailPurchaseCardSkeleton from "components/Customers/Detail/DetailPurchaseCardSkeleton";
import {
  useDeleteCustomerByIdMutation,
  useGetCustomerByIdQuery,
  useUpdateCustomerByIdMutation,
} from "services/customers";
import { useParams } from "react-router";
import PasswordModal from "components/Common/PasswordModal";
import moment from "moment";

function confirm(e) {
  console.log(e);
  message.success("Click on Yes");
}

const menu = ({ showModal, showPasswordModalHandler, user }) => (
  <Menu>
    {!user?.active && (
      <Menu.Item key="0">
        <button
          onClick={showPasswordModalHandler}
          className="text-primary btn p-0"
        >
          Delete User
        </button>
      </Menu.Item>
    )}
    <Menu.Item key="0">
      {user?.active ? (
        <button onClick={showModal} className=" btn p-0">
          Block User
        </button>
      ) : (
        <button onClick={showModal} className=" btn p-0">
          Unblock User
        </button>
      )}
    </Menu.Item>
  </Menu>
);
function CustomerDetail() {
  let { id } = useParams();
  const [currentTab, setCurrentTab] = useState("ride");
  const [sort, setSort] = useState({
    sortBy: `${currentTab}","created_at`,
    sortDirection: "desc",
  });
  const [populate, setPopulate] = useState([
    "ride",
    "ride.vehicle",
    "ride.location",
    "ride.driver",
    "ride.ride_status",
    "order",
    "order.shipping_address",
    "order.shipping_address.state",
    "order.order_item",
    "order.order_item.product",
    "order.order_item.product.product_image",
  ]);
  const { isLoading, isError, isSuccess, data, status } =
    useGetCustomerByIdQuery(
      { id, populate, sort },
      {
        refetchOnMountOrArgChange: true,
      }
    );
  const [updateCustomer] = useUpdateCustomerByIdMutation();
  const [deleteCustomer] = useDeleteCustomerByIdMutation();
  const [loading, setLoading] = useState(false);
  // const [updateRideBooking] = useUpdateRideBookingMutation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = async (user) => {
    setLoading(true);
    let upData = {
      id: user.id,
      active: !user.active,
    };
    const statusChange = await updateCustomer(upData);
    if (statusChange?.data?.message === "Updated") {
      message.success(
        `Customer ${upData.active ? "Unblocked" : "Blocked"} Successfully`
      );
      setLoading(false);
      setIsModalVisible(false);
    } else {
      statusChange?.data?.message
        ? message.error(statusChange?.data?.message)
        : message.error("Something went wrong");
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const showPasswordModalHandler = () => {
    setShowPasswordModal(true);
  };
  const hidePasswordModalHandler = () => {
    setShowPasswordModal(false);
  };
  let user = data?.data?.user;
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);
  const [rideList, setRideList] = useState([]);
  const [purchaseList, setPurchaseList] = useState([]);

  const handlePagination = (page, pageSize) => {
    setCurrentPage(page);
    setPostsPerPage(pageSize);
  };
  useEffect(() => {
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    setRideList(user?.ride?.slice(indexOfFirstPost, indexOfLastPost));
    setPurchaseList(user?.order?.slice(indexOfFirstPost, indexOfLastPost));
  }, [user?.ride, currentPage]);

  return (
    <>
      {" "}
      <PasswordModal
        showPasswordModal={showPasswordModal}
        hidePasswordModalHandler={hidePasswordModalHandler}
        type={"customer"}
        userId={id}
      />
      <Modal
        visible={isModalVisible}
        footer={null}
        centered
        onCancel={handleCancel}
      >
        <aside className=" p-4 d-flex justify-content-center flex-column">
          <WarningFilled
            style={{
              fontSize: "50px",
              color: "#ff0000",
            }}
          />
          <p className="text-center mt-3 font-weight-bold ">
            Are you sure to {user?.active ? "Block" : "Unblock"} this Customer?
          </p>
          <div className="d-flex justify-content-center">
            <button
              className="btn btn-outline-dark px-5  mr-2"
              onClick={handleCancel}
            >
              No
            </button>
            <button
              className="btn btn-outline-danger ml-2  "
              onClick={() => handleOk(user)}
            >
              Yes {user?.active ? "Block" : "Unblock"}
              {loading && (
                <LoadingOutlined
                  style={{
                    marginLeft: "10px",
                    marginTop: "-2px",
                  }}
                />
              )}
            </button>
          </div>
        </aside>
      </Modal>
      <Container fluid>
        {isLoading && <CustomerDetailSkeleton />}
        {isError && <div>Error</div>}
        {isSuccess && (
          <Card className="rounded-0 rounded-top border-0 mb-0 position-relative">
            <div className="customer_detail_settings">
              {" "}
              <Dropdown
                overlay={menu({ showModal, showPasswordModalHandler, user })}
                trigger={["click"]}
              >
                <button className="ant-dropdown-link btn ">
                  <MoreOutlined
                    style={{ fontSize: "30px", color: "gray", width: "50px" }}
                  />
                </button>
              </Dropdown>
            </div>

            <Card.Body className="border-bottom">
              <Row>
                <Col col="12" lg="2">
                  <div className="thumbnail-round-lg m-md-auto">
                    <img
                      alt="..."
                      src={
                        data?.data?.user?.image ||
                        `https://joeschmoe.io/api/v1/${data?.data?.user?.first_name}`
                      }
                    ></img>
                    {data?.user?.first_names}
                  </div>
                </Col>

                <Col col="12" lg="10" className="mt-1 p-0">
                  <Row>
                    <Col col="12" xl="6">
                      <div className="ml-md-3">
                        <h2 className="font-weight-bold my-1 text-truncate">
                          {data?.data?.user?.full_name}
                        </h2>
                        <Row>
                          <Col col="12" lg="6">
                            <div className="mb-3">
                              <div className="d-flex flex-column flex-md-row pb-1 align-items-md-center">
                                <h6 className="text-gray mr-1">
                                  Customer ID -{" "}
                                  <span className="text-primary">
                                    {data?.data?.user?.user_code}
                                  </span>{" "}
                                </h6>
                                {data?.data?.user?.active ? (
                                  <div className="ml-3 text-active">
                                    <span></span>
                                    Active
                                  </div>
                                ) : (
                                  <div className="ml-3 text-active text-danger">
                                    <span></span>
                                    Inactive
                                  </div>
                                )}
                              </div>
                              <h6 className="text-gray">
                                Date of join -{" "}
                                {moment(data?.data?.user?.created_at).format(
                                  "MM/DD/YY"
                                )}
                              </h6>
                            </div>
                          </Col>
                          <Col col="12" lg="6">
                            <div className="mb-3">
                              <h6 className="pb-2">
                                <span className="text-gray">Phone </span>
                                {data?.data?.user?.phone_code}{" "}
                                {data?.data?.user?.phone}
                              </h6>
                              <h6>
                                <span className="text-gray">Email </span>
                                {data?.data?.user?.email}
                              </h6>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>

                    <Col col="12" md="6" xl="3">
                      <Row className="align-items-center">
                        <Col xs="3" md="4">
                          <div className="text-center d-flex align-items-center justify-content-center py-2">
                            <img
                              className="img-fluid"
                              alt="..."
                              src={require("assets/img/rides.svg").default}
                            ></img>
                          </div>
                        </Col>
                        <Col xs="9" md="8" className="pl-0">
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="">
                              <h2 className="font-weight-bold m-0">
                                {data?.data?.user?.ride?.length}
                              </h2>
                              <h5 className="font-weight-bold text-uppercase m-0">
                                TOTAL RIDES
                              </h5>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Col>

                    <Col col="12" md="6" xl="3">
                      <Row className="align-items-center">
                        <Col xs="3" md="4">
                          <div className="text-center d-flex align-items-center justify-content-center py-2">
                            <img
                              className="img-fluid"
                              alt="..."
                              src={require("assets/img/purchase.svg").default}
                            ></img>
                          </div>
                        </Col>
                        <Col xs="9" md="8" className="pl-0">
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="">
                              <h2 className="font-weight-bold m-0">
                                {data?.data?.user?.order?.length}
                              </h2>
                              <h5 className="font-weight-bold text-uppercase m-0">
                                Purchase
                              </h5>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        )}

        <Tab.Container
          id="plain-tabs-example"
          defaultActiveKey="ride"
          onSelect={(e) => {
            setCurrentPage(1);
            setSort({
              sortBy: `${e}","created_at`,
              sortDirection: "desc",
            })
          }}
        >
          <Nav className="custom-tab pt-2 mb-3" role="tablist" variant="tabs">
            <Nav.Item>
              <Nav.Link eventKey="ride">Rides</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="order">Purchase</Nav.Link>
            </Nav.Item>
          </Nav>

          <Tab.Content className="bg-white">
            <Tab.Pane eventKey="ride">
              <div className="table-full-width table-responsive">
                <Table className="table-hover revup-table m-0">
                  <thead>
                    <tr>
                      <th className="border-0">#</th>
                      <TableTitle
                        title="Order ID"
                        setSort={setSort}
                        sortBy={`ride","id`}
                        sort={sort}
                      />
                      <TableTitle
                        title="Date"
                        setSort={setSort}
                        sortBy={`ride","ride_date`}
                        sort={sort}
                      />
                      <TableTitle
                        title="Vehicle"
                        setSort={setSort}
                        sortBy={`ride","vehicle","name`}
                        sort={sort}
                      />
                      <TableTitle
                        title="Duration"
                        setSort={setSort}
                        sortBy={`ride","duration`}
                        sort={sort}
                      />
                      <TableTitle
                        title="Pickup Location"
                        setSort={setSort}
                        sortBy={`ride","location","title`}
                        sort={sort}
                      />
                      <TableTitle
                        title="Ride Fee"
                        setSort={setSort}
                        sortBy={`ride","ride_cost`}
                        sort={sort}
                      />
                      <TableTitle
                        title="Driver"
                        setSort={setSort}
                        sortBy={`ride","driver","full_name`}
                        sort={sort}
                      />
                      <TableTitle
                        title="Status"
                        setSort={setSort}
                        sortBy={`ride","ride_status","name`}
                        sort={sort}
                      />
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading &&
                      [...Array(10)].map((d, i) => (
                        <DetailRideCardSkeleton key={i} />
                      ))}
                    {status === "pending" &&
                      [...Array(data?.data?.user?.ride.length || 10)].map(
                        (d, i) => <DetailRideCardSkeleton key={i} />
                      )}
                    {isError && <p>Error </p>}
                    {isSuccess &&
                      status === "fulfilled" &&
                      rideList?.map((ride, i) => (
                        <DetailRideCard
                          key={i}
                          ride={ride}
                          index={
                            currentPage > 1
                              ? i +
                                1 * postsPerPage * currentPage -
                                postsPerPage +
                                1
                              : i + 1
                          }
                        />
                      ))}
                    {data?.data?.user?.ride.length === 0 && (
                      <tr>
                        <td colspan="10" className="empty_items">
                          <Empty
                            description={false}
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                          >
                            <div className="ant-empty-description">
                              <p>No Ride Found</p>
                            </div>
                          </Empty>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
              <Row className="mt-3">
                <Col col="12" md="12" lg="12">
                  <Pagination
                    current={currentPage}
                    defaultCurrent={1}
                    defaultPageSize={postsPerPage}
                    onChange={handlePagination}
                    total={parseInt(user?.ride?.length)}
                  />
                </Col>
              </Row>
            </Tab.Pane>
            <Tab.Pane eventKey="order">
              <div className="table-full-width table-responsive">
                <Table className="table-hover revup-table m-0">
                  <thead className="bg-secondary">
                    <tr>
                      <th className="border-0">#</th>
                      <TableTitle
                        title="Order ID"
                        setSort={setSort}
                        sortBy={`order","id`}
                        sort={sort}
                      />
                      <TableTitle
                        title="Product"
                        setSort={setSort}
                        sortBy={`order","order_item","product","title`}
                        sort={sort}
                      />
                      <TableTitle
                        title="Price"
                        setSort={setSort}
                        sortBy={`order","total_amount`}
                        sort={sort}
                      />
                      <TableTitle
                        title="Order Date"
                        setSort={setSort}
                        sortBy={`order","created_at`}
                        sort={sort}
                      />
                      <TableTitle
                        title="Shipping Address"
                        setSort={setSort}
                        sortBy={`order","shipping_address","address`}
                        sort={sort}
                      />
                      <TableTitle
                        title="Shipping "
                        setSort={setSort}
                        sortBy={`order","carrier`}
                        sort={sort}
                      />
                      <TableTitle
                        title="Status "
                        setSort={setSort}
                        sortBy={`order","order_item","status`}
                        sort={sort}
                      />
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading &&
                      [...Array(10)].map((d, i) => (
                        <DetailPurchaseCardSkeleton key={i} />
                      ))}
                    {status === "pending" &&
                      [...Array(data?.data?.user?.order.length || 10)].map(
                        (d, i) => <DetailPurchaseCardSkeleton key={i} />
                      )}
                    {isError && <p>Error </p>}
                    {isSuccess &&
                      status === "fulfilled" &&
                      purchaseList?.map((order, i) => (
                        <DetailPurchaseCard
                          key={i}
                          order={order}
                          index={
                            currentPage > 1
                              ? i +
                                1 * postsPerPage * currentPage -
                                postsPerPage +
                                1
                              : i + 1
                          }
                        />
                      ))}
                    {data?.data?.user?.order.length === 0 && (
                      <tr>
                        <td colspan="10" className="empty_items">
                          <Empty
                            description={false}
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                          >
                            <div className="ant-empty-description">
                              <p>No Order Found</p>
                            </div>
                          </Empty>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
              <Row className="mt-3">
                <Col col="12" md="12" lg="12">
                  <Pagination
                    current={currentPage}
                    defaultCurrent={1}
                    defaultPageSize={postsPerPage}
                    onChange={handlePagination}
                    total={parseInt(user?.order?.length)}
                  />
                </Col>
              </Row>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </Container>
    </>
  );
}

export default CustomerDetail;
