import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getToken, removeToken } from "cookies/token";
import { authCheck } from "./functions/authCheck";
const header = (headers, { getState }) => {
  const token = getToken();
  if (token) {
    headers.set("authorization", `Bearer ${token}`);
  }
  return headers;
};

export const driverApi = createApi({
  reducerPath: "DriverApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: header,
  }),
  tagTypes: ["Driver"],
  endpoints: (builder) => ({
    getAllDriver: builder.query({
      query: (...data) => ({
        url: `/user/driver?offset=${data[0].PageSize * data[0].page}&limit=${
          data[0].PageSize
        }&sort=%5B%5B%22created_at%22%2C%22desc%22%5D%5D`,
        method: "GET",
        validateStatus: (response, result) => authCheck(response, result),
      }),
      providesTags: ["Driver"],
    }),
    getAllAvailableDriver: builder.query({
      query: (...data) => ({
        url: `/user/available_drivers?offset=${
          data[0].PageSize * data[0].page
        }&limit=${
          data[0].PageSize
        }&sort=%5B%5B%22created_at%22%2C%22desc%22%5D%5D&search=${
          data[0].search
        }`,
        method: "GET",
        validateStatus: (response, result) => authCheck(response, result),
      }),
      providesTags: ["Driver", "Rides"],
    }),
    getDriverById: builder.query({
      query: ({ id, sort }) => {
        const populate = encodeURIComponent(
          JSON.stringify([
            "driver_ride",
            "driver_ride.customer",
            "driver_ride.location",
            "driver_ride.ride_status",
            "driver_ride.tip",
            "driver_ride.review",
          ])
        );
        return {
          url: `/user/driver/${id}?populate=${populate}&sort=%5B%5B%22${sort.sortBy}%22%2C%22${sort.sortDirection}%22%5D%5D`,
          method: "GET",
          validateStatus: (response, result) => authCheck(response, result),
        };
      },
      providesTags: ["Driver"],
    }),
    createDriver: builder.mutation({
      query: (newDriver) => {
        return {
          url: `/user`,
          method: "POST",
          body: newDriver,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        };
      },
      invalidatesTags: ["Driver"],
    }),
    updateDriverById: builder.mutation({
      query: (user) => {
        return {
          url: `/user/${user.id}`,
          method: "PUT",
          body: { active: user.active },
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        };
      },
      invalidatesTags: ["Driver"],
    }),
    deleteDriverById: builder.mutation({
      query: (id) => {
        return {
          url: `/user/${id}`,
          method: "DELETE",
        };
      },
    }),
    invalidatesTags: ["Driver"],
  }),
});

export const {
  useGetAllDriverQuery,
  useCreateDriverMutation,
  useGetAllAvailableDriverQuery,
  useGetDriverByIdQuery,
  useUpdateDriverByIdMutation,
  useDeleteDriverByIdMutation,
} = driverApi;
