import React, { Component, useEffect, useRef, useState } from "react";
import { useLocation, Route, Outlet } from "react-router";

import AdminNavbar from "components/Navbars/AdminNavbar";
import Footer from "components/Footer/Footer";
import Sidebar from "components/Sidebar/Sidebar";


import sidebarImage from "assets/img/sidebar-3.jpg";

function Admin() {
  const [image, setImage] = useState(sidebarImage);
  const [color, setColor] = useState("black");
  const [hasImage, setHasImage] = useState(true);
  const location = useLocation();
  const mainPanel = useRef(null);
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainPanel.current.scrollTop = 0;
    if (
      window.innerWidth < 993 &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
      var element = document.getElementById("bodyClick");
      element.parentNode.removeChild(element);
    }
  }, [location]);
  const [burger, setBurger] = useState(true);
  return (
    <>
      <div className="wrapper">
        <Sidebar
          color={color}
          image={hasImage ? image : ""}
          className={burger?"":"close_bar"}
          burger={burger}
        />
        <div className={burger ? "main-panel" : "main-panel full_width"} ref={mainPanel}>
          <AdminNavbar setBurger={setBurger} burgerSlid={burger} />
          <div className="content">
            <Outlet />
          </div>
          {/* <Footer /> */}
        </div>
      </div>
    </>
  );
}

export default Admin;
