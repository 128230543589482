import { getToken, removeToken } from "cookies/token";
import { message } from "antd";
export const authCheck = (response, result) => {
  if (response.status === 401) {
    message.error("Another user has been logged in using the same account!");
    removeToken();
    window.location = "auth/login";
  } else {
    return response;
  }
};
