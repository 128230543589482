import React from "react";
import { Card, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
function DriverCard({ driver }) {
  return (
    <Col md="4" lg="3" xl="3">
      <Link to={"/admin/drivers/" + driver?.id}>
        <Card>
          <Card.Body className="text-center">
            {driver?.active ? (
              <div className="text-right">
                <span className="badge badge-success rounded-pill py-2 px-3 font-weight-light">
                  Active
                </span>
              </div>
            ) : (
              <div className="text-right">
                <span className="badge badge-danger rounded-pill py-2 px-3 font-weight-light">
                  Blocked
                </span>
              </div>
            )}
            <div className="thumbnail-round-lg m-auto">
              <LazyLoadImage
                alt={driver?.full_name}
                effect="blur"
                src={
                  driver?.image ||
                  `https://joeschmoe.io/api/v1/${driver?.full_name}`
                }
              />
            </div>
            <h5 className="m-0 pt-3 pb-1 font-weight-bold">
              {driver?.full_name}
            </h5>
            <h5 className="m-0">
              {driver?.phone_code} {driver?.phone}
            </h5>
            <h5> {driver?.email}</h5>
            <div className="d-flex align-items-center justify-content-center py-2">
              <h5 className="text-primary m-0">TOTAL RIDES</h5>
              <h4 className="font-weight-bold text-primary m-0 pl-1">
                {driver?.rides || 0}
              </h4>
            </div>
          </Card.Body>
        </Card>
      </Link>
    </Col>
  );
}

export default DriverCard;
