import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getToken } from "cookies/token";
import { authCheck } from "./functions/authCheck";

const header = (headers, { getState }) => {
  const token = getToken();
  if (token) {
    headers.set("authorization", `Bearer ${token}`);
  }
  return headers;
};
export const paymentReportApi = createApi({
  reducerPath: "PaymentReportApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: header,
  }),
  tagTypes: ["PaymentReport"],
  endpoints: (builder) => ({
    getAllPaymentReport: builder.query({
      query: (...data) => {
        const filterOrder = encodeURIComponent(JSON.stringify(data[0].filter));
        const populatePaymentReport = encodeURIComponent(
          JSON.stringify(data[0].populate)
        );
        return {
          url: `/user_payment?offset=${data[0].PageSize * data[0].page}&limit=${
            data[0].PageSize
          }&populate=${populatePaymentReport}&sort=%5B%5B%22${
            data[0].sort.sortBy
          }%22%2C%22${
            data[0].sort.sortDirection
          }%22%5D%5D&where=${filterOrder}`,
          method: "GET",
          validateStatus: (response, result) => authCheck(response, result),
        };
      },
      providesTags: ["PaymentReport"],
    }),
    updateRefund: builder.mutation({
      query: (updateRefund) => {
        return {
          url: `/user_payment/refund`,
          method: "POST",
          body: updateRefund,
          validateStatus: (response, result) => authCheck(response, result),
        };
      },
      invalidatesTags: ["PaymentReport"],
    }),
  }),
});
export const { useGetAllPaymentReportQuery, useUpdateRefundMutation } =
  paymentReportApi;
