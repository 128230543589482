import React, { useEffect, useState } from "react";
import ChartistGraph from "react-chartist";
import {
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  Tab,
} from "react-bootstrap";
import MetaTitle from "components/Meta";
import PaymentSettings from "./PaymentSettings";
import AccountSettings from "./AccountSettings";

function Settings() {
  const [currentTab, setCurrentTab] = useState("account-settings");
  return (
    <>
      <MetaTitle
        text={currentTab.charAt(0).toUpperCase() + currentTab.slice(1)}
      />
      <link
        rel="preload"
        as="image"
        href={require("assets/img/otpSent.gif").default}
      />
      <Container fluid className="settings_page">
        <Card className="mb-0 border-0">
          <Card.Body className="pb-0">
            <Row>
              <Col>
                <h3 className="m-0 py-2 font-weight-bold">Settings</h3>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <Tab.Container
          id="plain-tabs-example"
          defaultActiveKey={currentTab}
          onSelect={(eventKey) => {
            setCurrentTab(eventKey);
          }}
        >
          <Nav className="custom-tab mb-3" role="tablist" variant="tabs">
            <Nav.Item>
              <Nav.Link eventKey="account-settings">Account Settings</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="payment-settings">Payment Settings</Nav.Link>
            </Nav.Item>
          </Nav>

          <Tab.Content>
            <Tab.Pane eventKey="account-settings">
              <AccountSettings />
            </Tab.Pane>
            <Tab.Pane eventKey="payment-settings">
              <PaymentSettings isLoading={true} />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </Container>
    </>
  );
}

export default Settings;
