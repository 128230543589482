import React, { useState, useEffect, useRef } from "react";
import NotificationCard from "components/Notification/NotificationCard";
import { useGetAllNotificationQuery } from "services/notification";
import { Empty, Skeleton } from "antd";

function NotificationMain({ showNotification }) {
  const [PageSize, setPageSize] = useState(5); // default page size change value in query also
  const [page, setPageNumber] = useState(0);
  const [notification, setNotifications] = useState([]);
  const { isError, isLoading, isSuccess, data, status } =
    useGetAllNotificationQuery(
      { page, PageSize, showNotification },
      {
        refetchOnMountOrArgChange: true,
        skip: !showNotification,
      }
    );
  useEffect(() => {
    setNotifications(data?.data?.user_notifications);
  }, [data]);
  const infiniteScrollRef = useRef();
  const onScroll = () => {
    if (infiniteScrollRef?.current) {
      const { scrollTop, scrollHeight, clientHeight } =
        infiniteScrollRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 10) {
        if (data.data.count >= PageSize) {
          setPageSize((prev) => prev + 1);
        }
      }
    }
  };
  return (
    <div
      className="notification_main"
      ref={infiniteScrollRef}
      onScroll={onScroll}
      style={{
        height: "400px",
      }}
    >
      {isLoading &&
        [...Array(10)].map((d, i) => (
          <Skeleton
            avatar
            paragraph={{ rows: 2 }}
            style={{
              borderBottom: "1px solid #e9e9e9",
              paddingBottom: "10px",
              paddingTop: "10px",
              width: "200px",
            }}
          />
        ))}
      {status === "pending" &&
        [...Array(PageSize)].map((d, i) => (
          <Skeleton
            avatar
            paragraph={{ rows: 2 }}
            style={{
              borderBottom: "1px solid #e9e9e9",
              paddingBottom: "10px",
              paddingTop: "10px",
              width: "200px",
            }}
          />
        ))}
      {isError && <p>Error </p>}
      {isSuccess &&
        status === "fulfilled" &&
        notification?.map((notification, i) => (
          <NotificationCard key={notification.id} notification={notification} />
        ))}
      {notification?.length === 0 && (
        <tr>
          <td colspan="10" className="empty_items">
            <Empty description={false} image={Empty.PRESENTED_IMAGE_SIMPLE}>
              <div className="ant-empty-description ">
                <p>No Notifications Found</p>
              </div>
            </Empty>
          </td>
        </tr>
      )}
    </div>
  );
}

export default NotificationMain;
