import React, { useState } from "react";
import { CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";

function TableTitle({ title, setSort ,sortBy}) {
  const [orientationFetch, setOrientationFetch] = useState(false);
  const handleClick = () => {
    setOrientationFetch((prev) => !prev);
    setSort((prev) => ({
      ...prev,
      sortBy: sortBy,
      sortDirection: orientationFetch ? "desc" : "asc",
    }));
  };
  return (
    <th
      className="ant-table-cell pointer ant-table-column-has-sorters"
      onClick={() => handleClick()}
    >
      <div className="ant-table-column-sorters">
        <span className="ant-table-column-title">{title}</span>
        <span className="ant-table-column-sorter ant-table-column-sorter-full">
          <span className="ant-table-column-sorter-inner">
            {orientationFetch ? (
              <CaretUpOutlined style={{ fontSize: "12px" }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: "12px" }} />
            )}
          </span>
        </span>
      </div>
    </th>
  );
}

export default TableTitle;
