import { usePlacesWidget } from "react-google-autocomplete";

export const AutoComplete = ({
  locationData,
  setLocationData,
  setLocation,
  setValue,
}) => {
  const { ref, autocompleteRef } = usePlacesWidget({
    onPlaceSelected: (place) => {
      const data = {
        location: place.formatted_address,
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };
      setLocationData(data);
      setValue("locationDummy", place.formatted_address);
      setLocation({
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      });
    },
  });

  return (
    <>
      <label className="font-weight-bold">Select Location</label>
      <input
        className="form-control mb-3"
        placeholder="Type Location"
        ref={ref}
        defaultValue={locationData?.location}
        onChange={(e) => {
          setValue("locationDummy", e.target.value);
        }}
      />
    </>
  );
};
