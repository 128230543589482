import React, { useState } from "react";
import { Table, Row, Col, Form, Modal, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useUpdatePasswordMutation } from "services/settings";
import { message } from "antd";
import { logoutUser } from 'features/auth/authSlice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';

function ChangePasswordModal({ showPasswordModal, hidePasswordModalHandler }) {
  const dispatch = useDispatch();
  const navigation = useNavigate();

  const [updatePassword] = useUpdatePasswordMutation();
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    setIsLoading(true);
    const update = await updatePassword(data);
    if (update?.data?.message === "Password changed") {
      message.success("Password changed successfully");
      setIsLoading(false);
      reset();
      setTimeout(async () => {
        await dispatch(logoutUser());
        navigation("/");
        message.success("Logout Successfully");
      }, 1000);

    } else {
      setIsLoading(false);
      message.error(
        update?.data?.message?.replace("old", "Current") ||
        "Something went wrong"
      );
    }
  };
  const handelClose = () => {
    reset();
    hidePasswordModalHandler();
  };
  return (
    <Modal
      className="right-modal"
      show={showPasswordModal}
      onHide={handelClose}
    >
      <Modal.Header closeButton className="border-bottom pb-3">
        <Modal.Title className="m-0 font-weight-bold">
          Change Password{" "}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className="pt-4" onSubmit={handleSubmit(onSubmit)}>
          <Row className="align-items-center">
            <Col lg="12">
              <Form.Group controlId="formBasicPassword">
                <Form.Label>
                  <strong>Current Password</strong>
                </Form.Label>
                <input
                  type="password"
                  className="form-control"
                  placeholder="Enter Current Password"
                  name="currentPassword"
                  {...register("old_password", {
                    required: true,
                    minLength: 6,
                    maxLength: 20,
                  })}
                />
                {errors?.old_password?.type === "required" && (
                  <span className="text-danger">
                    Current Password is required
                  </span>
                )}
                {errors?.old_password?.type === "minLength" && (
                  <span className="text-danger">
                    Current Password must be at least 6 characters long
                  </span>
                )}
                {errors?.old_password?.type === "maxLength" && (
                  <span className="text-danger">
                    Current Password must be at most 20 characters long
                  </span>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col lg="12">
              <Form.Group controlId="formBasicPassword">
                <Form.Label>
                  <strong>New Password</strong>
                </Form.Label>
                <input
                  type="password"
                  className="form-control"
                  placeholder="Enter New Password"
                  name="newPassword"
                  {...register("new_password", {
                    required: true,
                    minLength: 6,
                    maxLength: 20,
                  })}
                />
                {errors?.new_password?.type === "required" && (
                  <span className="text-danger">New Password is required</span>
                )}
                {errors?.new_password?.type === "minLength" && (
                  <span className="text-danger">
                    New Password must be at least 6 characters long
                  </span>
                )}
                {errors?.new_password?.type === "maxLength" && (
                  <span className="text-danger">
                    New Password must be at most 20 characters long
                  </span>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col lg="12">
              <Form.Group controlId="formBasicPassword">
                <Form.Label>
                  <strong>Confirm Password</strong>
                </Form.Label>
                <input
                  type="password"
                  className="form-control"
                  placeholder="Enter Confirm Password"
                  name="confirmPassword"
                  {...register("password", {
                    required: true,
                    minLength: 6,
                    maxLength: 20,
                    validate: (value) => {
                      if (value !== watch("new_password")) {
                        return "Passwords must match";
                      } else {
                        return true;
                      }
                    },
                  })}
                />
                {errors?.password?.type === "required" && (
                  <span className="text-danger">
                    Confirm Password is required
                  </span>
                )}
                {errors?.password?.type === "minLength" && (
                  <span className="text-danger">
                    Confirm Password must be at least 6 characters long
                  </span>
                )}
                {errors?.password?.type === "maxLength" && (
                  <span className="text-danger">
                    Confirm Password must be at most 20 characters long
                  </span>
                )}
                {errors?.password?.type === "validate" && (
                  <span className="text-danger">Passwords do not match</span>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col lg="12">
              {isLoading && (
                <Spinner
                  animation="border"
                  variant="primary"
                  className="m-auto d-flex"
                  size="sm"
                />
              )}
              <input
                type="submit"
                value="save"
                className="btn btn-primary w-100 mt-2"
              />
            </Col>
          </Row>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default ChangePasswordModal;
