import React from "react";
import { Link } from "react-router-dom";
import { Avatar, Image, Rate } from "antd";
import moment from "moment";
function ListCards({ index, ride }) {
  return (
    <tr>
      <td>{index && index}</td>
      <td>
        <Link to={"/admin/rides/" + ride?.id} className="text-primary">
          {ride?.ride_code}
        </Link>
      </td>
      <td>
        <div className="d-flex align-items-center">
          <div className="thumbnail-round mr-3">
            <Avatar
              shape="square"
              className="w-100 h-100"
              src={
                <Image
                  src={
                    ride?.customer?.image ||
                    `https://joeschmoe.io/api/v1/${ride?.customer?.first_name}`
                  }
                />
              }
            />
          </div>
          <h5 className="m-0">
            <button className="btn">
              {ride?.customer?.full_name || (
                <span className="text-primary">{ride?.type}</span>
              )}
            </button>
          </h5>
        </div>
      </td>
      <td>
        <h5 className="m-0">
          {" "}
          {moment(ride?.ride_start).format("MM/DD/YY, h:mm a")}
        </h5>
      </td>
      <td>
        <h5 className="m-0">
          {" "}
          {moment(ride?.ride_end).format("MM/DD/YY, h:mm a")}
        </h5>
      </td>
      <td>
        <h5 className="m-0">
          {" "}
          {Math.floor(ride?.duration / 60)}{" "}
          {Math.floor(ride?.duration / 60) === 1 ? "hour" : "hours"}{" "}
        </h5>
      </td>
      <td>
        <h5 className="m-0">
          {" "}
          <b>{ride?.location?.title}</b>
        </h5>
      </td>
      <td>
        <h5 className="m-0 font-weight-bold text-center">
          ${ride?.tip?.amount || 0}
        </h5>
      </td>
      <td className="">
        {ride?.review?.rating && (
          <Rate disabled defaultValue={parseInt(ride?.review?.rating)} />
        )}
        {!ride?.review?.rating && <Rate disabled defaultValue={0} />}
      </td>
      <td className="ride_status" style={{ color: ride?.ride_status?.color }}>
        <span style={{ backgroundColor: ride?.ride_status?.color }}></span>
        {ride?.ride_status?.name}
      </td>
    </tr>
  );
}

export default ListCards;
