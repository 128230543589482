import React from "react";

function DetailSkeleton() {
  return (
    <div className="content">
      <div className="container-fluid">
        <div className="mb-3 rounded-0 rounded-top border-top-0 border-left-0 border-right-0  card">
          <div className="card-body">
            <div className="align-items-center row">
              <div col={12} className="col-xl-7">
                <h3 className="m-0 font-weight-bold">
                  <span className="skeleton">#1</span>
                </h3>
                <h6 className="secondary-gray m-0 pb-2">
                  <span className="skeleton">
                    {" "}
                    Added On January 28th 2022, 11:16:51 am
                  </span>
                </h6>
              </div>
            </div>
          </div>
        </div>
        <div className="mb-3 rounded-0 rounded-top border-0 p-md-4 card">
          <div className="card-body">
            <div className="row">
              <div col={12} className="mt-3 col-lg-7">
                <h3 className="m-0 font-weight-bold">
                  {" "}
                  <span className="skeleton">skeleton</span>
                </h3>
                <h6 className="text-gray">
                  {" "}
                  <span className="skeleton">skeleton</span>
                </h6>
                <h6 className>
                  {" "}
                  <span className="skeleton">skeleton</span>
                </h6>
                <div className="m-0 mt-3 row">
                  <div col={12} className="col-md-6">
                    <div className="row">
                      <div col={12} className="p-0 col-md-6">
                        <div className="p-3 border border-right-md-0">
                          <h5 className="secondary-gray m-0 pb-2">
                            <span className="skeleton">skeleton</span>
                          </h5>
                          <h3 className="m-0 font-weight-bold">
                            {" "}
                            <span className="skeleton">9850</span>
                          </h3>
                        </div>
                      </div>
                      <div col={12} className="p-0 col-md-6">
                        <div className="p-3 border">
                          <h5 className="secondary-gray m-0 pb-2">
                            <span className="skeleton">skeletccc on</span>
                          </h5>
                          <h4 className="m-0">
                            <span className="badge bg- text-white p-2 font-weight-light">
                              <span className="skeleton">stn</span>
                            </span>
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex py-3 align-items-center mb-3">
                  <h5 className="font-weight-bold m-0 mr-3">
                    <span className="skeleton">skeleton</span>

                  </h5>
                  <h6 className="mr-2">
                    {" "}
                    <i className="fas fa-star skeleton " />
                    <span className="secondary-gray skeleton">4.9</span>
                    <span className="text-primary skeleton"> Reviews (20)</span>
                  </h6>
                </div>
                <h6 className="font-weight-bold mb-2 text-uppercase text-gray">
                 
                  <span className="skeleton"> Product Description</span>

                  
                </h6>
                <p>
                <span className="skeleton"> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                  commodo sit amet massa a faucibus. Orci varius natoque
                  penatibus et magnis dis parturient montes, nascetur ridiculus
                  mus. Aliquam sed eros tincidunt, eleifend felis quis, sagittis
                  massa. In pretium metus quis est pulvinar lacinia.</span>

                 
                </p>
              </div>
              <div col={12} className="col-lg-5">
                <div className="p-2 border rounded mb-3 skeleton">
                  <img
                    className="img-fluid w-100 opacity-0"
                    alt="..."
                    src="https://via.placeholder.com/500x500"
                  />
                </div>
                <div className="d-flex">
                  <div className="border product-img mr-2 btn skeleton">
                    <img
                      className="img-fluid opacity-0"
                      alt="..."
                      src="https://via.placeholder.com/500x500"
                    />
                  </div>
                  <div className="border product-img mr-2 btn skeleton">
                    <img
                      className="img-fluid opacity-0"
                      alt="..."
                      src="https://via.placeholder.com/500x500"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetailSkeleton;
