import React from "react";
import { Card, Row, Col } from "react-bootstrap";

function Skeleton() {
  return (
    <Col col="12" xl="6">
      <Card className="rounded border-0 mb-3 card-list p-3">
        <Card.Body className="">
          <Row>
            <Col col="12" md="4">
              <div className="car-card-img skeleton">
                <img
                  className="rounded   opacity-0 img-fluid "
                  alt="..."
                  src={require("assets/img/car.jpg").default}
                ></img>
              </div>
            </Col>
            <Col col="12" md="8">
              <div className="text-right mt-2 mt-md-0">
                <span className="skeleton badge badge-primary rounded-pill py-2 px-3">
                  Primary
                </span>
              </div>
              <h5 className="secondary-gray  width_fit skeleton">TEXAS 202 HSZ</h5>
              <Row className="border-bottom m-0 py-3">
                <Col col="12" md="6" className="p-0 border-right-dashed">
                  <h6 className="secondary-gray width_fit skeleton  mb-2">Rates</h6>
                  <div className=" d-flex align-items-center width_fit skeleton ">
                    <h3 className=" m-0 font-weight-bold  opacity-0 ">
                      $350{" "}
                    </h3>
                    <h5 className="  m-0 font-weight-bold  opacity-0">
                      / Hour
                    </h5>
                  </div>
                </Col>

                <Col col="12" md="5" className="p-0 ml-auto">
                  <h6 className="secondary-gray mb-3 width_fit skeleton ">Next Ride</h6>
                  <h6 className="text-secondary font-weight-normal  skeleton ">
                    {" "}
                    <b>Oct 26 </b> Saturday, 8:00 pm
                  </h6>
                </Col>
              </Row>
              <p type="button" className="  skeleton    w-100 mt-3">
                VIEW AVAILABILITIES
              </p>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Col>
  );
}

export default Skeleton;
