import React from "react";
import { Popconfirm, message } from "antd";
import moment from "moment";
import {
  useDeleteCouponByIdMutation,
  useUpdateCouponMutation,
} from "services/coupon";
import { async } from "@firebase/util";
function ListCards({ handleShow, coupon, index, setFormData }) {
  const [deleteCouponById] = useDeleteCouponByIdMutation();
  const [updateCoupon] = useUpdateCouponMutation();
  async function confirmDelete(e) {
    const deleteResponds = await deleteCouponById(coupon?.id);
    if (deleteResponds?.data?.message === "Deleted") {
      message.success("Coupon deleted successfully");
    } else {
      message.error(deleteResponds?.error?.data?.message);
    }
  }
  async function confirmBlock(e) {
    const data = { active: e };
    const id = coupon?.id;
    const deleteResponds = await updateCoupon({ data, id });
    if (deleteResponds?.data?.message === "Updated") {
      deleteResponds?.data?.data.coupon_code.active
        ? message.success(`Coupon code successfully activated`)
        : message.success(` Coupon code successfully blocked `);
    } else {
      message.error(deleteResponds?.error?.data?.message);
    }
  }
  const editItem = async (e) => {
    setFormData(coupon);

    handleShow(coupon?.id);
  };
  return (
    <tr>
      <td>{index}</td>
      <td>
        <h5
          className="m-0 text-dark-blue font-weight-bold"
          style={{ textTransform: "uppercase" }}
        >
          {coupon?.code}
        </h5>
      </td>
      <td className="font-weight-bold text-capitalize text-center">
        {coupon?.category}
      </td>
      <td className="font-weight-bold">
        {coupon?.type === "price_discount" &&
          coupon?.discount &&
          coupon?.discount && (
            <>
              Price Discount
              <span className="text-primary">-{coupon?.discount}%</span>
            </>
          )}
        {coupon?.type === "product_offer" && coupon?.offer_name}
      </td>
      <td>
        <h5 className="m-0 ">
          {moment(coupon?.active_date).format("MM/DD/YY")}
        </h5>
      </td>
      <td>
        <h5 className="m-0 ">
          {moment(coupon?.expiry_date).format("MM/DD/YY")}
        </h5>
      </td>
      <td>{coupon?.applied}</td>
      <td>
        <h5 className="m-0 ">
          {moment(coupon?.created_at).format("MM/DD/YY")}
          <span className="text-gray">
            {" "}
            {moment(coupon?.created_at).format("h:mm a")}{" "}
          </span>
        </h5>
      </td>
      <td className={coupon?.active ? "text-active" : "cancelled"}>
        <span></span>
        {coupon?.active ? "Active" : "Blocked"}
      </td>
      <td>
        <button
          type="button"
          className="btn btn-outline-secondary"
          onClick={editItem}
        >
          Edit
        </button>
        <Popconfirm
          title="Are you sure to block this Coupon?"
          onConfirm={() => confirmBlock(coupon?.active ? false : true)}
          okText={coupon?.active ? "Block" : "Unblock"}
          cancelText="Cancel"
          placement="topRight"
        >
          <button
            type="button"
            className={`btn btn-outline-${
              coupon?.active ? "danger px-4" : " btn-danger px-3"
            } mx-2`}
          >
            {coupon?.active ? "Block" : "Unblock"}
          </button>
        </Popconfirm>
        <Popconfirm
          title="Are you sure to delete this coupon?"
          onConfirm={confirmDelete}
          okText="Delete"
          cancelText="Cancel"
          placement="topRight"
        >
          <button className="btn btn-danger" type="submit">
            Delete
          </button>
        </Popconfirm>
      </td>
    </tr>
  );
}

export default ListCards;
