import React from "react";
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  Form,
  Modal,
  Tab,
} from "react-bootstrap";

function SingleSkeleton() {
  return (
    <>
      <Card className="mb-3 rounded-0 rounded-top border-top-0 border-left-0 border-right-0 ">
        <Card.Body>
          <Row className="align-items-center">
            <Col col="12" xl="7">
              <h5 className="secondary-gray m-0">
                <span className="skeleton">TEXAS 202 HSZ</span>
              </h5>
              <h3 className="m-0 pb-2 font-weight-bold  mt-1">
                <span className="skeleton"> Lamborghini Aventador</span>
              </h3>
            </Col>
            <Col col="12" xl="5" className="ml-auto">
              <Row className="align-items-center">
                <Col className="my-1" col="12" lg="4">
                  <div className="d-flex text-right justify-content-end">
                    <p className=" mr-3 m-0 text-primary">
                      <span className="skeleton">ACTIVE</span>{" "}
                    </p>
                  </div>
                </Col>
                <Col className="my-1 " col="12" lg="4">
                  <span className="skeleton">Remove</span>
                </Col>
                <Col className="my-1" col="12" lg="4">
                  <span className="skeleton">Remove</span>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Card className="mb-3 rounded-0 rounded-top border-0 p-md-4">
        <Card.Body>
          <Row>
            <Col col="12" lg="3">
              <div className="v-detail rounded skeleton"></div>
            </Col>
            <Col col="12" lg="9" className="mt-3">
              <Row>
                <Col col="12" md="6">
                  <h5 className="font-weight-bold mb-4">
                    <span className="skeleton"> CAR DETAILS</span>
                  </h5>
                  <Row>
                    <Col col="12" md="6">
                      <div className="mb-3">
                        <h6 className="text-gray">
                          <span className="skeleton"># of seats</span>
                        </h6>
                        <h4 className="m-0">
                          <b className="skeleton">2</b>
                        </h4>
                      </div>
                      <div className="mb-3">
                        <h6 className="text-gray">
                          <span className="skeleton">Engine</span>
                        </h6>
                        <h4 className="m-0">
                          <b className="skeleton">V10 - 610 hp</b>
                        </h4>
                      </div>
                      <div className="mb-3">
                        <h6 className="text-gray">
                          <span className="skeleton">Maximum Speed</span>
                        </h6>
                        <h4 className="m-0">
                          <b className="skeleton">324 km/h - 200 mph</b>
                        </h4>
                      </div>
                      <div className="mb-3">
                        <h6 className="text-gray">
                          <span className="skeleton">Maximum Speed</span>{" "}
                        </h6>
                        <h4 className="m-0">
                          <b className="skeleton">Gasoline</b>
                        </h4>
                      </div>
                    </Col>
                    <Col col="12" md="6">
                      <div className="mb-3">
                        <h6 className="text-gray">
                          <span className="skeleton">Luggage space</span>
                        </h6>
                        <h4 className="m-0">
                          <b className="skeleton">2</b>
                        </h4>
                      </div>
                      <div className="mb-3">
                        <h6 className="text-gray">
                          <span className="skeleton">0-100 km/h</span>
                        </h6>
                        <h4 className="m-0">
                          <b className="skeleton">3.4 sec</b>
                        </h4>
                      </div>
                      <div className="mb-3">
                        <h6 className="text-gray">
                          <span className="skeleton">Transmission</span>{" "}
                        </h6>
                        <h4 className="m-0">
                          <b className="skeleton">Auto</b>
                        </h4>
                      </div>
                      <div className="mb-3">
                        <h6 className="text-gray">
                          <span className="skeleton">Options</span>{" "}
                        </h6>
                        <h4 className="m-0">
                          <b className="skeleton">Full Options</b>
                        </h4>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col col="12" md="5" className="ml-auto">
                  <h5 className="font-weight-bold">
                    <span className="skeleton">Review & Ratings</span>
                  </h5>
                  <h6>
                    <span className="skeleton">
                      <span>4.9</span>
                      <span> Reviews (20)</span>
                    </span>
                  </h6>
                  <h5 className="font-weight-bold mt-4">
                    <span className="skeleton">DESCRIPTION</span>
                  </h5>
                  <p>
                    <span className="skeleton">
                      {" "}
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Suspendisse sapien lectus, lobortis id euismod sed,
                      fringilla nec mi. Praesent mi orci, vehicula ac
                      condimentum vel, cursus id felis
                    </span>
                  </p>
                  <h5 className="font-weight-bold text-uppercase">
                    <span className="skeleton"> Rates & Availabilities</span>
                  </h5>
                  <div className=" d-flex justify-content-between flex-column flex-lg-row">
                    <h5 className="m-0 text-truncate">
                      <a className="skeleton">Edit Rates & Availabilities</a>
                    </h5>
                    <a className="m-0 skeleton text-primary text-truncate text-right mt-2 mt-lg-0 cursor-p d-flex align-items-center justify-content-end">
                      {" "}
                      <b>$500</b> / Hour{" "}
                    </a>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
}

export default SingleSkeleton;
