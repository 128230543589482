import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getToken, removeToken } from "cookies/token";
import { authCheck } from "./functions/authCheck";
const header = (headers, { getState }) => {
  const token = getToken();
  if (token) {
    headers.set("authorization", `Bearer ${token}`);
  }
  return headers;
};

export const rideApis = createApi({
  reducerPath: "RideApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: header,
  }),
  tagTypes: ["Ride"],
  endpoints: (builder) => ({
    getRides: builder.query({
      query: (...data) => {
        const filterRide = encodeURIComponent(JSON.stringify(data[0].filter));
        return {
          url: `/ride/${data[0].rideType}?offset=${
            data[0].PageSize * data[0].page
          }&limit=${data[0].PageSize}&sort=%5B%5B%22${
            data[0].sort.sortBy
          }%22%2C%22${data[0].sort.sortDirection}%22%5D%5D&where=${filterRide}`,
          method: "GET",
          validateStatus: (response, result) => authCheck(response, result),
        };
      },
      providesTags: ["Ride"],
    }),
    getRideById: builder.query({
      query: (id) => {
        return {
          url: `/ride/${id}?populate=%5B%22customer%22%2C%22driver%22%2C%22location%22%2C%22vehicle%22%2C%22vehicle.vehicle_image%22%2C%22user_payment%22%2C%22review%22%2C%22tip%22%2C%22ride_status%22%2C%22ride_log%22%2C%22%2Buser_notification%22%5D`,
          method: "GET",
          validateStatus: (response, result) => authCheck(response, result),
        };
      },
      providesTags: ["Ride"],
    }),
    addDriverToRide: builder.mutation({
      query: (data) => {
        return {
          url: `/ride/assign_driver`,
          method: "POST",
          body: data,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        };
      },
      invalidatesTags: ["Ride"],
    }),
    reassignDriverToRide: builder.mutation({
      query: (data) => {
        return {
          url: `/ride/reassign_driver`,
          method: "PUT",
          body: data,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        };
      },
      invalidatesTags: ["Ride"],
    }),
    updateRideBooking: builder.mutation({
      query: (newSpecialBooking) => {
        return {
          url: `/ride/${newSpecialBooking.id}`,
          method: "PUT",
          body: newSpecialBooking,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          validateStatus: (response, result) => authCheck(response, result),
        };
      },
      invalidatesTags: ["Ride"],
    }),
  }),
});
export const {
  useGetRidesQuery,
  useGetRideByIdQuery,
  useAddDriverToRideMutation,
  useReassignDriverToRideMutation,
  useUpdateRideBookingMutation,
} = rideApis;
