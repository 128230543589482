import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "api/axios";
import { setToken,getToken,removeToken} from "cookies/token";
const initialState = {
  data: null,
  validating: false,
  isFetching: false,
  isSuccess: false,
  isError: false,
  errorMessage: null,
};
export const loginUser = createAsyncThunk(
  "auth/login",
  async ({ email, password }, thunkAPI) => {
    try {
      const response = await axios.post("/auth/local", {
        username: email,
        password: password,
        info: {
          role_id: 1,
        },
      });
      if (response.status === 200) {
        await setToken(response.data.data.token);
        return response.data;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const validateUser = createAsyncThunk(
  "auth/validate",
  async (thunkAPI) => {
    const  token = getToken();
    try {
      const response = await axios.get("/user/me",{headers:{Authorization:`Bearer ${token}`}});
      if (response.status === 200) {
        return response.data;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const logoutUser = createAsyncThunk(
  "auth/logout",
  async (thunkAPI) => {
    const  token = getToken();    
    try {
      const response = await axios.post("/auth/logout","",{headers:{Authorization:`Bearer ${token}`}});
      if (response.status === 200) {
        removeToken();
        return response.data;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: {
    [loginUser.fulfilled]: (state, { payload }) => {
      state.data = payload;
      state.isFetching = false;
      state.isSuccess = true;
      state.errorMessage = null;
      return state;
    },
    [loginUser.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload.message;
    },
    [loginUser.pending]: (state) => {
      state.isFetching = true;
    },
    [validateUser.fulfilled]: (state, { payload }) => {
      state.validating = false;
      state.data = payload;
      state.isFetching = false;
      state.isSuccess = true;
      state.errorMessage = null;
      return state;
    },
    [validateUser.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.validating = false;
      state.isError = true;
    },
    [validateUser.pending]: (state) => {
      state.isFetching = true;
      state.validating = true;
    },
    [logoutUser.fulfilled]: (state, { payload }) => {
      state.validating = false;
      state.data = null;
      state.isFetching = false;
      state.isSuccess = false;
      state.errorMessage = null;
      return state;
    },
    [logoutUser.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.validating = false;
      state.isError = true;
    },
    [logoutUser.pending]: (state) => {
      state.isFetching = true;
      state.validating = true;
    },
  },
});

export const {} = authSlice.actions;
export const userSelector = (state) => state.auth;

export default authSlice.reducer;
