import React, { useState } from "react";
import { DatePicker, Empty, Pagination, Modal } from "antd";
const { RangePicker } = DatePicker;
// react-bootstrap components
import { Card, Table, Container, Row, Col, Form } from "react-bootstrap";
import MetaTitle from "components/Meta";
import Skeleton from "components/PaymentReport/Skeleton";
import ListCard from "components/PaymentReport/ListCard";
import PaymentHeader from "components/PaymentReport/PaymentHeader";
import { useGetAllPaymentReportQuery } from "services/paymentReport";
import TableTitle from "components/Products/List/TableTitle";
import moment from "moment";
import { FilterBy } from "components/Common/FilterBy";

function PaymentReport() {
  // initial set up for Item
  const [populate, setPopulate] = useState(["user", "ride", "order"]);
  const [filter, setFilter] = useState("");
  const [sort, setSort] = useState({
    sortBy: "created_at",
    sortDirection: "desc",
  });
  const [PageSize, setPageSize] = useState(10); // default page size change value in query also
  const [page, setPageNumber] = useState(0);
  // initial set up for Item END
  const handlePicker = (data, date) => {
    const fromDate = date[0] + " 00:00:00";
    const toDate = date[1] + " 23:59:00";
    const created_at = { $gte: fromDate, $lte: toDate };
    if (date[0] && date[1]) {
      setFilter({ ...filter, created_at });
    } else {
      const { created_at, ...newObj } = filter;
      setFilter((prev) => newObj);
    }
    setPageNumber(0);
  };
  const handlePagination = (page, pageSize) => {
    setPageNumber((prev) => page - 1);
    setPageSize(pageSize);
  };
  const { isLoading, isError, isSuccess, data, status } =
    useGetAllPaymentReportQuery(
      { page, PageSize, sort, filter, populate },
      {
        refetchOnMountOrArgChange: true,
      }
    );
  

  return (
    <>
      <MetaTitle text="Payment Report" />
      <Container fluid>
        {isLoading && <PaymentHeader type={"skeleton"} />}
        {data && <PaymentHeader data={data?.data?.user_payments[0]} />}

        <Row>
          <Col md="12">
            <Card className="mb-0 border-bottom-0 rounded-0">
              <Card.Body>
                <Row className="align-items-center">
                  <Col col="12" lg="6" className="pb-3">
                    <h3 className="m-0 py-1 font-weight-bold">
                      Payment Report
                    </h3>
                    {isLoading && (
                      <h6>
                        <span className="skeleton">May 1 - June 12, 2021</span>
                      </h6>
                    )}
                    {data && (
                      <h6>
                        {moment(
                          data?.data?.user_payments[0]?.report_start_date
                        ).format("MM/DD/YY")}{" "}
                        - {moment(new Date()).format("MM/DD/YY")} ,{" "}
                        {moment(new Date()).format("YYYY")}
                      </h6>
                    )}
                  </Col>
                  <Col col="12" lg="6" className="pb-3">
                    <Row>
                      <Col col="12" md="8">
                        <RangePicker
                          className="form-control"
                          onChange={(start, end) => handlePicker(start, end)}
                          format="MM/DD/YY"
                        />
                      </Col>
                      <Col col="12" md="4" className="mt-2 mt-md-0">
                        <FilterBy
                          filterKey={"category"}
                          setPageNumber={setPageNumber}
                          filter={filter}
                          setFilter={setFilter}
                          filterItems={[
                            { name: "Ride", value: "ride" },
                            { name: "Shop", value: "shop" },
                          ]}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card.Body>
            </Card>

            <Card className="strpied-tabled-with-hover rounded-0 rounded-bottom ">
              <Card.Body className="table-full-width table-responsive p-0 ">
                <Table className="table-hover revup-table m-0">
                  <thead>
                    <tr>
                      <th className="border-0">#</th>
                      <TableTitle
                        title="Date"
                        setSort={setSort}
                        sortBy="created_at"
                        sort={sort}
                      />
                      <TableTitle
                        title="Transaction ID"
                        setSort={setSort}
                        sortBy="transaction_id"
                        sort={sort}
                      />
                      <TableTitle
                        title="Amount"
                        setSort={setSort}
                        sortBy="total_amount"
                        sort={sort}
                      />
                      <TableTitle
                        title="Order ID"
                        setSort={setSort}
                        sortBy="transaction_id"
                        sort={sort}
                      />
                      <TableTitle
                        title="Category"
                        setSort={setSort}
                        sortBy="category"
                        sort={sort}
                      />
                      <TableTitle
                        title="Customer"
                        setSort={setSort}
                        sortBy={`user", "full_name`}
                        sort={sort}
                      />
                      <TableTitle
                        title="Payment Status"
                        setSort={setSort}
                        sortBy={"status"}
                        sort={sort}
                      />
                      <th className="border-0 text-center">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading &&
                      [...Array(10)].map((d, i) => <Skeleton key={i} />)}
                    {status === "pending" &&
                      [...Array(PageSize)].map((d, i) => <Skeleton key={i} />)}
                    {isError && <p>Error </p>}
                    {isSuccess &&
                      status === "fulfilled" &&
                      data?.data?.user_payments.map((payment, i) => (
                        <ListCard
                          key={i}
                          payment={payment}
                          index={page * PageSize + i + 1}
                        />
                      ))}
                    {data?.data?.user_payments.length === 0 && (
                      <tr>
                        <td colspan="9" className="empty_items">
                          <Empty
                            description={false}
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                          >
                            <div className="ant-empty-description">
                              <p>No Payments Found</p>
                            </div>
                          </Empty>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col col="12" md="12" lg="12">
            <Pagination
              current={page + 1}
              defaultCurrent={1}
              defaultPageSize={PageSize}
              onChange={handlePagination}
              total={parseInt(data?.data?.count)}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default PaymentReport;
