import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getToken } from "cookies/token";
import { authCheck } from "./functions/authCheck";

const header = (headers, { getState }) => {
  const token = getToken();
  if (token) {
    headers.set("authorization", `Bearer ${token}`);
  }
  return headers;
};
export const orderApi = createApi({
  reducerPath: "OrderApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: header,
  }),
  tagTypes: ["Order"],
  endpoints: (builder) => ({
    getOrderById: builder.query({
      query: (id) => {
        const populate = encodeURIComponent(
          JSON.stringify([
            "customer",
            "order_item",
            "order_item.product",
            "order_item.product.product_image",
            "order_item.review",
            "shipping_address",
            "transaction",
          ])
        );
        return {
          url: `/order/${id}?populate=${populate}`,
          method: "GET",
          validateStatus: (response, result) => authCheck(response, result),
        };
      },
      providesTags: ["Order"],
    }),
    getAllOrder: builder.query({
      query: (...data) => {
        const filterOrder = encodeURIComponent(
          JSON.stringify(data[0].filterOrder)
        );
        return {
          url: `/order?offset=${data[0].PageSize * data[0].page}&limit=${
            data[0].PageSize
          }&populate=${data[0].populate}&sort=%5B%5B%22${
            data[0].sort.sortBy
          }%22%2C%22${
            data[0].sort.sortDirection
          }%22%5D%5D&where=${filterOrder}`,
          method: "GET",
          validateStatus: (response, result) => authCheck(response, result),
        };
      },
      providesTags: ["Order"],
    }),
    addOrder: builder.mutation({
      query: (newOrder) => {
        return {
          url: `/order`,
          method: "POST",
          body: newOrder,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        };
      },
      invalidatesTags: ["Order"],
    }),
    deleteOrderById: builder.mutation({
      query: (id) => {
        return {
          url: `/order/${id}`,
          method: "DELETE",
          body: null,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        };
      },
      invalidatesTags: ["Order"],
    }),
    cancelOrderById: builder.mutation({
      query: (id) => {
        return {
          url: `/order/cancel/${id}`,
          method: "PUT",
          body: null,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        };
      },
      invalidatesTags: ["Order"],
    }),
  }),
});
export const {
  useGetOrderByIdQuery,
  useGetAllOrderQuery,
  useAddOrderMutation,
  useDeleteOrderByIdMutation,
  useCancelOrderByIdMutation,
} = orderApi;
