import React from "react";
import { Card, Table, Container, Row, Col, Form } from "react-bootstrap";
function PaymentHeader({ type ,data}) {
  function kFormatter(num) {
    return Math.abs(num) > 999
      ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(2) + "k"
      : Math.sign(num) * Math.abs(num);
  }

  if (type === "skeleton") {
    return (
      <Row className="bg-white m-0 mb-3 border border-bottom-0">
        <Col lg="3" sm="6" className="border-right border-bottom ">
          <div className="mt-1">
            <div className="py-3 d-flex justify-content-center ">
              <div className="d-flex align-items-center">
                <div className="">
                  <h3 className="font-weight-bold m-0 text-truncate ">
                    {" "}
                    <span className="skeleton"> $ </span>
                  </h3>
                  <h6 className="text-uppercase m-0  ">
                    <span className="skeleton">Total Revenue</span>
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </Col>

        <Col lg="3" sm="6" className="border-right border-bottom">
          <div className="mt-2">
            <div className="py-3 d-flex justify-content-center ">
              <div className="d-flex  align-items-center">
                <div className="skeleton">
                  <img
                    className="opacity-0"
                    src={require("assets/img/car-img.png").default}
                  ></img>
                </div>
                <div className="pl-4">
                  <h6 className="text-uppercase m-0 skeleton">
                    Total Ride Revenue
                  </h6>
                  <h3 className="font-weight-bold m-0 text-truncate skeleton">145 K</h3>
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col lg="3" sm="6" className="border-bottom border-right">
          <div>
            <div className="py-3 d-flex justify-content-center ">
              <div className="d-flex align-items-center">
                <div className="skeleton">
                  <img
                    className="opacity-0"
                    src={require("assets/img/car-img.png").default}
                  ></img>
                </div>{" "}
                <div className="pl-1">
                  <h3 className="font-weight-bold m-0 text-truncate skeleton">$ 200</h3>
                  <h6 className="text-uppercase m-0 skeleton">Total Shop Revenue</h6>
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col lg="3" sm="6" className="border-bottom">
          <div>
            <div className="py-3 d-flex justify-content-center ">
              <div className="d-flex align-items-center">
                <div className="skeleton">
                  <img
                    className="opacity-0"
                    src={require("assets/img/car-img.png").default}
                  ></img>
                </div>
                <div className="pl-3">
                  <h6 className="text-uppercase m-0 skeleton">Total Transactions</h6>
                  <h3 className="font-weight-bold m-0 text-truncate skeleton">$ 200</h3>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    );
  } else {
    return (
      <Row className="bg-white m-0 mb-3 border border-bottom-0 common-card">
        <Col lg="3" sm="6" className="border-right border-bottom ">
          <div className="mt-1">
            <div className="py-3 d-flex justify-content-center ">
              <div className="d-flex align-items-center">
                <div className="">
                  <h3 className="font-weight-bold m-0 text-truncate" >$ {kFormatter(data?.total_revenue)}</h3>
                  <h6 className="text-uppercase m-0">Total Revenue</h6>
                </div>
              </div>
            </div>
          </div>
        </Col>

        <Col lg="3" sm="6" className="border-right border-bottom">
          <div className="mt-2">
            <div className="py-3 d-flex justify-content-center ">
              <div className="d-flex  align-items-center">
                <img src={require("assets/img/car-img.png").default}></img>
                <div className="pl-4">
                  <h6 className="text-uppercase m-0">Total Ride Revenue</h6>
                  <h3 className="font-weight-bold m-0 text-truncate">$ {kFormatter(data?.total_ride_revenue)}</h3>
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col lg="3" sm="6" className="border-bottom border-right">
          <div>
            <div className="py-3 d-flex justify-content-center ">
              <div className="d-flex align-items-center">
                <img src={require("assets/img/shopping-bag.png").default}></img>
                <div className="pl-1">
                  <h3 className="font-weight-bold m-0 text-truncate">$ {kFormatter(data?.total_shop_revenue)}</h3>
                  <h6 className="text-uppercase m-0">Total Shop Revenue</h6>
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col lg="3" sm="6" className="border-bottom">
          <div>
            <div className="py-3 d-flex justify-content-center ">
              <div className="d-flex align-items-center">
                <img
                  src={require("assets/img/credit-card.svg").default}
                  style={{ width: "55px" }}
                ></img>
                <div className="pl-3">
                  <h6 className="text-uppercase m-0">Total Transactions</h6>
                  <h3 className="font-weight-bold m-0 text-truncate">{data?.total_transactions}</h3>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    );
  }
}

export default PaymentHeader;
