import React, { useEffect, useState } from "react";
import { Popconfirm, message, Switch, Empty, Pagination } from "antd";
import Gallery from "react-grid-gallery";

import { Card, Nav, Table, Container, Row, Col, Tab } from "react-bootstrap";
import PeakDayCard from "components/Vehicles/PeakDayCard";
import MilageCard from "components/Vehicles/MilageCard";
import RideCard from "components/Vehicles/RideCard";
import AddEditPeakDay from "components/Vehicles/AddEditPeakDay";
import { useNavigate } from "react-router-dom";
import SingleSkeleton from "components/Vehicles/SingleSkeleton";
import AvailabilityModal from "components/Vehicles/AvailabilityModal";
import {
  useGetVehicleByIdQuery,
  useDeleteVehicleMutation,
  useUpdateVehicleMutation,
  useGetRideByVehicleQuery,
  useGetVehicleMilageQuery,
} from "services/vehicles";
import { useParams } from "react-router";
import TableTitle from "components/Orders/List/TableTitle";
import { useGetAllPeakDayQuery } from "services/peakDays";
import MetaTitle from "components/Meta";

function Detail() {
  const [updateVehicle] = useUpdateVehicleMutation();
  const [deleteVehicle] = useDeleteVehicleMutation();
  const [PageSize, setPageSize] = useState(10); // default page size change value in query also
  const [page, setPageNumber] = useState(0);
  const [sort, setSort] = useState({
    sortBy: "ride_start",
    sortDirection: "desc",
  });
  const [milageSort, setMilageSort] = useState({
    sortBy: "created_at",
    sortDirection: "desc",
  });

  const [images, setImages] = useState([]);
  const populate = ["vehicle_image", "vehicle_availability"];
  const navigation = useNavigate();

  let { id } = useParams();
  const filter = { vehicle_id: id };

  const { isLoading, isError, isSuccess, data } = useGetVehicleByIdQuery(
    { id, populate },
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const rides = useGetRideByVehicleQuery(
    { id, page, PageSize, sort },
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const peakDay = useGetAllPeakDayQuery(id, {
    refetchOnMountOrArgChange: true,
  });
  const sortPopulate = ["driver"];
  const milages = useGetVehicleMilageQuery(
    { id, page, PageSize, milageSort, filter, sortPopulate },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const [show, setShow] = useState({
    showPeakDay: false,
    id: null,
    peakData: null,
  });
  const handleClosePeakDay = () =>
    setShow({ showPeakDay: false, id: null, peakData: null });
  const handleShowPeakDay = (data, peakData) =>
    setShow({ showPeakDay: true, id: data, peakData: peakData });

  const [showModal, setShowModal] = useState(false);
  const [modal, setModal] = useState({ type: "view", modalData: [] });
  const handleClose = () => setShowModal(false);
  const handleShow = (data) => {
    setModal((prev) => (prev.modalData = data));
    setShowModal(true);
  };

  useEffect(() => {
    const destructure = data?.data?.vehicle?.vehicle_image?.map((item) => {
      return {
        src: item.image,
        thumbnail: item.image,
      };
    });
    setImages(destructure);
  }, [isSuccess, data]);
  const deleteConformation = async () => {
    const deleteResp = await deleteVehicle(id);
    if (deleteResp?.data?.message === "Deleted") {
      message.success("Vehicle deleted successfully");
      navigation("/admin/vehicles");
    } else {
      message.error("Something went wrong");
    }
  };
  const thumbnail = data?.data?.vehicle?.vehicle_image?.filter((img) => {
    return img?.thumbnail === true;
  });
  async function confirmStatusChange(e) {
    const upData = { active: e };
    const addRep = await updateVehicle({ id, upData });
    if (addRep?.data?.message === "Updated") {
      message.success("Vehicle status updated successfully");
    }
    if (addRep?.data?.error) {
      message.error("Something went wrong");
    }
  }
  const handlePagination = (page, pageSize) => {
    setPageNumber((prev) => page - 1);
    setPageSize(pageSize);
  };
  return (
    <>
      <MetaTitle text={data?.data?.vehicle?.name} />
      {show && <AddEditPeakDay handleClose={handleClosePeakDay} show={show} />}
      {showModal && (
        <AvailabilityModal
          handleClose={handleClose}
          show={showModal}
          modal={modal}
          setModal={setModal}
        />
      )}
      <Container fluid>
        {isLoading && <SingleSkeleton />}
        {isError && (
          <label className="text-danger">
            Product Not Available for - {id}{" "}
          </label>
        )}
        {isSuccess && (
          <>
            <Card className="mb-3 rounded-0 rounded-top border-top-0 border-left-0 border-right-0 ">
              <Card.Body>
                <Row className="align-items-center">
                  <Col col="12" xl="7">
                    <h5 className="secondary-gray m-0">
                      {data?.data?.vehicle?.plate_number}
                    </h5>
                    <h3 className="m-0 pb-2 font-weight-bold">
                      {data?.data?.vehicle?.name}{" "}
                    </h3>
                  </Col>
                  <Col col="12" xl="5" className="ml-auto">
                    <Row className="align-items-center">
                      <Col className="my-1" col="12" lg="4">
                        <div className="d-flex text-right justify-content-end">
                          <p className=" mr-3 m-0 text-primary">ACTIVE</p>
                          <Popconfirm
                            title="Are you sure do you want to change status?"
                            onConfirm={() =>
                              confirmStatusChange(
                                data?.data?.vehicle?.active === true
                                  ? false
                                  : true
                              )
                            }
                            okText="Yes"
                            cancelText="cancel"
                            placement="bottom"
                          >
                            <Switch
                              loading={isLoading}
                              checked={data?.data?.vehicle?.active === true}
                            />
                          </Popconfirm>
                        </div>
                      </Col>
                      <Col className="my-1" col="12" lg="4">
                        <Popconfirm
                          title="Are you sure do you want to Remove this vehicle?"
                          onConfirm={deleteConformation}
                          okText="Yes"
                          cancelText="cancel"
                          placement="bottom"
                        >
                          <button
                            type="button"
                            className="btn btn-outline-danger w-100 "
                          >
                            Remove
                          </button>
                        </Popconfirm>
                      </Col>
                      <Col className="my-1" col="12" lg="4">
                        <button
                          type="button"
                          className="btn btn-outline-secondary w-100 "
                          onClick={() =>
                            navigation("/admin/vehicles/edit-vehicle/" + id)
                          }
                        >
                          Edit Details
                        </button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            <Card className="mb-3 rounded-0 rounded-top border-0 p-md-4">
              <Card.Body>
                <Row>
                  <Col col="12" lg="3">
                    <div className="v-detail rounded">
                      <img
                        src={
                          thumbnail[0]?.image ||
                          "https://via.placeholder.com/350x350?text=404"
                        }
                      />
                      <div className="v-detail-overlay">
                        {images?.length > 0 && (
                          <Gallery
                            images={images}
                            backdropClosesModal={true}
                            showLightboxThumbnails={true}
                            enableImageSelection={false}
                            margin={0}
                          />
                        )}
                      </div>
                      <div className="text-tag">
                        {data?.data?.vehicle?.vehicle_image?.length} Photos
                      </div>
                    </div>
                  </Col>
                  <Col col="12" lg="9" className="mt-3">
                    <Row>
                      <Col col="12" md="6">
                        <h5 className="font-weight-bold mb-4">CAR DETAILS</h5>
                        <Row>
                          <Col col="12" md="6">
                            <div className="mb-3">
                              <h6 className="text-gray"># of seats</h6>
                              <h4 className="m-0">
                                <b>{data?.data?.vehicle?.seats}</b>
                              </h4>
                            </div>
                            <div className="mb-3">
                              <h6 className="text-gray">Engine</h6>
                              <h4 className="m-0">
                                <b>{data?.data?.vehicle?.engine}</b>
                              </h4>
                            </div>
                            <div className="mb-3">
                              <h6 className="text-gray">Maximum Speed</h6>
                              <h4 className="m-0">
                                <b>{data?.data?.vehicle?.max_speed}</b>
                              </h4>
                            </div>
                            <div className="mb-3">
                              <h6 className="text-gray">Fuel </h6>
                              <h4 className="m-0">
                                <b>{data?.data?.vehicle?.fuel}</b>
                              </h4>
                            </div>
                          </Col>
                          <Col col="12" md="6">
                            <div className="mb-3">
                              <h6 className="text-gray">Luggage space</h6>
                              <h4 className="m-0">
                                <b>{data?.data?.vehicle?.luggage_space}</b>
                              </h4>
                            </div>
                            <div className="mb-3">
                              <h6 className="text-gray">0-100 km/h</h6>
                              <h4 className="m-0">
                                <b>{data?.data?.vehicle?.transmission} sec</b>
                              </h4>
                            </div>
                            <div className="mb-3">
                              <h6 className="text-gray">Transmission </h6>
                              <h4 className="m-0">
                                <b>{data?.data?.vehicle?.acceleration}</b>
                              </h4>
                            </div>
                            <div className="mb-3">
                              <h6 className="text-gray">Options </h6>
                              <h4 className="m-0">
                                <b>{data?.data?.vehicle?.options}</b>
                              </h4>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col col="12" md="5" className="ml-auto">
                        <h5 className="font-weight-bold text-uppercase">
                          Rates & Availabilities
                        </h5>
                        <div
                          className="outline-card d-flex justify-content-between flex-column flex-lg-row mb-4"
                          onClick={() => {
                            handleShow({
                              type: "edit",
                              modalData: data?.data?.vehicle,
                            });
                          }}
                        >
                          <h5 className="m-0 text-truncate">
                            <a>
                              {data?.data?.vehicle?.vehicle_availability
                                ?.length > 0
                                ? "Edit"
                                : "Add"}{" "}
                              Rates & Availabilities
                            </a>
                          </h5>
                          <a className="m-0 text-primary text-truncate text-right mt-2 mt-lg-0 cursor-p d-flex align-items-center justify-content-end">
                            <b>${data?.data?.vehicle?.rate}</b>
                            {data?.data?.vehicle?.rate && " / Hour"}
                            {data?.data?.vehicle?.rate && (
                              <svg
                                className="ml-2"
                                xmlns="http://www.w3.org/2000/svg"
                                width="6.101"
                                height="15.886"
                                viewBox="0 0 6.101 15.886"
                              >
                                <path
                                  id="Path_2213"
                                  data-name="Path 2213"
                                  d="M8109.087,8600l4.808,7.229-4.808,7.271"
                                  transform="translate(-8108.394 -8599.307)"
                                  fill="none"
                                  stroke="gray"
                                  strokeLinecap="round"
                                  strokeWidth="1"
                                />
                              </svg>
                            )}
                          </a>
                        </div>
                        <h5 className="font-weight-bold">Review & Ratings</h5>
                        <h6>
                          <span className="secondary-gray">
                            {data?.data?.vehicle?.rating_avg}
                          </span>
                          <span className="text-primary">
                            {" "}
                            Reviews ({data?.data?.vehicle?.rating_count})
                          </span>
                        </h6>
                        <h5 className="font-weight-bold mt-4">DSCRIPTION</h5>
                        <p>{data?.data?.vehicle?.description}</p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card.Body>
            </Card>

            <Tab.Container
              id="plain-tabs-example"
              defaultActiveKey="info-plain"
              onSelect={(e) => {
                setPageNumber(0);
              }}
            >
              <Nav className="custom-tab pt-2" role="tablist" variant="tabs">
                <Nav.Item>
                  <Nav.Link eventKey="info-plain">Rides</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="account-plain">Mileage</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="style-plain">Peak Days</Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content className="bg-white">
                <Tab.Pane eventKey="info-plain">
                  <div className="table-full-width table-responsive">
                    <Table className="table-hover revup-table m-0">
                      <thead className="bg-secondary">
                        <tr>
                          <th className="border-0">#</th>
                          <TableTitle
                            title="Ride Id"
                            setSort={setSort}
                            sortBy="id"
                            sort={sort}
                          />
                          <TableTitle
                            title="Customer"
                            setSort={setSort}
                            sortBy={`customer","full_name`}
                            sort={sort}
                          />
                          <TableTitle
                            title="Start Date"
                            setSort={setSort}
                            sortBy="ride_start"
                            sort={sort}
                          />
                          <TableTitle
                            title="End Date"
                            setSort={setSort}
                            sortBy="ride_end"
                            sort={sort}
                          />
                          <TableTitle
                            title="Duration"
                            setSort={setSort}
                            sortBy="duration"
                            sort={sort}
                          />
                          <TableTitle
                            title="Pickup Location"
                            setSort={setSort}
                            sortBy={`location","title`}
                            sort={sort}
                          />
                          <TableTitle
                            title="Ride Fee"
                            setSort={setSort}
                            sortBy="ride_cost"
                            sort={sort}
                          />
                          <TableTitle
                            title="Driver Info"
                            setSort={setSort}
                            sortBy={`driver","full_name`}
                            sort={sort}
                          />
                          <TableTitle
                            title="Status"
                            setSort={setSort}
                            sortBy={`ride_status","name`}
                            sort={sort}
                          />
                        </tr>
                      </thead>
                      <tbody>
                        {rides?.isLoading &&
                          [...Array(data?.data?.rides?.length || 10)].map(
                            (d, i) => <RideCard type="Skeleton" key={i} />
                          )}
                        {rides?.status === "pending" &&
                          [...Array(PageSize)].map((d, i) => (
                            <RideCard type="Skeleton" key={i} />
                          ))}
                        {rides?.isError && <p>Error </p>}
                        {rides?.isSuccess &&
                          rides?.status === "fulfilled" &&
                          rides?.data?.data?.rides?.map((ride, i) => (
                            <RideCard
                              key={i}
                              ride={ride}
                              index={page * PageSize + i + 1}
                            />
                          ))}
                        {rides?.data?.data?.rides?.length === 0 && (
                          <tr>
                            <td colspan="10" className="empty_items">
                              <Empty
                                description={false}
                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                              >
                                <div className="ant-empty-description">
                                  <p>No Rides Found</p>
                                </div>
                              </Empty>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                  <Row className="mt-3">
                    <Col col="12" md="12" lg="12">
                      {rides?.data?.data?.count > 0 && (
                        <Pagination
                          current={page + 1}
                          defaultCurrent={1}
                          defaultPageSize={PageSize}
                          onChange={handlePagination}
                          total={parseInt(rides?.data?.data?.count)}
                        />
                      )}
                    </Col>
                  </Row>
                </Tab.Pane>
                <Tab.Pane eventKey="account-plain">
                  <div className="table-full-width table-responsive">
                    <Table className="table-hover revup-table m-0">
                      <thead className="bg-secondary">
                        <tr>
                          <th className="border-0">#</th>
                          <TableTitle
                            title="Date"
                            setSort={setMilageSort}
                            sortBy="created_at"
                            sort={milageSort}
                          />
                          <TableTitle
                            title="Driver"
                            setSort={setMilageSort}
                            sortBy="created_at"
                            sort={milageSort}
                          />
                          <TableTitle
                            title="Start Reading"
                            setSort={setMilageSort}
                            sortBy="start_time"
                            sort={milageSort}
                          />
                          <TableTitle
                            title="End Reading"
                            setSort={setMilageSort}
                            sortBy="end_time"
                            sort={milageSort}
                          />{" "}
                          <TableTitle
                            title="Total Travelled (Miles)"
                            setSort={setMilageSort}
                            sortBy="total"
                            sort={milageSort}
                          />
                        </tr>
                      </thead>
                      <tbody>
                        {milages?.isLoading &&
                          [...Array(data?.data?.rides?.length || 10)].map(
                            (d, i) => <MilageCard type="Skeleton" key={i} />
                          )}
                        {milages?.status === "pending" &&
                          [...Array(PageSize)].map((d, i) => (
                            <MilageCard type="Skeleton" key={i} />
                          ))}
                        {milages?.isError && <p>Error </p>}
                        {milages?.isSuccess &&
                          milages?.status === "fulfilled" &&
                          milages?.data?.data?.vehicle_mileage?.map(
                            (milage, i) => (
                              <MilageCard
                                key={i}
                                milage={milage}
                                index={page * PageSize + i + 1}
                              />
                            )
                          )}
                        {milages?.data?.data?.vehicle_mileage?.length === 0 && (
                          <tr>
                            <td colspan="10" className="empty_items">
                              <Empty
                                description={false}
                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                              >
                                <div className="ant-empty-description">
                                  <p>No Milage Found</p>
                                </div>
                              </Empty>
                            </td>
                          </tr>
                        )}{" "}
                      </tbody>
                    </Table>
                  </div>
                  <Row className="mt-3">
                    <Col col="12" md="12" lg="12">
                      {milages?.data?.data?.count > 0 && (
                        <Pagination
                          current={page + 1}
                          defaultCurrent={1}
                          defaultPageSize={PageSize}
                          onChange={handlePagination}
                          total={parseInt(milages?.data?.data?.count)}
                        />
                      )}
                    </Col>
                  </Row>
                </Tab.Pane>
                <Tab.Pane eventKey="style-plain">
                  <div className="table-full-width table-responsive">
                    <div className="p-4">
                      <Row className="align-items-center mb-2">
                        <Col Col="12" md="8">
                          <h5 className="text-gray m-0">
                            {peakDay?.data?.data?.count > 0 &&
                              (peakDay?.data?.data?.count === 1
                                ? peakDay?.data?.data?.count + " Record"
                                : peakDay?.data?.data?.count + " Records")}
                          </h5>
                        </Col>
                        <Col className="ml-auto" Col="12" md="4" xl="2">
                          <button
                            type="button"
                            className="btn btn-primary w-100 my-2 btn-lg"
                            onClick={() =>
                              handleShowPeakDay(data?.data?.vehicle, null)
                            }
                          >
                            Add Peak Day
                          </button>
                        </Col>
                      </Row>
                      <Row>
                        {peakDay?.isLoading && <PeakDayCard type="Skeleton" />}
                        {peakDay?.status === "pending" &&
                          [...Array(10)].map((d, i) => (
                            <PeakDayCard type="Skeleton" key={i} />
                          ))}
                        {peakDay?.isError && <p>Error </p>}
                        {peakDay?.isSuccess &&
                          peakDay?.status === "fulfilled" &&
                          peakDay?.data?.data?.vehicle_peak_days?.map(
                            (peak, i) => (
                              <PeakDayCard
                                handleShow={handleShowPeakDay}
                                vehicle={data?.data?.vehicle}
                                peak={peak}
                              />
                            )
                          )}
                        {peakDay?.data?.data?.vehicle_peak_days.length ===
                          0 && (
                          <div className="col-md-12 empty_items">
                            <Empty
                              description={false}
                              image={Empty.PRESENTED_IMAGE_SIMPLE}
                              className="w-100"
                            >
                              <div className="ant-empty-description">
                                <p>No Peak Days</p>
                              </div>
                            </Empty>
                          </div>
                        )}
                      </Row>
                    </div>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </>
        )}
      </Container>
    </>
  );
}

export default Detail;
