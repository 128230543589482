import React, { useState } from "react";
import MetaTitle from "components/Meta/index";

import { Card, Table, Container, Row, Col } from "react-bootstrap";
import ListCard from "components/Customers/ListCard";
import Skelton from "components/Rides/Skelton";
import { Empty, Pagination } from "antd";
import { useGetAllCustomersQuery } from "services/customers";
import TableTitle from "components/Orders/List/TableTitle";
import { FilterBy } from "components/Common/FilterBy";

function Customers() {
  // initial set up for Item
  const [populate, setPopulate] = useState("");
  const [filter, setFilter] = useState("");
  const [sort, setSort] = useState({
    sortBy: "created_at",
    sortDirection: "desc",
  });
  const [PageSize, setPageSize] = useState(10); // default page size change value in query also
  const [page, setPageNumber] = useState(0);
  // initial set up for Item END
  const { isLoading, isError, isSuccess, data, status } =
    useGetAllCustomersQuery(
      { populate, filter, sort, PageSize, page },
      {
        refetchOnMountOrArgChange: false,
      }
    );
  const handlePagination = (page, pageSize) => {
    setPageNumber((prev) => page - 1);
    setPageSize(pageSize);
  };

  return (
    <>
      <MetaTitle text="Customers" />
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="mb-2">
              <Card.Body>
                <Row className="align-items-center">
                  <Col col="12" md="8" lg="6" xl="4">
                    <h3 className="m-0 py-2 font-weight-bold">Customers</h3>
                  </Col>
                  <Col col="12" md="4" lg="2" className="ml-auto">
                    <FilterBy
                      filterKey={"active"}
                      setPageNumber={setPageNumber}
                      filter={filter}
                      setFilter={setFilter}
                      filterItems={[
                        { name: "Blocked", value: false },
                        { name: "Active", value: true },
                      ]}
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            <Card className="strpied-tabled-with-hover rounded-0 rounded-bottom ">
              <Card.Body className="table-full-width table-responsive p-0 ">
                <Table className="table-hover revup-table m-0">
                  <thead>
                    <tr>
                      <th className="border-0">#</th>
                      <TableTitle
                        title="Customer #"
                        setSort={setSort}
                        sortBy="id"
                        sort={sort}
                      />
                      <TableTitle
                        title="Customer Info"
                        setSort={setSort}
                        sortBy="full_name"
                        sort={sort}
                      />
                      <TableTitle
                        title="Email"
                        setSort={setSort}
                        sortBy="email"
                        sort={sort}
                      />
                      <TableTitle
                        title="Phone"
                        setSort={setSort}
                        sortBy="phone"
                        sort={sort}
                      />
                      <TableTitle
                        title="Date of Join"
                        setSort={setSort}
                        sortBy="created_at"
                        sort={sort}
                      />
                      <TableTitle
                        title="Total Rides"
                        setSort={setSort}
                        sortBy="total_rides"
                        sort={sort}
                      />
                      <TableTitle
                        title="Products Purchased"
                        setSort={setSort}
                        sortBy="total_orders"
                        sort={sort}
                      />
                      <TableTitle
                        title="Status"
                        setSort={setSort}
                        sortBy="active"
                        sort={sort}
                      />
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading &&
                      [...Array(10)].map((d, i) => <Skelton key={i} />)}
                    {status === "pending" &&
                      [...Array(PageSize)].map((d, i) => <Skelton key={i} />)}
                    {isError && <p>Error </p>}
                    {isSuccess &&
                      status === "fulfilled" &&
                      data?.data?.users.map((user, i) => (
                        <ListCard
                          key={i}
                          user={user}
                          index={page * PageSize + i + 1}
                        />
                      ))}
                    {data?.data?.users.length === 0 && (
                      <tr>
                        <td colspan="10" className="empty_items">
                          <Empty
                            description={false}
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                          >
                            <div className="ant-empty-description">
                              <p>No users Found</p>
                            </div>
                          </Empty>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col col="12" md="12" lg="12">
            <Pagination
              current={page + 1}
              defaultCurrent={1}
              defaultPageSize={PageSize}
              onChange={handlePagination}
              total={parseInt(data?.data?.count)}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Customers;
