import React, { useEffect, useState } from "react";
import { Modal, Form, Spinner } from "react-bootstrap";
import { DatePicker, message, Radio } from "antd";
import { useForm } from "react-hook-form";
import {
  useGetCouponByIdQuery,
  useUpdateCouponMutation,
  useAddCouponMutation,
} from "services/coupon";
import moment from "moment";
function AddCouponModal({ handleClose, show, formData, setFormData }) {
  const [updateCoupon] = useUpdateCouponMutation();
  const [addCoupon] = useAddCouponMutation();
  const [loading, setLoading] = useState(false);
  const [coupon, setCoupon] = useState(false);
  const [type, setType] = useState(formData?.type || "price_discount");
  const [category, setCategory] = useState(null);

  useEffect(() => {
    setCoupon(true);
    setType(formData?.type || "price_discount");
    return () => {
      setCoupon(false);
    };
  }, [formData]);

  const onToggle = (e) => {
    setType(e.target.value);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm();

  const onSubmit = async (data) => {
    console.log(
      "🚀 ~ file: AddCouponModal.js ~ line 31 ~ AddCouponModal ~ errors",
      watch
    );

    setLoading(true);
    let discount = parseInt(data.discount) || 50;
    let upData = {
      discount,
      type,
      active_date: data.active_date,
      category: data.category,
      code: data.code.toUpperCase(),
      description: data.description,
      expiry_date: data.expiry_date,
      offer_name: data.offer_name || "Lamborghini T-Shirt for Men",
    };
    data = upData;
    const id = formData?.id;
    const createCoupon = await addCoupon({ data, id });
    if (createCoupon?.data?.message === "Created") {
      setLoading(false);
      message.success("Coupon Created Successfully");
      handleClose();
      reset();
    } else {
      setLoading(false);
      message.error(createCoupon?.data?.message);
      // handleClose();
    }
  };
  const closeAndClear = () => {
    handleClose();
    reset();
  };
  const disablePastDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  };
  console.log(errors);
  return (
    <Modal className="right-modal" show={show} onHide={closeAndClear}>
      <Modal.Header closeButton className="border-bottom pb-3">
        <Modal.Title className="m-0 font-weight-bold">
          Add Coupon Code
          <Spinner />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-3">
            <label className="font-weight-bold m-0">Category</label>
            <select
              className="form-control"
              name="category"
              {...register("category", { required: true })}
              onChange={(e) => {
                if (e.target.value === "shop") {
                  setCategory("price_discount");
                } else if (e.target.value === "ride") {
                  setCategory("price_discount&price_discount");
                }
              }}
            >
              <option value="">Select Category</option>
              <option value={"ride"}>Ride</option>
              <option value={"shop"}>Shop</option>
            </select>
            {errors.category && errors.category.type === "required" && (
              <span className="text-danger">Category is required</span>
            )}
          </div>
          <div>
            {category === "price_discount&price_discount" && (
              <Radio.Group onChange={onToggle} value={type} defaultValue={type}>
                <Radio value={"price_discount"}>Price Discount</Radio>
                <Radio value={"product_offer"}> Product Offer</Radio>
              </Radio.Group>
            )}
            {category === "price_discount" && (
              <Radio.Group value={"price_discount"} defaultValue={type}>
                <Radio value={"price_discount"}>Price Discount</Radio>
              </Radio.Group>
            )}
          </div>
          <div className="mb-3">
            <label className="font-weight-bold">Promo Code</label>
            <input
              type={"text"}
              className="form-control promo-code"
              placeholder="Enter promo code here.."
              defaultValue={formData?.code}
              {...register("code", {
                required: true,
                minLength: 5,
              })}
            />
            {errors.code && errors.code.type === "required" && (
              <p className="text-danger">{"Promo code is required"}</p>
            )}
            {errors.code && errors.code.type === "minLength" && (
              <p className="text-danger">{"at least 5 characters required"}</p>
            )}
          </div>
          {type === "price_discount" && (
            <div className="mb-3">
              <label className="font-weight-bold"> Discount Percentage</label>
              <input
                type="number"
                className="form-control"
                placeholder="50%"
                min="1"
                max="100"
                {...register("discount", { required: true })}
              />
              {errors.discount && errors.discount.type === "required" && (
                <p className="text-danger">{"Discount is required"}</p>
              )}
            </div>
          )}
          {type === "product_offer" && (
            <div className="mb-3">
              <label className="font-weight-bold"> Name of the offer</label>
              <input
                type={"text"}
                className="form-control"
                placeholder="Enter name of the offer here.."
                {...register("offer_name", { required: true })}
              />
              {errors.offer_name && errors.offer_name.type === "required" && (
                <p className="text-danger">{"Offer name is required"}</p>
              )}
            </div>
          )}
          <div className="md-3">
            <label className="font-weight-bold">Active From</label>
            <input
              type="date"
              className="form-control"
              defaultValue={formData?.active_date}
              min={disablePastDate()}
              {...register("active_date", { required: true })}
            />
            {errors.active_date && errors.active_date.type === "required" && (
              <p className="text-danger">{"Active date is required"}</p>
            )}
          </div>
          <div className="md-3">
            <label className="font-weight-bold">Expire On</label>
            <input
              type="date"
              className="form-control"
              defaultValue={formData?.expiry_date}
              min={disablePastDate()}
              {...register("expiry_date", {
                required: true,
                validate: (val) => {
                  if (watch("active_date") === val) {
                    return "Active Date  and Expiry Date cant be Same";
                  }
                  if(moment(val).isBefore(moment(watch("active_date")))){
                    return "Expiry Date cant be before Active Date";
                  }
                },
              })}
            />
            {errors.expiry_date && errors.expiry_date.type === "required" && (
              <p className="text-danger">{"Expiry date is required"}</p>
            )}
            {errors.expiry_date && errors.expiry_date.type === "validate" && (
              <p className="text-danger">
                {errors.expiry_date.message}
              </p>
            )}
          </div>
          <div className="mb-3">
            <label className="font-weight-bold">Description</label>
            <textarea
              className="form-control"
              rows="4"
              placeholder="Description"
              defaultValue={formData?.description}
              maxLength="500"
              {...register("description", { required: true })}
            />
            {errors.description && errors.description.type === "required" && (
              <p className="text-danger">{"Description is required"}</p>
            )}
          </div>
          <div>
            {loading && (
              <Spinner
                className="mx-auto mt-3
                   d-flex"
                size="sm"
                animation="border"
                variant="primary"
              />
            )}
            <input
              type="submit"
              value="Submit"
              className="btn btn-primary btn-lg w-100 mt-3"
            />
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default AddCouponModal;
