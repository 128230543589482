import React from "react";
import { MoreOutlined, StopOutlined } from "@ant-design/icons";
import { Popover, Popconfirm, message, Avatar, Image } from "antd";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { useUpdateSpecialBookingMutation } from "services/specialBookings";

import moment from "moment";
function ListCard({ type, index, ride }) {
  const [updateSpecialBooking] = useUpdateSpecialBookingMutation();

  const navigate = useNavigate();
  // 3 for cancel,7 for completed
  async function confirmCancelRide(e) {
    let upData = {
      id: ride.id,
      ride_status_id: 3,
    };
    const addSpecialBookingRes = await updateSpecialBooking(upData);
    if (addSpecialBookingRes?.data?.message === "Updated") {
      message.success("Ride Cancelled");
      navigate("/admin/special-booking");
    } else {
      addSpecialBookingRes?.data?.message
        ? message.error(addSpecialBookingRes?.data?.message)
        : message.error("Something went wrong");
    }
  }
  async function confirmCompleted(e) {
    let upData = {
      id: ride.id,
      ride_status_id: 7,
    };
    const addSpecialBookingRes = await updateSpecialBooking(upData);
    if (addSpecialBookingRes?.data?.message === "Updated") {
      message.success("Marked as completed");
      navigate("/admin/special-booking");
    } else {
      addSpecialBookingRes?.data?.message
        ? message.error(addSpecialBookingRes?.data?.message)
        : message.error("Something went wrong");
    }
  }

  return type === "Skeleton" ? (
    <tr>
      <td>
        <span className="skeleton">{index}</span>
      </td>
      <td>
        <div className="d-flex align-items-center">
          <div className="thumbnail-round mr-3 skeleton"></div>
          <div>
            <a href="#" className="skeleton">
              Order # 1236545
            </a>
            <h5 className="m-0 py-1">
              <b className="skeleton">Shawn Tom</b>
            </h5>
            <p className="m-0 ">
              {" "}
              <span className="skeleton"> (018)764-7658</span>{" "}
            </p>
            <p className="m-0">
              <span className="skeleton">stevegomez@gmail.com</span>
            </p>
          </div>
        </div>
      </td>
      <td>
        <h5 className="m-0 ">
          <span className="skeleton">TEXAS 202 HSZ</span>
          <br />
          <span className="skeleton mt-1"> Lamborghini Aventador</span>
        </h5>
      </td>
      <td>
        <h5 className="m-0 pb-2">
          <b className="skeleton">Oct 16 Saturday, 8:00 pm </b>
        </h5>
        <h5 className="m-0 ">
          <b className="skeleton">5 hours</b>
        </h5>
      </td>
      <td>
        <h5 className="m-0">
          <span className="skeleton">World Trade Center</span>
        </h5>
      </td>
      <td>
        <h5 className="m-0 font-weight-bold">
          <span className="skeleton">1500.00</span>
        </h5>
      </td>
      <td>
        <div className="d-flex align-items-center">
          <div className="thumbnail-round mr-3 skeleton"></div>
          <h5 className="m-0">
            <b className="skeleton">Shawn Tom</b>
          </h5>
        </div>
      </td>
      <td className="text-active">
        <b className="skeleton">Active</b>
      </td>

      <td className="opacity-0">
        <MoreOutlined style={{ fontSize: "35px", color: "#D1D1D1" }} />
      </td>
    </tr>
  ) : (
    <tr>
      <td>{index}</td>
      <td>
        <div className="d-flex align-items-center">
          <div className="thumbnail-round mr-3">
            <Avatar
              shape="square"
              className="w-100 h-100"
              src={
                <Image
                  src={
                    ride?.customer?.image ||
                    `https://joeschmoe.io/api/v1/${ride?.first_name}`
                  }
                />
              }
            />
          </div>
          <div>
            {/* <Link to={"/admin/rides/" + ride?.id} className="text-primary">
              Order # {ride?.ride_code}
            </Link> */}
            <p className="text-primary p-0 m-0">Order # {ride?.ride_code}</p>
            <h5 className="m-0 py-1">
              <b>
                {ride?.first_name} {ride?.last_name}
              </b>
            </h5>
            <p className="m-0">{ride?.phone} </p>
            <p className="m-0">{ride?.email}</p>
          </div>
        </div>
      </td>
      <td>
        <h5 className="m-0 ">
          {ride?.vehicle?.plate_number} <br />
          {ride?.vehicle?.name}{" "}
        </h5>
      </td>
      <td>
        <h5 className="m-0 pb-2">
          {moment(ride?.ride_start).format("MM/DD/YY, h:mm a")}
        </h5>
        <h5 className="m-0 ">
          {ride?.duration}{" "}
          <span className="text-gray">
            {ride?.duration > 1 ? "Hours" : "Hour"}
          </span>
        </h5>
      </td>
      <td>
        <h5 className="m-0">{ride?.location?.title}</h5>
      </td>
      <td>
        <h5 className="m-0 font-weight-bold">${ride?.ride_cost}</h5>
      </td>
      <td>
        <div className="d-flex align-items-center">
          <div className="thumbnail-round mr-3">
            <Avatar
              shape="square"
              className="w-100 h-100"
              src={
                <Image
                  src={
                    ride?.driver?.image ||
                    `https://joeschmoe.io/api/v1/${ride?.driver?.full_name}`
                  }
                />
              }
            />
          </div>
          <h5 className="m-0">
            <b>{ride?.driver?.full_name}</b>
          </h5>
        </div>
      </td>
      <td className="ride_status" style={{ color: ride?.ride_status?.color }}>
        <span style={{ backgroundColor: ride?.ride_status?.color }}></span>
        {ride?.ride_status?.name}
      </td>

      <td className="text-center">
        {ride?.ride_status_id != 3 && ride?.ride_status_id != 7 ? (
          <Popover
            placement="leftTop"
            content={popoverContent({
              confirmCancelRide,
              confirmCompleted,
              navigate,
              ride,
            })}
            trigger="click"
            className="rounded-circle"
          >
            <MoreOutlined style={{ fontSize: "35px", color: "#D1D1D1" }} />
          </Popover>
        ) : (
          <StopOutlined style={{ fontSize: "25px", color: "#D1D1D1" }} />
        )}
      </td>
    </tr>
  );
}
const popoverContent = ({
  confirmCancelRide,
  navigate,
  confirmCompleted,
  ride,
}) => {
  return (
    <div className="d-flex flex-column align-items-start">
      <p
        className="btn"
        onClick={() => {
          navigate(`/admin/special-booking/edit-special-booking/${ride?.id}`);
        }}
      >
        Edit Ride
      </p>
      <Popconfirm
        title="Are you sure to cancel Ride?"
        onConfirm={confirmCancelRide}
        okText="Yes"
        cancelText="No"
        placement="left"
      >
        <p className="btn text-primary">Cancel Ride</p>
      </Popconfirm>
      <Popconfirm
        title="Are you sure mark this ride as completed?"
        onConfirm={confirmCompleted}
        okText="Yes"
        cancelText="No"
        placement="left"
      >
        <p className="btn">Mark as Completed</p>
      </Popconfirm>
    </div>
  );
};

export default ListCard;
