import React from "react";
import { Card, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";

function VehicleCards({ handleShow, status, vehicle }) {
  const linkTo = `/admin/vehicles/${vehicle?.id}`;
  const thumbnail = vehicle?.vehicle_image?.filter((img) => {
    return img?.thumbnail === true;
  });

  return (
    <Col col="12" xl="6">
      <Card className="rounded border-0 mb-3 card-list p-3">
        <Card.Body className="">
          <Row>
            <Col col="12" xl="4" className={status === "inactive" && "opacity"}>
              <Link to={linkTo}>
                <div className="car-card-img">
                <LazyLoadImage
                alt={vehicle?.name}
                effect="blur"
                className="rounded img-fluid height_fix"

                src={
                  thumbnail[0]?.image ||
                  "https://via.placeholder.com/350x350?text=404"
                }
              />
                  
                  {/* <img
                    className="rounded img-fluid"
                    alt="..."
                    src={
                      thumbnail[0]?.image ||
                      "https://via.placeholder.com/350x350?text=404"
                    }
                  ></img> */}
                </div>
              </Link>
            </Col>
            <Col col="12" md="8" className="p-0">
              <div className="text-right mt-2 mt-md-0">
                {status === "active" ? (
                  <span className="badge badge-primary  rounded-pill py-2 px-3">
                    Active
                  </span>
                ) : (
                  <span className="badge badge-secondary rounded-pill py-2 px-3">
                    Inactive
                  </span>
                )}
              </div>
              <Link to={linkTo}>
                <div className={status === "inactive" && "opacity"}>
                  <h5 className="secondary-gray m-0">
                    {vehicle?.plate_number}
                  </h5>
                  <h4 className="text-secondary font-weight-bold m-0">
                    {vehicle?.name}{" "}
                  </h4>
                  <h6>
                    <span className="secondary-gray">
                      {vehicle?.rating_avg}
                    </span>
                    <span className="text-primary">
                      {" "}
                      Reviews ({vehicle?.rating_count})
                    </span>
                  </h6>
                </div>
              </Link>
              {vehicle?.vehicle_availability?.length > 0 ? (
                <AvailabilityDetails vehicle={vehicle} />
              ) : (
                <AvailabilityWarning vehicle={vehicle} />
              )}

              <AddAvailabilityButton
                status={status}
                handleShow={handleShow}
                vehicle={vehicle}
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Col>
  );
}
const AddAvailabilityButton = ({ status, handleShow, vehicle }) => {
  return (
    <button
      type="button"
      className={
        !vehicle?.vehicle_availability?.length > 0
          ? "btn btn-primary w-100 mt-3"
          : "btn btn-outline-primary w-100 mt-3"
      }
      onClick={() =>
        handleShow({
          type: !vehicle?.vehicle_availability?.length > 0 ? "edit" : "view",
          modalData: vehicle,
        })
      }
    >
      {!vehicle?.vehicle_availability?.length > 0
        ? "ADD AVAILABILITY & RATE"
        : "VIEW AVAILABILITY"}
    </button>
  );
};
const AvailabilityWarning = ({ vehicle }) => {
  return (
    <div className="d-flex align-items-center py-4">
      <img
        className="rounded mr-3"
        alt="..."
        src={require("assets/img/warning.png").default}
      ></img>

      <h6 className="text-danger">
        You have not update the availability of your car. Customer can't book
        the appointment without these data
      </h6>
    </div>
  );
};
const AvailabilityDetails = ({ vehicle }) => {
  return (
    <Row className="border-bottom m-0 py-3">
      <Col col="12" md="6" className="p-0 border-right-dashed">
        <h6 className="secondary-gray mb-2">Rates</h6>
        <div className=" d-flex align-items-center">
          <h4 className="text-primary m-0 font-weight-bold ">
            ${vehicle?.rate}{" "}
          </h4>
          <h5 className="text-primary m-0 font-weight-bold ">/ Hour</h5>
        </div>
      </Col>
      <Col col="12" md="5" className="p-0 ml-auto">
        <h6 className="secondary-gray mb-3">Next Ride</h6>
        {vehicle?.next_ride ? (
          <h6 className="text-secondary font-weight-normal">
            {" "}
            {vehicle?.next_ride}
          </h6>
        ) : (
          <h6 className="text-primary font-weight-normal"> No Ride </h6>
        )}
      </Col>
    </Row>
  );
};
export default VehicleCards;
