import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getToken } from "cookies/token";
import { authCheck } from "./functions/authCheck";

const header = (headers, { getState }) => {
  const token = getToken();
  if (token) {
    headers.set("authorization", `Bearer ${token}`);
  }
  return headers;
};
export const specialBookingApi = createApi({
  reducerPath: "SpecialBookingApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: header,
  }),
  tagTypes: ["SpecialBooking"],
  endpoints: (builder) => ({
    getSpecialBookingById: builder.query({
      query: (id) => {
        return {
          url: `/ride/${id}`,
          method: "GET",
          validateStatus: (response, result) => authCheck(response, result),
        };
      },
    }),
    getAllSpecialBookings: builder.query({
      query: (...data) => {
        const populate = encodeURIComponent(JSON.stringify(data[0].populate));
        const filter = encodeURIComponent(JSON.stringify(data[0].filter));
        const sort = encodeURIComponent(
          JSON.stringify([[data[0].sort.sortBy, data[0].sort.sortDirection]])
        );
        return {
          url: `/ride/special?offset=${data[0].PageSize * data[0].page}&limit=${
            data[0].PageSize
          }&populate=${data[0].populate}&sort=%5B%5B%22${
            data[0].sort.sortBy
          }%22%2C%22${data[0].sort.sortDirection}%22%5D%5D&where=${filter}`,
          method: "GET",
          validateStatus: (response, result) => authCheck(response, result),
        };
      },
      providesTags: ["SpecialBooking"],
    }),
    addSpecialBooking: builder.mutation({
      query: (newSpecialBooking) => {
        return {
          url: `/ride/special`,
          method: "POST",
          body: newSpecialBooking,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          validateStatus: (response, result) => authCheck(response, result),
        };
      },
      invalidatesTags: ["SpecialBooking"],
    }),
    updateSpecialBooking: builder.mutation({
      query: (newSpecialBooking) => {
        return {
          url: `/ride/${newSpecialBooking.id}`,
          method: "PUT",
          body: newSpecialBooking,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          validateStatus: (response, result) => authCheck(response, result),
        };
      },
      invalidatesTags: ["SpecialBooking"],
    }),
  }),
});
export const {
  useGetSpecialBookingByIdQuery,
  useGetAllSpecialBookingsQuery,
  useAddSpecialBookingMutation,
  useUpdateSpecialBookingMutation,
} = specialBookingApi;
