import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userSelector, logoutUser } from "features/auth/authSlice";
import { Popconfirm, message, Badge, Affix, Empty, Image } from "antd";
import { BellFilled } from "@ant-design/icons";
import { Popover } from "antd";
import { Card, Avatar } from "antd";
import { DownOutlined, ClearOutlined } from "@ant-design/icons";

const { Meta } = Card;

import {
  Navbar,
  Container,
  Nav,
  Button,
  DropdownButton,
} from "react-bootstrap";
import { Menu, Dropdown, DatePicker } from "antd";
import { useNavigate } from "react-router";
import NotificationCard from "components/Notification/NotificationCard";
import NotificationMain from "components/Notification/NotificationMain";
import { useGetNotificationCountQuery } from "services/notification";
import { useGetUserQuery } from "services/settings";
function Header({ setBurger, burgerSlid }) {
  const [showNotification, setNotification] = useState(false);
  const notificationCount = useGetNotificationCountQuery(showNotification, {
    refetchOnMountOrArgChange: true,
    skip: showNotification,
  });

  const dispatch = useDispatch();
  const navigation = useNavigate();
  async function confirm(e) {
    await dispatch(logoutUser());
    navigation("/");
    message.success("Logout Successfully");
  }

  function cancel(e) {
    message.error("Logout Cancelled");
  }
  const { isLoading, isError, isSuccess, data, status, isFetching } =
    useGetUserQuery(
      {},
      {
        refetchOnMountOrArgChange: false,
      }
    );
  const userData = data?.data?.user;

  const location = useLocation();
  const mobileSidebarToggle = (e) => {
    e.preventDefault();
    document.documentElement.classList.toggle("nav-open");
    var node = document.createElement("div");
    node.id = "bodyClick";
    node.onclick = function () {
      this.parentElement.removeChild(this);
      document.documentElement.classList.toggle("nav-open");
    };
    document.body.appendChild(node);
  };
  const handleVisibleChange = () => {
    setNotification((prev) => !prev);
  };
  const menu = (
    <Menu>
      <Menu.Item key="0">
        <Popconfirm
          placement="top"
          title="Are you sure to logout ?"
          onConfirm={confirm}
          onCancel={cancel}
          okText="logout"
          cancelText="Cancel"
        >
          <span>Logout</span>
        </Popconfirm>{" "}
      </Menu.Item>
      <Menu.Item key="1">
        <Link to="/admin/account-settings">Account Settings</Link>
      </Menu.Item>
    </Menu>
  );
  return (
    <Affix offsetTop={0}>
      <Navbar bg="light" expand="lg">
        <Container fluid>
          <div className="d-flex justify-content-center align-items-center ml-2 ml-lg-0">
            <Button
              variant="dark"
              className="d-lg-none btn-fill d-flex justify-content-center align-items-center rounded-circle p-2"
              onClick={mobileSidebarToggle}
            >
              <i className="fas fa-ellipsis-v"></i>
            </Button>
            <Navbar.Brand
              onClick={(e) => setBurger(!burgerSlid)}
              className="d-md-block d-none mr-2"
            >
              <i
                className={
                  burgerSlid
                    ? "fas fa-bars fa-lg"
                    : "fas fa-chevron-right fa-lg"
                }
              ></i>
            </Navbar.Brand>
          </div>
          <Navbar.Toggle aria-controls="basic-navbar-nav" className="mr-2">
            <span className="navbar-toggler-bar burger-lines"></span>
            <span className="navbar-toggler-bar burger-lines"></span>
            <span className="navbar-toggler-bar burger-lines"></span>
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto align-items-center " navbar>
              <Nav.Item>
                <Nav.Link
                  className="m-0 "
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  <Popover
                    content={
                      <NotificationMain showNotification={showNotification} />
                    }
                    trigger="click"
                    title={
                      <div className="d-flex justify-content-between pt-1 ">
                        <h6>Notifications</h6>
                      </div>
                    }
                    visible={showNotification}
                    onVisibleChange={handleVisibleChange}
                    placement="bottomRight"
                    style={{ padding: "0px" }}
                  >
                    <div className="position-relative">
                      <BellFilled style={{ color: "gray", fontSize: "25px" }} />
                      {!notificationCount?.isFetching &&
                        notificationCount?.currentData?.data?.count > 0 &&
                        !showNotification && (
                          <span className="notify_num">
                            {notificationCount?.currentData?.data?.count}
                          </span>
                        )}
                    </div>
                  </Popover>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="m-0"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  <Avatar
                    className="mt-1"
                    style={{ color: "#f56a00", backgroundColor: "#fde3cf" }}
                    src={<Image src={userData?.image} />}
                  >
                    {userData?.full_name.slice(0, 1) || "Admin"}
                  </Avatar>
                  <Dropdown overlay={menu} trigger={["click"]}>
                    <button
                      className="ant-dropdown-link btn m-0 p-0 pl-4"
                      onClick={(e) => e.preventDefault()}
                    >
                      {userData?.full_name || "Admin"} <DownOutlined />
                    </button>
                  </Dropdown>
                  {/* <DropdownButton
                    className="m-0 ml-3 admin_dropdown "
                    title={userData?.full_name || "Admin"}
                  >
                    <div className="d-flex flex-column">
                      <Button>
                        <Popconfirm
                          title="Are you sure to logout ?"
                          onConfirm={confirm}
                          onCancel={cancel}
                          okText="logout"
                          cancelText="Cancel"
                          placement="left"
                        >
                          <span>LogOut</span>
                        </Popconfirm>
                      </Button>
                      <Button>Account Settings</Button>
                    </div>
                  </DropdownButton> */}
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </Affix>
  );
}

export default Header;
