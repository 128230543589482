import React, { useState, useEffect } from "react";
import { Table, Row, Col, Form, Modal, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import {
  useSendVerificationCodeMutation,
  useVerifyAndUpdateEmailMutation,
} from "services/settings";
import { message } from "antd";
import { setSession, getSession } from "cookies/token";
function ChangeEmailModal({ showEmailModal, closeEmailModal, email }) {
  const [senderEmail, setSenderEmail] = useState(email);
  useEffect(() => {
    setSenderEmail(email);
  }, [email]);
  const [verifyStatus, setVerifyStatus] = useState("initial");
  const [sendVerificationCode] = useSendVerificationCodeMutation();
  const [verifyAndUpdateEmail] = useVerifyAndUpdateEmailMutation();
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    setIsLoading(true);
    const verify = await sendVerificationCode(data);
    if (verify?.data?.message === "OTP sent") {
      setSession(verify?.data?.data?.session_id);
      setSenderEmail(data.value);
      message.success("Verification code sent successfully");
      setIsLoading(false);
      reset();
      setTimeout(() => {
        setVerifyStatus("otpVerified");
      }, 2000);
      setVerifyStatus("otpSent");
    } else {
      setIsLoading(false);
      message.error(verify?.data?.error ||"jjjsomething went wrong");
    }
  };
  const handelClose = () => {
    reset();
    closeEmailModal();
    setVerifyStatus("initial");
  };
  const verifyAndChangeEmail = async (data) => {
    const upData = {
      ...data,
      session_id: await getSession(),
    };
    const verify = await verifyAndUpdateEmail(upData);
    if (verify?.data?.message === "Email updated") {
      message.success("Email updated successfully");
      setIsLoading(false);
      reset();
      setVerifyStatus("initial");
      handelClose();
    } else {
      setIsLoading(false);
      message.error(verify?.data?.error || "something went wrong");
    }
  };
  return (
    <Modal className="right-modal" show={showEmailModal} onHide={handelClose}>
      <Modal.Header closeButton className="border-bottom pb-3">
        <Modal.Title className="m-0 font-weight-bold">
          Change Contact Email{" "}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {verifyStatus === "initial" && (
          <form className="pt-4" onSubmit={handleSubmit(onSubmit)}>
            <Row className="align-items-center">
              <Col lg="12">
                <Form.Group controlId="formBasicPassword">
                  <Form.Label>
                    <strong>Contact Email Address</strong>
                  </Form.Label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Enter Contact Email Address"
                    name="contactEmail"
                    defaultValue={senderEmail}
                    {...register("value", {
                      required: true,
                      pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    })}
                  />
                  {errors?.value?.type === "required" && (
                    <span className="text-danger">
                      <>Contact Email Address is required</>
                    </span>
                  )}
                  {errors?.value?.type === "pattern" && (
                    <span className="text-danger">
                      <>Contact Email Address is invalid</>
                    </span>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col lg="12">
                {isLoading && (
                  <Spinner
                    animation="border"
                    variant="primary"
                    className="m-auto d-flex"
                    size="sm"
                  />
                )}
                <input
                  type="submit"
                  value="Change"
                  className="btn btn-primary w-100 mt-2"
                />
              </Col>
            </Row>
          </form>
        )}
        {verifyStatus === "otpSent" && (
          <div className="pt-4">
            <img
              src={require("assets/img/otpSent.gif").default}
              alt="otpSent"
              className="w-50 d-flex mx-auto"
            />
          </div>
        )}
        {verifyStatus === "otpVerified" && (
          <form className="pt-4" onSubmit={handleSubmit(verifyAndChangeEmail)}>
            <Row className="align-items-center">
              <Col lg="12">
                <p   style={{
                    fontSize: "14px"
                  }}>
                  Verification code has been sent to your new contact email
                  address <br /> {senderEmail}. Please verify email and continue to
                  change.
                </p>
                <Form.Group controlId="formBasicPassword">
                  <Form.Label>
                    <strong>Verification Code</strong>
                  </Form.Label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Code"
                    name="otp"
                    {...register("otp", {
                      required: true,
                      pattern: /^[0-9]{6}$/i,
                    })}
                  />
                  {errors?.otp?.type === "required" && (
                    <span className="text-danger">
                      <>Verification Code is required</>
                    </span>
                  )}
                  {errors?.otp?.type === "pattern" && (
                    <span className="text-danger">
                      <>Verification Code is invalid</>
                    </span>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col lg="12">
                {isLoading && (
                  <Spinner
                    animation="border"
                    variant="primary"
                    className="m-auto d-flex"
                    size="sm"
                  />
                )}
                <input
                  type="submit"
                  value="Done"
                  className="btn btn-primary w-100 mt-2"
                />
              </Col>
            </Row>
          </form>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default ChangeEmailModal;
