import React from "react";
import { Route, Routes, Navigate } from "react-router";
import LoginPage from "views/Pages/Auth/Login";
import NotFound from "views/Pages/NotFound";
import Admin from "layouts/Admin";
import Dashboard from "views/Pages/Admin/Dashboard";
import PaymentReport from "views/Pages/Admin/PaymentReport";
import CouponCodes from "views/Pages/Admin/CouponCodes";
import Rides from "views/Pages/Admin/Rides/index";
import Vehicles from "views/Pages/Admin/Vehicles";
import Drivers from "views/Pages/Admin/Drivers";
import SpecialBookings from "views/Pages/Admin/SpecialBookings";
import Orders from "views/Pages/Admin/Orders";
import Products from "views/Pages/Admin/Products";
import PickupLocations from "views/Pages/Admin/PickupLocations";
import Customers from "views/Pages/Admin/Customers/index";
import CustomerDetail from "views/Pages/Admin/Customers/Detail";
import { useSelector } from "react-redux";
import DriversDetail from "views/Pages/Admin/Drivers/Detail";
import RidesDetail from "views/Pages/Admin/Rides/Detail";
import AddVehicles from "views/Pages/Admin/Vehicles/AddVehicles";
import VehicleDetail from "views/Pages/Admin/Vehicles/Detail";
import ProductDetail from "views/Pages/Admin/Products/Detail";
import { useLocation } from "react-router-dom";
import { userSelector } from "features/auth/authSlice";
import OrderDetail from "views/Pages/Admin/Orders/Detail";
import AddSpecialBooking from "views/Pages/Admin/SpecialBookings/AddSpecialBookings";
import EditVehicles from "views/Pages/Admin/Vehicles/EditVehicles";
import EditSpecialBookings from "views/Pages/Admin/SpecialBookings/EditSpecialBookings";
import Settings from './../views/Pages/Admin/SettingsPage/index';

function Router() {
  const { data } = useSelector(userSelector);
  const location = useLocation();
  function AuthRoute({ children }) {
    if (!data) {
      return <Navigate to="/auth/login" />;
    }

    return children;
  }
  return (
    <Routes>
      <Route
        path="/"
        element={
          data ? (
            <Navigate to={"/admin/dashboard"} />
          ) : (
            <Navigate to="/auth/login" />
          )
        }
      />

      <Route
        path="/auth/login"
        element={data ? <Navigate to="/admin/dashboard" /> : <LoginPage />}
      />
      <Route
        path="/admin"
        element={
          <AuthRoute>
            <Admin />
          </AuthRoute>
        }
      >
        <Route index element={<Dashboard />} />
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="payment-report" element={<PaymentReport />} />
        <Route path="coupon-codes" element={<CouponCodes />} />
        <Route path="customers" element={<Customers />} />
        <Route path="customers/:id" element={<CustomerDetail />} />
        <Route path="rides" element={<Rides />} />
        <Route path="rides/:id" element={<RidesDetail />} />
        <Route path="vehicles" element={<Vehicles />} />
        <Route path="vehicles/:id" element={<VehicleDetail />} />
        <Route path="vehicles/add-new-vehicle" element={<AddVehicles />} />
        <Route path="vehicles/edit-vehicle/:id" element={<EditVehicles />} />
        <Route path="drivers" element={<Drivers />} />
        <Route path="drivers/:id" element={<DriversDetail />} />
        <Route path="special-booking" element={<SpecialBookings />} />
        <Route
          path="special-booking/add-special-booking"
          element={<AddSpecialBooking />}
        />
        <Route
          path="special-booking/edit-special-booking/:id"
          element={<EditSpecialBookings />}
        />
        <Route path="orders" element={<Orders />} />
        <Route path="orders/:id" element={<OrderDetail />} />

        <Route path="products" element={<Products />} />
        <Route path="products/:id" element={<ProductDetail />} />
        <Route path="pickup-locations" element={<PickupLocations />} />
        <Route path="account-settings" element={<Settings />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default Router;
