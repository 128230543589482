import React from "react";

function MetaTitle({ text }) {
  return (
    <title>
      Revup{text && "-"}
      {text}
    </title>
  );
}

export default MetaTitle;
