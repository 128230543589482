import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getToken, removeToken } from "cookies/token";
import { authCheck } from "./functions/authCheck";
const header = (headers, { getState }) => {
  const token = getToken();
  if (token) {
    headers.set("authorization", `Bearer ${token}`);
  }
  return headers;
};
export const settingsApis = createApi({
  reducerPath: "SettingsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: header,
  }),
  tagTypes: ["Settings"],
  endpoints: (builder) => ({
    getUser: builder.query({
      query: (...data) => {
        return {
          url: `/user/me`,
          method: "GET",
          validateStatus: (response, result) => authCheck(response, result),
        };
      },
      providesTags: ["Settings"],
    }),
    getPaymentMode: builder.query({
      query: (...data) => {
        return {
          url: `/payments/mode`,
          method: "GET",
          validateStatus: (response, result) => authCheck(response, result),
        };
      },
    }),
    updatePersonalInfo: builder.mutation({
      query: (...data) => {
        return {
          url: `/user/me`,
          method: "PUT",
          body: data[0],
          validateStatus: (response, result) => authCheck(response, result),
        };
      },
      invalidatesTags: ["Settings"],
    }),
    getContactEmail: builder.query({
      query: (...data) => {
        return {
          url: `/setting/find?offset=0&where=%7B%22name%22%3A%22to_email_admin%22%7D`,
          method: "GET",
          validateStatus: (response, result) => authCheck(response, result),
        };
      },
      providesTags: ["contactMail"],
    }),
    updatePassword: builder.mutation({
      query: (data) => {
        return {
          url: `/user/password`,
          method: "PUT",
          body: data,
          validateStatus: (response, result) => authCheck(response, result),
        };
      },
    }),
    sendVerificationCode: builder.mutation({
      query: (data) => {
        return {
          url: `/setting/contact_email`,
          method: "POST",
          body: data,
          validateStatus: (response, result) => authCheck(response, result),
        };
      },
    }),
    verifyAndUpdateEmail: builder.mutation({
      query: (data) => {
        return {
          url: `/setting/contact_email`,
          method: "PUT",
          body: data,
          validateStatus: (response, result) => authCheck(response, result),
        };
      },
      invalidatesTags: ["contactMail"],
    }),
  }),
});

export const {
  useGetUserQuery,
  useGetPaymentModeQuery,
  useUpdatePersonalInfoMutation,
  useGetContactEmailQuery,
  useUpdatePasswordMutation,
  useSendVerificationCodeMutation,
  useVerifyAndUpdateEmailMutation,
} = settingsApis;
