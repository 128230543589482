import React, { useState } from "react";
import { Modal, Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Calendar, message } from "antd";
import moment from "moment";
import { useGetTimeSlotsQuery } from "services/vehicles";
import { useUpdateRideBookingMutation } from "services/rides";

function ScheduleRideModal({
  show,
  handleClose,
  schedule,
  setSchedule,
  type,
  headingType,
}) {
  const [updateRideBooking] = useUpdateRideBookingMutation();

  const [Loading, setLoading] = useState(false);
  const [date, setDate] = useState(moment(schedule?.ride_date));
  const [time, setTime] = useState(0);
  const { isLoading, isError, isSuccess, data, status } = useGetTimeSlotsQuery(
    schedule,
    {
      refetchOnMountOrArgChange: true,
    }
  );

  function onPanelChange(value, mode) {
    setSchedule({ ...schedule, ride_date: value.format("YYYY-MM-DD") });
  }
  const onDone = async (freeslot, ride_date, start_at) => {
    if (type == "update") {
      const upData = {
        id: schedule?.id,
        duration: freeslot,
        ride_date: ride_date,
        start_at: start_at,
      };
      const updateRides = await updateRideBooking(upData);
      if (updateRides?.data?.message === "Updated") {
        setLoading(false);
        message.success("Reschedule Successfully");
        setSchedule({ ...schedule, freeslot, ride_date, start_at });
        handleClose();
      } else {
        setLoading(false);
        updateRides?.data?.message
          ? message.error(updateRides?.data?.message)
          : message.error("Something went wrong");
      }
    }
    setSchedule({ ...schedule, freeslot, ride_date, start_at });
    handleClose();
  };

  return (
    <Modal
      className="right-modal ScheduleRideModal"
      show={show}
      onHide={handleClose}
    >
      <Modal.Header closeButton className="border-bottom pb-3">
        <Modal.Title className="m-0 font-weight-bold">
          {headingType === "ADD" ? "Schedule Ride" : "Reschedule Ride"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isError && <p>{isError?.message} </p>}

        {isSuccess && (
          <>
            <Calendar
              value={date}
              fullscreen={false}
              onSelect={(e) => setDate(e)}
              onChange={onPanelChange}
            />
            <div className="my-4">
              <p className="text-uppercase">Time slots Available</p>
              <div className="slot_coloration">
                {isSuccess &&
                  status === "fulfilled" &&
                  data?.data?.slots.map((item, index) => (
                    <button
                      key={index}
                      className={`rounded-pill  btn-sm px-3 mr-2 mb-2 border-0  ${
                        index === time ? " white_btn  " : " orange_btn"
                      }`}
                      onClick={() => setTime(index)}
                    >
                      {item}
                    </button>
                  ))}
                {status === "pending" &&
                  [...Array(8)].map((d, i) => (
                    <button
                      key={i}
                      className="rounded-pill  btn-sm px-3 mr-2 mb-2 border-0 skeleton"
                    >
                      {"08:00 PM"}
                    </button>
                  ))}
                {data?.error && <p className="text-center">{data?.message}</p>}
              </div>
            </div>
            {isSuccess && status === "pending" && <Skeleton />}
            {isSuccess &&
              status === "fulfilled" &&
              data?.data?.slots.length > 0 && (
                <Conformation
                  schedule={schedule}
                  slots={data?.data?.slots}
                  time={time}
                  onDone={onDone}
                />
              )}
          </>
        )}
        {data?.data?.slots.length === 0 && (
          <p className="text-center">No time slots available</p>
        )}
      </Modal.Body>
    </Modal>
  );
}
const Conformation = ({ schedule, slots, time, onDone }) => {
  let startTime = moment(slots[time], "h:mm:ss A").format("HH:mm:ss")
  return (
    <>
      <label className="font-weight-bold">Confirm Schedule</label>
      <div className="border p-3">
        <div className="mb-3">
          <h6 className="text-gray mb-0">START TIME</h6>
          <h5 className="m-0">
            {moment(schedule?.ride_date + " " + startTime).format(
              "MM/DD/YY, h:mm a"
            )}
            {/* {moment(ride?.ride_start).format("MMM Do dddd, h:mm a")} */}
          </h5>
        </div>
        <Row>
          <Col col="12" md="6">
            <div className="mb-2">
              <h6 className="text-gray  mb-0">END TIME </h6>
              <h5 className="m-0">
                {moment(schedule?.ride_date + " " + startTime)
                  .add(schedule?.freeslot, "hours")
                  .format("MM/DD/YY, h:mm a")}
                {/* {moment(ride_start)
                  .add(duration, "hours")
                  .format("YYYY-MM-DD HH:mm:ss")} */}
              </h5>
            </div>
          </Col>
          <Col col="12" md="6">
            <div className="mb-2">
              <h6 className="text-gray  mb-0">DURATION</h6>
              <h5 className="m-0">
                <b>{schedule?.freeslot} </b>{" "}
                {schedule?.freeslot > 1 ? "Hours" : "Hour"}
              </h5>
            </div>
          </Col>
        </Row>
      </div>
      <button
        className="mt-5 w-100 btn-primary btn-lg mb-3 mb-md-0 btn btn-primary"
        onClick={() =>
          onDone(schedule?.freeslot, schedule?.ride_date, slots[time])
        }
      >
        Done
      </button>
    </>
  );
};

const Skeleton = () => {
  return (
    <>
      <label className="font-weight-bold">Confirm Schedule</label>
      <div className="border p-3">
        <div className="mb-3">
          <h6 className="text-gray mb-1">
            <span className="skeleton">START TIME</span>
          </h6>
          <h5 className="m-0">
            <b className="skeleton">Oct 20 Saturday, 9:00 am</b>
          </h5>
        </div>
        <Row>
          <Col col="12" md="6">
            <div className="mb-2">
              <h6 className="text-gray  mb-1">
                <span className="skeleton">START TIME</span>{" "}
              </h6>
              <h5 className="m-0">
                <b className="skeleton">Oct 20 Saturday, 9:00 am</b>
              </h5>
            </div>
          </Col>
          <Col col="12" md="6">
            <div className="mb-2">
              <h6 className="text-gray  mb-1">
                <span className="skeleton">START TIME</span>
              </h6>
              <h5 className="m-0">
                <b className="skeleton">Oct 20 Saturday, 9:00 am</b>
              </h5>
            </div>
          </Col>
        </Row>
      </div>
      <button className="mt-5 w-100 btn-primary btn-lg mb-3 mb-md-0 btn btn-primary">
        Done
      </button>
    </>
  );
};

export default ScheduleRideModal;
