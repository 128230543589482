import { Avatar, Image } from "antd";
import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import moment from "moment";
function NewRideListCards({ handleShow, ride, index }) {
  return (
    <tr>
      <td>{index}</td>
      <td>
        <Link to={"/admin/rides/" + ride?.id} className="text-primary">
          {ride?.ride_code}
        </Link>
      </td>
      <td>
        <div className="d-flex align-items-center">
          <div className="thumbnail-round mr-3">
            <Avatar
              shape="square"
              className="w-100 h-100"
              src={
                <Image
                  src={
                    ride?.customer?.image ||
                    `https://joeschmoe.io/api/v1/${ride?.customer?.full_name}`
                  }
                />
              }
            />
          </div>
          <h5 className="m-0">
            <b>{ride?.customer?.full_name}</b>
          </h5>
        </div>
      </td>
      <td>
        <h5 className="m-0 ">
          {ride?.vehicle?.plate_number} <br />
          {ride?.vehicle?.name}{" "}
        </h5>
      </td>
      <td>
        <h5 className="m-0 ">
          {moment(ride?.ride_start).format("MM/DD/YY, h:mm a")}
        </h5>
      </td>
      <td>
        <h5 className="m-0 ">
          {ride?.duration}{" "}
          <span className="text-gray">
            {ride?.duration > 1 ? "Hours" : "Hour"}
          </span>
        </h5>
      </td>
      <td>
        <h5 className="m-0 ">{ride?.location?.title}</h5>
      </td>
      <td>
        <h5 className="m-0 font-weight-bold">${ride?.ride_cost}</h5>
      </td>
      <td>
        {moment(moment(ride?.ride_start).format("MM/DD/YY")).diff(
          moment(Date.now()).format("MM/DD/YY"),
          "days"
        ) < 0 ? (
          "Ride expired"
        ) : (
          <Button
            className="w-100 btn-primary"
            type="submit"
            onClick={() => handleShow(ride?.id)}
          >
            Assign Driver
          </Button>
        )}
      </td>
    </tr>
  );
}

export default NewRideListCards;
