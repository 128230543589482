import React from "react";
import { useState } from "react";
import { Button, Row, Col, Form, Modal, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { UploadImageToS3WithReactS3, uploadImagesToS3 } from "bucket/s3";
import { message } from "antd";
import {
  useAddProductMutation,
  useUpdateProductByIdMutation,
} from "services/products";
import { LazyLoadImage } from "react-lazy-load-image-component";

function AddEditModal({ show, handleClose, product }) {
  const [updateProductById] = useUpdateProductByIdMutation();
  const [addProduct] = useAddProductMutation();
  const [loading, setLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const defaultImages = product?.product_image.map((image, i) => {
    return {
      location: image.image,
      key: image.id,
      is_thumbnail: image.is_thumbnail,
    };
  });
  let defaultThumbnail = 0;
  for (let index = 0; index < product?.product_image.length; index++) {
    const element = product?.product_image[index];
    if (element.is_thumbnail === true) {
      defaultThumbnail = index;
    }
  }
  const [thumbnail, setThumbnail] = useState(defaultThumbnail || 0);
  const dataSchema = {
    title: null,
    brand: null,
    unit_price: null,
    stock: null,
    length: null,
    width: null,
    height: null,
    weight: null,
    description: null,
    images: defaultImages ? defaultImages : [],
  };

  const [productImages, setProductImages] = useState(
    defaultImages ? defaultImages : []
  );
  const [filesError, setFilesError] = useState(true);
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    reset,
  } = useForm({ reValidateMode: "onChange" });
  const watchAllFields = watch();
  //console.log(errors);
  const onSubmit = async (data) => {
    // console.log("productImages", productImages);
    let inputImageData = productImages.map((image, index) => {
      return {
        image: image.location,
        is_thumbnail: thumbnail === index ? true : false,
      };
    });
    //console.log("inputImageData", inputImageData);

    setLoading(true);
    dataSchema.title = data.product_title;
    dataSchema.brand = data.brand;
    dataSchema.unit_price = parseFloat(data.unit_price);
    dataSchema.stock = parseInt(data.stock);
    dataSchema.length = parseFloat(data.length);
    dataSchema.width = parseFloat(data.width);
    dataSchema.height = parseFloat(data.height);
    dataSchema.weight = parseFloat(data.weight);
    dataSchema.description = data.description;
    dataSchema.images = inputImageData;
    if (product?.id) {
      let updateData = [product?.id, dataSchema];
      const changeStatusResponds = await updateProductById(updateData);
      if (changeStatusResponds?.data?.message === "Updated") {
        setLoading(false);
        handleClose();
        message.success("Product Updated Successfully");
      }
      if (changeStatusResponds?.error) {
        setLoading(false);
        message.error("Something went wrong");
      }
    }
    if (!product) {
      const createResponds = await addProduct(dataSchema);
      if (createResponds?.data?.message === "Created") {
        reset();
        //console.log(createResponds?.data?.data?.product);
        setProductImages([]);
        setLoading(false);
        message.success("Product added successfully");
        handleClose();
      }
      if (createResponds?.error) {
        setProductImages([]);
        setLoading(false);
        message.error("Something went wrong");
      }
    }
  };
  const handleImagesChange = async (images, e) => {
    if (images.length < 0) {
      setFilesError(true);
    }
    if (images.length > 0) {
      setFilesError(false);
    }

    if (images.length > 5) {
      setFilesError("You can only upload 5 images");
      message.error("You can only upload 5 images");
    }
    let dummy = [];
    for (let index = 0; index < images.length; index++) {
      const element = images[index];
      dummy.push({ location: URL.createObjectURL(element), key: element.name });
    }
    //setProductImages(dummy);
    // productImages.push(...dummy);
    try {
      if (images.length > 0 && images.length < 6) {
        setImageLoading(true);
        const responds = await uploadImagesToS3(images);
        if (responds.length > 0) {
          // console.log("length", productImages.length);
          const lengthDiff = 5 - productImages.length;
          // console.log("lengthDiff", lengthDiff);
          if (images.length > lengthDiff) {
            setFilesError("You can only upload 5 images");
            message.error("You can only upload 5 images");
          } else {
            productImages.push(...responds);
            e.target.value = null;
          }
          setImageLoading(false);
        }
      }
    } catch (error) {
      setImageLoading(false);
      message.error("Something went wrong");
      // console.log(
      //   "🚀 ~ file: AddEditModal.js ~ line 19 ~ onSubmit ~ error",
      //   error
      // );
    }
  };
  // const closeModal = () => {
  //   handleClose();
  //   reset();
  //   setProductImages([]);
  // };
  return (
    <Modal className="right-modal" show={show} onHide={handleClose}>
      <Modal.Header closeButton className="border-bottom pb-3">
        <Modal.Title className="m-0 font-weight-bold">
          {product ? "Edit Product" : "Add Products"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit(onSubmit)}>
          <h5 className="text-black font-weight-bold d-flex align-items-center justify-content-between ">
            {" "}
            {product ? "Edit " : "Upload"} Product Photos
            {imageLoading && (
              <span>
                {" "}
                <Spinner
                  className="mx-auto mt-3
                       d-flex "
                  size="sm"
                  animation="border"
                  variant="primary"
                />
              </span>
            )}
          </h5>
          <div className="d-flex overflow-auto flex-wrap add_vehicle_photos">
            {productImages?.map(
              (image, i) =>
                i < 6 && (
                  <PreviewPhoto
                    key={i}
                    register={register}
                    image={image}
                    watchAllFields={watchAllFields}
                    index={i}
                    productImages={productImages}
                    setProductImages={setProductImages}
                    setFilesError={setFilesError}
                    imageLoading={imageLoading}
                    setValue={setValue}
                    setThumbnail={setThumbnail}
                    thumbnail={thumbnail}
                  />
                )
            )}
            {productImages.length < 5 && (
              <label className="addphoto mb-3 mr-4" htmlFor="vehicle_photos">
                <img
                  className="rounded img-fluid cursor-p"
                  alt="..."
                  src={require("assets/img/upload.png").default}
                ></img>
              </label>
            )}
            <input
              className=" w-0 h-0 "
              type="file"
              name="vehicle_photos"
              id="vehicle_photos"
              {...register("vehicle_photos", {
                required: productImages.length > 0 ? false : true,
              })}
              onChange={(e) => {
                handleImagesChange(e.target.files, e);
                if (e.target.files.length > 5) {
                  setProductImages((prev) => []);
                  e.target.value = null;
                }
              }}
              multiple
            />
          </div>
          {filesError && <p className="text-primary">{filesError}</p>}
          {errors?.vehicle_photos?.type === "required" && filesError && (
            <label className="text-danger">
              Please upload at least one image
            </label>
          )}
          <div className="mb-3">
            <label className="font-weight-bold">Product Title</label>
            <input
              className="form-control"
              type="text"
              placeholder="Product title"
              name="product_title"
              defaultValue={product?.title}
              maxLength="50"
              {...register("product_title", { required: true })}
            />
            {errors?.product_title?.type === "required" && (
              <label className="text-danger">Product title is required</label>
            )}
          </div>
          <div className="mb-3">
            <label className="font-weight-bold">Brand Name</label>
            <input
              className="form-control"
              type="text"
              placeholder="Brand Name"
              name="brand"
              maxLength="50"
              defaultValue={product?.brand}
              {...register("brand", { required: true })}
            />
            {errors?.brand?.type === "required" && (
              <label className="text-danger">Brand is required</label>
            )}
          </div>
          <Row>
            <Col md="6">
              <div className="mb-3">
                <label className="font-weight-bold">Unit Price</label>
                <input
                  className="form-control"
                  type="number"
                  placeholder="$ 00"
                  name="unit_price"
                  min="1"
                  step=".01"
                  max={999999}
                  defaultValue={product?.unit_price}
                  {...register("unit_price", { required: true })}
                />
                {errors?.unit_price?.type === "required" && (
                  <label className="text-danger">Unit price is required</label>
                )}
              </div>
            </Col>
            <Col md="6">
              <div className="mb-3">
                <label className="font-weight-bold">Stocks</label>
                <input
                  className="form-control"
                  type="number"
                  placeholder="00"
                  name="stock"
                  min="0"
                  max={999999}
                  defaultValue={product?.stock}
                  {...register("stock", { required: true })}
                />
                {errors?.stock?.type === "required" && (
                  <label className="text-danger">Stocks is required</label>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <div className="mb-3">
                <label className="font-weight-bold">
                  Height <span className="opacity">(Inches)</span>
                </label>
                <input
                  className="form-control"
                  type="number"
                  placeholder="0"
                  name="height"
                  step="0.01"
                  defaultValue={product?.height}
                  min="1"
                  max={25}
                  {...register("height", { required: true })}
                />
                {errors?.height?.type === "required" && (
                  <label className="text-danger">Height is required</label>
                )}
              </div>
            </Col>
            <Col md="6">
              <div className="mb-3">
                <label className="font-weight-bold">
                  Weight <span className="opacity">(Pounds)</span>
                </label>
                <input
                  className="form-control"
                  type="number"
                  placeholder="0"
                  name="weight"
                  min="1"
                  step="0.01"
                  max={1000}
                  defaultValue={product?.weight}
                  {...register("weight", { required: true })}
                />
                {errors?.weight?.type === "required" && (
                  <label className="text-danger">Weight is required</label>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <div className="mb-3">
                <label className="font-weight-bold">
                  Width <span className="opacity">(Inches)</span>
                </label>
                <input
                  className="form-control"
                  type="number"
                  placeholder="0"
                  name="width"
                  min="1"
                  step="0.01"
                  max={25}
                  defaultValue={product?.width}
                  {...register("width", { required: true })}
                />
                {errors?.width?.type === "required" && (
                  <label className="text-danger">Width is required</label>
                )}
              </div>
            </Col>
            <Col md="6">
              <div className="mb-3">
                <label className="font-weight-bold">
                  Length <span className="opacity">(Inches)</span>
                </label>
                <input
                  className="form-control"
                  type="number"
                  placeholder="0"
                  name="length"
                  min="1"
                  step="0.01"
                  max={30}
                  defaultValue={product?.length}
                  {...register("length", { required: true })}
                />
                {errors?.length?.type === "required" && (
                  <label className="text-danger">Length is required</label>
                )}
              </div>
            </Col>
          </Row>
          <div className="mb-3">
            <label className="font-weight-bold">Product Description</label>
            <textarea
              placeholder="Product Description"
              className="form-control"
              defaultValue={product?.description}
              name=""
              id=""
              rows="5"
              maxLength="500"
              {...register("description", { required: true })}
            ></textarea>
            {errors?.description?.type === "required" && (
              <label className="text-danger">Description is required</label>
            )}
          </div>
          <div className="mb-3">
            {loading && (
              <Spinner
                className="mx-auto mt-3
                     d-flex"
                size="sm"
                animation="border"
                variant="primary"
              />
            )}
          </div>
          <input
            className="btn btn-primary btn-lg w-100 mt-3"
            type="submit"
            value={product ? "Save changes " : "Save & Publish"}
            //disabled={filesError && errors}
          />
        </form>
      </Modal.Body>
    </Modal>
  );
}
const PreviewPhoto = ({
  image,
  index,
  productImages,
  setProductImages,
  setFilesError,
  imageLoading,
  setValue,
  thumbnail,
  setThumbnail,
}) => {
  return (
    <div
      className={`addphoto btn p-0 mb-3 mr-4 ${imageLoading && " uploading"}`}
    >
      {" "}
      {!imageLoading && (
        <div
          className="close-b "
          onClick={() =>
            removeImage(
              setProductImages,
              productImages,
              image.key,
              setFilesError,
              setValue,
              setThumbnail,
              thumbnail,
              index
            )
          }
        >
          <img
            className=""
            alt="..."
            src={require("assets/img/close.svg").default}
          ></img>
        </div>
      )}
      <label
        htmlFor={image.key}
        onClick={() => {
          setThumbnail(index);
        }}
      >
        <img
          className="rounded img-fluid skeleton"
          alt="..."
          src={image.location}
        ></img>
        {thumbnail === index ? (
          <div className="mark-sec">
            <div className="d-flex align-items-center">
              <img
                className="mr-1"
                alt="..."
                src={require("assets/img/mark.svg").default}
              ></img>
              <h6 className="m-0">Mark as thumb</h6>
            </div>
          </div>
        ) : null}
      </label>
    </div>
  );
};
const removeImage = (
  setProductImages,
  productImages,
  image,
  setFilesError,
  setValue,
  setThumbnail,
  thumbnail,
  index
) => {
  if (index === thumbnail) {
    setThumbnail(0);
  }
  const dummy = productImages.filter((item) => item.key !== image);
  setProductImages(dummy);
  if (dummy.length === 0) {
    setValue("vehicle_photos", "");
    setProductImages([]);
    setFilesError(true);
    message.error("At least 1 image is required.");
  }
  if (dummy.length < 0) {
    setFilesError(true);
  }
  if (dummy.length > 0) {
    setFilesError(false);
  }

  if (dummy.length > 5) {
    setFilesError("You can only upload 5 images");
    message.error("You can only upload 5 images");
  }
};

export default AddEditModal;
