import Skeleton from "components/Rides/Detail/Skeleton";
import React, { useEffect, useState } from "react";
import { Button, Card, Container, Row, Col } from "react-bootstrap";
import Header from "components/Rides/Detail/Header";
import Content from "components/Rides/Detail/Content";
import TrackMap from "components/Rides/Detail/TrackMap";
import ScheduleRideModal from "components/Rides/ScheduleRideModal";
import { useGetRideByIdQuery } from "services/rides";
import { useParams } from "react-router";

import moment from "moment";
function Detail() {
  const { id } = useParams();
  const { isLoading, isError, isSuccess, data, status } = useGetRideByIdQuery(
    id,
    {
      refetchOnMountOrArgChange: true,
    }
  );
  console.log(data?.data?.ride?.vehicle?.id);
  const [schedule, setSchedule] = useState({
    ride_date:
      data?.data?.ride?.ride_date || new Date().toISOString().slice(0, 10),
    vehicle_id: data?.data?.ride?.vehicle?.id || "",
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    freeslot: data?.data?.ride?.duration || 0,
    start_at: moment(data?.data?.ride?.start_at, "hh").format("LT") || "",
    id: data?.data?.ride?.id,
  });
  useEffect(() => {
    if (data?.data?.ride) {
      setSchedule({
        ride_date:
          data?.data?.ride?.ride_date || new Date().toISOString().slice(0, 10),
        vehicle_id: data?.data?.ride?.vehicle?.id,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        freeslot: data?.data?.ride?.duration ,
        start_at: moment(data?.data?.ride?.start_at, "hh").format("LT") || "",
        id: data?.data?.ride?.id,
      });
    }
  }, [data]);

  const [track, setTrack] = useState(false);
  const [showScheduleRideModal, setShowScheduleRideModal] = useState(false);
  const handleCloseScheduleRideModal = () => setShowScheduleRideModal(false);
  const handleShowScheduleRideModal = () => setShowScheduleRideModal(true);
  return (
    <>
      {isLoading && <Skeleton />}
      {isSuccess && (
        <>
          {data?.data?.ride && (
            <ScheduleRideModal
              show={showScheduleRideModal}
              handleClose={handleCloseScheduleRideModal}
              schedule={schedule}
              setSchedule={setSchedule}
              type={"update"}
            />
          )}
          <Container fluid>
            <Header track={track} setTrack={setTrack} ride={data?.data?.ride} />
            {!track && (
              <Content
                setTrack={setTrack}
                ride={data?.data?.ride}
                handleShowScheduleRideModal={handleShowScheduleRideModal}
              />
            )}
            {track && <TrackMap ride={data?.data?.ride} />}
          </Container>
        </>
      )}
    </>
  );
}

export default Detail;
