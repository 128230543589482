import React from "react";
import { Row, Col } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import  moment from 'moment';
import { checkStyle } from './ListCard';

function OrderdItemsCard({ item }) {
  return (
    <Row className="bg-white  py-3 align-items-center mb-4 rounded">
      <Col md="3">
        <LazyLoadImage
          className="img-fluid rounded"
          effect="blur"
          src={item?.product?.product_image[0]?.image}
        />
      </Col>
      <Col md="9">
        <h4 className="m-0 pb-1 font-weight-bold">{item?.product?.title}</h4>
        <h5 className="secondary-gray m-0 pb-1">{item?.product?.brand}</h5>
        <h5 className="secondary-gray m-0 pb-1">
          Qty - <strong> {item?.qty}</strong>
        </h5>
        <h5 className="secondary-gray m-0 pb-1">
          Unit Price -
          <strong className="font-weight-bold text-black">
            {" "}
            ${item?.amount}
          </strong>
          {item?.status != "Delivered" && (
            <>
              <span className={`ml-2 text- ${checkStyle(item?.status)}`}><span></span> {item?.status} - </span>
              <strong className="text-primary">
                <a
                  className="text-primary"
                  href={item?.public_url}
                  target="_blank"
                >
                  {item?.tracking_id}
                </a>
              </strong>
            </>
          )}
          {item?.status === "Delivered" && (
            <strong className=" ml-2 delivered">
              <span></span> Delivered <b className="text-gray">on {moment(item?.last_updated).format('MM/DD/YY')}</b>
            </strong>
          )}
        </h5>
      </Col>
    </Row>
  );
}

export default OrderdItemsCard;
