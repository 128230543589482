import React, { useState, useEffect, useRef } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import Geocode from "react-geocode";
import { useForm } from "react-hook-form";
import { AutoComplete } from "./autoComplete";

Geocode.setApiKey("AIzaSyDIj0QBVIMnAxlKJf4WbGcISWXPvchSj-Y");
Geocode.setLanguage("en");
Geocode.setRegion("es");
Geocode.setLocationType("ROOFTOP");
Geocode.enableDebug();
import {
  GoogleMap,
  LoadScriptNext,
  Marker,
  DirectionsRenderer,
  Polyline,
} from "@react-google-maps/api";
import { useUpdatePickupLocationMutation } from "services/pickupLocations";
import { message } from "antd";

function EditLocationModal({ handleClose, show, editData }) {
  const [locationData, setLocationData] = useState({
    location: editData.location,
  });
  const [Loading, setLoading] = useState(false);
  const [locationText, setLocationText] = useState("unites states");
  const [location, setLocation] = useState({
    lat: parseFloat(editData?.latitude),
    lng: parseFloat(editData?.longitude),
  });
  const [updatePickupLocation] = useUpdatePickupLocationMutation();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm();
  const onSubmit = async (upData) => {
    if (!locationData?.location) {
      setLocationData({ ...locationData, error: true });
    } else {
      setLoading(true);
      const data = {
        ...upData,
        latitude: location.lat,
        longitude: location.lng,
        location: locationData?.location,
      };
      const updateLocation = await updatePickupLocation({
        data,
        id: editData.id,
      });
      if (updateLocation?.data?.message === "Updated") {
        setLoading(false);
        message.success("pickup location updated successfully");
        handleClose();
        reset();
      } else {
        setLoading(false);
        message.error(updateLocation?.data?.message || "Something went wrong");
        reset();
        // handleClose();
      }
    }
  };

  useEffect(() => {
    setLocation({
      lat: parseFloat(editData?.latitude),
      lng: parseFloat(editData?.longitude),
    });
    if (locationText) {
      Geocode.fromAddress(locationText).then(
        (response) => {
          const { lat, lng } = response.results[0].geometry.location;
          setLocation({
            lat: parseFloat(editData?.latitude),
            lng: parseFloat(editData?.longitude),
          });
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }, []);

  const containerStyle = {
    width: "100%",
    height: "50vh",
  };
  const handleClick = (locationText) => {
    Geocode.fromAddress(locationText).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        console.log(lat, lng);
        setLocation({ lat: lat, lng: lng });
      },
      (error) => {
        console.error(error);
      }
    );
  };
  return (
    <Modal className="right-modal" show={show} onHide={handleClose}>
      <Modal.Header closeButton className="border-bottom pb-3">
        <Modal.Title className="m-0 font-weight-bold">
          Edit Pickup Locations
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-3" controlId="formBasicEmail">
            <label className="font-weight-bold">Location Title</label>
            <input
              type="text"
              placeholder="Type Location Title"
              className="form-control"
              name="title"
              {...register("title", {
                required: true,
                maxLength: 120,
              })}
              defaultValue={editData?.title}
            />
            {errors.title && (
              <span className="text-danger">
                {errors.title.type === "required" &&
                  "Location title is required"}
                {errors.title.type === "maxLength" &&
                  "Location title should be less than 120 characters"}
              </span>
            )}
          </div>
          <AutoComplete
            locationData={locationData}
            setLocationData={setLocationData}
            setLocation={setLocation}
            setValue={setValue}
          />
          <input
            type="text"
            placeholder="Type Location Title"
            className="form-control d-none"
            name="locationDummy"
            {...register("locationDummy", {
              required: true,
              maxLength: 120,
            })}
            defaultValue={editData?.location}
          />
          {errors.locationDummy && (
            <span className="text-danger">
              {errors.locationDummy.type === "required" &&
                "Location is required"}{" "}
            </span>
          )}
          <div className="border rounded">
            <LoadScriptNext googleMapsApiKey="AIzaSyDIj0QBVIMnAxlKJf4WbGcISWXPvchSj-Y">
              <GoogleMap
                mapContainerStyle={containerStyle}
                center={location}
                zoom={15}
              >
                <Marker
                  icon={require("assets/img/mark.png").default}
                  position={location}
                  animation={google?.maps?.Animation?.DROP}
                  draggable={true}
                  onDragEnd={(e) => {
                    setLocation({ lat: e.latLng.lat(), lng: e.latLng.lng() });
                  }}
                />
              </GoogleMap>
            </LoadScriptNext>
          </div>
          {Loading && (
            <Spinner
              className="mx-auto mt-3 d-flex"
              size="sm"
              animation="border"
              variant="primary"
            />
          )}
          <Button
            variant="primary"
            type="submit"
            className="btn btn-primary btn-lg w-100 mt-3"
          >
            Update
          </Button>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default EditLocationModal;
