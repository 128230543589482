import { configureStore } from "@reduxjs/toolkit";
import authSlice from "features/auth/authSlice";
import { setupListeners } from "@reduxjs/toolkit/query";
import { driverApi } from "services/drivers";
import { productApi } from "services/products";
import { orderApi } from "services/orders";
import { rideApis } from "services/rides";
import { couponApi } from "services/coupon";
import { vehicleApi } from "services/vehicles";
import { peakDayApi } from "services/peakDays";
import { specialBookingApi } from "services/specialBookings";
import { pickupLocationApi } from "services/pickupLocations";
import { dashboardApi } from "services/dashboard";
import { CustomersApi } from "services/customers";
import { notificationApi } from "services/notification";
import { passwordConformationApi } from "services/passwordConformation";
import { paymentReportApi } from "services/paymentReport";
import { settingsApis } from "services/settings";
export const store = configureStore({
  reducer: {
    auth: authSlice,
    [driverApi.reducerPath]: driverApi.reducer,
    [productApi.reducerPath]: productApi.reducer,
    [orderApi.reducerPath]: orderApi.reducer,
    [rideApis.reducerPath]: rideApis.reducer,
    [couponApi.reducerPath]: couponApi.reducer,
    [vehicleApi.reducerPath]: vehicleApi.reducer,
    [peakDayApi.reducerPath]: peakDayApi.reducer,
    [specialBookingApi.reducerPath]: specialBookingApi.reducer,
    [pickupLocationApi.reducerPath]: pickupLocationApi.reducer,
    [dashboardApi.reducerPath]: dashboardApi.reducer,
    [CustomersApi.reducerPath]: CustomersApi.reducer,
    [notificationApi.reducerPath]: notificationApi.reducer,
    [passwordConformationApi.reducerPath]: passwordConformationApi.reducer,
    [paymentReportApi.reducerPath]: paymentReportApi.reducer,
    [settingsApis.reducerPath]: settingsApis.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(driverApi.middleware)
      .concat(productApi.middleware)
      .concat(orderApi.middleware)
      .concat(rideApis.middleware)
      .concat(couponApi.middleware)
      .concat(vehicleApi.middleware)
      .concat(peakDayApi.middleware)
      .concat(specialBookingApi.middleware)
      .concat(pickupLocationApi.middleware)
      .concat(dashboardApi.middleware)
      .concat(CustomersApi.middleware)
      .concat(notificationApi.middleware)
      .concat(passwordConformationApi.middleware)
      .concat(paymentReportApi.middleware)
      .concat(settingsApis.middleware),
});
setupListeners(store.dispatch);
