import React from "react";
import moment from "moment";
import { Avatar, Image } from "antd";

function MilageCard({ type, milage, index }) {
  return type === "Skeleton" ? (
    <tr>
      <td>
        <span className="skeleton">1</span>
      </td>
      <td>
        <h5 className="m-0">
          <span className="skeleton">07/15/1982</span>
        </h5>
      </td>
      <td>
        <div className="d-flex align-items-center">
          <div className="thumbnail-round mr-3 skeleton"></div>
          <h5 className="m-0">
            <b className="skeleton">Shawn Tom</b>
          </h5>
        </div>
      </td>
      <td>
        <h5 className="m-0 ">
          <span className="skeleton">35265</span>{" "}
          <span className="text-gray ml-3 skeleton">8:00 pm</span>{" "}
        </h5>
      </td>
      <td>
        <h5 className="m-0 ">
          <span className="skeleton">35322</span>{" "}
          <span className="skeleton text-gray ml-3">11:00 pm</span>{" "}
        </h5>
      </td>
      <td>
        <h5 className="m-0 ">
          <span className="skeleton">50</span>
        </h5>
      </td>
    </tr>
  ) : (
    <tr>
      <td>{index}</td>
      <td>
        <h5 className="m-0">
          {moment(milage?.created_at).format("MM/DD/YY")}
        </h5>
      </td>
      <td>
        <div className="d-flex align-items-center">
          <div className="thumbnail-round mr-3">
            <Avatar
              shape="square"
              className="w-100 h-100"
              src={
                <Image
                  src={
                    milage?.driver?.image ||
                    `https://joeschmoe.io/api/v1/${milage?.driver?.full_name}`
                  }
                />
              }
            />
          </div>
          <h5 className="m-0">
            <b>{milage?.driver?.full_name}</b>
          </h5>
        </div>
      </td>
      <td>
        <h5 className="m-0 ">
          <span>{milage?.start_mileage}</span>{" "}
          <span className="text-gray ml-3">
            {" "}
            {milage?.start_time ? moment(milage?.start_time).format("h:mm a") : ""}
          </span>{" "}
        </h5>
      </td>
      <td>
        <h5 className="m-0 ">
          <span>{milage?.end_mileage}</span>{" "}
          <span className="text-gray ml-3">
            {" "}
            {milage?.end_time ? moment(milage?.end_time).format("h:mm a") : ""}
          </span>{" "}
        </h5>
      </td>
      <td>
        <h5 className="m-0 ">{milage?.total}</h5>
      </td>
    </tr>
  );
}

export default MilageCard;
