import React, { useEffect, useState } from "react";
import { Card, Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import VehicleCards from "components/Vehicles/VehicleCards";
import Skeleton from "components/Vehicles/Skeleton";
import AvailabilityModal from "components/Vehicles/AvailabilityModal";
import MetaTitle from "components/Meta";
import { useGetAllVehiclesQuery } from "services/vehicles";

function Vehicles() {
  // initial set up for Item
  const [populate, setPopulate] = useState([
    "vehicle_image",
    "vehicle_availability",
  ]);
  const [filter, setFilter] = useState("");
  const [sort, setSort] = useState({
    sortBy: "created_at",
    sortDirection: "desc",
  });
  const [PageSize, setPageSize] = useState(100); // default page size change value in query also
  const [page, setPageNumber] = useState(0);
  // initial set up for Item END
  const { isLoading, isError, isSuccess, data, status } =
    useGetAllVehiclesQuery(
      { page, PageSize, sort, filter, populate },
      {
        refetchOnMountOrArgChange: true,
      }
    );
  const [show, setShow] = useState(false);
  const [modal, setModal] = useState({ type: "view", modalData: [] });
  const handleClose = () => setShow(false);
  const handleShow = (data) => {
    setModal((prev) => (prev.modalData = data));
    setShow(true);
  };
  return (
    <>
      <MetaTitle text="Vehicles" />
      <Container fluid>
        <Card className="mb-3">
          <Card.Body>
            <Row>
              <Col col="12" md="8">
                <h3 className="m-0 py-2 font-weight-bold">Vehicles</h3>
              </Col>
              <Col col="12" md="4" xl="2" className="ml-auto">
                <Link
                  to={"/admin/vehicles/add-new-vehicle"}
                  className="btn w-100 btn-primary"
                >
                  Add New Vehicle
                </Link>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        {show && (
          <AvailabilityModal
            handleClose={handleClose}
            show={show}
            modal={modal}
            setModal={setModal}
          />
        )}
        <Row>
          {/* {isLoading && [...Array(10)].map((d, i) => <Skeleton key={i} />)} */}
          {status === "pending" &&
            [...Array(PageSize)].map((d, i) => <Skeleton key={i} />)}
          {isError && <p>Error </p>}
          {isSuccess &&
            status === "fulfilled" &&
            data?.data?.vehicles.map((vehicle, i) => (
              <VehicleCards
                key={i}
                vehicle={vehicle}
                index={page * PageSize + i + 1}
                handleShow={handleShow}
                status={vehicle?.active ? "active" : "inactive"}
              />
            ))}
          {data?.data?.vehicles.length === 0 && (
            <tr>
              <td colspan="10" className="empty_items">
                <Empty description={false} image={Empty.PRESENTED_IMAGE_SIMPLE}>
                  <div className="ant-empty-description">
                    <p>No Vehicles Found</p>
                  </div>
                </Empty>
              </td>
            </tr>
          )}
        </Row>
      </Container>
    </>
  );
}

export default Vehicles;
