import React from "react";
import { Card, Table, Container, Row, Col, Form, Modal } from "react-bootstrap";
import Skeleton from "components/Drivers/Skeleton";

function DriverDetailSkeleton() {
  return (
    <Container fluid>
      <Card className="rounded-0 rounded-top border-0 mb-0">
        <Card.Body className="border-bottom py-0">
          <Row className="align-items-center border-bottom py-3">
            <Col col="12" lg="6">
              <Row className="align-items-center">
                <Col col="12" lg="3">
                  <div className="thumbnail-round-lg m-md-auto skeleton"></div>
                </Col>
                <Col col="12" lg="9">
                  <h2 className="font-weight-bold my-1 text-truncate">
                    <span className="skeleton"> Matthew Hawkins</span>
                  </h2>
                  <div className="mb-3">
                    <div className="d-flex flex-column flex-md-row pb-1 align-items-md-center">
                      <h6 className="text- mr-3">
                        <span className="text-primary skeleton">
                          {" "}
                          Driver ID -123125
                        </span>{" "}
                      </h6>
                      <div className="text-active">
                        <b className="skeleton p-0 m-0">Active</b>
                      </div>
                    </div>
                    <b className="text-gray skeleton">
                      Date of join - 02/15/2019
                    </b>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col col="12" lg="6">
              <Row className="align-items-center">
                <Col col="12" lg="6" className="mb-3 mb-md-0">
                  <div>
                    <b className="skeleton">
                      <span className="text-gray skeleton">Phone </span>+1 99856
                      25642
                    </b>
                    <b className="skeleton">
                      <span className="text-gray skeleton ">Email </span>
                      shawntom@example.com
                    </b>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col col="12" md="6" xl="3" className="my-2 my-md-0">
              <Row className="align-items-center">
                <Col xs="3" md="4">
                  <div className="text-center thumbnail-round-sm  d-flex align-items-center justify-content-center py-2">
                    <div className="skeleton thumbnail-round-md">
                      <img
                        className="img-fluid opacity-0"
                        alt="..."
                        src={require("assets/img/rides.svg").default}
                      ></img>
                    </div>
                  </div>
                </Col>
                <Col xs="9" md="8" className="pl-0">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="">
                      <h2 className="font-weight-bold m-0">
                        <span className="skeleton">56</span>
                      </h2>
                      <h5 className="font-weight-bold text-uppercase m-0">
                        <span className="skeleton"> TOTAL RIDES</span>
                      </h5>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col col="12" md="6" xl="3" className="border-right my-2 my-md-0">
              <Row className="align-items-center">
                <Col xs="3" md="4">
                  <div className="text-center thumbnail-round-sm  d-flex align-items-center justify-content-center py-2">
                    <div className="skeleton thumbnail-round-md">
                      <img
                        className="img-fluid opacity-0"
                        alt="..."
                        src={require("assets/img/rides.svg").default}
                      ></img>
                    </div>
                  </div>
                </Col>
                <Col xs="9" md="8" className="pl-0">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="">
                      <h2 className="font-weight-bold m-0">
                        <span className="skeleton">56</span>
                      </h2>
                      <h5 className="font-weight-bold text-uppercase m-0">
                        <span className="skeleton"> TOTAL RIDES</span>
                      </h5>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col col="12" md="6" xl="3" className="my-2 my-md-0">
              <Row className="align-items-center">
                <Col xs="3" md="3">
                  <div className="text-center d-flex align-items-center  skeleton justify-content-end py-2">
                    <img
                      className="img-fluid opacity-0"
                      alt="..."
                      src={require("assets/img/file.svg").default}
                    ></img>
                  </div>
                </Col>
                <Col xs="9" md="8" className="pl-0">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="pl-2">
                      <h5 className="font-weight-bold m-0 pb-2">
                        <span className="skeleton"> Driving License</span>
                      </h5>
                      <h5 className="font-weight-bold m-0 text-primary">
                        <span className="skeleton"> Driving License</span>
                      </h5>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col col="12" md="6" xl="3" className="my-2 my-md-0">
              <Row className="align-items-center">
                <Col xs="3" md="3">
                  <div className="text-center d-flex align-items-center  skeleton justify-content-end py-2">
                    <img
                      className="img-fluid opacity-0"
                      alt="..."
                      src={require("assets/img/file.svg").default}
                    ></img>
                  </div>
                </Col>
                <Col xs="9" md="8" className="pl-0">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="pl-2">
                      <h5 className="font-weight-bold m-0 pb-2">
                        <span className="skeleton"> Driving License</span>
                      </h5>
                      <h5 className="font-weight-bold m-0 text-primary">
                        <span className="skeleton"> Driving License</span>
                      </h5>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <div className="bg-white mt-2">
        <div className="table-full-width table-responsive">
          <Table className="table-hover revup-table m-0">
            <thead>
              <tr>
                <th className="border-0 x">
                  <span className="skeleton">#</span>
                </th>
                <th className="border-0">
                  <span className="skeleton">Order ID</span>
                </th>
                <th className="border-0">
                  <span className="skeleton">Customer</span>
                </th>
                <th className="border-0">
                  <span className="skeleton"> Start Date</span>
                </th>
                <th className="border-0">
                  <span className="skeleton"> End Date</span>
                </th>
                <th className="border-0">
                  <span className="skeleton">Duration</span>
                </th>
                <th className="border-0">
                  {" "}
                  <span className="skeleton">Pickup Location</span>
                </th>
                <th className="border-0">
                  {" "}
                  <span className="skeleton"> Tips Earned</span>
                </th>
                <th className="border-0">
                  {" "}
                  <span className="skeleton"> Rating</span>
                </th>
                <th className="border-0">
                  {" "}
                  <span className="skeleton">Ride Status</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {[...Array(10)].map((d, i) => (
                <Skeleton key={i} />
              ))}

            </tbody>
          </Table>
        </div>
      </div>
    </Container>
  );
}

export default DriverDetailSkeleton;
