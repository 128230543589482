import React, { useEffect } from "react";
import { useState } from "react";
import { Table, Row, Col, Form, Modal, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import moment from "moment";
import AvailabilityData from "./AvailabilityData";
import { TimePicker, message } from "antd";
import {
  useAddAvailabilityMutation,
  useUpdateAvailabilityMutation,
} from "services/vehicles";
function AvailabilityModal({ show, handleClose, modal, setModal }) {
  const [loading, setLoading] = useState(false);
  const [addAvailability] = useAddAvailabilityMutation();
  const [updateAvailability] = useUpdateAvailabilityMutation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm();

  let vehicle = modal?.modalData;
  const thumbnail = vehicle?.vehicle_image?.filter((img) => {
    return img?.thumbnail === true;
  });
  const presetDate = AvailabilityData();
  const [availability, setAvailability] = useState(
    (vehicle?.vehicle_availability?.length > 0 &&
      vehicle?.vehicle_availability) ||
      presetDate
  );
  useEffect(() => {
    setAvailability(
      (vehicle?.vehicle_availability?.length > 0 &&
        vehicle?.vehicle_availability) ||
        presetDate
    );
  }, [vehicle]);

  const onSubmit = async (data) => {
    setLoading(true);

    let upData = {
      vehicle_id: parseInt(vehicle?.id),
      rate: data.rate,
      vehicle_availabilities: availability,
    };
    if (vehicle?.vehicle_availability?.length > 0) {
      const editResp = await updateAvailability(upData);
      if (editResp?.data?.message === "Created") {
        setLoading(false);
        message.success("Availability Updated");
        handleClose();
      } else {
        setLoading(false);
        editResp?.data?.message
          ? message.error(addAvailabilityRes?.data?.message)
          : message.error("Something went wrong");
        // handleClose();
      }
    } else {
      const addAvailabilityRes = await addAvailability(upData);
      if (addAvailabilityRes?.data?.message === "Created") {
        setLoading(false);
        message.success("Availability Updated");
        handleClose();
      } else {
        setLoading(false);
        addAvailabilityRes?.data?.message
          ? message.error(addAvailabilityRes?.data?.message)
          : message.error("Something went wrong");
        // handleClose();
      }
    }
  };
  const [inputType, setInputType] = useState("text");
  return (
    <Modal className="right-modal" show={show} onHide={handleClose}>
      <Modal.Header closeButton className="border-bottom pb-3">
        <Modal.Title className="m-0 font-weight-bold">
          {vehicle?.vehicle_availability?.length > 0 ? "Edit" : "Add"}{" "}
          Availability & Rate
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="align-items-center mb-4">
          <Col md="3">
            {thumbnail?.length > 0 && (
              <img
                className="img-fluid rounded"
                src={
                  thumbnail[0]?.image ||
                  "https://via.placeholder.com/350x350?text=404"
                }
              />
            )}
          </Col>
          <Col md="9">
            <h5 className="secondary-gray m-0">{vehicle?.plate_number}</h5>
            <h3 className="m-0 pb-2 font-weight-bold">{vehicle?.name}</h3>
          </Col>
        </Row>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-default border-bottom">
            <label className="secondary-gray mb-0">Rates per hour</label>
            {inputType === "text" && (
              <input
                className="form-control"
                type="text"
                placeholder="$ 00"
                defaultValue={"$" + (vehicle?.rate || "00") + "/ Hour"}
                disabled={modal.type === "view"}
                onClick={() => {
                  setInputType("number");
                }}
              />
            )}
            {inputType === "number" && (
              <input
                className="form-control"
                type="number"
                placeholder="$ 00"
                defaultValue={vehicle?.rate}
                min="1"
                step=".01"
                {...register("rate", {
                  required: vehicle?.rate ? false : true,
                })}
                disabled={modal.type === "view"}
              />
            )}

            {errors?.rate?.type === "required" && (
              <span className="text-danger">Rate is required</span>
            )}
          </div>
          <h5 className="font-weight-bold pt-4">Availability</h5>
          {modal.type === "view" &&
            vehicle?.vehicle_availability?.length > 0 &&
            vehicle?.vehicle_availability?.map((availability, i) => (
              <ViewFormData availability={availability} key={i} index={i} />
            ))}
          {modal.type === "edit" && (
            <EditFormData
              register={register}
              setValue={setValue}
              errors={errors}
              presetDate={presetDate}
              setAvailability={setAvailability}
              getValues={getValues}
              availability={availability}
            />
          )}
          {modal.type === "edit" && (
            <>
              {loading && (
                <Spinner
                  className="mx-auto mt-3
                   d-flex"
                  size="sm"
                  animation="border"
                  variant="primary"
                />
              )}
              <input
                className="btn btn-primary btn-lg w-100 mt-3"
                type="submit"
                value={"Done"}
              />
            </>
          )}
          {modal.type === "view" && (
            <button
              className="btn btn-outline-primary btn-lg w-100 mt-3"
              onClick={() =>
                setModal({
                  type: "edit",
                  modalData: vehicle,
                })
              }
            >
              Edit Availability & Rate
            </button>
          )}
        </form>
      </Modal.Body>
    </Modal>
  );
}
const ViewFormData = ({ availability, index }) => {
  return (
    <Table className="mb-2">
      {index === 0 && (
        <tr className="d-flex align-items-center  rounded mb-1">
          <th className="border-0 mr-5 text-gray">DAY</th>
          <th className="border-0 w-100 text-gray">FROM</th>
          <th className="border-0 w-100 text-gray">TO</th>
        </tr>
      )}
      <tr
        className={
          !availability?.active
            ? " opacity d-flex align-items-center border rounded mb-0"
            : "d-flex align-items-center border rounded mb-0"
        }
      >
        <th
          className="border-0 mr-4"
          style={{ textTransform: "uppercase", width: "150px" }}
        >
          {availability.day_of_week.slice(0, 3)}
        </th>

        <th className="border-0 w-100">
          {moment(availability.start_at, "HH:mm:ss").format("hh:mm A")}
        </th>
        <th className="border-0 w-100">
          {moment(availability.end_at, "HH:mm:ss").format("hh:mm A")}
        </th>
      </tr>
    </Table>
  );
};
const EditFormData = ({
  register,
  setValue,
  errors,
  presetDate,
  setAvailability,
  getValues,
  availability,
}) => {
  return (
    <Table>
      <tr className="d-flex align-items-center  rounded mb-1">
        <th className="border-0 mr-5 text-gray"></th>
        <th className="border-0 mr-5 text-gray"></th>
        <th className="border-0 w-100 text-gray">FROM</th>
        <th className="border-0 w-100 text-gray">TO</th>
      </tr>

      {availability?.map((preset, i) => (
        <DateInput
          key={i}
          register={register}
          day={preset.day_of_week}
          setValue={setValue}
          errors={errors}
          preset={preset}
          setAvailability={setAvailability}
          getValues={getValues}
        />
      ))}
    </Table>
  );
};

const DateInput = ({
  register,
  day,
  setValue,
  errors,
  preset,
  setAvailability,
  getValues,
}) => {
  const [status, setStatus] = useState(true);
  return (
    <tr
      className={
        !preset.active
          ? "d-flex align-items-center opacity"
          : "d-flex align-items-center"
      }
    >
      <td className="border-0">
        <Form.Check
          type="switch"
          id={`custom-switch-${day}`}
          onChange={(e) => {
            setAvailability((prev) => {
              return prev.map((availability) => {
                if (availability.day_of_week === day) {
                  return { ...availability, active: e.target.checked };
                } else {
                  return availability;
                }
              });
            });
            if (e.target.checked) {
              setStatus(false);
            } else {
              setStatus(true);
            }
          }}
          checked={preset?.active}
        />
      </td>
      <td className="border-0">
        <h5
          className="m-0 font-weight-bold"
          style={{ textTransform: "uppercase" }}
        >
          {day.slice(0, 3)}
        </h5>
      </td>
      <td className="border-0 form-default">
        <TimePicker
          use12Hours
          format="h:00 a"
          className="form-control"
          defaultValue={moment(preset?.start_at, "HH:00:ss")}
          onChange={(e, date) =>
            setAvailability((prev) => {
              return prev.map((availability) => {
                if (availability.day_of_week === day) {
                  return {
                    ...availability,
                    start_at: moment(date, "h:00 a").format("HH:00:ss"),
                  };
                } else {
                  return availability;
                }
              });
            })
          }
        />
      </td>
      <td className="border-0 form-default">
        <TimePicker
          use12Hours
          format="h:00 a"
          className="form-control"
          defaultValue={moment(preset?.end_at, "HH:00:ss")}
          onChange={(e, date) =>
            setAvailability((prev) => {
              return prev.map((availability) => {
                if (availability.day_of_week === day) {
                  return {
                    ...availability,
                    end_at: moment(date, "h:00 a").format("HH:00:ss"),
                  };
                } else {
                  return availability;
                }
              });
            })
          }
        />
      </td>
    </tr>
  );
};
export default AvailabilityModal;
