import React from "react";

function Skeleton() {
  return (
    <tr>
      <td>
        {" "}
        <span className="skeleton">1</span>
      </td>
      <td>
        <a className="skeleton">#1236589</a>
      </td>
      <td>
        <div className="d-flex align-items-center">
          <div className="thumbnail-round mr-3  skeleton"></div>
          <h5 className="m-0">
            <b className="skeleton">Shawn Tom</b>
          </h5>
        </div>
      </td>
      <td>
        <h5 className="m-0 skeleton">06/01/2021 1:00 pm</h5>
      </td>
      <td>
        <h5 className="m-0 skeleton">06/01/2021 6:00 pm</h5>
      </td>
      <td>
        <h5 className="m-0 skeleton">5:00</h5>
      </td>
      <td>
        <h5 className="m-0 skeleton">World Trade Center</h5>
      </td>
      <td>
        <h5 className="m-0 skeleton font-weight-bold">00</h5>
      </td>
      <td className="">
        <div className="skeleton">
          <i className="fas fa-star"></i>
          <i className="fas fa-star"></i>
          <i className="fas fa-star"></i>
          <i className="fas fa-star"></i>
          <i className="fas fa-star"></i>
        </div>
      </td>
      <td className="text-completed">
        <b className="skeleton">pending</b>
      </td>
    </tr>
  );
}

export default Skeleton;
