import React, { Component } from "react";
import { useLocation, useNavigate } from "react-router";

import { Nav } from "react-bootstrap";

import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userSelector, logoutUser } from "features/auth/authSlice";
import { Popconfirm, message } from "antd";
function Sidebar({ color, image, className, burger }) {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigation = useNavigate();
  async function confirm(e) {
    await dispatch(logoutUser());
    navigation("/");
    message.success("Logout Successfully");
  }

  function cancel(e) {
    message.error("Logout Cancelled");
  }
  return (
    <div
      className={className + " sidebar"}
      data-image={image}
      data-color={color}
    >
      <div
        className="sidebar-background"
        style={{
          backgroundImage: "url(" + image + ")",
        }}
      />
      <div className="sidebar-wrapper">
        <div className="logo d-flex align-items-center justify-content-start">
          <a href="/" className="simple-text logo-mini">
            <div className="logo-img">
              <img
                style={{ opacity: burger ? 1 : 0 }}
                src={require("assets/img/logo.svg").default}
                alt="..."
              />
            </div>
          </a>
        </div>
        <Nav>
          <div className="main-menu">
            <li
              className={
                location.pathname.includes("dashboard") ? "menu_active" : ""
              }
            >
              <Link to={"/admin/dashboard"} className="nav-link">
                <i className="fas fa-tachometer-alt" />
                <p>Dashboard</p>
              </Link>
            </li>
            <li
              className={
                location.pathname.includes("payment-report")
                  ? "menu_active"
                  : ""
              }
            >
              <Link to={"/admin/payment-report"} className="nav-link">
                <i className="fas fa-chart-bar" />
                <p>Payment Report</p>
              </Link>
            </li>
            <li
              className={
                location.pathname.includes("coupon-codes") ? "menu_active" : ""
              }
            >
              <Link to={"/admin/coupon-codes"} className="nav-link">
                <i className="fas fa-percentage" />
                <p>Coupon Codes</p>
              </Link>
            </li>
            <li
              className={
                location.pathname.includes("customers") ? "menu_active" : ""
              }
            >
              <Link to={"/admin/customers"} className="nav-link">
                <i className="fas fa-user" />
                <p>Customers</p>
              </Link>
            </li>
          </div>
          <div className="rides-menu">
            {burger && <h6 className="nav-link mt-3">RIDES</h6>}
            <li
              className={
                location.pathname.includes("rides") ? "menu_active" : ""
              }
            >
              <Link to={"/admin/rides"} className="nav-link">
                <i className="fas  fa-car-side" />
                <p>Rides</p>
              </Link>
            </li>
            <li
              className={
                location.pathname.includes("vehicles") ? "menu_active" : ""
              }
            >
              <Link to={"/admin/vehicles"} className="nav-link">
                <i className="fas  fa-car" />
                <p>Vehicles</p>
              </Link>
            </li>
            <li
              className={
                location.pathname.includes("drivers") ? "menu_active" : ""
              }
            >
              <Link to={"/admin/drivers"} className="nav-link">
                <i className="fas fa-user-circle" />
                <p>Drivers</p>
              </Link>
            </li>
            <li
              className={
                location.pathname.includes("special-booking")
                  ? "menu_active"
                  : ""
              }
            >
              <Link to={"/admin/special-booking"} className="nav-link">
                <i className="fas fa-book-open" />
                <p>Special Booking</p>
              </Link>
            </li>
          </div>
          <div className="shop-menu">
            {burger && <h6 className="nav-link mt-3">SHOP</h6>}
            <li
              className={
                location.pathname.includes("orders") ? "menu_active" : ""
              }
            >
              <Link to={"/admin/orders"} className="nav-link">
                <i className="fas fa-shopping-bag" />
                <p>Orders</p>
              </Link>
            </li>
            <li
              className={
                location.pathname.includes("products") ? "menu_active" : ""
              }
            >
              <Link to={"/admin/products"} className="nav-link">
                <i className="fas fa-cube" />
                <p>Products</p>
              </Link>
            </li>
          </div>
          <div className="settings-menu">
            {burger && <h6 className="nav-link mt-3">SETTINGS</h6>}
            <li
              className={
                location.pathname.includes("pickup-locations")
                  ? "menu_active"
                  : ""
              }
            >
              <Link to={"/admin/pickup-locations"} className="nav-link">
                <i className="fas fa-map-marker-alt" />
                <p>Pickup Locations</p>
              </Link>
            </li>
            <li
              className={
                location.pathname.includes("account-settings")
                  ? "menu_active"
                  : ""
              }
            >
              <Link to={"/admin/account-settings"} className="nav-link">
                <i className="fas fa-user" />
                <p>Account Settings</p>
              </Link>
            </li>
            <li
              className={
                location.pathname.includes("special-booking")
                  ? "menu_active"
                  : ""
              }
            >
              <Popconfirm
                title="Are you sure to logout ?"
                onConfirm={confirm}
                onCancel={cancel}
                okText="logout"
                cancelText="Cancel"
                className="nav-link btn"
                placement="right"
              >
                <i className="fas fa-power-off" />

                <p>LogOut</p>
              </Popconfirm>
            </li>
          </div>
        </Nav>
      </div>
    </div>
  );
}

export default Sidebar;
