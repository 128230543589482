import React, { useState, useEffect } from "react";

// react-bootstrap components
import { Card, Form, Container, Col, Spinner } from "react-bootstrap";

function Loading() {
  const [cardClasses, setCardClasses] = useState("card-hidden");

  useEffect(() => {
    setTimeout(function () {
      setCardClasses("");
    }, 1000);
  });
  return (
    <>
      <div className="full-page section-image" data-color="black">
        <div className="content d-flex align-items-center p-0">
          <Container>
            <Col className="mx-auto" lg="4" md="8">
              <img
                src={require("assets/img/tire.png").default}
                className="loading_tire mx-auto mt-3
                d-flex"
              />
            </Col>
          </Container>
        </div>
        <div
          className="full-page-background"
          style={{
            backgroundImage:
              "url(" +
              require("assets/img/full-screen-image-2.jpg").default +
              ")",
          }}
        ></div>
      </div>
    </>
  );
}

export default Loading;
