import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getToken } from "cookies/token";
import { authCheck } from "./functions/authCheck";

const header = (headers, { getState }) => {
  const token = getToken();
  if (token) {
    headers.set("authorization", `Bearer ${token}`);
  }
  return headers;
};
export const pickupLocationApi = createApi({
  reducerPath: "PickupLocationApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: header,
  }),
  tagTypes: ["PickupLocation"],
  endpoints: (builder) => ({
    getPickupLocationById: builder.query({
      query: ({ id }) => {
        return {
          url: `/pickup_location/${id}`,
          method: "GET",
          validateStatus: (response, result) => authCheck(response, result),
        };
      },
    }),
    getAllPickupLocation: builder.query({
      query: (...data) => {
        const populate = encodeURIComponent(
          JSON.stringify(data[0].populate || "")
        );
        const filter = encodeURIComponent(JSON.stringify(data[0].filter));
        const sort = encodeURIComponent(
          JSON.stringify([[data[0].sort.sortBy, data[0].sort.sortDirection]])
        );
        return {
          url: `/pickup_location?offset=${
            data[0].PageSize * data[0].page
          }&limit=${
            data[0].PageSize
          }&populate=${populate}&sort=${sort}&where=${filter}`,
          method: "GET",
          validateStatus: (response, result) => authCheck(response, result),
        };
      },
      providesTags: ["PickupLocation"],
    }),
    addPickupLocation: builder.mutation({
      query: ({ data, id }) => {
        return {
          url: `/pickup_location`,
          method: "POST",
          body: data,
          validateStatus: (response, result) => authCheck(response, result),
        };
      },
      invalidatesTags: ["PickupLocation"],
    }),
    updatePickupLocation: builder.mutation({
      query: ({ data, id }) => {
        return {
          url: `/pickup_location/${id}`,
          method: "PUT",
          body: data,
          validateStatus: (response, result) => authCheck(response, result),
        };
      },
      invalidatesTags: ["PickupLocation"],
    }),
    deletePickupLocation: builder.mutation({
      query: ({ id }) => {
        return {
          url: `/pickup_location/${id}`,
          method: "DELETE",
          validateStatus: (response, result) => authCheck(response, result),
        };
      },
      invalidatesTags: ["PickupLocation"],
    }),
  }),
});

export const {
  useGetAllPickupLocationQuery,
  useGetPickupLocationByIdQuery,
  useAddPickupLocationMutation,
  useUpdatePickupLocationMutation,
  useDeletePickupLocationMutation,
} = pickupLocationApi;
