import React, { useState, useEffect, useRef } from "react";
import { Modal } from "react-bootstrap";
import DriversCards from "./DriversCards";
import { useGetAllAvailableDriverQuery } from "services/drivers";
import DriversCardsSkeleton from "./DriversCardsSkeleton";
import { CloseCircleOutlined } from "@ant-design/icons";
function AssignDriverModal({
  show,
  handleClose,
  activeRide,
  pickDriver,
  setPickDriver,
}) {
  const searchValue = useRef("");
  const [search, setSearch] = useState("");

  const [active, setActive] = useState({ id: null, active: false });

  const infiniteScrollRef = useRef();
  const [PageSize, setPageSize] = useState(10); // default page size change value in query also
  const [page, setPageNumber] = useState(0); // default page number
  const { isLoading, isError, isSuccess, data, status } =
    useGetAllAvailableDriverQuery(
      { page, PageSize, search, show },
      {
        refetchOnMountOrArgChange: true,
      }
    );
  const onScroll = () => {
    if (infiniteScrollRef?.current) {
      const { scrollTop, scrollHeight, clientHeight } =
        infiniteScrollRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 10) {
        if (data.data.count >= PageSize) {
          setPageSize((prev) => prev + 1);
        }
      }
    }
  };

  return (
    <Modal
      className="right-modal overflow-hidden"
      show={show}
      onHide={handleClose}
    >
      <Modal.Header closeButton className="border-bottom pb-3">
        <Modal.Title className="m-0 font-weight-bold">
          Assign Driver
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="pr-0 pb-5">
        <div className="position-relative mb-4" style={{ width: "50%" }}>
          <input
            type="text"
            className="form-control"
            placeholder="Search Driver"
            ref={searchValue}
            onChange={(e) => setSearch(e.target.value)}
          />
          {searchValue?.current?.value?.length > 0 && (
            <div
              onClick={() => {
                searchValue.current.value = "";
                setSearch("");
              }}
              className="show_false btn"
            >
              <CloseCircleOutlined />
            </div>
          )}
        </div>
        <div
          ref={infiniteScrollRef}
          onScroll={onScroll}
          className="overflow-auto pr-4 "
          style={{ height: "78vh" }}
        >
          {isSuccess &&
            status === "fulfilled" &&
            data.data.users.map((driver, i) => (
              <DriversCards
                key={i}
                driver={driver}
                handleClose={handleClose}
                id={i}
                active={active}
                setActive={setActive}
                activeRide={activeRide}
                setSearch={setSearch}
                pickDriver={pickDriver}
                setPickDriver={setPickDriver}
              />
            ))}
          {status === "pending" &&
            [...Array(PageSize)].map((d, i) => (
              <DriversCardsSkeleton key={i} />
            ))}
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default AssignDriverModal;
