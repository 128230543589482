import React from "react";
import { Card, Row, Col } from "react-bootstrap";
function CustomerDetailSkeleton() {
  return (
    <Card className="rounded-0 rounded-top border-0 mb-0">
      <Card.Body className="border-bottom">
        <Row>
          <Col col="12" lg="2">
            <div className="thumbnail-round-lg m-md-auto skeleton"></div>
          </Col>

          <Col col="12" lg="10" className="mt-1">
            <Row>
              <Col col="12" xl="6">
                <div className="ml-md-3">
                  <h2 className="font-weight-bold my-1 text-truncate">
                    <span className="skeleton"> Carmen Freeman</span>
                  </h2>
                  <Row>
                    <Col col="12" lg="6">
                      <div className="mb-3">
                        <div className="d-flex flex-column flex-md-row pb-1 align-items-md-center">
                          <h6 className="text-gray mr-3">
                            <b className="text-primary skeleton">
                              {" "}
                              Customer ID -123125
                            </b>{" "}
                          </h6>
                          <div className="text-active">
                            <b className="skeleton">Active</b>
                          </div>
                        </div>
                        <h6 className="text-gray">
                          <span className="skeleton">
                            Date of join - 02/15/2019
                          </span>
                        </h6>
                      </div>
                    </Col>
                    <Col col="12" lg="6">
                      <div className="mb-3">
                        <h6 className="pb-2">
                          <span className="skeleton">Phone +1 99856 25642</span>
                        </h6>
                        <h6>
                          <span className="skeleton">
                            Email shawntom@example.com{" "}
                          </span>
                        </h6>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>

              <Col col="12" md="6" xl="3">
                <Row className="align-items-center">
                  <Col xs="3" md="4">
                    <div className="text-center d-flex align-items-center justify-content-center py-2 skeleton thumbnail-round "></div>
                  </Col>
                  <Col xs="9" md="8" className="pl-0">
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="">
                        <h2 className="font-weight-bold m-0 mb-1">
                          {" "}
                          <span className="skeleton">Pu</span>
                        </h2>
                        <h5 className="font-weight-bold text-uppercase m-0">
                          <span className="skeleton">Purchase</span>
                        </h5>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>

              <Col col="12" md="6" xl="3">
                <Row className="align-items-center">
                  <Col xs="3" md="4">
                    <div className="text-center d-flex align-items-center justify-content-center py-2 skeleton thumbnail-round "></div>
                  </Col>
                  <Col xs="9" md="8" className="pl-0">
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="">
                        <h2 className="font-weight-bold m-0 mb-1">
                          {" "}
                          <span className="skeleton ">e</span>
                        </h2>
                        <h5 className="font-weight-bold text-uppercase m-0">
                          <span className="skeleton">Purchase</span>
                        </h5>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

export default CustomerDetailSkeleton;
