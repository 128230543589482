import React from "react";

function Skeleton() {
  return (
    <tr>
      <td>
        <span className="skeleton">1</span>
      </td>
      <td>
        <a href="#" className="text-primary">
          <span className="skeleton">OR100004</span>
        </a>
      </td>
      <td>
        <div className="d-flex align-items-center">
          <div className="thumbnail-box mr-3 skeleton"></div>
          <h5 className="m-0">
            <span className="skeleton">1 dfjnsfkfl dskfdsb askfjbas</span>{" "}
            <br />
            <span className="text-gray skeleton">Tonino Lamborghini</span>
          </h5>
        </div>
      </td>

      <td>
        <h5 className="m-0">
          <span className="skeleton">1</span>
        </h5>
      </td>
      <td>
        <h5 className="m-0 skeleton">
          <span className="skeleton">$ 350.00</span>
        </h5>
      </td>
      <td>
        <h5 className="m-0">
          <span className="skeleton">bosda fsfa</span>
        </h5>
      </td>
      <td>
        <h5 className="m-0">
          <span className="skeleton">February 10th 2022</span>
          <span className="text-gray skeleton">, 4:35:14 pm</span>
        </h5>
      </td>
      <td className="ordered ">
        <div className="skeleton">1</div>
      </td>
    </tr>
  );
}

export default Skeleton;
