import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getToken } from "cookies/token";
import { authCheck } from "./functions/authCheck";
const header = (headers, { getState }) => {
  const token = getToken();
  if (token) {
    headers.set("authorization", `Bearer ${token}`);
  }
  return headers;
};

export const vehicleApi = createApi({
  reducerPath: "VehiclesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: header,
  }),
  tagTypes: ["Vehicle"],
  endpoints: (builder) => ({
    getVehicleById: builder.query({
      query: (...data) => {
        const populate = encodeURIComponent(JSON.stringify(data[0].populate));
        return {
          url: `/vehicle/${data[0].id}?populate=${populate}`,
          method: "GET",
          validateStatus: (response, result) => authCheck(response, result),
        };
      },
      providesTags: ["Vehicle"],
    }),
    getAllVehicles: builder.query({
      query: (...data) => {
        const populate = encodeURIComponent(JSON.stringify(data[0].populate));
        const filter = encodeURIComponent(JSON.stringify(data[0].filter));
        const sort = encodeURIComponent(
          JSON.stringify([[data[0].sort.sortBy, data[0].sort.sortDirection]])
        );
        return {
          url: `/vehicle/admin?offset=${
            data[0].PageSize * data[0].page
          }&limit=${
            data[0].PageSize
          }&populate=${populate}&sort=${sort}&where=${filter}`,
          method: "GET",
          validateStatus: (response, result) => authCheck(response, result),
        };
      },
      providesTags: ["Vehicle"],
    }),
    addVehicle: builder.mutation({
      query: (newVehicle) => {
        return {
          url: `/vehicle`,
          method: "POST",
          body: newVehicle,
          validateStatus: (response, result) => authCheck(response, result),
        };
      },
      invalidatesTags: ["Vehicle"],
    }),
    updateVehicle: builder.mutation({
      query: ({ id, upData }) => {
        return {
          url: `/vehicle/${id}`,
          method: "PUT",
          body: upData,
          validateStatus: (response, result) => authCheck(response, result),
        };
      },
      invalidatesTags: ["Vehicle"],
    }),
    deleteVehicle: builder.mutation({
      query: (id) => {
        return {
          url: `/vehicle/${id}`,
          method: "DELETE",
          validateStatus: (response, result) => authCheck(response, result),
        };
      },
      invalidatesTags: ["Vehicle"],
    }),
    addAvailability: builder.mutation({
      query: (data) => {
        return {
          url: `/vehicle_availability/batch`,
          method: "POST",
          body: data,
          validateStatus: (response, result) => authCheck(response, result),
        };
      },
      invalidatesTags: ["Vehicle"],
    }),
    updateAvailability: builder.mutation({
      query: (data) => {
        return {
          url: `/vehicle_availability/batch`,
          method: "POST",
          body: data,
          validateStatus: (response, result) => authCheck(response, result),
        };
      },
      invalidatesTags: ["Vehicle"],
    }),
    getRideByVehicle: builder.query({
      query: (...data) => {
        return {
          url: `/ride?offset=${data[0].PageSize * data[0].page}&limit=${
            data[0].PageSize
          }&where=%7B%22vehicle_id%22%3A${
            data[0].id
          }%2C%22type%22%3A%22Normal%22%7D&populate=%5B%22%2Bcustomer%22%2C%22%2Bdriver%22%2C%22%2Blocation%22%2C%22ride_status%22%5D&sort=%5B%5B%22${
            data[0].sort.sortBy
          }%22%2C%22${data[0].sort.sortDirection}%22%5D%5D
          `,
          method: "GET",
          validateStatus: (response, result) => authCheck(response, result),
        };
      },
    }),
    getTimeSlots: builder.query({
      query: (data) => {
        let vehicle_id = parseInt(data.vehicle_id);
        let upData = { ...data, vehicle_id };
        return {
          url: `/vehicle_availability/free_slots`,
          method: "POST",
          body: upData,
          validateStatus: (response, result) => authCheck(response, result),
        };
      },
    }),
    getVehicleMilage: builder.query({
      query: (...data) => {
        const populate = encodeURIComponent(
          JSON.stringify(data[0].sortPopulate)
        );
        const filter = encodeURIComponent(JSON.stringify(data[0].filter));
        const sort = encodeURIComponent(
          JSON.stringify([
            [data[0].milageSort.sortBy, data[0].milageSort.sortDirection],
          ])
        );
        return {
          url: `/vehicle_mileage/vehicle?offset=${
            data[0].PageSize * data[0].page
          }&limit=${
            data[0].PageSize
          }&populate=${populate}&sort=${sort}&where=${filter}`,
          method: "GET",
          validateStatus: (response, result) => authCheck(response, result),
        };
      },
    }),
  }),
});

export const {
  useGetVehicleByIdQuery,
  useGetAllVehiclesQuery,
  useAddVehicleMutation,
  useUpdateVehicleMutation,
  useDeleteVehicleMutation,
  useAddAvailabilityMutation,
  useUpdateAvailabilityMutation,
  useGetRideByVehicleQuery,
  useGetTimeSlotsQuery,
  useGetVehicleMilageQuery,
} = vehicleApi;
