import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getToken } from "cookies/token";
import { authCheck } from "./functions/authCheck";

const header = (headers, { getState }) => {
  const token = getToken();
  if (token) {
    headers.set("authorization", `Bearer ${token}`);
  }
  return headers;
};

export const notificationApi = createApi({
  reducerPath: "NotificationApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: header,
  }),
  tagTypes: ["Notification"],
  endpoints: (builder) => ({
    getAllNotification: builder.query({
      query: (...data) => {
        const sort = encodeURIComponent(
          JSON.stringify([["created_at", "desc"]])
        );
        return {
          url: `/user_notification?offset=${
            data[0].PageSize * data[0].page
          }&limit=${data[0].PageSize}&sort=${sort}`,
          method: "GET",
          validateStatus: (response, result) => authCheck(response, result),
        };
      },
      providesTags: ["Notification"],
    }),
    getNotificationCount: builder.query({
      query: (...data) => {
        return {
          url: `/user_notification/count`,
          method: "GET",
          validateStatus: (response, result) => authCheck(response, result),
        };
      },
      invalidatesTags: ["Notification"],
    }),
  }),
});
export const { useGetAllNotificationQuery, useGetNotificationCountQuery } =
  notificationApi;
