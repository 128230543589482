import React from "react";

function Skeleton() {
  return (
    <tr>
      <td>
        <span className="skeleton">1</span>
      </td>
      <td>
        <span className="text-gray ps-3 skeleton">skeleton skeleton----</span>
      </td>
      <td>
        <span className="skeleton ">1233456654</span>
      </td>
      <td>
        <span className="skeleton ">1233456654</span>
      </td>
      <td>
        <span className="skeleton ">1233456654</span>
      </td>
      <td>
        <span className="skeleton ">1233456654</span>
      </td>
      <td>
        <span className="skeleton ">1233456654</span>
      </td>
      <td>
        <span className="skeleton ">1233456654</span>
      </td>
      <td>
        <span className="skeleton ">1233456654</span>
      </td>
    </tr>
  );
}

export default Skeleton;
