import React from "react";
import { Col } from "react-bootstrap";
import { Popover, Popconfirm, message } from "antd";
import { useDeletePickupLocationMutation } from "services/pickupLocations";

import {
  GoogleMap,
  LoadScriptNext,
  Marker,
  DirectionsRenderer,
  Polyline,
} from "@react-google-maps/api";
const containerStyle = {
  width: "100%",
  height: "30vh",
};
async function confirm({ deletePickupLocation, location }) {
  const DeleteLocation = await deletePickupLocation({
    id: location.id,
  });
  if (DeleteLocation?.data?.message === "Deleted") {
    message.success("Pickup location deleted successfully");
  } else {
    message.error(DeleteLocation?.data?.message || "Something went wrong");
  }
}

const PopoverContent = ({ location, EditTrigger, deletePickupLocation }) => {
  return (
    <div className="d-flex flex-column">
      <button className="btn text-left" onClick={() => EditTrigger(location)}>
        Edit Location
      </button>
      <Popconfirm
        placement="leftTop"
        title={"Are you sure to delete this location?"}
        onConfirm={() => confirm({ deletePickupLocation, location })}
        okText="Remove"
        cancelText="No"
      >
        <button className="btn text-primary text-left">Delete</button>
      </Popconfirm>
    </div>
  );
};
function PickupCard({ location, EditTrigger }) {
  const [deletePickupLocation] = useDeletePickupLocationMutation();

  return (
    <Col md="6" className="mt-4">
      <div className=" bg-white border rounded">
        <LoadScriptNext googleMapsApiKey="AIzaSyDIj0QBVIMnAxlKJf4WbGcISWXPvchSj-Y">
          {location?.latitude && location?.longitude && (
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={{
                lat: parseFloat(location?.latitude),
                lng: parseFloat(location?.longitude),
              }}
              zoom={15}
            >
              <Marker
                icon={require("assets/img/mark.png").default}
                position={{
                  lat: parseFloat(location?.latitude),
                  lng: parseFloat(location?.longitude),
                }}
              />
            </GoogleMap>
          )}
        </LoadScriptNext>{" "}
        <div className="p-3 d-flex justify-content-between align-items-center py-2">
          <div>
            <h5 className="m-0 word_break">{location?.title}</h5>
            <p className="m-0">{location?.location}</p>
          </div>
          <Popover
            placement="leftTop"
            title={null}
            content={PopoverContent({
              location,
              EditTrigger,
              deletePickupLocation,
            })}
            trigger="click"
          >
            <span className="btn">
              <i className="fas fa-ellipsis-v text-gray"></i>{" "}
            </span>
          </Popover>
        </div>
      </div>
    </Col>
  );
}

export default PickupCard;
