import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import {
  useGetContactEmailQuery,
  useGetUserQuery,
  useUpdatePersonalInfoMutation,
} from "services/settings";
import { Tooltip, message } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import ImgCrop from "antd-img-crop";
import { Upload } from "antd";
import { uploadImagesToS3 } from "./../../../../bucket/s3";
import ChangePasswordModal from "./ChangePasswordModal";
import ChangeEmailModal from "./ChangeEmailModal";
function AccountSettings() {
  const contactMail = useGetContactEmailQuery(
    {},
    {
      refetchOnMountOrArgChange: false,
    }
  );
  const [updatePersonalInfo] = useUpdatePersonalInfoMutation();
  const [fileList, setFileList] = useState();
  const [uploading, setUploading] = useState(false);

  const { isLoading, isError, isSuccess, data, status, isFetching } =
    useGetUserQuery(
      {},
      {
        refetchOnMountOrArgChange: false,
      }
    );
  const user = data?.data?.user;
  const [image, setImage] = useState(
    user?.image || "https://joeschmoe.io/api/v1/jon"
  );
  useEffect(() => {
    setImage(user?.image || "https://joeschmoe.io/api/v1/jon");
  }, [user]);

  const props = {
    beforeUpload: (file) => {
      const isImage = file.type === "image/png" || file.type === "image/jpeg" || file.type === "image/webp" || file.type === "image/gif";
      if (!isImage) {
        message.error(`${file.name} is not a image format`);
      } else {
        setImage(URL.createObjectURL(file));
      }
      return isImage || Upload.LIST_IGNORE;
    },
    async customRequest({
      action,
      data,
      file,
      filename,
      headers,
      onError,
      onProgress,
      onSuccess,
      withCredentials,
      fileList,
    }) {
      setUploading(true);

      const upload = await uploadImagesToS3([file]);
      if (upload?.length > 0) {
        const changeImage = await updatePersonalInfo({
          image: upload[0].location,
        });
        if (changeImage?.data?.message === "Updated") {
          setUploading(false);
          message.success("Profile photo uploaded successfully");
        } else {
          setUploading(false);
          message.error("Something went wrong");
        }
      } else {
        onError();
      }
    },
  };
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const closeEmailModal = () => {
    setShowEmailModal(false);
  };
  const openEmailModal = () => {
    setShowEmailModal(true);
  };

  const showPasswordModalHandler = () => {
    setShowPasswordModal(true);
  };
  const hidePasswordModalHandler = () => {
    setShowPasswordModal(false);
  };
  if (!isFetching) {
    return (
      <>
        <ChangePasswordModal
          showPasswordModal={showPasswordModal}
          hidePasswordModalHandler={hidePasswordModalHandler}
        />
        <ChangeEmailModal
          showEmailModal={showEmailModal}
          closeEmailModal={closeEmailModal}
          email={contactMail?.data?.data?.setting?.value}
        />

        <Row className="align-items-center">
          <Col col="12" lg="8" xl="7" className="m-auto">
            <div className="bg-white p-4 border">
              <Row className="py-4 border-bottom align-items-center">
                <Col lg="8">
                  <div className="d-flex align-items-center ">
                    <div className="circle_load">
                      <img
                        className={
                          uploading
                            ? "avatar avatar-150 border-gray blur_image"
                            : "avatar avatar-150 border-gray "
                        }
                        src={image}
                        alt="avatar"
                      />
                      {uploading && (
                        <svg
                          viewBox="0 0 100 100"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="50" cy="50" r="40" />
                        </svg>
                      )}
                    </div>
                    <div>
                      <h3 className="m-0 p-0 font-weight-bold">
                        <b>{user?.full_name}</b>
                      </h3>
                      <h6 className="m-0">{user?.email}</h6>
                    </div>
                  </div>
                </Col>
                <Col lg="4">
                  <ImgCrop rotate modalOk="Set as profile photo">
                    <Upload
                      action={null}
                      maxCount={1}
                      className="upload-list-inline w-100"
                      {...props}
                    >
                      <button
                        type="button"
                        className="btn btn-black btn-lg w-100"
                        disabled={uploading}
                      >
                        Change Photo
                      </button>
                    </Upload>
                  </ImgCrop>
                </Col>
              </Row>
              <Row className="py-4 border-bottom align-items-center">
                <Col lg="8">
                  <h6 className="m-0 p-0 font-weight-bold">
                    <b>Contact Email Address</b>
                  </h6>
                  <h6 className="m-0 d-flex">
                    {contactMail?.data?.data?.setting?.value}
                    {contactMail?.isFetching && (
                      <span className="skeleton">revupnyc@mailinator.com</span>
                    )}
                    <Tooltip
                      placement="right"
                      title={"All email will be sending to this mail address"}
                      color="#949494"
                    >
                      <InfoCircleOutlined className="ml-1"
                        style={{
                          fontSize: "15px",
                          cursor: "pointer",
                        }}
                      />
                    </Tooltip>
                  </h6>
                </Col>
                <Col lg="4">
                  <button type="button" className="btn btn-black  btn-lg w-100"
                    onClick={openEmailModal}
                  >
                    Change Email
                  </button>
                </Col>
              </Row>
              <Row className="py-4  align-items-center">
                <Col lg="8">
                  <h6 className="m-0 p-0 font-weight-bold">
                    <b>Password</b>
                  </h6>
                  <h6 className="m-0">********</h6>
                </Col>
                <Col lg="4">
                  <button
                    type="button"
                    className="btn btn-black  btn-lg w-100"
                    onClick={() => showPasswordModalHandler()}
                  >
                    Change Password
                  </button>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </>
    );
  }
  return (
    <Row className="align-items-center">
      <Col col="12" lg="8" xl="7" className="m-auto">
        <div className="bg-white p-4 border">
          <Row className="py-4 border-bottom align-items-center">
            <Col lg="8">
              <div className="d-flex align-items-center ">
                <div className="thumbnail-round-lg mr-3 skeleton"></div>
                <div>
                  <h3 className="m-0 p-0 font-weight-bold">
                    <b className="skeleton">Revup Admin</b>
                  </h3>
                  <h6 className="m-0">
                    {" "}
                    <span className="skeleton">admin@examplemail.com</span>
                  </h6>
                </div>
              </div>
            </Col>
            <Col lg="4">
              <button
                type="button"
                className="btn  btn-lg w-100 skeleton border-0"
              >
                Change Photo
              </button>
            </Col>
          </Row>
          <Row className="py-4 border-bottom align-items-center">
            <Col lg="8">
              <h6 className="m-0 p-0 font-weight-bold">
                <b className="skeleton">Email Address</b>
              </h6>
              <h6 className="m-0">
                <span className="skeleton">admin@examplemail.com</span>
              </h6>
            </Col>
            <Col lg="4">
              <button
                type="button"
                className="btn  btn-lg w-100 skeleton border-0"
              >
                Change Email
              </button>
            </Col>
          </Row>
          <Row className="py-4 border-bottom align-items-center">
            <Col lg="8">
              <h6 className="m-0 p-0 font-weight-bold">
                <b className="skeleton">Password</b>
              </h6>
              <h6 className="m-0">
                <span className="skeleton">********</span>
              </h6>
            </Col>
            <Col lg="4">
              <button
                type="button"
                className="btn  btn-lg w-100 skeleton border-0"
              >
                Change Password
              </button>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
}

export default AccountSettings;
