import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getToken } from "cookies/token";
import { authCheck } from "./functions/authCheck";

const header = (headers, { getState }) => {
  const token = getToken();
  if (token) {
    headers.set("authorization", `Bearer ${token}`);
  }
  return headers;
};

export const couponApi = createApi({
  reducerPath: "CouponApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: header,
  }),
  tagTypes: ["Coupon"],
  endpoints: (builder) => ({
    getCouponById: builder.query({
      query: ({ id }) => {
        return {
          url: `/coupon_code/${id}`,
          method: "GET",
          validateStatus: (response, result) => authCheck(response, result),
        };
      },
    }),
    getAllCoupon: builder.query({
      query: (...data) => {
        const populate = encodeURIComponent(JSON.stringify(data[0].populate));
        const filter = encodeURIComponent(JSON.stringify(data[0].filter));
        const sort = encodeURIComponent(
          JSON.stringify([[data[0].sort.sortBy, data[0].sort.sortDirection]])
        );
        return {
          url: `/coupon_code?offset=${data[0].PageSize * data[0].page}&limit=${
            data[0].PageSize
          }&populate=${data[0].populate}&sort=${sort}&where=${filter}`,
          method: "GET",
          validateStatus: (response, result) => authCheck(response, result),
        };
      },
      providesTags: ["Coupon"],
    }),
    addCoupon: builder.mutation({
      query: ({ data, id }) => {
        return {
          url: `/coupon_code`,
          method: "POST",
          body: data,
          validateStatus: (response, result) => authCheck(response, result),
        };
      },
      invalidatesTags: ["Coupon"],
    }),
    updateCoupon: builder.mutation({
      query: ({ data, id }) => {
        return {
          url: `/coupon_code/${id}`,
          method: "PUT",
          body: data,
          validateStatus: (response, result) => authCheck(response, result),
        };
      },
      invalidatesTags: ["Coupon"],
    }),
    deleteCouponById: builder.mutation({
      query: (id) => {
        return {
          url: `/coupon_code/${id}`,
          method: "DELETE",
          validateStatus: (response, result) => authCheck(response, result),
        };
      },
      invalidatesTags: ["Coupon"],
    }),
  }),
});

export const {
  useGetCouponByIdQuery,
  useGetAllCouponQuery,
  useAddCouponMutation,
  useUpdateCouponMutation,
  useDeleteCouponByIdMutation,
} = couponApi;
