import React, { useEffect, useState } from "react";
// react-bootstrap components
import { Card, Table, Container, Row, Col } from "react-bootstrap";
import ListCards from "components/Drivers/ListCards";
import Skeleton from "components/Drivers/Skeleton";
import { useParams } from "react-router";
import { useGetDriverByIdQuery } from "services/drivers";
import DriverDetailSkeleton from "components/Drivers/Detail/Skeleton";
import {
  FileOutlined,
  FileExcelOutlined,
  LoadingOutlined,
  WarningFilled,
} from "@ant-design/icons";
import PasswordModal from "components/Common/PasswordModal";
import moment from "moment";
import { Modal, Empty, Pagination, message } from "antd";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import TableTitle from "components/Orders/List/TableTitle";
import { useUpdateDriverByIdMutation } from "services/drivers";
function DriversDetail() {
  let { id } = useParams();
  const [sort, setSort] = useState({
    sortBy: `driver_ride","created_at`,
    sortDirection: "desc",
  });
  const { isLoading, isError, isSuccess, data, status } = useGetDriverByIdQuery(
    { id, sort },
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const showPasswordModalHandler = () => {
    setShowPasswordModal(true);
  };
  const hidePasswordModalHandler = () => {
    setShowPasswordModal(false);
  };
  const driver = data?.data?.user || {};
  const [visible, setVisible] = useState(false);
  const [visibleInsurance, setVisibleInsurance] = useState(false);

  const license = [
    { uri: driver?.license_front },
    { uri: driver?.license_back },
  ];
  const insurance = [{ uri: driver?.insurance }];
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);
  const [rideList, setRideList] = useState([]);

  const handlePagination = (page, pageSize) => {
    setCurrentPage(page);
    setPostsPerPage(pageSize);
  };
  useEffect(() => {
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    setRideList(driver?.driver_ride?.slice(indexOfFirstPost, indexOfLastPost));
  }, [driver?.driver_ride, currentPage]);
  const [updateDriverById] = useUpdateDriverByIdMutation();
  const [loading, setLoading] = useState(false);
  // const [updateRideBooking] = useUpdateRideBookingMutation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = async (user) => {
    setLoading(true);
    let upData = {
      id: user.id,
      active: !user.active,
    };
    const statusChange = await updateDriverById(upData);
    if (statusChange?.data?.message === "Updated") {
      message.success(
        `Driver ${upData.active ? "Unblocked" : "Blocked"} Successfully`
      );
      setLoading(false);
      setIsModalVisible(false);
    } else {
      statusChange?.data?.message
        ? message.error(statusChange?.data?.message)
        : message.error("Something went wrong");
      setLoading(false);
    }
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <PasswordModal
        showPasswordModal={showPasswordModal}
        hidePasswordModalHandler={hidePasswordModalHandler}
        type={"driver"}
        userId={id}
      />
      <Modal
        visible={isModalVisible}
        footer={null}
        centered
        onCancel={handleCancel}
      >
        <aside className=" p-4 d-flex justify-content-center flex-column">
          <WarningFilled
            style={{
              fontSize: "50px",
              color: "#ff0000",
            }}
          />
          <p className="text-center mt-3 font-weight-bold ">
            Are you sure to {driver?.active ? "Block" : "Unblock"} this user?
          </p>
          <div className="d-flex justify-content-center">
            <button
              className="btn btn-outline-dark px-5  mr-2"
              onClick={handleCancel}
            >
              No
            </button>
            <button
              className="btn btn-outline-danger ml-2  "
              onClick={() => handleOk(driver)}
            >
              Yes {driver?.active ? "Block" : "Unblock"}
              {loading && (
                <LoadingOutlined
                  style={{
                    marginLeft: "10px",
                    marginTop: "-2px",
                  }}
                />
              )}
            </button>
          </div>
        </aside>
      </Modal>
      <Modal
        title={driver?.full_name + " - Driving License"}
        centered
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        width={800}
        footer={null}
      >
        <DocViewer
          pluginRenderers={DocViewerRenderers}
          documents={license}
          style={{ height: 500 }}
        />
      </Modal>
      {visibleInsurance && (
        <Modal
          title={driver?.full_name + " - Insurance"}
          centered
          visible={visibleInsurance}
          onOk={() => setVisibleInsurance(false)}
          onCancel={() => setVisibleInsurance(false)}
          width={800}
          footer={null}
        >
          <DocViewer
            pluginRenderers={DocViewerRenderers}
            documents={insurance}
            style={{ height: 500 }}
          />
        </Modal>
      )}
      {isLoading && <DriverDetailSkeleton />}
      {isSuccess && (
        <Container fluid>
          <Card className="rounded-0 rounded-top border-0 mb-0">
            <Card.Body className="border-bottom py-0">
              <Row className="align-items-center border-bottom py-3">
                <Col col="12" lg="6">
                  <Row className="align-items-center">
                    <Col col="12" lg="3">
                      <div className="thumbnail-round-lg m-md-auto">
                        <img
                          alt="..."
                          src={
                            driver?.image ||
                            `https://joeschmoe.io/api/v1/${driver?.first_name}`
                          }
                        ></img>
                      </div>
                    </Col>
                    <Col col="12" lg="9">
                      <h2 className="font-weight-bold my-1 text-truncate">
                        <span className=""> {driver?.full_name}</span>
                      </h2>
                      <div className="mb-3">
                        <div className="d-flex flex-column flex-md-row pb-1 align-items-md-center">
                          <h6 className="text-gray mr-3">
                            Driver ID -{" "}
                            <span className="text-primary">
                              {driver?.user_code}
                            </span>{" "}
                          </h6>{" "}
                          {driver?.active ? (
                            <td className="text-active">
                              <span></span>
                              Active
                            </td>
                          ) : (
                            <td className=" text-active text-danger">
                              <span></span>
                              Inactive
                            </td>
                          )}
                        </div>
                        <h6 className="text-gray">
                          Date of join -{" "}
                          {moment(driver?.created_at).format("MM/DD/YY")}
                        </h6>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col col="12" lg="6">
                  <Row className="align-items-center">
                    <Col col="12" lg="6" className="mb-3 mb-md-0">
                      <div>
                        <h6 className="pb-2">
                          <span className="text-gray">Phone </span>
                          {driver?.phone_code} {driver?.phone}
                        </h6>
                        <h6>
                          <span className="text-gray">Email </span>
                          {driver?.email}
                        </h6>
                      </div>
                    </Col>
                    <Col col="12" lg="6" className="text-right">
                      <button
                        type="button"
                        className="btn btn-outline-primary mx-2 px-4"
                        onClick={showModal}
                      >
                        {driver?.active ? "Block" : "Unblock"}
                      </button>
                      {!driver?.active && (
                        <button
                          type="button"
                          className="btn btn-outline-danger mx-2 px-4"
                          onClick={showPasswordModalHandler}
                        >
                          Delete
                        </button>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="align-items-center">
                <Col col="12" md="6" xl="3" className="my-2 my-md-0">
                  <Row className="align-items-center">
                    <Col xs="3" md="4">
                      <div className="text-center d-flex align-items-center justify-content-center py-2">
                        <img
                          className="img-fluid"
                          alt="..."
                          src={require("assets/img/rides.svg").default}
                        ></img>
                      </div>
                    </Col>
                    <Col xs="9" md="8" className="pl-0">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="">
                          <h2 className="font-weight-bold m-0">
                            {driver?.total_rides || 0}
                          </h2>
                          <h5 className="font-weight-bold text-uppercase m-0">
                            TOTAL RIDES
                          </h5>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col
                  col="12"
                  md="6"
                  xl="3"
                  className="border-right my-2 my-md-0"
                >
                  <Row className="align-items-center">
                    <Col xs="3" md="4">
                      <div className="text-center d-flex align-items-center justify-content-center py-2">
                        <img
                          className="img-fluid"
                          alt="..."
                          src={require("assets/img/dollar.jpg").default}
                        ></img>
                      </div>
                    </Col>
                    <Col xs="9" md="8" className="pl-0">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="">
                          <h2 className="font-weight-bold m-0">
                            ${driver?.total_tips || 0}
                          </h2>
                          <h5 className="font-weight-bold text-uppercase m-0">
                            Tips Earned
                          </h5>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col
                  col="12"
                  md="6"
                  xl="3"
                  className={
                    driver?.license_front
                      ? "my-2 my-md-0 "
                      : "my-2 my-md-0 opacity"
                  }
                  disabled={driver?.license_front}
                  onClick={() => driver?.license_front && setVisible(true)}
                >
                  <Row className="align-items-center btn w-100 p-0 m-0 d-flex">
                    <Col xs="3" md="3">
                      <div className="text-center d-flex align-items-center justify-content-end py-2">
                        {driver?.license_front ? (
                          <FileOutlined
                            style={{ fontSize: "2.5rem", color: "gray" }}
                          />
                        ) : (
                          <FileExcelOutlined
                            style={{ fontSize: "2.5rem", color: "gray" }}
                          />
                        )}
                      </div>
                    </Col>
                    <Col xs="9" md="8" className="pl-0">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="pl-2">
                          <h5 className="font-weight-bold m-0 pb-2">
                            Driving License
                          </h5>
                          <h5 className="font-weight-bold m-0 text-primary">
                            <u>View Document</u>
                          </h5>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col
                  col="12"
                  md="6"
                  xl="3"
                  className={
                    driver?.insurance ? "my-2 my-md-0 " : "my-2 my-md-0 opacity"
                  }
                  disabled={driver?.insurance}
                  onClick={() => driver?.insurance && setVisibleInsurance(true)}
                >
                  <Row className="align-items-center btn w-100 p-0 m-0 d-flex">
                    <Col xs="3" md="3">
                      <div className="text-center d-flex align-items-center justify-content-end py-2">
                        {driver?.insurance ? (
                          <FileOutlined
                            style={{ fontSize: "2.5rem", color: "gray" }}
                          />
                        ) : (
                          <FileExcelOutlined
                            style={{ fontSize: "2.5rem", color: "gray" }}
                          />
                        )}
                      </div>
                    </Col>
                    <Col xs="9" md="8" className="pl-0">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="pl-2">
                          <h5 className="font-weight-bold m-0 pb-2 text-left">
                            Insurance
                          </h5>
                          <h5 className="font-weight-bold m-0 text-primary">
                            <u>View Document</u>
                          </h5>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card.Body>
          </Card>

          <div className="bg-white mt-2">
            <div className="table-full-width table-responsive">
              <Table className="table-hover revup-table m-0">
                <thead>
                  <tr>
                    <th className="border-0">#</th>
                    <TableTitle
                      title="Order ID"
                      setSort={setSort}
                      sortBy={`driver_ride","id`}
                      sort={sort}
                    />
                    <TableTitle
                      title="Customer"
                      setSort={setSort}
                      sortBy={`driver_ride","customer","full_name`}
                      sort={sort}
                    />
                    <TableTitle
                      title="Start Date"
                      setSort={setSort}
                      sortBy={`driver_ride","ride_start`}
                      sort={sort}
                    />
                    <TableTitle
                      title="End Date"
                      setSort={setSort}
                      sortBy={`driver_ride","ride_end`}
                      sort={sort}
                    />
                    <TableTitle
                      title="Duration"
                      setSort={setSort}
                      sortBy={`driver_ride","duration`}
                      sort={sort}
                    />
                    <TableTitle
                      title="Pickup Location"
                      setSort={setSort}
                      sortBy={`driver_ride","location","title`}
                      sort={sort}
                    />
                    <TableTitle
                      title="Tips Earned"
                      setSort={setSort}
                      sortBy={`driver_ride","tip","amount`}
                      sort={sort}
                    />
                    <TableTitle
                      title="Rating"
                      setSort={setSort}
                      sortBy={`driver_ride","review","rating`}
                      sort={sort}
                    />
                    <TableTitle
                      title="Ride Status"
                      setSort={setSort}
                      sortBy={`driver_ride","ride_status","name`}
                      sort={sort}
                    />
                  </tr>
                </thead>
                <tbody>
                  {isLoading &&
                    [...Array(10)].map((d, i) => <Skeleton key={i} />)}
                  {status === "pending" &&
                    [...Array(driver?.driver_ride?.length || 10)].map(
                      (d, i) => <Skeleton key={i} />
                    )}
                  {isError && <p>Error </p>}
                  {isSuccess &&
                    status === "fulfilled" &&
                    rideList?.map((ride, i) => (
                      <ListCards
                        key={i}
                        ride={ride}
                        index={
                          currentPage > 1
                            ? i +
                              1 * postsPerPage * currentPage -
                              postsPerPage +
                              1
                            : i + 1
                        }
                      />
                    ))}
                  {driver?.driver_ride?.length === 0 && (
                    <tr>
                      <td colspan="10" className="empty_items">
                        <Empty
                          description={false}
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                        >
                          <div className="ant-empty-description">
                            <p>No Ride Found</p>
                          </div>
                        </Empty>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </div>
          <Row className="mt-3">
            <Col col="12" md="12" lg="12">
              <Pagination
                current={currentPage}
                defaultCurrent={1}
                defaultPageSize={postsPerPage}
                onChange={handlePagination}
                total={parseInt(driver?.driver_ride?.length)}
              />
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
}

export default DriversDetail;
