import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getToken } from "cookies/token";
import { authCheck } from "./functions/authCheck";

const header = (headers, { getState }) => {
  const token = getToken();
  if (token) {
    headers.set("authorization", `Bearer ${token}`);
  }
  return headers;
};
export const peakDayApi = createApi({
  reducerPath: "PeakDayApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: header,
  }),
  tagTypes: ["PeakDay"],
  endpoints: (builder) => ({
    getAllPeakDay: builder.query({
      query: (id) => {
        return {
          url: `/vehicle_peak_day?offset=0&limit=10&where=%7B%22vehicle_id%22%3A${id}%7D`,
          method: "GET",
          validateStatus: (response, result) => authCheck(response, result),
        };
      },
      providesTags: ["PeakDay"],
    }),
    addPeakDay: builder.mutation({
      query: (newPeakDay) => {
        return {
          url: `/vehicle_peak_day`,
          method: "POST",
          body: newPeakDay,
          validateStatus: (response, result) => authCheck(response, result),
        };
      },
      invalidatesTags: ["PeakDay"],
    }),
    updatePeakDay: builder.mutation({
      query: (updatePeakDay) => {
        return {
          url: `/vehicle_peak_day/${updatePeakDay.id}`,
          method: "PUT",
          body: updatePeakDay,
          validateStatus: (response, result) => authCheck(response, result),
        };
      },
      invalidatesTags: ["PeakDay"],
    }),
    deletePeakDay: builder.mutation({
      query: (id) => {
        return {
          url: `/vehicle_peak_day/${id}`,
          method: "DELETE",
          validateStatus: (response, result) => authCheck(response, result),
        };
      },
      invalidatesTags: ["PeakDay"],
    }),
  }),
});
export const {
  useGetAllPeakDayQuery,
  useAddPeakDayMutation,
  useUpdatePeakDayMutation,
  useDeletePeakDayMutation,
} = peakDayApi;
